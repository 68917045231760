import manager from './../utils/permission-manager';
import { DirectiveOptions } from 'vue';
import { is } from '@/common/utils';
import { AuthEnum } from '@/services/permission.service';
import { message } from 'ant-design-vue';

/**
 * 权限验证指令
 */
const permissionDirective: DirectiveOptions = {
  async inserted(el, binding, node) {
    let pnames: AuthEnum[] = [];
    if (is(binding.value, Array)) {
      pnames = [...binding.value];
    } else if (is(binding.value, String)) {
      pnames = [binding.value];
    }

    const {
      /** 无权限时组件隐藏 */
      hidden,
      /** 无权限时组件无点击效果  */
      silence,
    } = binding.modifiers;

    el.classList.add('app-permission-hidden');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    manager.send(pnames).subscribe((permissions: any) => {
      if (!permissions) {
        // 权限查询失败
        return;
      }
      if (
        // 有权限
        Object.values(permissions).some((item) => item)
      ) {
        el.classList.remove('app-permission-hidden');
      } else {
        // 无权限
        if (!hidden) {
          el.classList.remove('app-permission-hidden');
        }
        const { componentInstance } = node;
        if (componentInstance && silence) {
          componentInstance.$off('click');
          componentInstance.$on('click', async () => {
            message.warn('无权限!');
          });
        }
      }
    });
  },
};

export default permissionDirective;
