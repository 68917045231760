
import { DebounceTime } from '@/common/decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TyreSpecField extends Vue {
  tyreWidth: number | string = '';

  tyreType = '';

  tyreDiameter: number | string = '';

  tyreRatio: number | string = '';

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Prop()
  readonly!: boolean;

  @Model('change')
  value!: {
    tyreWidth: number;
    tyreDiameter: number;
    tyreType: string;
    tyreRatio: number;
  };

  @Watch('value')
  watchValue() {
    if (this.value) {
      const { tyreDiameter, tyreRatio, tyreWidth, tyreType } = this.value;
      this.tyreDiameter = tyreDiameter || '';
      this.tyreRatio = tyreRatio || '';
      this.tyreWidth = tyreWidth || '';
      this.tyreType = tyreType || '';
      return;
    }
    this.reset();
  }

  @Watch('tyreType')
  watchTyreType() {
    if (!this.value || this.value.tyreType === this.tyreType) {
      return;
    }
    this.onChange();
  }

  @Watch('tyreRatio')
  watchTyreRatio() {
    if (
      !this.value ||
      Number(this.value.tyreRatio) === Number(this.tyreRatio)
    ) {
      return;
    }
    this.onChange();
  }

  @Watch('tyreDiameter')
  watchTyreDiameter() {
    if (
      !this.value ||
      Number(this.value.tyreDiameter) === Number(this.tyreDiameter)
    ) {
      return;
    }
    this.onChange();
  }

  @Watch('tyreWidth')
  watchTyreWidth() {
    if (
      !this.value ||
      Number(this.value.tyreWidth) === Number(this.tyreWidth)
    ) {
      return;
    }
    this.onChange();
  }

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @DebounceTime(320)
  async onChange() {
    this.$emit('change', {
      tyreWidth: +this.tyreWidth,
      tyreRatio: +this.tyreRatio,
      tyreDiameter: +this.tyreDiameter,
      tyreType: this.tyreType,
    });
  }

  reset() {
    this.tyreWidth = '';
    this.tyreType = '';
    this.tyreDiameter = '';
    this.tyreRatio = '';
  }

  mounted() {
    this.watchValue();
    console.log('++++ 轮胎规格+++ mounted end');
  }
}
