/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { UserRes } from '@/common/interface/account';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export class AccountService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 用户列表
   * @param params
   * @param loadingEl
   * @returns
   */
  async list(
    params: {
      pageSize?: number;
      page: number;
      phone?: string;
      aid?: number;
      id?: number;
      userType?: number;
      userName?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    try {
      const res = await this.http.get(
        'w/users',
        {
          params,
          data: {
            loadingEl: loadingEl || document.body,
          },
        },
      ) as {
        list: UserRes[];
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
      };
      const { list, lastPage, page, pageSize, total } = res;
      return {
        lastPage,
        page,
        total,
        pageSize,
        list,
      };
    } catch (error) {
      throw error;
    }
  }

  /**
   * 新增账号（只能新增地推员类型）
   * @param param
   * @param loadingEl
   * @returns
   */
  async create(param: Pick<UserRes, 'userName' | 'customerName' | 'phone'> & {aid: number; password: string}, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.post('w/user', {
        ...param,
      }, {
        data: {
          loadingEl: loadingEl || document.body,

        },
      }) as {
        aid: number;
        createtime: number;
        customername: string;
        id: number;
        phone: string;
        pwd: string;
        status: number;
        username: string;
        usertype: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 编辑修改账号（支持非地推人员类型 转成地推员）
   * @param param
   * @param loadingEl
   * @returns
   */
  async modify(param: Pick<UserRes, 'userId' | 'userName' | 'status' | 'userType' | 'phone' | 'customerName' > & {aid: number; password?: string }, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.put(`w/user/${param.userId}`, {
        ...param,
      }, {
        data: {
          loadingEl: loadingEl || document.body,

        },
      }) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 变更账号状态
   * @param userId
   * @param status
   * @param loadingEl
   * @returns
   */
  async setUserStatus(userId: number, status: number, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.post(`w/user/${userId}`, {
        status,
      }, {
        data: {
          loadingEl: loadingEl || document.body,

        },
      }) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 获取地推员已管理地区列表
   * @param userId
   * @returns
   */
  async salesStaffRelativeRegionList(userId: number) {
    try {
      const res = await this.http.get(`w/user/${userId}/regions`) as Array<{areaName: string; regionId: number}>;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 地推员删除关联区域
   * @param userId
   * @param regionId
   * @returns
   */
  async removeRelativeRegion(userId: number, regionId: number) {
    try {
      const res = await this.http.delete(`w/user/${userId}/region/${regionId}`) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 地推员新增关联区域
   * @param userId
   * @param regionId
   * @returns
   */
  async addRelativeRegion(userId: number, regionId: number) {
    try {
      const res = await this.http.post(`w/user/${userId}/region`, {
        regionId,
      }) as {
        createtime: number;
        regionid: number;
        userid: number;
      };
      return res;
    } catch (error) {
      return undefined;
    }
  }
}
