
import { BASE_URL } from '@/common/constant';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TempSpuShopPoisField extends Vue {
  val = '';

  uploading = false;

  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  @lazyInject(UserService)
  protected userService!: UserService;

  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  beforeUpload(file: { type: string; size: number }) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async uploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      this.$message.success(`${data}`);
      this.val = data;
      this.uploading = false;
    }
  }

  mounted() {
    this.watchValue();
  }

  created() {
    this.token = this.userService.currentUser()?.token;
  }
}
