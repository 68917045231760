
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import { DebounceTime } from '@/common/decorator';

@Component({})
export default class AutoOffShelfTimeField extends Vue {
  val: Moment | null = null;

  get autoOffShelfTime() {
    if (this.val === null) {
      return '未设置';
    }
    return moment(this.val).format('YYYY-MM-DD HH:mm');
  }

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  placeholder!: string;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  @DebounceTime(300)
  watchInternalVal(): void {
    if (this.val !== null) {
      this.$emit('change', moment(this.val).format('YYYY-MM-DD HH:mm'));
    } else {
      this.$emit('change', '');
    }
  }

  @Watch('value')
  watchValue() {
    if (!this.value || this.value === '') {
      this.val = null;
      return;
    }
    this.val = moment(this.value);
  }

  mounted() {
    this.watchValue();
  }
}
