import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import ShopAll from '@/views/shop/ShopAll.vue';
import ShopList from '@/views/shop/ShopList.vue';
import ShopListExamine from '@/views/shop/ShopListExamine.vue';
import ShopInfoChange from '@/views/shop/ShopInfoChange.vue';
import ShopDetail from '@/views/shop/ShopDetail.vue';
import ShopProfile from '@/views/shop/ShopProfile.vue';
import Goods from '@/views/shop/Goods.vue';
import ShopPlatformSpus from '@/views/shop/ShopPlatformSpus.vue';
import ShopOperationLog from '@/views/shop/ShopOperationLog.vue';

import { AuthEnum } from '@/services/permission.service';

const shopRoutes: RouteConfig = {
  name: 'main.shop',
  path: '/shop',
  component: Main,
  redirect: {
    name: 'main.shop.list',
  },
  meta: {
    title: '店铺管理',
  },
  children: [
    {
      name: 'main.shop.all',
      path: '/shop/shopall',
      component: ShopAll,
      props: {
        auth: [AuthEnum.全部门店],
      },
      meta: { title: '全部门店列表' },
    },
    {
      name: 'main.shop.list',
      path: '/shop/shoplist',
      component: ShopList,
      props: {
        auth: [AuthEnum.门店列表],
      },
      meta: { title: '店铺列表' },
    },
    {
      name: 'main.shop.list.examine',
      path: '/shop/shoplist_examine',
      component: ShopListExamine,
      props: {
        auth: [AuthEnum.门店列表],
      },
      meta: { title: '店铺审核列表' },
    },
    {
      name: 'main.shop.detail',
      path: '/shop/detail/:id',
      component: ShopDetail,
      props: {},
      meta: { title: '店铺详情' },
    },
    {
      name: 'main.shop.goods',
      path: '/shop/detail/:shopId/goods',
      component: Goods,
      props: {
        auth: [AuthEnum.门店运营, AuthEnum.代运营门店编辑],
      },
      meta: { title: '商品管理' },
    },

    {
      name: 'main.shop.platformSpus',
      path: '/shop/detail/:shopId/platformSpus',
      component: ShopPlatformSpus,
      props: {
        auth: [AuthEnum.门店运营, AuthEnum.代运营门店编辑],
      },
      meta: { title: '商品报名' },
    },
    {
      name: 'main.shop.infochange',
      path: '/shop/:shopId/infochange',
      component: ShopInfoChange,
      props: {
        auth: [AuthEnum.门店运营],
      },
      meta: { title: '店铺信息/商品变更' },
    },
    {
      name: 'main.shop.profile',
      path: '/shop/profile/:id',
      component: ShopProfile,
      props: {
        auth: [AuthEnum.全部门店],
      },
      meta: { title: '门店信息' },
    },
    {
      name: 'main.shop.operation',
      path: '/shop/operation/:shopId/:shopName/:type',
      component: ShopOperationLog,
      props: true,
      meta: { title: '门店操作记录' },
    },
  ],
};

export default shopRoutes;
