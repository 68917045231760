/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable } from 'inversify';
import BaseService from './base.service';
import {
  ShopListRes,
  OrderListParams,
  IResCOrder,
} from '@/common/interface/order';
import { COrder } from '@/models/order.model';

export enum PaymentChannelEnum {
  支付宝 = 1,
  微信 = 2,
  企业转账 = 3,
  业务员代收 = 4,
  其他 = 5,
  无需付款 = 6,
}

export interface IRComfirmReceipt {
  id: number;
  paychannel: PaymentChannelEnum | number | string;
  tradeid?: string;
  paymentTime?: string;
}
@injectable()
export default class OrderService extends BaseService {
  /**
   * 获取订单列表
   *
   * @param {OrderListParams} params
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {(Promise<ShopListRes | null>)}
   * @memberof OrderService
   */
  async getList(
    paramObj: OrderListParams,
    loadingEl?: Element | Vue,
  ): Promise<ShopListRes | null> {
    try {
      const params: any = Object.assign({}, paramObj);
      if (paramObj.packageName) {
        params.OrderDesc = paramObj.packageName;
        delete params.packageName;
      }
      const result = (await this.http.get('/dyh-order/list', {
        params,
        data: {
          loadingEl,
        },
      })) as ShopListRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 删除订单
   *
   * @param {number} id
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {(Promise<string | null>)}
   * @memberof OrderService
   */
  async del(id: number, loadingEl?: Element | Vue): Promise<string | null> {
    try {
      const result = (await this.http.delete(`/dyh-order/${id}/order`, {
        data: {
          loadingEl,
        },
      })) as string;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * B端订单退款
   * @param id
   * @param loadingEl
   */
  async refund(id: number, isPartialRefund?: boolean) {
    try {
      const result =
        typeof isPartialRefund !== 'undefined' && isPartialRefund === true
          ? ((await this.http.post(`/dyh-order/${id}/refund`, {
              part: 1,
            })) as string)
          : ((await this.http.post(`/dyh-order/${id}/refund`)) as string);
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 确认收款
   *
   * @param {number} id
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {(Promise<string | null>)}
   * @memberof OrderService
   */
  async payStatus(
    params: IRComfirmReceipt,
    loadingEl?: Element | Vue,
  ): Promise<string | null> {
    try {
      const { id, paychannel, tradeid, paymentTime } = params;
      const param: { [key: string]: any } = { paychannel };
      if (tradeid && paymentTime) {
        param.tradeid = tradeid;
        param.paymentTime = paymentTime;
      }
      const result = (await this.http.post(
        `/dyh-order/${id}/pay-status`,
        param,
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 赠送时长
   *
   * @param {number} id
   * @param {number} day
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {(Promise<string | null>)}
   * @memberof OrderService
   */
  async duration(
    id: number,
    day: number,
    loadingEl?: Element | Vue,
  ): Promise<string | null> {
    try {
      const result = (await this.http.post(
        `/dyh-order/${id}/duration`,
        {
          day,
        },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 获取BI报表token
   *
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {(Promise<string | null>)}
   * @memberof OrderService
   */
  async BIToken(loadingEl?: Element | Vue): Promise<string | null> {
    try {
      const result = (await this.http.get('/bi/report-token', {
        data: {
          loadingEl,
        },
      })) as string;
      return result;
    } catch (error) {
      return null;
    }
  }

  async BITable(
    name = 'GOODS_LIST',
    loadingEl?: Element | Vue,
  ): Promise<string | null> {
    try {
      const result = (await this.http.get(`/bi/${name}/path`, {
        data: {
          loadingEl,
        },
      })) as string;
      return result;
    } catch (error) {
      return null;
    }
  }

  async paymentChannels() {
    try {
      const res = (await this.http.get('/dyh-order/pay-info')) as Array<{
        id: PaymentChannelEnum;
        name: string;
      }>;
      return res;
    } catch (error) {
      return null;
    }
  }

  async launchAdConirmation(orderId: number | string) {
    try {
      const res = await this.http.post('/dyh-order/create/ad-put', {
        orderId,
      });
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * B端订单添加追加备注
   * @param orderId
   * @param content
   * @returns
   */
  async addRemark(orderId: string, content: string) {
    try {
      const res = (await this.http.post(
        `/dyh-order/${orderId}/add-append-remark`,
        {
          appendRemark: content,
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * C端订单列表
   * @param params
   * @param loadingEl
   * @returns
   */
  async COrderList(
    params: {
      pageSize: number;
      page: number;
      orderId?: string; // 订单号
      orderStatus?: string; // 订单状态
      regionId?: string | number; // 城市
      shopName?: string; // 店铺名称
      poi?: string;
      refundProgress?: string;
      createStartTime?: string;
      createEndTime?: string;
      refundStartTime?: string;
      refundEndTime?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    try {
      const res = (await this.http.get('/amap/order/list', {
        params,
        data: {
          loadingEl,
        },
      })) as {
        total: number;
        page: number;
        pageSize: number;
        lastPage: number;
        list: IResCOrder[];
      };
      const { total, page, pageSize, lastPage, list } = res;

      return {
        total,
        page,
        pageSize,
        lastPage,
        list: list.map((data) => {
          return COrder.from(data);
        }),
      };
    } catch (error) {
      return null;
    }
  }

  /**  编辑C端订单备注 */
  async COrderRemarkEdit(orderId: string, remark: string) {
    try {
      const res = (await this.http.post(`/amap/order/${orderId}/edit-notes`, {
        notes: remark,
      })) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 获取C端线下退款详情
   * @param orderNum
   * @returns
   */
  async COrderRefundNumDetail(orderNum: string) {
    try {
      const res = (await this.http.get(
        `/amap/order/${orderNum}/offline-refund/info`,
      )) as {
        refund_order_num: string;
        refund_time: string;
        refund_amount: string;
        payee: string;
      };
      if (res) {
        return {
          refundOrderNum: res.refund_order_num,
          payee: res.payee,
          refundTime: res.refund_time,
          refundAmount: res.refund_amount,
        };
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  /** C端订单线下转账记录编辑 */
  async COrderRefundRecordEdit(params: {
    channel: string;
    orderId: string;
    transferOrderNum: string;
    refundTime: string;
    refundAmount: string;
    payee: string;
  }) {
    try {
      const {
        orderId,
        transferOrderNum,
        refundTime,
        refundAmount,
        payee,
        channel,
      } = params;
      const res = (await this.http.post(
        `/amap/order/${orderId}/offline-refund-record`,
        {
          transferOrderNum,
          refundTime,
          refundAmount,
          payee,
          channel,
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 发起C端订单退款 */
  async COrderRefundInitiate(orderId: string) {
    try {
      const res = (await this.http.post(
        `/amap/order/${orderId}/offline-refund`,
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** B端订单确认收款前 校验交流流水单号 @todo 让接口调整 与 /template-spu-ba/check-shop 类似，不要code=500 */
  async BOrderTransactionNoCheck(orderId: string, tradeid: string) {
    try {
      const res = (await this.http.get(
        `/dyh-order/${orderId}/pay-check?tradeid=${tradeid}`,
      )) as { error: string };
      return res.error && res.error !== '' ? res.error : null;
    } catch (error) {
      return null;
    }
  }

  /**  B端订单套餐列表 */
  async BOrderPackages() {
    try {
      const res = (await this.http.get('/dyh-order/list_option')) as string[];
      return res;
    } catch (error) {
      return null;
    }
  }
}
