
import { BASE_URL } from '@/common/constant';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import { $delay } from '@/common/utils';
import VideoUModal from '@/components/video/VideoModal.vue';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { Vue, Component, Watch, Model, Ref } from 'vue-property-decorator';

interface IVideo {
  id: string;
  url: string;
  cover?: string;
  order?: number;
}

@Component({
  components: { VideoUModal },
})
export default class ShopVideosField extends Vue {
  watchable = true;

  uploading = false;

  val: IVideo[] = [];

  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  /** 上传配置 */
  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  @Model('change')
  value!: IVideo[];

  @Ref()
  videoModal!: VideoUModal;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val.length ? [this.val[0].id] : [0]);
  }

  @Watch('value')
  watchValue() {
    if (!this.watchable) {
      return;
    }
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  @lazyInject(UserService)
  userService!: UserService;

  /** @todo 确认是否需要更改为OSS直传方式 */
  beforeUpload(file: { type: string; size: number }) {
    const isLt5M = file.size / 1024 / 1024 < 30;
    if (!isLt5M) {
      this.$message.error('视频必须小于30MB!');
    }
  }

  removeVideo() {
    this.val = [];
  }

  openVideoModal(videoUrl: string) {
    this.videoModal.open(videoUrl);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onUploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const result = {
        id: data[0]?.id,
        url: data[0]?.url,
      };
      this.val = [result];

      this.uploading = false;
    }
  }

  mounted() {
    this.watchValue();
    this.watchable = false;
    this.$emit('updateSubmittable', true);
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
