
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class AreaField extends Vue {
  val: number | null = 1;

  areaArray: Array<{
    label: string;
    value: number;
  }> = [
    {
      label: '200平米以下',
      value: 1,
    },
    {
      label: '200-400平米',
      value: 2,
    },
    {
      label: '400-600平米',
      value: 3,
    },
    {
      label: '600-1000平米',
      value: 4,
    },
    {
      label: '1000平米以上',
      value: 5,
    },
  ];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: number;

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  mounted() {
    this.watchValue();
  }
}
