
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class StationField extends Vue {
  val = 0;

  stationArray = Array.from({ length: 50 }, (_v, k) => {
    return {
      value: k + 1,
      label: `${k + 1}个`,
    };
  });

  @Prop()
  placeholder?: string;

  @Model('change', {
    type: Number,
  })
  value!: number;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
    if (!this.val) {
      this.$emit('updateSubmittable', false);
    } else {
      this.$emit('updateSubmittable', true);
    }
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = 0;
    }
  }

  mounted() {
    this.watchValue();
  }
}
