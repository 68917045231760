
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';

@Component({
  components: {},
})
export default class ShopAll extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  form: {
    name: string;
    poiId: string;
  } = {
    name: '',
    poiId: '',
  };

  listData: Array<{
    dyhStaffName: string;
    poi: string;
    shopId: number;
    shopName: string;
  }> = [];

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  goToShopProfile(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'main.shop.profile',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }

  mounted() {
    this.handleSearch();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, poiId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }

    if (poiId) {
      query.poiId = poiId;
    }

    this.$router.push({
      query,
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, poiId } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };
    if (name) {
      query.name = name;
    }

    if (poiId) {
      query.poiId = poiId;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const { name, poiId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };

    if (name) {
      this.form.name = name as string;
      param.shopName = name;
    }
    if (poiId) {
      this.form.poiId = poiId as string;
      param.poi = poiId;
    }

    await this.all(param);
  }

  async all(params: {
    pageSize: number;
    page: number;
    shopName?: string;
    poiId?: string;
  }) {
    const res = await this.shopService.all(params, this.$el);
    if (res) {
      this.listData = res.list;

      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: +res.pageSize,
        onChange: this.paginationChange,
      };
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      poiId: '',
    };
    this.$router.push({
      query: {},
    });
  }
}
