
import { Component, Vue } from 'vue-property-decorator';
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';

@Component({
  components: {
    'video-player': videoPlayer,
  },
})
export default class VideoUModal extends Vue {
  videoVisible = false;

  playerOptions = {
    muted: true,
    visible: true,
    autoplay: true,
    language: 'zh-CN',
    loop: false,
    playbackRates: [0.7, 1.0, 1.5, 2.0],
    sources: [
      {
        type: 'video/mp4',
        // src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
        src: '',
      },
    ],
    // poster: '/static/images/author.jpg',
    height: 390,
    width: 520,
    fullScreen: false,
    notSupportedMessage: '此视频无法播放，请重新上传.mp4格式视频',
    fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
  };

  open(videoUrl: string) {
    const obj = this.playerOptions.sources[0];
    obj.src = videoUrl;
    this.$set(this.playerOptions, 'sources', [obj]);
    this.videoVisible = true;
  }

  handleOk() {}
}
