
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import { FormModel } from 'ant-design-vue';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';

@Component({})
export default class SPUAutoOffShelfDrawer extends Vue {
  @lazyInject(SPUService)
  spuService!: SPUService;

  @Ref()
  public readonly ruleForm!: FormModel;

  drawerVisible = false;

  moment = moment;

  shopId = 0;

  spuId = 0;

  form = {
    shouldOffShelf: false,
    autoOffShelfTime: '',
  };

  rules = {
    autoOffShelfTime: [],
  };

  @Watch('form', { deep: true })
  watchForm() {
    if (this.form.shouldOffShelf === true) {
      this.$set(this.rules, 'autoOffShelfTime', [...this.autoOffShelfTimeRule]);
    } else {
      this.$set(this.rules, 'autoOffShelfTime', []);
      this.form.autoOffShelfTime = '';
    }
  }

  /** 自动下架变更 */
  autoOffShelfTimeValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { shouldOffShelf } = this.form;
    if (!shouldOffShelf) {
      return true;
    }
    const duration = 60 * 10;
    const now = Math.round(Date.now() / 1000);
    const offShelfTime = Math.round(new Date(value).getTime() / 1000);

    if (offShelfTime - now < duration) {
      this.$message.error('自动下架时间至少设置在10分钟以后');
      return false;
    }
    return true;
  }

  autoOffShelfTimeRule = [
    { required: true, message: '自动下架时间不能为空', trigger: 'blur' },
    {
      validator: this.autoOffShelfTimeValidator,
      message: '自动下架时间至少设置在10分钟以后',
      trigger: 'blur',
    },
  ];

  onChangeAutoOffShelfTime(time: Moment, timeStr: string) {
    this.ruleForm.resetFields();

    const duration = 60 * 10;
    const now = Math.round(Date.now() / 1000);
    const offShelfTime = Math.round(new Date(timeStr).getTime() / 1000);

    if (offShelfTime - now < duration) {
      this.$message.error('自动下架时间至少设置在10分钟以后');
    }
    this.$set(this.form, 'autoOffShelfTime', timeStr);
  }

  open(value: string, shopId: number, spuId: number) {
    if (value) {
      this.form = {
        shouldOffShelf: true,
        autoOffShelfTime: value,
      };
    }
    this.shopId = shopId;
    this.spuId = spuId;
    this.drawerVisible = true;
  }

  onClose() {
    this.drawerVisible = false;
    this.reset();
  }

  reset() {
    this.spuId = 0;
    this.shopId = 0;
    this.form = {
      shouldOffShelf: false,
      autoOffShelfTime: '',
    };
  }

  async onOk() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        const res = await this.spuService.setAutoOffShelfTime({
          spuId: this.spuId,
          autoOffShelfTime: this.form.autoOffShelfTime,
        });
        if (res) {
          this.$message.success(res);
          setTimeout(() => {
            this.onClose();
          }, 1500);
        }
      }
    });
  }
}
