
import { Vue, Prop, Ref, Watch, Component } from 'vue-property-decorator';
import { editor } from 'monaco-editor';
import { $delay, is } from '@/common/utils/index';

@Component({})
export default class Editor extends Vue {
  @Prop({
    default: 'json',
  })
  language!: string;

  @Prop()
  value?: string;

  @Prop({
    default: () => 200,
  })
  height!: number | string;

  @Ref()
  editorContainer!: HTMLElement;

  private editor?: editor.IStandaloneCodeEditor;

  code?: string;

  get style() {
    let height: string;
    if (is(this.height, Number)) {
      height = `${this.height}px`;
    } else {
      height = this.height as string;
    }
    return {
      height,
    };
  }

  public async mounted() {
    await $delay(500);
    this.format();

    this.code = this.value;

    this.editor = editor.create(this.editorContainer, {
      value: this.code,
      // language: this.language,
      theme: 'vs',
      scrollbar: {
        verticalSliderSize: 10,
        horizontalSliderSize: 10,
      },
      minimap: {
        enabled: false,
        // maxColumn: 60,
      },
      selectOnLineNumbers: true,
      roundedSelection: false,
      readOnly: false,
      cursorStyle: 'line',
      automaticLayout: true,
      glyphMargin: true,
      showFoldingControls: 'always',
      formatOnPaste: true,
      formatOnType: true,
      folding: true,
    });

    this.editor.onDidChangeModelContent(() => {
      this.code = this.editor?.getValue();
      this.currentValueChange();
    });
  }

  /** 手动格式化 */
  public format() {
    this.editor?.getAction('editor.action.formatDocument').run();
  }

  currentValueChange() {
    if (this.value !== this.code) {
      this.$emit('input', this.code);
      this.$emit('change', this.code);
    }
  }

  @Watch('value')
  async valueChange() {
    const { code, value = '' } = this;
    if (code !== value) {
      this.editor?.setValue(value as string);
      await $delay(500);
      this.format();
    }
    this.code = this.value;
  }

  public getError() {
    return editor?.getModelMarkers({}) || [];
  }
}
