
import { EShopParkNum } from '@/common/enum/shop';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class ParkNumField extends Vue {
  /** 免费停车位 */
  EShopParkNum = EShopParkNum;

  parkNumArray = [
    ...['无', '1 ~ 5个', '6 ~ 10个', '11 ~ 20个', '20个以上'],
  ].map((item, k) => ({ value: k, label: item }));

  val = 0;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: Number,
  })
  value!: number;

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  mounted() {
    this.watchValue();
  }
}
