import config from '@/config';

/** 登陆/注册 相关接口的url */
export const LOGIN_URL = [
  '/user/login', // 通过用户名、密码、或者验证码进行登陆
  '/user/register', // 手机号，密码注册接口
  '/verification-code', // 获取/刷新验证码
];

/** API路径前缀 */
export const BASE_URL = `${config.API_URL}/api/v2`;


/** RBAC路径前缀 */
export const BASE_RBAC_URL = `${config.RBAC_URL}/api/rbac`;

/** AUDIT */
export const BASE_AUDIT_URL = `${config.AUDIT_URL}/api`;
