
import ZH_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Component, Vue } from 'vue-property-decorator';
import App from './App.vue';
import '../public/iconfont/iconfont.css';

@Component({
  components: { App },
})
export default class LocalApp extends Vue {
  public locale = ZH_CN;
}
