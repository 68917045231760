/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { injectable, decorate, inject } from 'inversify';
import { AxiosRequestConfig, AxiosResponse, Axios } from 'axios';
import { LOGIN_URL, BASE_URL } from '@/common/constant';
import { KeyObject } from '@/common/interface';
import UserService from './user.service';
import { message } from 'ant-design-vue';
import Loading from '@/common/utils/loading';

decorate(injectable(), Axios);

@injectable()
export default class HttpClient extends Axios {
  @inject(UserService)
  protected userService!: UserService;

  private loadingInstance?: Loading;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor() {
    super({
      baseURL: BASE_URL,
    });
    /** request拦截器 */
    this.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const { url, data } = config;

        const $host = config.data?.loadingEl;
        if ($host) {
          const loadingElement = (($host as Vue).$el || $host) as HTMLElement;
          this.loadingInstance = new Loading(loadingElement);
          this.loadingInstance.loading();
          document.activeElement && (document.activeElement as HTMLElement).blur();
        }

        if (data && data.extData) {
          const { showErrorToast, authorization } = data.extData;
          this.showErrorToast = showErrorToast === undefined ? true : showErrorToast;
          this.authorization = authorization === undefined ? true : authorization;
        } else {
          this.showErrorToast = true;
          this.authorization = true;
        }

        if (url && !LOGIN_URL.includes(url) && this.authorization) {
          if (!config.headers) {
            config.headers = {
              'Content-Type': 'application/json;charset=UTF-8',
            };
          }
          (config.headers as KeyObject<any>).AUTHORIZATION =
            this.userService.currentUser()?.token;
        } else {
          config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
          };
        }

        if (data && data.extData) {
          delete data.extData;
        }

        if (config.data) {
          if (config.data.loadingEl) {
            delete config.data.loadingEl;
          }
          config.data = JSON.stringify(config.data);
        }

        config.paramsSerializer = undefined;

        return config;
      },
      error => {
        // 取消或者其他异常
        Promise.reject(error);
        if (this.loadingInstance) {
          this.loadingInstance.close();
        }
      },
    );

    /** respone拦截器 */
    this.interceptors.response.use(
      response => {
        if (response.status === 200 && response.data) {
          if (this.loadingInstance) {
            this.loadingInstance.close();
          }
          return this.processData(response);
        } else if (response.status === 401) {
          if (this.loadingInstance) {
            this.loadingInstance.close();
          }
          // 登陆异常 清除token缓存 跳转至登陆页
          message.error('登录异常');
          this.userService.logout();
        } else {
          if (this.loadingInstance) {
            this.loadingInstance.close();
          }
          try {
            const errData = typeof response.data === 'string'
              ? JSON.parse(response.data)
              : response.data;
            const reason = errData.error;
            if (this.showErrorToast) {
              message.error(reason);
            }
            return Promise.reject(
              new Error(reason || '请求错误'),
            );
          } catch (error) {
            return Promise.reject(
              new Error(response.data),
            );
          }
        }
      },
      error => {
        if (this.loadingInstance) {
          this.loadingInstance.close();
        }
        // 取消或者其他异常
        this.processError(error);
      },
    );
  }

  /** 是否设置token请求头 */
  protected authorization = true;

  /** 是否toast提示请求错误 */
  protected showErrorToast = true;

  /** 请求成功默认回调 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processData(res: AxiosResponse<any>) {
    const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
    const status = data.status || data.code;
    switch (status) {
      case 0:
        return Promise.resolve(data.data);
      default:
        message.error(data);
        return Promise.reject(data);
    }
  }

  /** 请求失败默认回调 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processError(err: any) {
    message.error(err.message || err.errmsg || '请求错误');
    return Promise.reject(err);
  }
}
