import { createDecorator } from 'vue-class-component';
import Vue, { ComponentOptions } from 'vue';

/**
 * @decorator
 * getter 属性强制不缓存
 *
 */
const NoCache = createDecorator((options: ComponentOptions<Vue>, key) => {
  // component options should be passed to the callback
  // and update for the options object affect the component

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const component = options as any;
  const computedProp = component.computed[key];
  if (computedProp) {
    computedProp.cache = false;
  } else {
    throw new Error(`No computed property "${key}"`);
  }
});

export default NoCache;
