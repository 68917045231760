
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import PermissionService from '@/services/permission.service';

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AddPermissionModal extends Vue {
  loading = false;

  @Prop()
  visible!: boolean;

  form = {
    name: '',
    path: '',
  };

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  async submit() {
    if (!this.form.name || !this.form.path) {
      return;
    }

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const res = await this.permissionService.createPermission(
        this.form.name,
        this.form.path,
      );

      if (res) {
        this.$message.success('添加成功');

        await $delay(500);
        this.$emit('close', true);
      }
    } catch (error) {
      this.$message.info(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      name: '',
      path: '',
    };
  }
}
