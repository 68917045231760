
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import ActivityService from '@/services/activity.service';
import { IResActivityItemInList } from '@/common/interface/activity';
import { ActivityType, ActivityStatus } from '@/common/enum/activity';
import { City } from '@/common/interface';
import BaseService from '@/services/base.service';
import ActivityDetailDrawer from './components/ActivityDetailDrawer.vue';
import moment, { Moment } from 'moment';
import VueQr from 'vue-qr';
import { AuthEnum } from '@/services/permission.service';

@Component({
  components: {
    ActivityDetailDrawer,
    VueQr,
  },
})
export default class ActivityList extends Vue {
  AuthEnum = AuthEnum;

  activityTypeMap = new Map([
    [ActivityType.秒杀, '秒杀'],
    [ActivityType.折扣, '折扣'],
    [ActivityType.一口价, '一口价'],
  ]);

  activityStatusMap = new Map([
    [ActivityStatus.上线, '上线'],
    [ActivityStatus.下线, '下线'],
    [ActivityStatus.已过期, '已过期'],
  ]);

  ActivityStatus = ActivityStatus;

  moment = moment;

  form: {
    name: string;
    status: number;
    regionId: string | number;
    platformProductId: string | number;
    gmtStartTime: Moment | null;
    gmtEndTime: Moment | null;
  } = {
    name: '',
    status: -1,
    regionId: '',
    platformProductId: '',
    gmtStartTime: null,
    gmtEndTime: null,
  };

  listData: IResActivityItemInList[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  drawerVisible = false;

  qrcode = 'amapuri://c3/carservice/wash_portal';

  qrcodeModalVisible = false;

  citys: City[] = [];

  @Ref()
  drawer!: ActivityDetailDrawer;

  @lazyInject(BaseService)
  protected baseService!: BaseService;

  @lazyInject(ActivityService)
  activityService!: ActivityService;

  mounted() {
    this.handleSearch();
    this.getCityList();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    Object.assign(query, this.extractFilterFromForm());

    this.$router.push({ query });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const query = this.extractFilterFromForm();
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  extractFilterFromForm() {
    const {
      name,
      regionId,
      platformProductId,
      status,
      gmtStartTime,
      gmtEndTime,
    } = this.form;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};
    if (name) {
      params.name = name;
    }
    if (regionId) {
      params.regionId = regionId;
    }
    if (platformProductId) {
      params.platformProductId = platformProductId;
    }
    if (status !== -1) {
      params.status = status;
    }
    if (gmtEndTime) {
      params.gmtEndTime = `${gmtEndTime}`;
    }
    if (gmtStartTime) {
      params.gmtStartTime = `${gmtStartTime}`;
    }
    return params;
  }

  extractFilterFromRouterAndFillForm() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};
    const {
      name,
      status,
      regionId,
      platformProductId,
      gmtStartTime,
      gmtEndTime,
      page,
    } = this.$route.query;

    if (name) {
      this.form.name = name as string;
      param.name = name;
    } else {
      this.form.name = '';
    }

    if (regionId) {
      param.adcode = regionId;
      this.form.regionId = +regionId;
    }

    if (status) {
      param.status = status;
      this.form.status = +status;
    }

    if (platformProductId) {
      param.platformProductId = platformProductId;
      this.form.platformProductId = +platformProductId;
    }
    if (gmtEndTime) {
      param.gmtEndTime = gmtEndTime as unknown as Moment;
      this.form.gmtEndTime = gmtEndTime as unknown as Moment;
    }
    if (gmtStartTime) {
      param.gmtStartTime = gmtStartTime as unknown as Moment;
      this.form.gmtStartTime = gmtStartTime as unknown as Moment;
    }
    if (page) {
      param.page = page;
      this.pagination.current = +page;
    }
    return param;
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };
    Object.assign(param, this.extractFilterFromRouterAndFillForm());

    const data = await this.activityService.list(param, document.body);

    const { page, total, list } = data;

    this.pagination = {
      total,
      pageSize,
      current: page,
      onChange: this.paginationChange,
    };
    this.listData = list;
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      platformProductId: '',
      name: '',
      status: -1,
      regionId: '',
      gmtStartTime: null,
      gmtEndTime: null,
    };
    this.$router.push({
      query: {},
    });
  }

  add() {
    this.drawer.setFormFields();
    this.drawerVisible = true;
  }

  open() {
    this.drawer.setFormFields();
    this.drawerVisible = true;
  }

  drawerClose(refresh = false) {
    this.drawerVisible = false;
    if (refresh) {
      this.handleSearch();
    }
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.baseService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  onChangeTime(value: Moment[]) {
    if (!value.length) {
      this.form.gmtStartTime = null;
      this.form.gmtEndTime = null;
      return;
    }

    this.$set(
      this.form,
      'gmtStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.form,
      'gmtEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  async getActivityDetail(id: number, status: ActivityStatus) {
    if (status === ActivityStatus.上线) {
      this.$message.warn(
        '当前为上线活动，若需编辑，请先下线活动再进行编辑!',
        4,
      );
    }

    const res = await this.activityService.detail(id);
    if (res) {
      this.drawer.setFormFields(res);
      this.drawerVisible = true;
    }
  }

  async online(package_id: number | string) {
    const res = await this.activityService.online(package_id);
    if (res) {
      this.$message.success(`${res}`);
      this.handleSearch();
    }
  }

  async offline(package_id: number | string) {
    const res = await this.activityService.offline(package_id);
    if (res) {
      this.$message.success(`${res}`);
      this.handleSearch();
    }
  }

  async duplicateActivity(id: number, cloneBaseInfo?: boolean) {
    const res =
      typeof cloneBaseInfo !== 'undefined' && cloneBaseInfo === true
        ? await this.activityService.duplicate(id, cloneBaseInfo)
        : await this.activityService.duplicate(id);
    if (res) {
      this.$message.success(`${res}`);
      this.handleSearch();
    }
  }
}
