/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable } from 'inversify';

@injectable()
export default class StorageService {
  get(key: string) {
    try {
      const result = localStorage.getItem(key);
      if (!result) {
        return null;
      }
      return JSON.parse(result);
    } catch (error) {
      return null;
    }
  }

  set(key: string, data: string | Record<string, any>) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
