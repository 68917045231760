/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { EOperationType } from '@/common/enum/shop';
import { IShopOperationLogItem } from '@/common/interface/shop';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export class ShopOperationLogService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async list(reqParams: {
    page_size: number;
    page: number;
    operate_type: string;
    shop_id?: number | string; // 批量商品将不传shop_id
    ext_idx?: number;
  }) {
    try {
      const url = '/shop/trace_log';
      const params = Object.assign({}, reqParams);

      if (reqParams.operate_type === EOperationType.SHOP_LICENSE) {
        params.operate_type = 'shopLiscen';
      }
      if (reqParams.operate_type === EOperationType.BOSS_ID_IMAGES) {
        params.operate_type = 'shopIdImage';
      }
      if (reqParams.operate_type === EOperationType.LEADING_SPU) {
        params.operate_type = 'batchTemplate';
      }
      if (reqParams.operate_type === EOperationType.SHOP_AUDIT_VIDEO) {
        params.operate_type = 'shopAuditVideo';
      }

      const res = (await this.http.get(url, { params })) as {
        list: any[];
        total: number;
        pageSize: number;
        page: number;
        prev_data: any; // 与res 一致；
      };
      if (res) {
        const { total, pageSize, page, list, prev_data } = res;
        const data = {
          total,
          pageSize,
          page,
          list: [] as IShopOperationLogItem[],
        };
        const getContent = (param: {
          type: EOperationType;
          detail: any[];
          prevDetail?: any[];
          operate_desc?: string;
        }) => {
          const { type, detail, prevDetail, operate_desc } = param;

          if (
            type !== EOperationType.BOSS_ID_IMAGES &&
            type !== EOperationType.SHOP_IMAGES &&
            type !== EOperationType.SHOP_LICENSE &&
            type !== EOperationType.SHOP_AUDIT_VIDEO
          ) {
            if (type === EOperationType.LEADING_SPU) {
              return operate_desc;
            }
            if (type === EOperationType.STAFF) {
              return `${operate_desc}：${detail}`;
            }
            return detail;
          }
          return {
            previous: prevDetail,
            current: detail,
          };
        };

        for (let index = 0; index < list.length; index++) {
          const prevDataDetail =
            prev_data && prev_data.detail ? prev_data.detail : [];
          const prevData =
            index === list.length - 1 ? prevDataDetail : list[index + 1].detail;
          const item = list[index];
          const operItem = {
            id: item.id,
            operateTime: item.operate_time,
            operatorId: item.operate_id,
            operatorName: item.operate_name,
            content: getContent({
              type: reqParams.operate_type as EOperationType,
              detail: item.detail,
              prevDetail: prevData,
              operate_desc: item.operate_desc,
            }),
          };
          data.list.push(operItem);
        }
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }
}
