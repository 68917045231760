
import DebounceTime from '@/common/decorator/debounceTime';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class FilterTagField extends Vue {
  fetching = false;

  val: string[] = [];

  tagOptions: Array<{ label: string; value: string }> = [];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: string;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Watch('value')
  async watchValue() {
    if (this.value) {
      const tags = this.value.split(',');
      await this.searchFilterTags(tags[0]);
      this.val = [...tags];
    } else {
      await this.searchFilterTags(' ');
    }
  }

  @Watch('val', { deep: true })
  watchVal() {
    this.$emit('update', this.val.length > 0 ? this.val[0] : '');
  }

  @DebounceTime(300)
  async onSearch(text: string) {
    await this.searchFilterTags(text);
  }

  onChange(val: any) {
    if (val.length && this.val.length) {
      this.$message.info('当前仅支持配置1个筛选标签');
      return;
    }
    this.val = [...val];
  }

  async searchFilterTags(tag: string) {
    if (this.fetching) {
      return;
    }
    this.fetching = true;

    try {
      const res = await this.spuService.leadingSpuFilterTags(tag);
      if (res) {
        this.tagOptions = res.list;
      }
      this.fetching = false;
    } catch (error) {
      this.fetching = false;
    }
  }

  mounted() {
    this.watchValue();
  }
}
