/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable, inject } from 'inversify';
import HttpClient from './http.service';
import StorageService from './storage.service';
import { from, of } from 'rxjs';
import asyncPermission from '@/common/utils/permission';
import Permission from '@/models/permission.model';
import { BASE_RBAC_URL } from '@/common/constant';

/** 所有权限枚举 */
export enum AuthEnum {
  /** 门店领域 */
  门店列表 = 'shop:list',
  门店运营 = 'shop:operation',
  门店管控 = 'shop:admin',
  /** B端订单 */
  B端订单列表 = 'business-order:list',
  B端订单修改 = 'business-order:edit',
  B端订单修改地推员 = 'b-order:fix-staff',
  黑卡订单退款 = 'blackcard-order:refund',
  /** C端订单 */
  C端订单列表 = 'customer-order:list',

  /** 提现 */
  提现管理 = 'withdraw:manage',
  提现查询 = 'withdraw:query',
  /** 活动 */
  活动列表 = 'activity:list',
  /** 权限 */
  权限列表 = 'permission:list',
  团队列表 = 'organization:list',
  /** 开城管理 */
  开城管理 = 'add:region:cover',
  /** 标签 */
  标签列表 = 'label:list',
  /** 店铺编辑 */
  门店编辑 = 'baseInfo:edit',
  /** 审核管理 */
  审核管理 = 'audit:admin',
  /** 代运营权限 */
  代运营门店编辑 = 'baseInfo:edit:limit',
  /** 报表 */
  BI商品报表 = 'bi:goods-list',
  BI门店统计 = 'bi:shop-summary',
  门店报表 = 'shop:all-list',
  门店操作日志 = 'shop:operation-log',
  拓店战报 = 'b.order.daily',
  服务商访店 = 'bi.agent.visit',
  城市门店明细 = 'city.shop.detail',
  黑卡门店明细 = 'blackcard.shop.detail',
  门店到期时间 = 'shop.outdate',
  门店权益到期 = 'shop.right.outdate',
  异常检查 = 'menu.error.check',
  门店营业执照查看 = 'shop.license',
  /** 德友惠提现管理 */
  德友惠提现管理 = 'menu:withdraw:admin',
  /** 全部门店（管理） */
  全部门店 = 'menu:w:shop',
  删除门店草稿和申请 = 'menu:w:deleteShop',
  地推员操作 = 'menu:w:operationStaff',
  已入驻门店报表 = 'menu:w:passshop.report',
  查看门店上游数据 = 'shop:upstream:data',
  /** 账号管理 */
  账号管理 = 'menu:account-manage',
  /** 商家平台入驻 */
  商家平台入驻 = 'merchant:join',
  /** 广告线索 */
  广告线索 = 'menu:clue:admin',
  品牌加盟线索 = 'brand:save:info',
  黑卡留资 = 'menu:blackcard.lead',
  /** 绑定门店代运营人员 */
  绑定门店代运营人员 = 'agent:bind',
  /** 修改第三方店铺绑定 */
  修改第三方店铺绑定 = 'change:third',
  /** 系统追踪 */
  系统追踪 = 'system:log',
  /** 设置推广通权限 */
  设置推广通权限 = 'ad:staff',
  /** 推广通广告投放确认 */
  推广通广告投放确认 = 'boss-confirmation-ad',
  /** 代运营管理 */
  代运营管理 = 'menu:agencyOperation',
  代运营管理操作 = 'agent_city_relation:all',
  /** 商品管理 */
  平台标准商品管理 = 'menu:platformProductOperation',
  品牌标准商品管理 = 'menu:KaPlatformProductOperation',
  批量商品管理 = 'menu:LeadingSpuOperation',
  /** 批量导入品牌门店操作权限 */
  品牌门店导入 = 'operation: batch-import-brand-shops',
  /** 编辑广告主电话 */
  编辑广告主电话 = 'operation: advertiser-mobile-phone-number',
  /** 标品活动报名 */
  标品活动报名 = 'operation: bind-platformProduct',
  /** 标品视频管理 */
  标品视频管理 = 'operation:spu-videos-manage',
  /** 门店视频管理 */
  门店视频管理 = 'operation:shop-videos-manage',
  /** 圈店推送 */
  圈店推送 = 'push:push-setting',
  /** 下载二维码海报 */
  下载二维码海报 = 'operation:download-qrcode-poster',
  /** B端订单追加备注编辑权限 */
  编辑B端订单追加备注 = 'operation: BOrder-remark',
  /** 入驻门店视频管理 */
  入驻门店视频管理 = 'operation:create-shop-videos-manage',
  /** 人工干预商家平台入驻 */
  人工干预商家平台入驻 = 'operation:intervention-mechant-join',
  /** C端订单操作（通用） */
  C端订单操作 = 'operation:c-order',
  /** 菜单C端订单 */
  菜单C端订单列表 = 'menu:COrder-list',
  菜单推广通余额数据 = 'menu:ad-campaigns-balance',
  菜单门店巡检记录 = 'menu:shop-inspection-record',
  查看导出门店信息 = 'view:export-shop-info',
  门店子账号管理 = 'operation:shop-staff',
  菜单品牌管理 = 'menu:brand-admin',

  设置考核门店 = 'examine:shop',
  门店控价考核调研表 = 'examine:summary',
  调研结果汇总 = 'examine:research',

}

/** 菜单权限 set */
export const AuthMenuSet: Set<AuthEnum> = new Set([
  AuthEnum.门店列表,
  AuthEnum.B端订单列表,
  AuthEnum.C端订单列表,
  AuthEnum.提现查询,
  AuthEnum.提现管理,
  AuthEnum.活动列表,
  AuthEnum.权限列表,
  AuthEnum.团队列表,
  AuthEnum.开城管理,
  AuthEnum.标签列表,
  AuthEnum.审核管理,
  AuthEnum.代运营门店编辑,
  AuthEnum.BI商品报表,
  AuthEnum.BI门店统计,
  AuthEnum.门店报表,
  AuthEnum.门店操作日志,
  AuthEnum.拓店战报,
  AuthEnum.服务商访店,
  AuthEnum.城市门店明细,
  AuthEnum.黑卡门店明细,
  AuthEnum.门店到期时间,
  AuthEnum.门店权益到期,
  AuthEnum.异常检查,
  AuthEnum.德友惠提现管理,
  AuthEnum.全部门店,
  AuthEnum.已入驻门店报表,
  AuthEnum.查看门店上游数据,
  AuthEnum.账号管理,
  AuthEnum.商家平台入驻,
  AuthEnum.广告线索,
  AuthEnum.品牌加盟线索,
  AuthEnum.黑卡留资,
  AuthEnum.绑定门店代运营人员,
  AuthEnum.系统追踪,
  AuthEnum.设置推广通权限,
  AuthEnum.推广通广告投放确认,
  AuthEnum.代运营管理,
  AuthEnum.代运营管理操作,
  AuthEnum.平台标准商品管理,
  AuthEnum.批量商品管理,
  AuthEnum.品牌标准商品管理,
  AuthEnum.黑卡订单退款,
  AuthEnum.品牌门店导入,
  AuthEnum.编辑广告主电话,
  AuthEnum.标品活动报名,
  AuthEnum.标品视频管理,
  AuthEnum.门店视频管理,
  AuthEnum.圈店推送,
  AuthEnum.下载二维码海报,
  AuthEnum.编辑B端订单追加备注,
  AuthEnum.入驻门店视频管理,
  AuthEnum.人工干预商家平台入驻,
  AuthEnum.菜单C端订单列表,
  AuthEnum.C端订单操作,
  AuthEnum.菜单推广通余额数据,
  AuthEnum.菜单门店巡检记录,
  AuthEnum.查看导出门店信息,
  AuthEnum.门店子账号管理,
  AuthEnum.菜单品牌管理,
  AuthEnum.设置考核门店,
  AuthEnum.门店控价考核调研表,
  AuthEnum.调研结果汇总,
]);
interface InterfacePagination {
  lastPage: number;
  page: number;
  pageSize: number;
  total: number;
}
export interface PermissionListRes extends InterfacePagination {
  list: Permission[];
}
export interface OrganizationListRes extends InterfacePagination {
  list: Array<{
    id: number;
    name: string;
  }>;
}

export interface InterfaceOrganizationRole {
  roleId: number;
  roleName: string;
}

export interface InterfaceOrganizationRoleRes {
  roleName: string;
  roleID: number;
  permission: Array<{
    permissionId: number;
    permissionName: string;
  }>;
}

interface OrganizationUserRoleRes {
  [roleId: number]: Array<{
    phone: string | null;
    roleId: number;
    roleName: string;
    userId: number;
    userName: string | null;
  }>;
}
@injectable()
export default class PermissionService {
  @inject(HttpClient)
  protected http!: HttpClient;

  @inject(StorageService)
  protected storage!: StorageService;

  /**
   * 查询多个权限
   *
   * @param {AuthEnum[]} pnames
   * @return {*}  {Promise<Observable<KeyObject<boolean>>>}
   * @memberof PermissionService
   */
  async asyncPermissions(pnames: AuthEnum[]) {
    try {
      const permissions = asyncPermission(pnames);
      if (permissions) {
        return from(permissions);
      } else {
        return of({});
      }
    } catch (error) {
      return of({});
    }
  }

  /**
   * 创建权限
   */
  async createPermission(name: string, path: string): Promise<number> {
    try {
      const result = (await this.http.post(`${BASE_RBAC_URL}/permission`, {
        name,
        path,
      })) as number;

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 给组织创建角色
   * @param organizationId 组织id
   * @param name
   * @returns 返回新创建角色的id
   */
  async createRoleOfOrganization(organizationId: string, name: string) {
    try {
      const res = (await this.http.post(
        `${BASE_RBAC_URL}/organization/${organizationId}/role`,
        {
          name,
        },
      )) as number;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 给角色添加权限
   * @param roleId 角色id;
   * @param permissionIds 权限id
   * @returns
   */
  async addPermissionToRole(params: {
    roleId: string;
    permissionIds: string[];
  }) {
    try {
      const res = await this.http.post(
        `${BASE_RBAC_URL}/role/${params.roleId}/permission`,
        {
          permissionIds: params.permissionIds,
        },
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 给组织的角色添加权限
   * @param params
   * @returns
   */
  async addPermissionToOrganizationRole(params: {
    roleId: number;
    organizationId: string;
    permissionIds: number[];
  }) {
    try {
      const { roleId, organizationId, permissionIds } = params;
      const res = await this.http.post(
        `${BASE_RBAC_URL}/organization/${organizationId}/role/${roleId}/permission`,
        {
          permissionIds,
        },
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 删除组织角色的权限
   * @param params {roleId: 角色id, organizationId: 组织id，permissionIds: 权限id，多个权限以","分隔 }
   * @returns
   */
  async deletePermissionOfOrganizationRole(params: {
    roleId: number;
    organizationId: string;
    permissionIds: string;
  }) {
    try {
      const { roleId, organizationId, permissionIds } = params;
      const res = await this.http.delete(
        `${BASE_RBAC_URL}/organization/${organizationId}/role/${roleId}/permission/${permissionIds}`,
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 删除组织角色
   * @param roleID
   * @param organizationId
   * @returns
   */
  async deleteRoleOfOrganization(roleID: number, organizationId: string) {
    try {
      const res = await this.http.delete(
        `${BASE_RBAC_URL}/organization/${organizationId}/role/${roleID}`,
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 删除角色的权限
   * @param roleId  角色id
   * @param permissionIds  `1,2,3`
   * @returns
   */
  async deletePermissionOfRole(roleId: string, permissionIds: string) {
    try {
      const res = await this.http.delete(
        `${BASE_RBAC_URL}/role/${roleId}/permission/${permissionIds}`,
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 删除已有权限
   * @param {string} id 权限id
   * @returns {string}
   */
  async deletePermission(id: string): Promise<string> {
    try {
      const result = (await this.http.delete(
        `${BASE_RBAC_URL}/permission/${id}`,
      )) as string;
      // '删除成功'
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取全部权限列表
   * @param params
   * @returns
   */
  async permissionList(params: {
    page: number;
    pageSize: number;
    name?: string; // pathname?: string
  }) {
    try {
      const data = (await this.http.get(`${BASE_RBAC_URL}/permission/list`, {
        params,
      })) as PermissionListRes;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取团队列表
   * @param params
   * @returns
   */
  async orgList(params: {
    pageSize?: number;
    page: number;
    name?: string;
  }): Promise<OrganizationListRes> {
    const res = (await this.http.get(`${BASE_RBAC_URL}/organization/list`, {
      params,
    })) as OrganizationListRes;

    return res;
  }

  /**
   * 删除团队
   * @param {string} orgId 团队id
   * @returns
   */
  async deleteOrganization(orgId: string): Promise<string> {
    try {
      const result = (await this.http.delete(
        `${BASE_RBAC_URL}/organization/${orgId}`,
      )) as string;
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 创建新团队
   * @param {string} name 团队名称
   * @returns
   */
  async createOrganization(name: string): Promise<number> {
    try {
      const result = (await this.http.post(`${BASE_RBAC_URL}/organization`, {
        name,
      })) as number;
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 新增团队成员
   * @param params
   */
  async addOrgMember(params: {
    userName?: string;
    phone: string;
    orgId: string;
    orgRoleId: string[];
  }) {
    const res = (await this.http.post(
      `${BASE_RBAC_URL}/user/${params.phone}/organization/${params.orgId}/role`,
      {
        roleId: params.orgRoleId,
        name: params.userName || '',
      },
    )) as string;

    return res;
  }

  /**
   * 删除团队下某个成员
   * @param orgId
   * @param userId
   * @returns
   */
  async deleteOrgMember(orgId: string, userId: string) {
    const res = (await this.http.delete(
      `${BASE_RBAC_URL}/organization/${orgId}/user/${userId}`,
    )) as null;

    // @notice 返回null 代表 删除成功！！！
    return res;
  }

  /**
   * 获取团队所有角色列表
   * @param id 团队id
   * @returns
   */
  async organizationRoleList(id: string): Promise<InterfaceOrganizationRole[]> {
    const result = (await this.http.get(
      `${BASE_RBAC_URL}/organization/${id}/role`,
    )) as InterfaceOrganizationRole[];
    return result;
  }

  /**
   * 获取团队角色
   * @param {string} id 团队id
   * @returns
   */
  async getOrganizationRoleList(id: string) {
    try {
      const rolePermissionRes = (await this.http.get(
        `${BASE_RBAC_URL}/organization/${id}/role-permission`,
      )) as InterfaceOrganizationRoleRes[];

      return rolePermissionRes;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 获取团队下成员列表（成员的角色）
   * @param id 团队id
   */
  async getOrgMemberList(id: string) {
    const userRoleRes = (await this.http.get(
      `${BASE_RBAC_URL}/organization/${id}/user-role`,
    )) as OrganizationUserRoleRes;

    if (userRoleRes) {
      const userRoleMap = new Map(Object.entries(userRoleRes));
      const userRoleList: Array<{
        phone: string;
        name: string;
        id: string;
        roles: Array<{
          id: string;
          name: string;
        }>;
      }> = [];

      for (const [key, value] of userRoleMap) {
        const item = {
          id: key,
          name: value[0].userName || '',
          phone: value[0].phone || '',
          roles: value.map(
            (data: {
              phone: string | null;
              roleId: number;
              roleName: string;
              userId: number;
              userName: string | null;
            }) => {
              return {
                id: data.roleId,
                name: data.roleName,
              };
            },
          ),
        };
        userRoleList.push(item);
      }

      return userRoleList;
    }
  }
}
