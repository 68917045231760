
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class CommonField extends Vue {
  val: string | number | boolean = '';

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: any;

  @Watch('value')
  watchValue() {
    if (this.value == null) {
      this.val = '';
      return;
    }
    if (
      typeof this.value === 'string' ||
      typeof this.value === 'number' ||
      typeof this.value === 'boolean'
    ) {
      this.val = this.value;
      return;
    }
    this.val = JSON.stringify(this.value);
  }

  mounted() {
    this.watchValue();
  }
}
