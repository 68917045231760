/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConstructorType, KeyObject } from '../interface/index';
/**
 * 判断值的类型
 * @param value 需要判断的值
 * @param type 需要判断的类型
 */
export function is(
  value: any,
  type: ConstructorType,
) {
  return Object.prototype.toString.call(value) === `[object ${type.name}]`;
}

/**
 * 冻结对象，对象/数组不可修改(对于只用作展示的数据时，可以提高性能)
 * @param source 源数据
 * @param options 配置选择
 *
 * 冻结模式 [self:只冻结souce数据本身, sub:只冻结source的子属性/item, all:冻结所有]
 * 默认all
 */
export function freeze<T>(
  source: T,
  options: {
    mode: 'all' | 'self' | 'sub';
  } = { mode: 'all' },
) {
  const { mode } = options;

  if (is(source, Object)) {
    const obj = source as KeyObject<any>;
    if (['all', 'sub'].includes(mode)) {
      Object.keys(obj).forEach(key => {
        obj[key] = freeze(obj[key], { mode: 'all' });
      });
    }

    if (['all', 'self'].includes(mode)) {
      Object.freeze(source);
    }
  } else if (is(source, Array)) {
    const arr = (source as unknown) as any[];
    if (['all', 'sub'].includes(mode)) {
      arr.forEach((item, index) => {
        arr[index] = freeze(item, { mode: 'all' });
      });
    }
    if (['all', 'self'].includes(mode)) {
      Object.freeze(source);
    }
  }
  return source;
}

/**
 * 延迟后续代码执行
 * @param interval 延迟的时间 默认0ms
 */
export function $delay(interval = 0): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), interval);
  });
}

/**
 *  将一个数组串行进行异步处理
 * @param list 原始数据数组
 * @param callback 处理函数
 * @param parallelCount 并行处理的个数, 默认是1
 * @returns {{R[]}} callback返回的异步数据的的数组。
 *
 */
export async function serialAsync<T, R>(
  list: T[],
  callback: (item: T) => Promise<R>,
  parallelCount = 1,
) {
  const parallelList: T[][] = [];
  const len = list.length;
  for (let i = 0; i < len; i += parallelCount) {
    parallelList.push(list.slice(i, i + parallelCount));
  }
  const result = await parallelList.reduce<Promise<R[]>>(
    async (lastResultPromise: Promise<R[]>, subList: T[]) => {
      const lastResult = await lastResultPromise;
      const currentResult = await Promise.all(subList.map(item => callback(item)));
      return [...lastResult, ...currentResult];
    },
    Promise.resolve([]),
  );
  return result;
}
