/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import Crypto from 'crypto-es';

export default class AESEncryptor {
  static KEY = Crypto.enc.Utf8.parse('HCp98d3hpOHDFh89');

  static IV_DICT = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';

  encrypt(input: string, key = AESEncryptor.KEY) {
    const ivString = this.randomIV();
    const iv = Crypto.enc.Utf8.parse(ivString);
    try {
      const encrypted = Crypto.AES.encrypt(input, key, {
        iv,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7,
      });

      const encryptedHex = Crypto.enc.Hex.parse(
        encrypted.ciphertext.toString(),
      );

      const encryptedBase64 = Crypto.enc.Base64.stringify(encryptedHex);

      return `${encryptedBase64},${ivString}`;
    } catch (e) {
      return null;
    }
  }

  randomIV(): string {
    const iv = new Array(16);

    for (let index = 0; index < iv.length; index++) {
      iv[index] = AESEncryptor.IV_DICT[this.random(0, 48)];
    }

    return iv.join('');
  }

  random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }
}
