import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import RegionCoveredList from '@/views/region/RegionCoveredList.vue';

const regionCoverRoutes: RouteConfig = {
  name: 'region',
  path: '/region',
  component: Main,
  redirect: {
    name: 'region.covered',
  },
  meta: {
    title: '开城管理',
  },
  children: [
    {
      name: 'region.covered',
      path: '/region/covered',
      component: RegionCoveredList,
      meta: {
        title: '开城城市列表',
      },
    },
  ],
};

export default regionCoverRoutes;
