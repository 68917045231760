import { render, staticRenderFns } from "./TelsField.vue?vue&type=template&id=58bce4ce&scoped=true"
import script from "./TelsField.vue?vue&type=script&lang=ts"
export * from "./TelsField.vue?vue&type=script&lang=ts"
import style0 from "./TelsField.vue?vue&type=style&index=0&id=58bce4ce&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bce4ce",
  null
  
)

export default component.exports