
import { SignupStatus } from '@/common/enum/activity';
import { SpuSignupableActivity } from '@/common/interface/activity';
import lazyInject from '@/di';
import ActivityService from '@/services/activity.service';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SPUSignup extends Vue {
  labelCol = { span: 6 };

  wrapperCol = { span: 14 };

  signupStatus = SignupStatus;

  @Prop()
  visible!: boolean;

  @Prop({
    default: () => {
      return {
        spuid: '',
        shopid: '',
        signupableActivityList: [],
      };
    },
  })
  spuSignupConfig!: {
    spuid: string;
    shopid: string;
    signupableActivityList: SpuSignupableActivity[];
  };

  @lazyInject(ActivityService)
  protected activityService!: ActivityService;

  /** 报名 */
  async signup(activityId: string) {
    const result = await this.activityService.spuSignup({
      activityId,
      spuid: this.spuSignupConfig.spuid,
      shopid: this.spuSignupConfig.shopid,
    });
    if (result) {
      this.$message.success(`${result}`);
      // 刷新当前spu的报名状态
      this.$emit('update', this.spuSignupConfig.spuid);
    }
  }

  /** 取消报名 */
  async cancel(signupId: number) {
    const result = await this.activityService.updateSignupStatus(
      signupId,
      SignupStatus.删除,
    );
    if (result) {
      this.$message.success(`${result}`);
      // 刷新当前spu的报名状态
      this.$emit('update', this.spuSignupConfig.spuid);
    }
  }
}
