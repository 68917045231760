
import { City } from '@/common/interface';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BehaviorSubject } from 'rxjs';
import SPUService from '@/services/spu.service';

interface IApplcableShop {
  id: number;
  name: string;
  poi: string;
  ka: string;
  province: string;
  city: string;
  businessStatus: string;
  checked?: boolean;
}

/** @note 全站已有营业状态枚举值， API要求重造！ */
enum EShopBusinessStatus {
  全部 = -1,
  正常营业 = 0,
  暂停营业 = 1,
  永久停业 = 2,
  惩罚下线 = 3,
}

enum ESelectedStatus {
  全部 = 0,
  选中 = 1,
  未选中 = 2,
}

@Component({})
export default class ApplicableShops extends Vue {
  loading = false;

  isVisible = false;

  businessStatusEnum = EShopBusinessStatus;

  ESelectedStatus = ESelectedStatus;

  initFormValue = {
    name: '',
    regionId: '',
    provinceId: '',
    ka: '',
    id: '',
    businessStatus: EShopBusinessStatus.全部,
    poi: '',
    selectedStataus: ESelectedStatus.全部,
  };

  form: {
    regionId: number | string;
    provinceId: number | string;
    id: number | string;
    ka: string;
    businessStatus: EShopBusinessStatus;
    name: string;
    poi: string;
    selectedStataus: ESelectedStatus;
  } = { ...this.initFormValue };

  businessStatusList: EShopBusinessStatus[] = [
    EShopBusinessStatus.全部,
    EShopBusinessStatus.正常营业,
    EShopBusinessStatus.暂停营业,
    EShopBusinessStatus.永久停业,
    EShopBusinessStatus.惩罚下线,
  ];

  selectedStatausList: ESelectedStatus[] = [
    ESelectedStatus.全部,
    ESelectedStatus.选中,
    ESelectedStatus.未选中,
  ];

  kaList: Array<{ key: string; val: number }> = [];

  kaListMap = new Map<string | number, string | number>();

  citys: City[] = [];

  regions: Array<{ city: City[]; id: number; name: string }> = [];

  columns = [
    {
      title: '门店ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'POIID',
      dataIndex: 'poi',
      key: 'poi',
      width: 80,
    },
    {
      title: '门店名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: '省份',
      dataIndex: 'province',
      key: 'province',
      width: 80,
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      width: 80,
    },
    {
      title: '品牌',
      dataIndex: 'ka',
      key: 'ka',
      width: 80,
    },
    {
      title: '营业状态',
      dataIndex: 'businessStatus',
      key: 'businessStatus',
      width: 80,
    },
  ];

  $listData = new BehaviorSubject([]);

  listData: IApplcableShop[] = [];

  pagination = {
    total: 0,
    pageSize: 50,
    current: 1,
    pageSizeOptions: ['10', '20', '50', '100', '200'],
    showSizeChanger: true,
    showTotal: () => {
      return `总数：${this.pagination.total}`;
    },
    onChange: this.paginationChange,
    onShowSizeChange: this.paginationSizeChange,
  };

  selectedIds = '';

  removeIds: number[] = [];

  addIds: number[] = [];

  selectedRowKeys: number[] = [];

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Prop()
  ids?: string;

  @Prop()
  spuType!: 'spu' | 'platformSpu' | 'kaSpu' | 'leadingSpu';

  @Watch('ids', { deep: true })
  watchIds() {
    if (this.ids && this.ids !== '') {
      this.selectedIds = this.ids;
      const selectedRowKeys = this.ids.split(',').map((item) => {
        return Number(item);
      });
      this.selectedRowKeys = [...selectedRowKeys];
    }
  }

  filterByProvinceId() {
    if (Number(this.form.provinceId) < 0) {
      return [];
    } else {
      for (const idx of this.regions) {
        if (Number(this.form.provinceId) === idx.id) {
          return idx.city;
        }
      }
    }
  }

  async fetchKas() {
    if (this.spuType === 'leadingSpu') {
      return this.spuService.leadingSpuKas();
    } else if (this.spuType === 'kaSpu') {
      return this.spuService.kaSpuKas();
    }
  }

  async getKaList() {
    const res = await this.fetchKas();
    if (res && res.length) {
      this.kaList = [...res];

      const map = new Map<string | number, string | number>();
      for (const item of res) {
        const { key, val } = item;
        map.set(key, val);
        map.set(val, key);
      }
      if (map.size) {
        this.kaListMap = new Map(map);
      }
    }
  }

  onChangeProvince(val: number) {
    this.$set(this.form, 'regionId', '');
    if (val !== undefined) {
      this.$set(this.form, 'provinceId', val);
    }
  }

  diff(original: number[], current: number[]) {
    const intersection = current.filter((item) => original.indexOf(item) > -1);
    const toAdd = current.filter((item) => intersection.indexOf(item) === -1);
    return ([] as number[]).concat(intersection, toAdd);
  }

  handleRowChange(selectedRowKeys: number[]) {
    const newKeys = this.diff(this.selectedRowKeys, selectedRowKeys);
    this.selectedRowKeys = [...newKeys];
  }

  getCheckboxProps(record: IApplcableShop) {
    return {
      props: {
        checked: this.selectedRowKeys.includes(record.id),
      },
    };
  }

  resetForm() {
    this.form = { ...this.initFormValue };
  }

  resetPagination() {
    Object.assign(this.pagination, {
      total: 0,
      pageSize: 50,
      current: 1,
    });
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;

      const all = Object.values(response);
      const list: Array<{ id: number; name: string; city: City[] }> = [];
      for (const province of all) {
        list.push({
          city: province.city,
          id: province.province ? province.province.id : 0,
          name: province.province ? province.province.name : '直辖市',
        });
      }
      this.regions = [...list];
    } else {
      this.citys = [];
    }
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /** KA 品牌过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  kaFilterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  async search() {
    this.resetPagination();
    const {
      poi,
      provinceId,
      name,
      ka,
      businessStatus,
      id,
      regionId,
      selectedStataus,
    } = this.form;
    const reqParam: {
      pageSize: number;
      page: number;
      regionId?: number | string;
      provinceId?: number | string;
      id?: number | string;
      ka?: string;
      businessStatus?: EShopBusinessStatus;
      selectedStatus?: ESelectedStatus;
      name?: string;
      poi?: string;
      shopids?: string;
    } = {
      page: this.pagination.current,
      pageSize: this.pagination.pageSize,
    };

    if (poi) {
      reqParam.poi = poi;
    }
    if (name) {
      reqParam.name = name;
    }
    if (ka) {
      reqParam.ka = ka;
    }
    if (id) {
      reqParam.id = id;
    }
    if (regionId) {
      reqParam.regionId = regionId;
    }
    if (provinceId && (!regionId || regionId === '')) {
      reqParam.provinceId = provinceId;
    }

    if (businessStatus !== EShopBusinessStatus.全部) {
      reqParam.businessStatus = businessStatus;
    }
    if (selectedStataus !== ESelectedStatus.全部) {
      reqParam.selectedStatus = selectedStataus;
    }
    if (this.ids !== '') {
      reqParam.shopids = this.ids;
    }
    await this.list(reqParam);
  }

  async getAccessibleShops(params: any) {
    if (this.spuType === 'leadingSpu') {
      return this.spuService.leadingSpuAccessibleShops(params);
    } else if (this.spuType === 'kaSpu') {
      return this.spuService.kaSpuAccessibleShops(params);
    }
  }

  async list(reqParam: {
    pageSize: number;
    page: number;
    regionId?: number | string;
    provinceId?: number | string;
    id?: number | string;
    ka?: string;
    businessStatus?: EShopBusinessStatus;
    name?: string;
    poi?: string;
    selectedStatus?: ESelectedStatus;
    shopids?: string;
  }) {
    const {
      regionId,
      provinceId,
      id,
      ka,
      businessStatus,
      name,
      poi,
      selectedStatus,
      shopids,
    } = reqParam;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      pageSize: reqParam.pageSize,
      page: reqParam.page,
      pick: 0,
    };
    if (id) {
      params.id = id;
    }
    if (name) {
      params.name = name;
    }
    if (ka) {
      params.ka = ka;
    }
    if (poi) {
      params.poi = poi;
    }
    if (typeof provinceId !== 'undefined') {
      params.provinceId = provinceId;
    }
    if (typeof regionId !== 'undefined') {
      params.regionId = regionId;
    }
    if (typeof businessStatus !== 'undefined') {
      params.businessStatus = businessStatus;
    }
    if (typeof selectedStatus !== 'undefined') {
      params.pick = selectedStatus;
    }
    if (typeof shopids !== 'undefined') {
      params.shopids = shopids;
    }

    const res = await this.getAccessibleShops(params);

    if (res) {
      const list: IApplcableShop[] = res.list.map((item) => {
        const cityTarget = this.citys.find((city) => city.id === item.regionid);
        const provinceTarget =
          this.regions.find((province) => province.id === item.regionpid) ||
          this.citys.find((city) => city.id === item.regionpid);
        return {
          name: item.name,
          poi: item.poi,
          id: item.AutoRepairShopDataId,
          ka: `${this.kaListMap.get(item.ka_id) || '--'} `,
          businessStatus: EShopBusinessStatus[item.onlinestatus],
          city: cityTarget ? cityTarget.name : '--',
          province: provinceTarget ? provinceTarget.name : '--',
        };
      });

      this.listData = [...list];

      Object.assign(this.pagination, {
        total: res.total,
        pageSize: +res.pageSize,
        current: res.page,
      });
    }
  }

  onChangeKa(val: number | string) {
    const idx = this.kaList?.findIndex((item) => item.val === val);

    if (this.kaList && typeof idx !== 'undefined' && idx > -1) {
      const targert = this.kaList[idx];
      this.$set(this.form, 'ka', targert.val);
    }
  }

  async paginationChange(current: number) {
    this.pagination.current = current;
    const reqParam: {
      pageSize: number;
      page: number;
      regionId?: number | string;
      id?: number | string;
      ka?: string;
      businessStatus?: EShopBusinessStatus;
      selectedStatus?: ESelectedStatus;
      name?: string;
      poi?: string;
      shopids?: string;
    } = {
      pageSize: this.pagination.pageSize,
      page: current,
    };
    const { id, name, poi, ka, regionId, businessStatus, selectedStataus } =
      this.form;
    if (id) {
      reqParam.id = id;
    }
    if (name) {
      reqParam.name = name;
    }
    if (poi) {
      reqParam.poi = poi;
    }
    if (ka) {
      reqParam.ka = ka;
    }
    if (regionId) {
      reqParam.regionId = regionId;
    }
    if (businessStatus !== EShopBusinessStatus.全部) {
      reqParam.businessStatus = businessStatus;
    }
    if (selectedStataus !== ESelectedStatus.全部) {
      reqParam.selectedStatus = selectedStataus;
    }
    if (this.ids !== '') {
      reqParam.shopids = this.ids;
    }

    await this.list(reqParam);
  }

  async paginationSizeChange(current: number, size: number) {
    this.$set(this.pagination, 'pageSize', size);
    // 重置每页显示数目后，默认重新刷新第1页
    await this.paginationChange(1);
  }

  async open() {
    this.watchIds();
    this.isVisible = true;
    await this.list({
      pageSize: this.pagination.pageSize,
      page: this.pagination.current,
    });
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  save() {
    this.$emit('save', this.selectedRowKeys.join(','));
    this.close();
  }

  reset() {
    this.resetForm();
    this.resetPagination();
    this.selectedIds = '';
    this.removeIds = [];
    this.addIds = [];
    this.selectedRowKeys = [];
    this.listData = [] as IApplcableShop[];
  }

  async mounted() {
    await this.getCityList();
    await this.getKaList();
  }
}
