import { Vue } from 'vue-property-decorator';
import downloadDirective from './download';
import permissionDirective from './permission';

export default {
  install(v: typeof Vue) {
    v.directive('permission', permissionDirective);
    v.directive('download', downloadDirective);
  },
};
