/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class UTILService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async getOssConfig(shopId: number | string) {
    const url = `/tool/video/upload-access-credentials?shopId=${shopId}`;
    try {
      const res = (await this.http.get(url)) as {
        access_id: string;
        host: string;
        policy: string;
        signature: string;
        callback: string;
        dir: string;
        file_name: string;
      };
      return res;
    } catch (error) {
      return null;
    }
  }
}
