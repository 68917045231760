
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class HasNewUserPriceField extends Vue {
  val = false;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled?: boolean;

  @Model('change', {
    type: Boolean,
  })
  value!: boolean;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  mounted() {
    this.watchValue();
  }
}
