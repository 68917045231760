
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';

@Component({})
export default class GiveOrderDuration extends Vue {
  @Prop()
  giveOrderVisible!: boolean;

  @Ref()
  ruleForm!: FormModel;

  @Prop()
  cacheId!: number;

  @lazyInject(OrderService)
  orderService!: OrderService;

  visible = false;

  form = {
    value: '',
  };

  rules = {
    value: [
      { required: true, message: '请输入赠送天数', trigger: 'blur' },
      {
        validator: (
          rule: {[key: string]: string | (() => boolean)},
          value: string,
        ) => {
          return !isNaN(+value);
        },
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  };

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  @Watch('giveOrderVisible')
  giveOrderVisibleChange() {
    this.visible = this.giveOrderVisible;
  }

  cancel() {
    this.ruleForm.resetFields();
    this.$emit('close');
  }

  submitForm() {
    this.ruleForm.validate(async valid => {
      if (valid) {
        const res = await this.orderService.duration(this.cacheId, +this.form.value, this.$el);
        if (!res) return;
        this.$message.success(res);
        this.ruleForm.resetFields();
        this.$emit('close');
      }
    });
  }
}
