/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { IImage, IVideo } from '@/common/interface';
import {
  IKASPU,
  ILeadingSPU,
  IPlatformSPU,
  IResKASPUDetail,
  IResLeadingSPUDetail,
  IResPlatformSPUDetail,
  IResSPU,
  ISPU,
  ISPUCategory,
  ISPUProduct,
  TSPUPlatFlag,
} from '@/common/interface/spu';

/** 普通商品 */
export class SPU implements ISPU {
  category: ISPUCategory = {
    id: 0,
    name: '',
  };
  headlineImage: IImage = {
    id: 0,
    url: '',
  };
  effectiveTimes = 1;
  isCombo = false;
  id = 0;
  isHot = false;
  name = '';
  newUserPriceCent = 0;
  originalPriceCent = 0;
  vipPriceCent = 0;
  isOnline = false;
  unavailableTime: number[] = [];
  images: IImage[] = [];
  introduction = '';
  products: ISPUProduct[] = [];

  hasNewUserPrice = false;
  platFlag = 0 as TSPUPlatFlag;
  indicativePrice = 0;

  static from(data: IResSPU) {
    const spu = new SPU();
    spu.category = data.category;
    spu.headlineImage = data.headlineImage;
    spu.effectiveTimes = data.effectiveTimes;
    spu.id = data.id;
    spu.isHot = data.isHot;
    spu.name = data.name;
    spu.newUserPriceCent = data.newUserPriceCent;
    spu.originalPriceCent = data.originalPriceCent;
    spu.vipPriceCent = data.vipPriceCent;
    spu.isOnline = data.isOnline;
    spu.unavailableTime = data.unavailableTime;
    spu.introduction = data.introduction;
    spu.platFlag = data.platFlag;
    spu.isCombo = data.effectiveTimes > 1;
    spu.hasNewUserPrice = data.newUserPriceCent > 0;
    spu.images = data.images || ([] as IImage[]);

    if (data.products && data.products.length) {
      spu.products = data.products;
    }
    return spu;
  }
}

/** 平台标准商品 */
export class PlatformSPU implements IPlatformSPU {
  indicativePrice = 0;
  headlineImages: IImage[] = [];
  tempSpuShopPois = '';
  shortComment = '';
  commissionRatio = 0;
  isDrawCommission = false;
  videos: IVideo[] = [];
  createTime?: string;
  category: ISPUCategory = {
    id: 0,
    name: '',
  };
  headlineImage: IImage = {
    id: 0,
    url: '',
  };
  effectiveTimes = 1;
  isCombo = false;
  id = 0;
  isHot = false;
  name = '';
  newUserPriceCent = 0;
  originalPriceCent = 0;
  vipPriceCent = 0;
  isOnline = false;
  unavailableTime: number[] = [];
  images: IImage[] = [];
  introduction = '';
  products: ISPUProduct[] = [];

  hasNewUserPrice = false;
  platFlag = 1 as TSPUPlatFlag;

  static from(data: IResPlatformSPUDetail) {
    const spu = new PlatformSPU();
    spu.category = data.category;
    spu.headlineImage = data.headlineImage;
    spu.effectiveTimes = data.effectiveTimes;
    spu.id = data.id;
    spu.isHot = data.isHot;
    spu.name = data.name;
    spu.newUserPriceCent = data.newUserPriceCent;
    spu.originalPriceCent = data.originalPriceCent;
    spu.vipPriceCent = data.vipPriceCent;
    spu.isOnline = data.isOnline;
    spu.unavailableTime = data.unavailableTime;
    spu.introduction = data.introduction;
    spu.platFlag = data.platFlag;
    spu.isCombo = data.effectiveTimes > 1;
    spu.hasNewUserPrice = data.newUserPriceCent > 0;
    spu.images = data.images || ([] as IImage[]);
    spu.videos = data.videos || [];
    spu.headlineImages = [data.headlineImage].concat(
      data.headlineOtherImage || [],
    );

    if (data.products && data.products.length) {
      spu.products = data.products;
    }
    const { tempSpuShopPois, isDrawCommission, shortComment, commissionRatio } =
      data.platformProduct;
    spu.tempSpuShopPois = tempSpuShopPois;
    spu.isDrawCommission = isDrawCommission === 1;
    spu.shortComment = shortComment;
    spu.commissionRatio = commissionRatio;

    return spu;
  }
}

/** 品牌标准商品 */
export class KASPU implements IKASPU {
  ka?: string | undefined;
  tyreTag?: string | undefined;
  tyreSpec?:
    | {
        tyreType?: string | undefined;
        tyreWidth?: string | number | undefined;
        tyreRatio?: string | number | undefined;
        tyreDiameter?: string | number | undefined;
      }
    | undefined;
  tyreType?: string | undefined;
  tyreWidth?: string | number | undefined;
  tyreRatio?: string | number | undefined;
  tyreDiameter?: string | number | undefined;
  indicativePrice = 0;
  headlineImages: IImage[] = [];
  tempSpuShopIds = '';
  shortComment = '';
  isDrawCommission = false;
  videos: IVideo[] = [];
  createTime?: string;
  category: ISPUCategory = {
    id: 0,
    name: '',
  };
  headlineImage: IImage = {
    id: 0,
    url: '',
  };
  effectiveTimes = 1;
  isCombo = false;
  id = 0;
  isHot = false;
  name = '';
  newUserPriceCent = 0;
  originalPriceCent = 0;
  vipPriceCent = 0;
  commissionRatio = 0;
  isOnline = false;
  unavailableTime: number[] = [];
  images: IImage[] = [];
  introduction = '';
  products: ISPUProduct[] = [];

  hasNewUserPrice = false;
  platFlag = 1 as TSPUPlatFlag;

  static from(data: IResKASPUDetail) {
    const spu = new KASPU();
    spu.category = data.category;
    spu.headlineImage = data.headlineImage;
    spu.effectiveTimes = data.effectiveTimes;
    spu.id = data.id;
    spu.isHot = data.isHot;
    spu.name = data.name;
    spu.newUserPriceCent = data.newUserPriceCent;
    spu.originalPriceCent = data.originalPriceCent;
    spu.vipPriceCent = data.vipPriceCent;
    spu.isOnline = data.isOnline;
    spu.unavailableTime = data.unavailableTime;
    spu.introduction = data.introduction;
    spu.platFlag = data.platFlag;
    spu.isCombo = data.effectiveTimes > 1;
    spu.hasNewUserPrice = data.newUserPriceCent > 0;
    spu.images = data.images || ([] as IImage[]);
    spu.videos = data.videos || [];
    spu.headlineImages = [data.headlineImage].concat(
      data.headlineOtherImage || [],
    );

    if (data.products && data.products.length) {
      spu.products = data.products;
    }
    if (data.ext_json) {
      const { aspectRatio, sectionWidth, tireType, tireTag, wheelDiameter } =
        data.ext_json;
      spu.tyreTag = tireTag || '';
      spu.tyreSpec = {
        tyreDiameter: wheelDiameter,
        tyreRatio: aspectRatio,
        tyreWidth: sectionWidth,
        tyreType: tireType,
      };
    }
    const { isDrawCommission, shortComment, commissionRatio } = data.platformProduct;
    spu.tempSpuShopIds = data.tempSpuShopIds;
    spu.commissionRatio = commissionRatio;

    spu.isDrawCommission = isDrawCommission === 1;
    spu.shortComment = shortComment;

    return spu;
  }
}

/** 批量操作/多门店商品 */
export class LeadingSPU implements ILeadingSPU {
  autoOffShelfTime = '';
  isAutoOffShelf = false;
  tempSpuShopIds = '';
  category: ISPUCategory = {
    id: 0,
    name: '',
  };
  headlineImage: IImage = {
    id: 0,
    url: '',
  };
  effectiveTimes = 1;
  isCombo = false;
  id = 0;
  isHot = false;
  name = '';
  newUserPriceCent = 0;
  originalPriceCent = 0;
  vipPriceCent = 0;
  isOnline = false;
  unavailableTime: number[] = [];
  images: IImage[] = [];
  introduction = '';
  products: ISPUProduct[] = [];

  hasNewUserPrice = false;
  platFlag = 2 as TSPUPlatFlag;
  indicativePrice = 0;
  filterTag = '';
  remark = '';

  static from(data: IResLeadingSPUDetail) {
    const spu = new LeadingSPU();
    spu.category = data.category;
    spu.headlineImage = data.headlineImage;
    spu.effectiveTimes = data.effectiveTimes;
    spu.id = data.id;
    spu.isHot = data.isHot;
    spu.name = data.name;
    spu.newUserPriceCent = data.newUserPriceCent;
    spu.originalPriceCent = data.originalPriceCent;
    spu.vipPriceCent = data.vipPriceCent;
    spu.isOnline = data.isOnline;
    spu.unavailableTime = data.unavailableTime;
    spu.introduction = data.introduction;
    spu.platFlag = data.platFlag;
    spu.isCombo = data.effectiveTimes > 1;
    spu.hasNewUserPrice = data.newUserPriceCent > 0;
    spu.images = data.images || ([] as IImage[]);
    spu.tempSpuShopIds = data.tempSpuShopIds;
    if (data.products && data.products.length) {
      spu.products = data.products;
    }
    if (data.note_tag) {
      spu.filterTag = data.note_tag;
    }
    if (data.remark) {
      spu.remark = data.remark;
    }

    return spu;
  }
}
