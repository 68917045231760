
import { BusinessTime, BusinessTimeSegment } from '@/models/businessTime.model';
import { Vue, Component, Model, Watch, Ref } from 'vue-property-decorator';
import BusinessTimePicker from '../BusinessTimePicker.vue';

@Component({
  components: {
    BusinessTimePicker,
  },
})
export default class BusinessTimeField extends Vue {
  watchable = true;

  val: unknown[] = [];

  businessTimeText = '';

  businessTime: BusinessTime = new BusinessTime();

  businessTimeSegments: BusinessTimeSegment[] = [];

  get isValidBusinessTime() {
    return this.businessTime.isValid();
  }

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('value')
  watchValue() {
    if (!this.watchable) {
      return;
    }
    this.businessTimeText = this.value;
  }

  @Watch('isValidBusinessTime')
  watchValidBusinessTime() {
    this.$emit('updateSubmittable', this.isValidBusinessTime === true);
  }

  @Watch('businessTimeSegments', { deep: true })
  watchBusinessTimeSegments() {
    this.$emit('change', this.businessTime.toString());
  }

  @Ref()
  businessTimePicker!: BusinessTimePicker;

  closeTimepicker() {
    this.businessTime.sortValidSegments();
  }

  addBizTime() {
    const segment = this.businessTime.createSegment();
    this.editSegment(segment);
  }

  async editSegment(segment: BusinessTimeSegment) {
    this.businessTimePicker.edit(segment);
  }

  /** 删除某段营业时间 */
  removeSegment(index: number) {
    this.businessTime.removeSegment(index);
  }

  initBusinessTime() {
    this.businessTime = BusinessTime.parse(this.businessTimeText);
    this.businessTime.sortValidSegments();

    this.businessTime.$segments.subscribe((segments: BusinessTimeSegment[]) => {
      this.businessTimeSegments = segments;
    });
  }

  mounted() {
    this.watchValue();
    this.watchable = false;
    this.initBusinessTime();
  }
}
