
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class BOrderField extends Vue {
  val = { poi: '', name: '' };

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: { poi: string; name: string };

  @Watch('value')
  watchValue() {
    if (!this.value) {
      this.val = { poi: '', name: '' };
    } else {
      this.val = { ...this.value };
    }
  }

  mounted() {
    this.watchValue();
  }
}
