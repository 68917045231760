
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { City } from '@/common/interface/shop';
import { Order, OrderListParams } from '@/common/interface/order';
import { AuthEnum } from '@/services/permission.service';
import GiveOrderDuration from './components/GiveOrderDuration.vue';
import SalesmanChange from './components/SalesmanChange.vue';
import UploadPaymentOrderNo from './components/UploadPaymentOrderNo.vue';
import PartialRefoundPopover from './components/PartialRefundPopover.vue';
import { EOperationType } from '@/common/enum/shop';
import BCOrderRemarkModal from './components/BCOrderRemarkModal.vue';

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger?: boolean;
  showQuickJumper?: boolean;
  hideOnSinglePage?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showTotal?: (...args: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any) => any;
}

interface Form {
  shopName: string;
  regionId: '' | number;
  staffName: string;
  payStatus: number;
  payChannel: string;
  poiId: string;
  orderId: string;
  packageName: string | undefined;
}

enum AdConfirmEnum {
  推广通投放确认 = 0,
  投放确认中 = 1,
  投放已同意 = 2,
  投放已拒绝 = 3,
}

@Component({
  filters: {
    payTimeFormat(time: string) {
      return time.startsWith('0000') ? null : time;
    },
  },
  components: {
    GiveOrderDuration,
    SalesmanChange,
    UploadPaymentOrderNo,
    PartialRefoundPopover,
    BCOrderRemarkModal,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name?.indexOf('main.order.list.b') === -1) {
      return next();
    }
    (this as unknown as { pagination: Pagination }).pagination.current = 1;
    (this as unknown as { form: Form }).form.shopName = '';
    (this as unknown as { form: Form }).form.payChannel = '';
    (this as unknown as { form: Form }).form.regionId = '';
    (this as unknown as { form: Form }).form.staffName = '';
    (this as unknown as { form: Form }).form.orderId = '';
    (this as unknown as { form: Form }).form.packageName = undefined;
    (this as unknown as { form: Form }).form.payStatus = +(
      to.query.payStatus || 0
    );
    next();
  },
})
export default class BOrderList extends Vue {
  AuthEnum = AuthEnum;

  AdConfirmEnum = AdConfirmEnum;

  EOperationType = EOperationType;

  @lazyInject(OrderService)
  orderService!: OrderService;

  form: Form = {
    shopName: '',
    regionId: '',
    staffName: '',
    payStatus: 0,
    poiId: '',
    orderId: '',
    payChannel: '',
    packageName: undefined,
  };

  listData: Order[] = [];

  citys: City[] = [];

  pagination: Pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  giveOrderVisible = false;

  packages: string[] = [];

  @Ref('salesmanChange')
  salesmanChange!: SalesmanChange;

  @Ref('uploadPaymentOrderNo')
  uploadPaymentOrderNo!: UploadPaymentOrderNo;

  @Ref('bcOrderRemarkModal')
  bcOrderRemarkModal!: BCOrderRemarkModal;

  currentOrder: Order | null = null;

  openSalesmanChange(order: Order) {
    this.currentOrder = order;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.salesmanChange as any).open();
  }

  openUploadPaymentOrderNoModal(order: Order) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.uploadPaymentOrderNo as any).open(order);
  }

  closeUploadPaymentOrderNo() {
    this.currentOrder = null;
    this.handleSearch();
  }

  closeSalesmanChange() {
    this.currentOrder = null;
    this.handleSearch();
  }

  goToShopDetail(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'main.shop.detail',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }

  cacheId = -1;

  mounted() {
    const { payStatus, shopName, poiId, orderId, packageName, payChannel } =
      this.$route.query;
    this.form.payStatus = +(payStatus || 0);
    if (shopName) {
      this.form.shopName = shopName as string;
    }
    if (poiId) {
      this.form.poiId = poiId as string;
    }
    if (orderId) {
      this.form.orderId = orderId as string;
    }
    if (packageName) {
      this.form.packageName = packageName as string;
    }
    if (payChannel) {
      this.form.payChannel = payChannel as string;
    }
    this.handleSearch();
    this.getCityList();
    this.getPackages();
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const {
      shopName,
      regionId,
      staffName,
      payStatus,
      payChannel,
      poiId,
      orderId,
      packageName,
    } = this.form;
    const query: { [key: string]: any } = {
      timestamp: `${Math.round(Date.now() / 1000)}`,
    };
    if (shopName) {
      query.shopName = shopName;
    }
    if (regionId) {
      query.regionId = regionId;
    }
    if (staffName) {
      query.staffName = staffName;
    }
    if (poiId) {
      query.poiId = poiId;
    }
    if (orderId) {
      query.orderId = orderId;
    }
    if (packageName) {
      query.packageName = packageName;
    }
    if (payStatus) {
      query.payStatus = payStatus;
    }
    if (payChannel) {
      query.payChannel = payChannel;
    }
    this.$router.push({
      query,
    });
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;
    const {
      shopName,
      regionId,
      staffName,
      payStatus,
      payChannel,
      poiId,
      orderId,
      packageName,
    } = this.$route.query;

    const param: OrderListParams = {
      page: current,
      pageSize,
      payStatus: 0,
    };
    if (shopName) {
      param.shopName = shopName as string;
    }
    if (regionId) {
      param.regionId = regionId as string;
    }
    if (staffName) {
      param.staffName = staffName as string;
    }
    if (poiId) {
      param.poiId = poiId as string;
    }
    if (payStatus) {
      param.payStatus = +payStatus;
    }
    if (payChannel) {
      param.payChannel = payChannel as string;
    }
    if (orderId) {
      param.orderId = orderId as string;
    }
    if (packageName) {
      param.packageName = packageName as string;
    }

    const data = await this.orderService.getList(param, this.$el);
    if (!data) {
      this.pagination = {
        total: 0,
        pageSize: 10,
        current: 1,
        onChange: this.paginationChange,
      };
      this.listData = [];
      return;
    }
    const { total, list } = data;
    this.pagination = {
      total,
      pageSize,
      current,
      onChange: this.paginationChange,
    };
    this.listData = list;
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      shopName: '',
      regionId: '',
      staffName: '',
      payStatus: 0,
      poiId: '',
      orderId: '',
      payChannel: '',
      packageName: undefined,
    };
    // const query: { [key: string]: any } = {
    //   timestamp: `${Math.round(Date.now() / 1000)}`,
    // };
    // if (shopName) {
    //   query.shopName = shopName;
    // }
    // if (regionId) {
    //   query.regionId = regionId;
    // }
    // if (staffName) {
    //   query.staffName = staffName;
    // }
    // if (poiId) {
    //   query.poiId = poiId;
    // }
    // if (orderId) {
    //   query.orderId = orderId;
    // }
    // if (packageName) {
    //   query.packageName = packageName;
    // }
    // if (payStatus) {
    //   query.payStatus = payStatus;
    // }
    // this.$router.push({
    //   query,
    // });

    this.$router.push({
      query: {},
    });
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.orderService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    this.handleSearch();
  }

  /** 赠送时长 */
  give(id: number) {
    this.cacheId = id;
    this.giveOrderVisible = true;
  }

  /** 推广通广告投放确认 */
  showAdConfirmModal(adConfirmStatus: AdConfirmEnum, id: number | string) {
    if (adConfirmStatus === AdConfirmEnum.投放确认中) {
      return;
    }
    if (adConfirmStatus === AdConfirmEnum.投放已同意) {
      return;
    }

    this.$confirm({
      title: '广告投放确认',
      content: '向门店确认是否开启广告投放计划，请确认门店信息无误。',
      okText: '提交主账号确认',
      cancelText: '取消',

      onOk: () => {
        return this.popupAdConfirm(id);
      },
      onCancel() {},
    });
  }

  async popupAdConfirm(id: number | string) {
    const res = await this.orderService.launchAdConirmation(id);
    if (res) {
      this.$message.success('发起“广告投放确认”成功！');
      this.handleSearch();
    }
  }

  openRemarkModal(orderId: number, text = '') {
    this.bcOrderRemarkModal.open({
      type: 'BOrder',
      title: '追加备注',
      orderId: `${orderId}`,
      text,
    });
  }

  async getPackages() {
    const res = await this.orderService.BOrderPackages();
    if (res && res.length) {
      this.packages = [...res];
    }
  }
}
