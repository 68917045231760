
import { Vue, Component } from 'vue-property-decorator';
import BaseBIPage from '../order/components/BaseBIPage.vue';

@Component({
  components: {
    BaseBIPage,
  },
})

export default class Map extends Vue {
  path = '';

  name = '';

  async created() {
    this.name = this.$route.params.name;
    const path = `6bb2fdf7-ece4-4db6-85de-362969b203c1&param=%5B%7B"paramKey"%3A"regionid"%2C"conditionList"%3A%5B%7B"operate"%3A"%3D"%2C"value"%3A"${this.$route.params.regionid}"%7D%5D%2C"joinType"%3A"and"%7D%5D`;
    this.path = path;
  }
}
