import { IBrand } from '@/common/interface/account';

export class Brand implements IBrand {
  name = '';
  id = 0;
  brandcode = '';
  brandAdmin = '';
  createTime = '';
  shopNum = '';
  protection = '';
  logo = '';
  headlineImage = '';
  isLinked = false;
  autoWithdrawl = '否';
  serviceProvider = '';
  primaryAccount = '';

  static from(data: any) {
    const brand = new Brand();
    brand.id = data.id || 0;
    brand.name = data.name;
    brand.brandcode = data.brand_code || '';
    brand.createTime = data.create_time || '';
    brand.shopNum = data.shop_count;
    brand.protection = data.protection_rules || '--';
    brand.logo = data.logo || '';
    brand.headlineImage =
      data.header_pic.length > 0 ? data.header_pic[0].url : '';
    brand.isLinked = data.is_abutment;
    brand.autoWithdrawl = data.is_auto_payment;
    brand.serviceProvider = data.service_name || '--';
    brand.primaryAccount = data.owner_id || '--';
    brand.brandAdmin = data.staff_id || '--';
    return brand;
  }
}
