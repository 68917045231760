import { BehaviorSubject, from, throttleTime, distinctUntilChanged, mergeMap, Subject } from 'rxjs';
import { BASE_RBAC_URL } from '@/common/constant/index';
import axios from 'axios';

class PermissionManager {
  private eventsMap: Map<string, any>;

  private resultMap: Map<string, any>;

  public constructor() {
    this.eventsMap = new Map();
    this.resultMap = new Map();
  }

  private getData(names: string) {
    const userStorage = localStorage.getItem('user');
    const userId = userStorage ? JSON.parse(userStorage).id : null;
    const AUTHORIZATION = userStorage ? JSON.parse(userStorage).token : null;
    return axios.get(`${BASE_RBAC_URL}/user/${userId}/permission/${encodeURIComponent(names)}`, {
      data: {
        loadingEl: document.body,
      },
      headers: {
        AUTHORIZATION,
      },
    });
  }

  public send(namesList: string[]) {
    const names = namesList.join(',');
    let result = this.resultMap.get(names);
    if (!result) {
      result = new BehaviorSubject({});
      this.resultMap.set(names, result);
    }

    let events = this.eventsMap.get(names);
    if (!events) {
      events = new Subject();
      events
        .pipe(
          throttleTime(3000),
          mergeMap((name2: string) => from(this.getData(name2))),
          distinctUntilChanged((a: object, b: object) => {
            return JSON.stringify(a) === JSON.stringify(b);
          }),
        )
        .subscribe(result);
      this.eventsMap.set(names, events);
    }

    events.next(names);
    return result;
  }
}

const manager = new PermissionManager();
export default manager;
