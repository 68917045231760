
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';

@Component({
  components: {},
})
export default class ShopInfoChange extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  /**
   * 返回
   */
  back() {
    this.$router.push({
      name: 'main.shop.list.examine',
    });
  }

  /**
   *
   * 刷新
   */
  refresh() {}
}
