
import { $delay } from '@/common/utils';
import { AgentAccountRes } from '@/common/interface/account';
import lazyInject from '@/di';
import { AgentService } from '@/services/agent.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class EditAgentModal extends Vue {
  loading = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  agent?: AgentAccountRes;

  isVisible = false;

  form: {
    id: number;
    name: string;
    isDisabled: boolean;
  } = {
    name: '',
    isDisabled: false,
    id: -1,
  };

  @Watch('agent', { deep: true, immediate: true })
  watchAgentChange() {
    if (this.agent == null) {
      this.reset();
      return;
    }
    const { aid, agentName, status } = this.agent;
    this.form = {
      id: aid,
      name: agentName,
      isDisabled: status !== 1,
    };
  }

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  async submit() {
    if (!this.form.name) {
      return;
    }

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;

      let res;
      if (this.agent == null) {
        res = await this.agentService.create(this.form.name);
      } else {
        res = await this.agentService.modify(
          this.form.id,
          this.form.name,
          +!this.form.isDisabled,
        );
      }

      if (res) {
        const msg = this.agent == null ? '添加成功' : '修改成功';
        this.$message.success(msg);

        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
    } catch (error) {
      this.$message.info(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      name: '',
      isDisabled: false,
      id: -1,
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close', true);
  }
}
