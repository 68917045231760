import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import WithdrawList from '@/views/withdraw/WithdrawList.vue';

const withdrawRoutes: RouteConfig = {
  name: 'withdraw',
  path: '/withdraw',
  component: Main,
  redirect: {
    name: 'withdraw.list',
  },
  meta: {
    title: '提现管理',
  },
  children: [
    {
      name: 'withdraw.list',
      path: '/withdraw/list',
      component: WithdrawList,
      meta: {
        title: '提现列表',
      },
    },
  ],
};

export default withdrawRoutes;
