
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class AdvertiserMobilePhoneNumberField extends Vue {
  val = '';

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);

    this.$emit('updateSubmittable', /1[3-9]\d{9}/.test(this.val));
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
