
import { DebounceTime } from '@/common/decorator';
import { IResRegion } from '@/common/interface/region';
import { IEditShopFieldAddress } from '@/common/interface/shop';
import lazyInject from '@/di';
import RegionService from '@/services/region.service';
import { v4 } from 'uuid';
import { Vue, Component, Watch, Model } from 'vue-property-decorator';

@Component({})
export default class AddressField extends Vue {
  val: IEditShopFieldAddress = {
    address: '',
    regions: [] as number[],
    latitude: '',
    longitude: '',
    locationName: '',
  };

  regions: IResRegion[] = [];

  zoom = 14;

  private locationNameStyle = {
    'border-radius': '4px',
    border: 'none',
    background: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
  };

  private uuid = v4;

  isReady = false;

  @Model('change')
  value!: IEditShopFieldAddress;

  @DebounceTime(618)
  @Watch('val', { deep: true })
  watchInternalVal(): void {
    const { address, regions, latitude, longitude, locationName } = this.val;
    if (
      address === '' ||
      latitude === '' ||
      longitude === '' ||
      locationName === '' ||
      regions.length < 3
    ) {
      this.$emit('updateSubmittable', false);
    } else {
      this.$emit('updateSubmittable', true);
    }

    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      const intersection = this.val.regions.filter(
        (item) => this.value.regions.indexOf(item) > -1,
      );
      if (
        this.val.address !== this.value.address ||
        this.val.latitude !== this.value.latitude ||
        this.val.longitude !== this.value.longitude ||
        this.val.locationName !== this.value.locationName ||
        intersection.length !== 3
      ) {
        this.val = Object.assign({}, this.value);
      }
    } else {
      this.val = {
        address: '',
        regions: [] as number[],
        latitude: '',
        longitude: '',
        locationName: '',
      };
    }
    if (!this.isReady) {
      this.isReady = true;
    }
  }

  @lazyInject(RegionService)
  protected regionService!: RegionService;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMapClicked(e: any) {
    if (e.lnglat == null) {
      return;
    }

    const { lng, lat } = e.lnglat;

    this.$set(this.val, 'latitude', lat);
    this.$set(this.val, 'longitude', lng);
  }

  async onChangeRegion(value: string[]) {
    this.$set(this.val, 'regions', [...value]);
    await this.$nextTick();
  }

  async allRegions() {
    const res = await this.regionService.allRegions();
    if (res && res.length) {
      this.regions = [...res];
    }
  }

  async mounted() {
    await this.allRegions();
    this.watchValue();
  }
}
