
import { ESPUField } from '@/common/enum/spu';
import { ISPUField } from '@/common/interface/spu';
import CategoryField from '@/components/spu/fields/CategoryField.vue';
import ComboTotalPriceField from '@/components/spu/fields/ComboTotalPriceField.vue';
import EffectiveTimesField from '@/components/spu/fields/EffectiveTimesField.vue';
import HasNewUserPriceField from '@/components/spu/fields/HasNewUserPriceField.vue';
import HeadlineImageField from '@/components/spu/fields/HeadlineImageField.vue';
import IdField from '@/components/spu/fields/IdField.vue';
import ImagesField from '@/components/spu/fields/ImagesField.vue';
import IntroductionField from '@/components/spu/fields/IntroductionField.vue';
import IsComboField from '@/components/spu/fields/IsComboField.vue';
import IsHotField from '@/components/spu/fields/IsHotField.vue';
import NameField from '@/components/spu/fields/NameField.vue';
import NewUserPriceCentField from '@/components/spu/fields/NewUserPriceCentField.vue';
import OriginalPriceCentField from '@/components/spu/fields/OriginalPriceCentField.vue';
import ProductsField from '@/components/spu/fields/ProductsField.vue';
import UnavailableTimeField from '@/components/spu/fields/UnavailableTimeField.vue';
import VideosField from '@/components/spu/fields/VideosField.vue';
import VipPriceCentField from '@/components/spu/fields/VipPriceCentField.vue';
import lazyInject from '@/di';
import { SPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import SPUService from '@/services/spu.service';
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class SPUAudit extends Vue {
  toAuditForm: { [key: string]: any } = {};

  toAuditFormItems: ISPUField[] = [];

  toAuditSpu: SPU = new SPU();

  rawForm: { [key: string]: any } | null = null;

  rawSpu: SPU = new SPU();

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'spu',
    vipPriceValidator: this.vipPriceValidator,
    originalPriceValidator: this.originalPriceValidator,
    newUserPriceValidator: this.newUserPriceValidator,
  });

  @Prop({
    default: {},
  })
  readonly auditUpdateData!: { [key: string]: any };

  @Prop()
  readonly rawData!: { [key: string]: any };

  @Watch('auditUpdateData', { deep: true })
  async watchAuditUpdateData() {
    if (!this.auditUpdateData) {
      this.toAuditForm = {};
      return;
    }

    const spu = SPU.from(this.auditUpdateData as any);
    this.toAuditSpu = spu;
    this.toAuditForm = this.initSpuForm(spu);

    const shouldGetIndicativePrice = this.toAuditForm.category.id !== 0;

    const fields = await this.initSpuConfig({
      previewRawSpu: false,
      spuForm: this.toAuditForm,
      refreshIndicative: shouldGetIndicativePrice,
    });
    this.toAuditFormItems = [...fields];
  }

  @Watch('rawData', { deep: true })
  async watchRawData() {
    if (this.rawData == null) {
      return;
    }
    const rawSpu = SPU.from(this.rawData as any);
    this.rawSpu = rawSpu;
    this.rawForm = this.initSpuForm(rawSpu);
  }

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  getComponent(type: ESPUField) {
    switch (type) {
      case ESPUField.CATEGORY: {
        return CategoryField;
      }
      case ESPUField.COMBO_TOTAL_PRICE: {
        return ComboTotalPriceField;
      }
      case ESPUField.EFFECTIVE_TIMES: {
        return EffectiveTimesField;
      }
      case ESPUField.HAS_NEW_USER_PRICE: {
        return HasNewUserPriceField;
      }
      case ESPUField.HEADLINE_IMAGE: {
        return HeadlineImageField;
      }
      case ESPUField.ID: {
        return IdField;
      }
      case ESPUField.IMAGES: {
        return ImagesField;
      }
      case ESPUField.INTRODUCTION: {
        return IntroductionField;
      }
      case ESPUField.IS_COMBO: {
        return IsComboField;
      }
      case ESPUField.IS_HOT: {
        return IsHotField;
      }

      case ESPUField.NAME: {
        return NameField;
      }
      case ESPUField.NEW_USER_PRICE_CENT: {
        return NewUserPriceCentField;
      }
      case ESPUField.ORIGINAL_PRICE_CENT: {
        return OriginalPriceCentField;
      }
      case ESPUField.PRODUCTS: {
        return ProductsField;
      }
      // case ESPUField.HEADLINE_IMAGES: {
      //   return HeadlineImagesField;
      // }
      // case ESPUField.IS_DRAW_COMMISSION: {
      //   return IsDrawCommissionField;
      // }
      // case ESPUField.AUTO_OFF_SHELF_TIME: {
      //   return AutoOffShelfTimeField;
      // }
      // case ESPUField.KA: {
      //   return KaField;
      // }
      // case ESPUField.IS_AUTO_OFF_SHELF: {
      //   return IsAutoOffShelfField;
      // }
      // case ESPUField.SHORT_COMMENT: {
      //   return ShortCommentField;
      // }
      // case ESPUField.TEMP_SPU_SHOP_IDS: {
      //   return TempSpuShopIdsField;
      // }
      // case ESPUField.TEMP_SPU_SHOP_POIS: {
      //   return TempSpuShopPoisField;
      // }
      // case ESPUField.TYRE_SPEC: {
      //   return TyreSpecField;
      // }
      // case ESPUField.TYRE_TAG: {
      //   return TyreTagField;
      // }
      case ESPUField.UNAVAILABLE_TIME: {
        return UnavailableTimeField;
      }
      case ESPUField.VIDEOS: {
        return VideosField;
      }
      case ESPUField.VIP_PRICE_CENT: {
        return VipPriceCentField;
      }
      default: {
        return null;
      }
    }
  }

  /**
   * @note 审核系统中对商品的审核并不会触发关于价格的验证器（只读查看模式），
   * 因此验证器内部选取商品送审或原始数据随选其一即可（新商品会没有原始数据，选择送审数据更为合适）。
   */
  vipPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { originalPriceCent, products } = this.toAuditForm;

    if (products.length) {
      return +value < +this.toAuditForm.comboTotalPrice.originalPriceCent;
    } else {
      if (+value > 0 && (!originalPriceCent || isNaN(+originalPriceCent))) {
        return true;
      }
      return +value < +originalPriceCent;
    }
  }

  newUserPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { vipPriceCent } = this.toAuditForm;
    if (!vipPriceCent || isNaN(+vipPriceCent)) {
      return true;
    }
    return +value < +vipPriceCent;
  }

  originalPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { products } = this.toAuditForm;
    if (products.length) {
      return +this.toAuditForm.comboTotalPrice.originalPriceCent > 0;
    } else {
      return +value > 0;
    }
  }

  initSpuForm(spu: SPU) {
    const form: { [key: string]: any } = {};
    const times = spu.effectiveTimes > 0 ? spu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(spu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: spu.originalPriceCent,
      vipPriceCent: spu.vipPriceCent,
      newUserPriceCent: spu.newUserPriceCent,
    };

    return form;
  }

  async initSpuConfig(params: {
    previewRawSpu: boolean;
    spuForm: { [key: string]: any };
    refreshIndicative?: boolean;
  }) {
    const { previewRawSpu, spuForm, refreshIndicative } = params;

    const spu = { ...spuForm };
    const detail = _.cloneDeep(this.initSpuFields);

    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);
    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const headlineImageIdx = detail.findIndex(
      (item) => item.label === ESPUField.HEADLINE_IMAGE,
    );
    const hasNewUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.HAS_NEW_USER_PRICE,
    );
    const imagesIdx = detail.findIndex(
      (item) => item.label === ESPUField.IMAGES,
    );

    if (spu.id === 0) {
      // 新增商品时允许更换是否套餐
      detail[isComboIndex].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 编辑商品不允许更改是否套餐的选项
      detail[isComboIndex].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    if (refreshIndicative === true) {
      const indicativePrice = await this.spuService.indicativePrice({
        shopId: Number(this.auditUpdateData.shopId),
        categoryId: spu.category.id,
      });
      if (indicativePrice) {
        this.toAuditSpu.indicativePrice = indicativePrice;
        // eslint-disable-next-line standard/computed-property-even-spacing
        detail[
          vipPriceIdx
        ].extra = `参考价：该商品分类，同城均价¥${indicativePrice}`;
      } else {
        detail[vipPriceIdx].extra = '暂无参考价';
      }
    } else {
      detail[vipPriceIdx].extra =
        this.toAuditSpu.indicativePrice > 0
          ? `参考价：该商品分类，同城均价¥${this.toAuditSpu.indicativePrice}`
          : '暂无参考价';
    }
    delete detail[headlineImageIdx].extra;
    delete detail[imagesIdx].extra;

    if (previewRawSpu === true) {
      delete detail[hasNewUserPriceIdx].extra;
      delete detail[vipPriceIdx].extra;

      if (spu.hasNewUserPrice === true) {
        detail[hasNewUserPriceIdx].isHidden = true;
      } else {
        detail[hasNewUserPriceIdx].isHidden = false;
      }
    }

    return detail;
  }

  getToAuditData() {
    const {
      category,
      originalPriceCent,
      newUserPriceCent,
      vipPriceCent,
      effectiveTimes,
      images,
      name,
      id,
      isOnline,
      isHot,
      headlineImage,
      introduction,
      unavailableTime,
      products,
    } = this.auditUpdateData;

    const toAuditSpu: { [key: string]: any } = {
      category,
      originalPriceCent,
      vipPriceCent,
      newUserPriceCent,
      effectiveTimes,
      images,
      name,
      id,
      isOnline,
      isHot,
      headlineImage,
      introduction,
      unavailableTime,
    };
    if (products && products.length) {
      toAuditSpu.products = products;
    }
    return toAuditSpu;
  }

  mounted() {
    this.watchAuditUpdateData();
    this.watchRawData();
  }
}
