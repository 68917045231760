
import { Vue, Component, Watch, Model, Prop } from 'vue-property-decorator';
import { IShopStaffConfig } from '@/common/interface/shop';
import ShopService from '@/services/shop.service';
import lazyInject from '@/di';

@Component({})
export default class StaffField extends Vue {
  isVisible = false;

  isLoading = false;

  val: IShopStaffConfig = {};

  get isReady() {
    if (!Object.keys(this.val).length) {
      return false;
    }
    return true;
  }

  get sortedKey() {
    if (!Object.keys(this.val).length) {
      return [];
    }
    const fields = ['收银员', '店长', '合伙人'];

    const keys = Object.keys(this.val);
    const kernel = fields.filter((item) => {
      return keys.indexOf(item) > -1;
    });
    const diff = keys.filter((item: string) => kernel.indexOf(item) === -1);
    const sortedKeyList = [...kernel, ...diff];
    return sortedKeyList;
  }

  get disabled() {
    if (!this.toAddMember.name || !this.toAddMember.phone) {
      return true;
    }

    return !this.testPhoneValid();
  }

  toAddMember = {
    phone: '',
    name: '',
  };

  @Prop({
    default: () => {
      return '';
    },
  })
  shopId!: string;

  @Model()
  value!: { [key: string]: any };

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  // @Watch('val')
  // watchInternalVal(): void {
  //   this.$emit('change', this.val === true ? '有' : '无');
  // }

  @Watch('value')
  watchValue() {
    this.val = Object.assign({}, this.value);
  }

  testPhoneValid(): boolean {
    return /^1[3-9]\d{9}$/.test(this.toAddMember.phone);
  }

  showDeleteConfirm(params: {
    role: string;
    name: string;
    phone: string;
    userId: number;
  }) {
    const { role, name, phone } = params;
    this.$confirm({
      title: '是否删除门店子账号操作权限?',
      content: `${role}：${name}（${phone}）`,
      okText: '确认删除',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => this.delMember(params),
      onCancel: () => {},
    });
  }

  /** 新增子账号 */
  async addMember() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    try {
      const res = await this.shopService.shopStaffAdd({
        shopId: this.shopId,
        phone: this.toAddMember.phone,
        name: this.toAddMember.name,
        roleId: 3,
      });

      if (res) {
        this.$message.success(res);
        await this.fetchStaff();
        this.close();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async delMember(params: {
    role: string;
    name: string;
    phone: string;
    userId: number;
  }) {
    const res = await this.shopService.shopStaffDelete({
      shopId: this.shopId,
      group: params.role,
      userId: params.userId,
    });
    if (res) {
      this.$message.success(res);
      await this.fetchStaff();
    }
  }

  async fetchStaff() {
    if (!this.shopId) {
      return;
    }
    const res = await this.shopService.shopStaff(this.shopId);
    if (res) {
      this.val = { ...res };
      this.$emit('forceRefresh');
    }
  }

  close() {
    this.isVisible = false;
    this.toAddMember = {
      phone: '',
      name: '',
    };
  }

  mounted() {
    this.watchValue();
    this.$emit('updateSubmittable', true);
  }
}
