
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class EditThirdpartnarModal extends Vue {
  loading = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  thirdpartnar?: {
    shopId: string | number;
    oldVenderId: any;
    venderId: string | number;
  };

  isVisible = false;

  form: {
    oldVenderId: any;
    venderId: number | string;
  } = {
    oldVenderId: '',
    venderId: '',
  };

  @Watch('thirdpartnar', { deep: true, immediate: true })
  watchThirdpartnarChange() {
    if (this.thirdpartnar == null) {
      this.reset();
      return;
    }

    const { venderId, oldVenderId } = this.thirdpartnar;

    this.form = {
      venderId,
      oldVenderId,
    };
  }

  @lazyInject(ShopService)
  protected shopService!: ShopService;


  async submit() {
    if (!this.thirdpartnar) {
      return;
    }


    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      const res = await this.shopService.postVenderIdChange(this.thirdpartnar.shopId, this.form.venderId);
      if (res) {
        this.$message.success('修改成功');
        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
    } catch (error) {
      this.$message.info(`${error}`);
    } finally {
      this.loading = false;
    }
  }
  changeId(val: any) {
    // console.log(val.length);
    // this.form.venderId = val.replace(/^[a-zA-Z0-9!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]+$/g, '');
    if (val.length > 40) {
      this.form.venderId = val.slice(0, 40);
    }
    // this.form.venderId = val.replace(/[\s\t]/g, '');
  }
  reset() {
    this.form = {
      venderId: '',
      oldVenderId: '',
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close', true);
  }
}
