
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { component as Viewer } from 'v-viewer';
import { IImage } from '@/common/interface';

@Component({
  components: {
    Viewer,
  },
})
export default class ShopImagesField extends Vue {
  val: IImage[] = [];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: IImage[];

  @Watch('value')
  watchValue() {
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  mounted() {
    this.watchValue();
  }
}
