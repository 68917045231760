
import { Vue, Component, Prop } from 'vue-property-decorator';
/**
 * 图片全屏预览组件
 */
@Component({})
export default class ViewImage extends Vue {
  @Prop()
  src!: string;

  @Prop()
  visible!: boolean;

  handleESC(e: Event) {
    if ((e as unknown as { which: number }).which === 27) {
      this.$emit('close');
    }
  }

  created() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleESC);
  }
}
