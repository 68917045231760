/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';
import {
  IAgencyOperation,
  IResAgencyOperationList,
} from '@/common/interface/account';
import { EShopType } from '@/common/enum/account';

@injectable()
export class AgencyOperationService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 新增推广通代运营
   * @param params
   * @param loadingEl
   * @returns
   */
  async add(
    params: { name: string; uid: number; phone: number },
    loadingEl?: Element | Vue,
  ) {
    try {
      const { name, uid, phone } = params;
      const res = (await this.http.post(
        'agent/add',
        {
          agent_id: uid,
          agent_name: name,
          agent_phone: phone,
        },
        {
          data: {
            loadingEl: loadingEl || document.body,
          },
        },
      )) as boolean;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 代运营列表
   * @param {kaType} 0为小B，1为大B
   * @param loadingEl
   * @returns
   */
  async list(
    params: {
      kaType: string | number;
      page: number;
      pageSize?: number;
      phone?: string;
      uid?: number;
      userName?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    try {
      const { kaType, uid, userName, phone } = params;
      const newParams: {
        page: number;
        pageSize?: number;
        agent_phone?: string;
        agent_id?: number;
        agent_name?: string;
      } = {
        page: params.page,
      };
      if (uid) {
        newParams.agent_id = uid;
      }
      if (userName) {
        newParams.agent_name = userName;
      }
      if (phone) {
        newParams.agent_phone = phone;
      }
      if (params.pageSize) {
        newParams.pageSize = params.pageSize;
      }

      const res = (await this.http.get(`agent/list?ka_type=${kaType}`, {
        params: newParams,
        data: {
          loadingEl: loadingEl || document.body,
        },
      })) as IResAgencyOperationList;
      const { list, lastPage, page, pageSize, total } = res;
      const result: {
        lastPage: number;
        page: number;
        total: number;
        pageSize: number;
        list: IAgencyOperation[];
      } = {
        lastPage,
        page: typeof page === 'number' ? page : +page,
        total,
        pageSize: typeof pageSize === 'number' ? pageSize : +pageSize,
        list: list.map((item) => {
          const agent = {
            id: item.id,
            uid: item.agent_id,
            userName: item.agent_name,
            phone: item.agent_phone,
            createTime: item.created_at,
            updateTime: item.updated_at,
            annualShopNum: item.year_num,
            adCampaignShopNum: item.ad_num,
            kaName: item.ka_name || '',
            annualBiPagePath: `/#/bi/embedded/活动管理/活动推送明细/85f321a4-fd15-48ad-8e6a-b958fba2b52a?${item.bi_url}`,
            adCampaignBiPagePath: `/#/bi/embedded/活动管理/活动推送明细/85f321a4-fd15-48ad-8e6a-b958fba2b52a?${item.ad_bi_url}`,
          };
          if (kaType === '1') {
            agent.kaName = item.ka_name || '';
          }
          return agent;
        }),
      };
      return result;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 转让门店
   * @param uid
   * @param toUid
   * @returns
   */
  async transfer(uid: number | string, toUid: number | string) {
    try {
      const res = (await this.http.post('agent/turn_shop', {
        agent_id: uid,
        new_agent_id: toUid,
      })) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 转让门店：小B：转让推广通门店，转让年费门店；大B：转让推广通门店，转让年费门店
   * @param {kaType} 小B：'0'; 大B：'1';
   * @param {shopType} 门店类型枚举：年费门店 =1; 推广通门店 = 2；
   */
  async transferShop(param: {
    kaType: string;
    shopType: EShopType;
    uid: number | string;
    toUid: number | string;
  }) {
    const { kaType, shopType, uid, toUid } = param;
    let url = '';
    if (shopType === EShopType.年费门店) {
      url = 'annual_agent/turn_shop_v2';
    } else if (shopType === EShopType.推广通门店) {
      url = 'agent/turn_shop_v2';
    }
    url += `?ka_type=${kaType}`;

    try {
      const res = (await this.http.post(url, {
        agent_id: uid,
        new_agent_id: toUid,
      })) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 黑卡代运营转让门店
   * @param uid
   * @param toUid
   * @returns
   */
  async transferBlackCardAgency(uid: number | string, toUid: number | string) {
    try {
      const res = (await this.http.post('annual_agent/turn_shop', {
        agent_id: uid,
        new_agent_id: toUid,
      })) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }
}
