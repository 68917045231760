export interface Options {
  allowDuplicateUploads: boolean;
  target: string;
  testChunks: boolean;
  headers: {
    AUTHORIZATION?: string;
  };
  chunkSize: number;
}

export interface Form {
  headlineImage: {
    id: number;
    url: string;
  };
  package: boolean;
  name: string;
  effectiveTimes: string;
  originalPriceCent: string;
  vipPriceCent: string;
  products: Array<{
    name: string;
    num: string;
    originalPriceCent: string;
  }>;
  newUser: boolean;
  newUserPriceCent: string;
  isHot: boolean;
  introduction: string;
  id: number;
  images: Array<{
    id: number;
    url: string;
  }>;
  videos: Array<{
    id: number;
    url: string;
  }>;
  category?: number; // id
  isOnline: boolean;
  unavailableTime: number[];
  createTime: string;
  isDrawCommission: boolean;
  isPlatformProduct: boolean;
  isCopyPlatformProduct: boolean;
  shortComment: string;
  tempSpuShopPois: string;
  platformProduct?: {
    createTime: string;
    isDrawCommission: number;
    isPlatformProduct: number;
    isCopyPlatformProduct: number;
    shortComment: string;
    tempSpuShopPois: string;
  };
  headlineOtherImage: Array<{
    id: number;
    url: string;
  }>;
  indicativePrice?: string;
}

export enum SpuNonBusinessHoursEnum {
  周末不可用 = 1,
  春节期间不可用 = 2,
  法定节假日不可用 = 3,
}

export interface Detail {
  name: string;
  num: string;
  originalPriceCent: string;
}

export const initialFormValue = {
  headlineImage: {
    id: -1,
    url: '',
  },
  package: false,
  name: '',
  effectiveTimes: '',
  originalPriceCent: '',
  vipPriceCent: '',
  products: [],
  newUser: false,
  newUserPriceCent: '',
  isHot: false,
  introduction: '',
  id: -1,
  images: [],
  videos: [],
  isOnline: true,
  unavailableTime: [],
  createTime: '', // 创建时间
  isDrawCommission: false, // 是否分佣
  isPlatformProduct: false, // 是否平台商品
  shortComment: '', // 推荐理由 20240103乐开明确认修改文案
  tempSpuShopPois: '', // 适用门店poi
  isCopyPlatformProduct: false, // 是否平台复制商品
  headlineOtherImage: [],
  // 自动下架变更
  shouldOffShelf: false,
  autoOffShelfTime: '',
  indicativePrice: '',
};
