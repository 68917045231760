
import { v4 } from 'uuid';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class MapLocationField extends Vue {
  private uuid = v4;

  private locationNameStyle = {
    'border-radius': '4px',
    border: 'none',
    background: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
  };

  zoom = 14;

  val: { name: string; location: number[] } = {
    name: '',
    location: [],
  };

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: { name: string; location: number[] };

  @Watch('value')
  watchValue() {
    if (this.value.name && this.value.location.length) {
      this.val = { ...this.value };
    } else {
      this.val = {
        name: '',
        location: [],
      };
    }
  }

  mounted() {
    this.watchValue();
  }
}
