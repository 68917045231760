/** 门店字段枚举 */
export enum EShopField {
  ADDRESS = 'address', // 地址
  BOSS_ID_IMAGES = 'bossIdImage', // 身份证
  BUSINESS_TIME = 'businessTime', // 营业时间
  DATA_CHED = 'dataChed', // 独立洗车位
  MAIN_BUSINESS = 'mainBusiness', // 主营业务
  MANAGER_TELS = 'managerTels', // 经理手机号
  PARK_NUM = 'parkNum', // 免费停车位
  REST_AREA = 'restArea', // 休息区
  SHOP_IMAGES = 'shopImages', // 店铺图片
  SHOP_VIDEOS = 'shopVideos', // POI推送高德视频
  VIDEOS = 'videos', // 实拍视频
  SHOP_LICENSE = 'shoplicense', // 营业执照
  STAFF = 'staffList', // 店铺子账号：店长与收银员
  START_TIME = 'startTime', // 开业时间
  STATION = 'station', // 洗车工位数
  TELS = 'tels', // 联系电话
  NAME = 'name', // 店铺名称
  ADVERTISER_MOBILE_PHONE_NUMBER = 'advertiserMobilePhoneNumber', // 广告主电话
  /** 以上为编辑店铺信息常用字段 */
  B_ORDER = 'bOrder', // B端年费信息
  BOSS_TELS = 'bossTels',
  BUSINESS_STATUS = 'businessStatus', // 营业状态
  LATITUDE = 'latitude', // 纬度
  LONGITUDE = 'longitude', // 经度
  MAP_LOCATION = 'mapLocation', // 地图位置
  PAYEE_ACCOUNT_FRESSZE_STATUS = 'payeeAccountFreezeStatus', // 收款账户状态
  POI = 'poi',
  REGION = 'region', // 省市区
  SHOP_ID = 'shopId',
  SHOP_SERVICE = 'shopService', // 商品列表
  MECHINE_CALLBACK_DATA = 'mechineCallBackResponse', // 支付状态
  AREA = 'area', // 营业面积

  CONTRACT = 'contract', // 合同
  QRCODE = 'qrcode', // 二维码
  MECHANICS = 'mechaniscs', // 金牌技师
  // STAFF_LIST = 'staffList',
  // ALIPAY_INFO = 'alipayInfo',
  // ORIGIN_NAME = 'originName',
}

/** 门店免费停车位 */
export enum EShopParkNum {
  '无' = 0,
  '1 ~ 5个' = 1,
  '6 ~ 10个' = 2,
  '11 ~ 20个' = 3,
  '20个以上' = 4,
}

/** 门店营运状态 */
export enum EShopBusinessStatus {
  全部 = '全部',
  正常营业 = '正常营业',
  暂停营业 = '暂停营业',
  永久停业 = '永久停业',
  惩罚下线 = '惩罚下线', //  工作台和APP端门店营业状态均展示为：【临时下线】
}

/** 门店员工角色 */
export enum EShopStaffRole {
  合伙人 = '合伙人',
  店长 = '店长',
  收银员 = '收银员',
}

/** 操作类型枚举 */
export enum EOperationType {
  ADDRESS = 'address',
  POI = 'poiChange',
  BOSS_ID_IMAGES = 'bossIdImage',
  SHOP_IMAGES = 'shopImages',
  MAIN_BUSINESS = 'mainBusiness',
  SHOP_LICENSE = 'shopLicense',
  SHOP_NAME = 'shopName',
  SHOP_AUDIT_VIDEO = 'shopAuditVideo',
  LEADING_SPU = 'leadingSpu',
  STAFF = 'editAccount',
  DEFAULT = '',
}
/** 收款账号状态 */
export enum EPayeeAccountFreezeStatus {
  正常 = 0,
  冻结 = 1,
}
