
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Main extends Vue {
  public collapsed = false;

  @Prop({
    default: () => [],
  })
  breadcrumbs!: Array<{title: string; path: string}>;
}
