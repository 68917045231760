
import { Vue, Component } from 'vue-property-decorator';
import BaseBIPage from './components/BaseBIPage.vue';

@Component({
  components: {
    BaseBIPage,
  },
})
export default class COrderStat extends Vue {}
