
import { City } from '@/common/interface';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import { AgentService } from '@/services/agent.service';
import BaseService from '@/services/base.service';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AddAgentRegion extends Vue {
  loading = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  agentId?: number;

  isVisible = false;

  selectedRegion: number[] = [];

  regions: Array<{
    id: number;
    name: string;
    city: City[];
  }> = [];

  @lazyInject(BaseService)
  protected baseService!: BaseService;

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  async submit() {
    try {
      this.loading = true;

      const res = await this.agentService.addRegion({ agentId: this.agentId as number, regionPid: this.selectedRegion[0], regionId: this.selectedRegion[1] || 0 });
      if (res) {
        this.$message.success(res);

        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  onChange(value: number[]) {
    this.selectedRegion = [...value];
  }

  reset() {
    this.selectedRegion = [];

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close', true);
  }

  async getCities() {
    const res = (await this.baseService.getCityList({
      joined: false,
      aggregation: false,
    })) as unknown as {
      [key: number]: {
        province: City | null;
        city: City[];
      };
    };

    const regions = [];

    for (const [k, v] of Object.entries(res)) {
      if (v.province) {
        const item = {
          id: +k,
          name: v.province.name,
          city: v.city,
        };
        regions.push(item);
      } else {
        for (let index = 0; index < v.city.length; index++) {
          const city = v.city[index];
          const item = {
            id: +city.id,
            name: city.name,
            city: [],
          };
          regions.push(item);
        }
      }
    }

    this.regions = regions;
  }

  async mounted() {
    await this.getCities();
  }
}
