import { RouteConfig } from 'vue-router';
import AuthHome from '@/views/auth/AuthHome.vue';

const authRoutes: RouteConfig = {
  name: 'auth.home',
  path: '/auth',
  component: AuthHome,
  children: [
    {
      name: 'auth.login',
      path: '/login',
      component: () => import('@/views/auth/login.vue'),
      meta: { title: '登录德友惠' },
    },
  ],
};

export default authRoutes;
