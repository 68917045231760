
import { BrandService } from '@/services/brand_service';
import lazyInject from '@/di';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import EditBrandModal from './components/EditBrandModal.vue';
import { Brand } from '@/models/brand.model';

@Component({
  components: {
    'edit-brand-modal': EditBrandModal,
  },
})
export default class BrandList extends Vue {
  listData: Brand[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
    },
    {
      title: '品牌名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'brandcode',
      dataIndex: 'brandcode',
      key: 'brandcode',
    },
    {
      title: '品牌logo',
      dataIndex: 'logo',
      key: 'logo',
      ellipsis: true,
      scopedSlots: { customRender: 'logo' },
    },
    {
      title: '头图',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      ellipsis: true,
      scopedSlots: { customRender: 'headlineImage' },
    },

    {
      title: '服务商名称',
      dataIndex: 'serviceProvider',
      key: 'serviceProvider',
      ellipsis: true,
    },
    {
      title: '是否自动提现',
      dataIndex: 'autoWithdrawl',
      key: 'autoWithdrawl',
    },
    {
      title: '主账号',
      dataIndex: 'primaryAccount',
      key: 'primaryAccount',
      ellipsis: true,
    },
    {
      title: '品牌管理员',
      dataIndex: 'brandAdmin',
      key: 'brandAdmin',
    },
    {
      title: '门店数量',
      dataIndex: 'shopNum',
      key: 'shopNum',
      ellipsis: true,
    },
    {
      title: '是否系统对接',
      dataIndex: 'isLinked',
      key: 'isLinked',
      ellipsis: true,
    },
    {
      title: '保护规则',
      dataIndex: 'protection',
      key: 'protection',
      ellipsis: true,
    },

    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
  ];

  @Ref('editBrandModal')
  editBrandModal!: EditBrandModal;

  @lazyInject(BrandService)
  protected brandService!: BrandService;

  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;

    await this.list({
      pageSize,
      page: current,
    });
  }

  oepnEditBrandModal() {
    this.editBrandModal.open();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    // const query = this.extractRouterQueryAndFillFilterForm();
    // Object.assign(query, { page: current });

    this.$router.push({ query: { page: `${current}` } });
  }

  async list(params: { pageSize?: number; page: number }) {
    const res = await this.brandService.list(params);
    if (res) {
      this.listData = res.list.map((data: any) => {
        return Brand.from(data);
      });

      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };

    this.$router.push({
      query: {},
    });
  }

  async created() {
    this.$nextTick(async () => {
      await this.handleSearch();
    });
  }
}
