
import { Vue, Watch, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import PermissionService, {
  AuthMenuSet,
  AuthEnum,
} from '@/services/permission.service';
import { KeyObject } from '@/common/interface';

@Component({})
export default class MainNav extends Vue {
  AuthEnum = AuthEnum;

  currentKeys: string[] = [];

  rootSubmenuKeys = [
    'main.shop',
    'region',
    'main.bi',
    'main.order',
    'withdraw',
    'activity',
    'permission',
    'label',
    'audit',
    'account',
    'lead',
    'log',
    'examine',
  ];

  openKeys = ['main.shop'];

  menuPermissions: KeyObject<boolean> = {};

  @lazyInject(PermissionService)
  permissionService!: PermissionService;

  created(): void {
    this.routeChange();
    this.asyncGetMenuPermissions();
  }

  onOpenChange(openKeys: string[]) {
    const latestOpenKey = openKeys.find(
      (key) => this.openKeys.indexOf(key) === -1,
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey as string) === -1) {
      this.openKeys = openKeys;
    } else {
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    }
  }

  goToAdManagement() {
    // 新窗口打开 -> 推广通的投放管理
    window.open('https://tg.amap.com/agency#', '_blank');
  }

  /** 获取菜单相关权限 */
  async asyncGetMenuPermissions() {
    const $asyncP = await this.permissionService.asyncPermissions([
      ...AuthMenuSet,
    ]);
    $asyncP.subscribe(
      (menuPermissions: KeyObject<boolean>) => {
        if (menuPermissions) {
          this.menuPermissions = menuPermissions;
        } else {
          this.menuPermissions = {};
        }
      },
      () => {
        this.menuPermissions = {};
      },
    );
  }

  go(routeName: string): void {
    this.$router.push({ name: routeName });
  }

  goWithdraw() {
    this.$router.push({
      name: 'withdraw.list',
      query: {
        status: '0',
      },
    });
  }

  goNotPayBOrderList() {
    this.$router.push({
      name: 'main.order.list.b.notpay',
      query: {
        payStatus: '1',
      },
    });
  }

  @Watch('$route')
  routeChange(): void {
    const keys = this.$route.matched.map((r) => {
      return r.name as string;
    });

    if (this.$route.name === 'bi.embedded') {
      keys.push(
        decodeURIComponent(`/bi/embedded/${this.$route.params.category}`),
      );
      keys.push(decodeURIComponent(this.$route.fullPath));
    }

    this.currentKeys = keys;
  }
}
