
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { $delay } from '@/common/utils';
import ShopService, { BlackcardRankTypeEnum } from '@/services/shop.service';


enum PushChannelEnum {
  短信 = 1,
  APP = 2,
}

const initialForm = {
  pushType: [],
  sms: '',
  title: '',
  desc: '',
  pois: '',
};

@Component({})
export default class PushSettingDrawer extends Vue {
  PushChannelEnum = PushChannelEnum;

  uploading = false;

  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  form: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pushType: any[];
    sms: string;
    title: string;
    desc: string;
    pois: string;
  } = { ...initialForm };

  fileList: File[] = [];

  pushTypeList = [
    { label: '短信', value: PushChannelEnum.短信 },
    // { label: '商家端APP Push', value: PushChannelEnum.APP },
  ];

  @Prop()
  drawerVisible!: boolean;

  @Prop()
  pushConfig!: {
    regionId: number;
    type: string | BlackcardRankTypeEnum;
    min: number;
    max: number;
  };

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  get disabled() {
    if (!this.form.pushType.length) {
      return true;
    }
    return false;
    // return (this.smsPushDisabled || this.appPushDisabled);
  }

  get smsPushDisabled() {
    const pushTypes = [...this.form.pushType];
    if (pushTypes.indexOf('短信') < 0) {
      return false;
    }

    return !(this.form.sms && this.form.pois);
  }

  get appPushDisabled() {
    const pushTypes = [...this.form.pushType];
    if (pushTypes.indexOf('APP') < 0) {
      return false;
    }
    return !(this.form.desc && this.form.pois);
  }

  onPushChannelChange(vals: string[]) {
    // console.log('vals===', vals)
    const values = vals.map((
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item: any,
    ) => {
      return PushChannelEnum[item];
    });
    if (values.length) {
      this.$set(this.form, 'pushType', values);
    }
  }

  onRemove() {
    this.$set(this.form, 'pois', undefined);
    this.fileList = [];
  }

  onClose() {
    this.$emit('close');
    this.reset();
    this.$forceUpdate();
  }

  async submit() {
    const { regionId, type, min, max } = this.pushConfig;
    const formatPushType = (list: string[]) => {
      const pushTypes = [...list];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formatedPushType: any[] = [];
      for (const pushType of pushTypes) {
        if (pushType === '短信') {
          formatedPushType.push(PushChannelEnum.短信);
        } else if (pushType === 'APP') {
          formatedPushType.push(PushChannelEnum.APP);
        }
      }
      return formatedPushType;
    };

    const params: {
      regionId: number;
      type: BlackcardRankTypeEnum | string;
      min: number;
      max: number;
      pushType: number[];
      sms: string;
      desc: string;
      pois: string;
    } = {
      regionId,
      type,
      min,
      max,
      pushType: formatPushType(this.form.pushType),
      sms: this.form.sms,
      desc: this.form.desc,
      pois: this.form.pois,
    };
    const res = await this.shopService.pushSetting(params);
    if (res) {
      this.$message.success(`${res}`);
      setTimeout(() => {
        this.onClose();
      }, 1000);
    }
  }

  reset() {
    this.form = { ...initialForm };
    this.onRemove();
  }

  beforeUpload(file: File) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleUploadPOIS(info: any) {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;

      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      this.$message.success(`${data}`);
      this.$set(this.form, 'pois', data);
      this.uploading = false;
      // this.fileList = [info.file];
    }

    this.fileList = [...fileList];

    // eslint-disable-next-line no-console
    console.log('this.fileList===', this.fileList, 'this====', this);
  }

  created() {
    this.token = this.userService.currentUser()?.token;
  }
}
