
import { Order } from '@/common/interface/order';
import lazyInject from '@/di';
import { AgentService } from '@/services/agent.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class SalesmanChange extends Vue {
  confirmLoading = false;

  isVisible = false;

  staffTemp = [];

  agentStaff: Array<{
    id: number;
    name: string;
    phone: string;
  }> = [];

  form: {
    reason: string;
    staffId: number;
    previousStaffName: string;
    orderId: number;
    shopName: string;
  } = {
    reason: '',
    staffId: -1,
    previousStaffName: '',
    orderId: -1,
    shopName: '',
  };

  @Prop({})
  order!: Order;

  @Watch('order', { deep: true, immediate: true })
  watchOrder() {
    if (this.order != null) {
      this.$set(this.form, 'previousStaffName', this.order.staffName);
      this.$set(this.form, 'orderId', this.order.orderId);
      this.$set(this.form, 'shopName', this.order.shopName);
    }
  }

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.$emit('close');
    this.reset();
  }

  reset() {
    this.form = {
      reason: '',
      staffId: -1,
      previousStaffName: '',
      shopName: '',
      orderId: -1,
    };
    this.staffTemp = [];
  }

  async staff() {
    const res = await this.agentService.staff();
    if (res && res.length > 0) {
      this.agentStaff = res.map(item => {
        return item.staffs;
      }).filter(staffs => {
        return staffs.length;
      }).flat();
    }
  }

  onChange(nameAndPhone: string) {
    const phone = nameAndPhone.split('|')[1];

    const person = this.agentStaff.filter(item => {
      return item.phone === phone;
    })[0];

    if (this.form.staffId === person.id) {
      return;
    }
    this.$set(this.form, 'staffId', person.id);
  }

  async handleOk() {
    if (!this.form.orderId || this.form.orderId === -1) {
      return;
    }

    if (this.form.staffId === -1 || !this.form.staffId) {
      this.$message.warn('请选择地推人员。');
      return;
    }

    if (!this.form.reason) {
      this.$message.warn('变更原因不能为空！');
      return;
    }

    const res = await this.agentService.staffChange({
      orderId: this.form.orderId,
      userId: this.form.staffId,
      reason: this.form.reason,
    });

    if (!res) return;
    this.$message.success(res);
    this.close();
  }

  mounted() {
    this.staff();
  }
}
