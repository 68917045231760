
import { City } from '@/common/interface';
import { UserRes } from '@/common/interface/account';
// import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import { AccountService } from '@/services/account_service';
import { AgentService } from '@/services/agent.service';
import BaseService from '@/services/base.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class EditSalesStaffRelativeRegionModal extends Vue {
  loading = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  user?: UserRes;

  isVisible = false;

  selectedRegion = -1;

  regions: Array<{
    id: number;
    name: string;
  }> = [];

  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customRender: (text: any, record: any, index: number) => `${index + 1}`,
      width: 140,
    },
    {
      title: '关联区域',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 85,
    },
  ];

  @lazyInject(BaseService)
  protected baseService!: BaseService;

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  @lazyInject(AccountService)
  protected accountService!: AccountService;

  @Watch('user', { immediate: true, deep: true })
  async watchUserIdChange() {
    if (this.user == null) {
      this.relativeRegionList = [];
      return;
    }
    await this.getRelativeRegionList();
  }

  get title() {
    if (this.user) {
      return `编辑地推员【${this.user.userName}】的关联地区`;
    }
    return '编辑地推员关联地区';
  }

  async add() {
    if (this.user == null) {
      return;
    }
    const res = await this.accountService.addRelativeRegion(
      this.user?.userId as number,
      +this.selectedRegion,
    );
    if (res) {
      this.$message.success('新增成功！');
      await this.getRelativeRegionList();
      this.selectedRegion = -1;
    }
  }

  async remove(regionId: number) {
    if (this.user == null) {
      return;
    }
    const res = await this.accountService.removeRelativeRegion(
      this.user?.userId as number,
      regionId,
    );

    if (res) {
      this.$message.success('删除成功！');
      await this.getRelativeRegionList();
      this.selectedRegion = -1;
    }
  }

  onChange(value: number[]) {
    this.selectedRegion = value[0];
  }

  reset() {
    this.selectedRegion = -1;

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close');
  }

  /** 提取省和直辖市 */
  async getRegions() {
    const res = (await this.baseService.getCityList({
      joined: false,
      aggregation: false,
    })) as unknown as {
      [key: number]: {
        province: City | null;
        city: City[];
      };
    };

    const regions = [
      {
        id: -1,
        name: '请选择地域',
      },
    ];

    for (const [k, v] of Object.entries(res)) {
      if (v.province) {
        const item = {
          id: +k,
          name: v.province.name,
        };
        regions.push(item);
      } else {
        for (let index = 0; index < v.city.length; index++) {
          const city = v.city[index];
          const item = {
            id: +city.id,
            name: city.name,
          };
          regions.push(item);
        }
      }
    }

    this.regions = regions;
  }

  relativeRegionList: Array<{
    id: number;
    name: string;
  }> = [];

  async getRelativeRegionList() {
    const res = await this.accountService.salesStaffRelativeRegionList(
      this.user?.userId as number,
    );
    if (res) {
      this.relativeRegionList = res.map((item) => {
        return {
          id: item.regionId,
          name: item.areaName,
        };
      });
    }
  }

  async mounted() {
    await this.getRegions();
  }
}
