
import { Vue, Component, Prop } from 'vue-property-decorator';
import QRCode from 'qrcode';
import html2canvas from 'html2canvas';
import qrcodeBg from '@/assets/qrcode_bg.jpg';

@Component({})
export default class DrawQRCodePoster extends Vue {
  posterConfig = {
    bgImgUrl: qrcodeBg,
    bgImgWidth: 7088,
    bgImgHeight: 10630,
    qrcodeUrl: '',
    offsetY: 3860,
    offsetX: 2130,
    qrcodeWidth: 2840,
    qrcodeHeight: 2840,
    shopNameOffsetX: 710,
    shopNameOffsetY: 8960,
    shopNameWidth: 5660,
    posterUrl: '',
    deviceRation: 1,
  };

  isDrawing = false;

  @Prop()
  qrcodeSchemeConifg!: {
    name: string;
    poi: string;
    lat: string;
    lon: string;
  };

  setupPosterConfig() {
    const ratio = +parseFloat(`${window.devicePixelRatio}`).toFixed(1);
    const {
      bgImgHeight,
      bgImgWidth,
      offsetX,
      offsetY,
      qrcodeWidth,
      qrcodeHeight,
      shopNameOffsetX,
      shopNameOffsetY,
      shopNameWidth,
    } = this.posterConfig;
    const scale = 0.5 / ratio;
    const updated = {
      deviceRation: ratio,
      bgImgWidth: Math.round(bgImgWidth * scale),
      bgImgHeight: Math.round(bgImgHeight * scale),
      offsetX: Math.round(offsetX * scale),
      offsetY: Math.round(offsetY * scale),
      qrcodeWidth: Math.round(qrcodeWidth * scale),
      qrcodeHeight: Math.round(qrcodeHeight * scale),
      shopNameOffsetX: Math.round(shopNameOffsetX * scale),
      shopNameOffsetY: Math.round(shopNameOffsetY * scale),
      shopNameWidth: Math.round(shopNameWidth * scale),
    };
    Object.assign(this.posterConfig, updated);
  }

  get qrcodeScheme() {
    const shortUrl = 'https://surl.amap.com/9eWIuYYr27p';
    const { name, poi, lat, lon } = this.qrcodeSchemeConifg;
    if (!name || !poi || !lat || !lon) {
      return '';
    }
    const encodeUrl = encodeURIComponent(`amapuri://poi/detail?poiname=${name}&poiid=${poi}&lat=${lat}&lon=${lon}&superid=z_999_99_99`);
    return `${shortUrl}?schema=${encodeUrl}`;
  }

  createQrcode() {
    if (!this.qrcodeScheme) {
      return;
    }

    const canvas = document.createElement('canvas');
    const { qrcodeWidth, qrcodeHeight } = this.posterConfig;

    const ctx = canvas.getContext('2d');
    if (ctx == null) {
      return;
    }
    ctx.rect(0, 0, qrcodeWidth, qrcodeHeight);
    ctx.fill();
    QRCode.toCanvas(canvas, this.qrcodeScheme, {
      errorCorrectionLevel: 'M',
      with: qrcodeWidth,
      Heigh: qrcodeHeight,
    });

    const qrcodeUrl = canvas.toDataURL('image/jpg', 1);
    this.$set(this.posterConfig, 'qrcodeUrl', qrcodeUrl);
  }

  /**
   * text         待填充文本
   * x,y          轴
   * width        行宽
   * fontSize     字体大小
   * rowHeight    行高
   * ctx          画板内容对象
   */

  drawText(
    text: string,
    x: number,
    y: number,
    width: number,
    fontSize: number,
    rowHeight: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ctx: any,
  ) {
    // 1 将字符串转换成数组
    const test = text.split('');
    const row = [];
    let temp = '';

    ctx.font = `${fontSize}px 微软雅黑 border`;
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'left';
    // 1.2 计算文字宽度，若文字宽度大于设定的宽度，则push到数组下一个元素，否则将字符串++
    for (let i = 0; i < test.length; i++) {
      if (ctx.measureText(temp).width > width) {
        row.push(temp);
        temp = '';
      }
      temp += test[i];
    }
    // 1.3 循环结束将temp最后一段字符push
    row.push(temp);
    // 1.4 遍历数组,输出文字
    for (let j = 0; j < row.length; j++) {
      ctx.fillText(row[j], x, y + (j + 1) * fontSize * rowHeight);
    }
  }

  /** 下载门店二维码 */
  downloadQrcode() {
    if (this.posterConfig.posterUrl) {
      this.downloadPoster();
      return;
    }

    const { name, poi, lat, lon } = this.qrcodeSchemeConifg;

    if (!name || !poi || !lat || !lon) {
      this.$message.error(
        '门店名称，门店POI，门店经度、门店维度不能为空，请检测！',
      );
      return;
    }

    if (this.isDrawing) {
      return;
    }
    this.isDrawing = true;
    const name_ = name.length > 31 ? `${name.substring(0, 31)}...` : name;
    try {
      if (!this.posterConfig.qrcodeUrl) {
        this.createQrcode();
      }

      const canvas = document.getElementById('poster') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');

      if (ctx == null) {
        this.isDrawing = false;
        return;
      }

      const bg = new Image();
      bg.crossOrigin = 'Anonymous';
      bg.src = this.posterConfig.bgImgUrl;

      bg.onload = () => {
        canvas.width = this.posterConfig.bgImgWidth;
        canvas.height = this.posterConfig.bgImgHeight;

        ctx.drawImage(
          bg,
          0,
          0,
          this.posterConfig.bgImgWidth,
          this.posterConfig.bgImgHeight,
        );

        this.drawText(
          `${name_}`,
          this.posterConfig.shopNameOffsetX,
          this.posterConfig.shopNameOffsetY,
          this.posterConfig.shopNameWidth,
          88,
          1.4,
          ctx,
        );
        this.drawText(
          `${name_}`,
          this.posterConfig.shopNameOffsetX,
          this.posterConfig.shopNameOffsetY,
          this.posterConfig.shopNameWidth,
          88,
          1.4,
          ctx,
        );
        this.drawText(
          `${name_}`,
          this.posterConfig.shopNameOffsetX,
          this.posterConfig.shopNameOffsetY,
          this.posterConfig.shopNameWidth,
          88,
          1.4,
          ctx,
        );
        this.drawText(
          `${name_}`,
          this.posterConfig.shopNameOffsetX,
          this.posterConfig.shopNameOffsetY,
          this.posterConfig.shopNameWidth,
          88,
          1.4,
          ctx,
        );

        const qrcode = new Image();
        qrcode.src = this.posterConfig.qrcodeUrl;
        qrcode.crossOrigin = 'Anonymous';
        qrcode.onload = () => {
          ctx.drawImage(
            qrcode,
            this.posterConfig.offsetX,
            this.posterConfig.offsetY,
            this.posterConfig.qrcodeWidth,
            this.posterConfig.qrcodeHeight,
          );

          html2canvas(canvas).then((canvas_) => {
            const posterUrl = canvas_.toDataURL('image/jpg', 1);
            this.$set(this.posterConfig, 'posterUrl', posterUrl);
            this.downloadPoster();

            setTimeout(() => {
              this.isDrawing = false;
            }, 2000);
          });
        };
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('下载二维码海报error：', error);
      this.isDrawing = false;
    }
  }

  downloadPoster() {
    const link = document.createElement('a');
    link.setAttribute('id', 'download_poster');
    link.download = 'qrcode.jpg';
    link.href = this.posterConfig.posterUrl;
    link.click();

    setTimeout(() => {
      const poster = document.getElementById('download_poster');
      poster?.remove();
      this.$emit('complete');
    }, 2000);
  }

  mounted() {
    this.createQrcode();
    this.setupPosterConfig();
  }
}
