
import { City } from '@/common/interface';
import lazyInject from '@/di';
import RegionService from '@/services/region.service';
import { Vue, Component, Ref } from 'vue-property-decorator';
import RegionCoverModal from './components/RegionCoverModal.vue';

@Component({
  components: {
    RegionCoverModal,
  },
})
export default class RegionCoveredList extends Vue {
  cities: Array<City & {province: City | null}> = [];

  @Ref('regionCoverModal')
  regionCoverModal!: RegionCoverModal;

  @lazyInject(RegionService)
  protected regionService!: RegionService;

  add() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.regionCoverModal as any).open();
  }

  async getCoveredList() {
    const res = await this.regionService.coveredList(this.$el) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (res) {
      const cities = Object.values(res).map(item => {
        return item.city.map(city => {
          const { province } = item;
          return Object.assign({ province }, city);
        });
      }).reduce((prev, cur) => prev.concat(cur), []);
      this.cities = cities;
    } else {
      this.cities = [];
    }
  }

  /**
   * 页面刷新
   */
  async refresh() {
    await this.getCoveredList();
  }

  async created() {
    this.$nextTick(async () => {
      await this.getCoveredList();
    });
  }
}
