var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-layout',{attrs:{"back":_vm.back,"refresh":_vm.refresh,"showBack":true,"title":_vm.title},scopedSlots:_vm._u([{key:"header-control",fn:function(){return [(_vm.currentTab == _vm.tabEnum.成员)?_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.openOrgMemberModal(_vm.enumOrgMemberModalMode.ADD_MEMBER)}}},[_vm._v("添加成员")]):_vm._e(),(_vm.currentTab == _vm.tabEnum.角色)?_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.openAddRoleModal()}}},[_vm._v("添加角色")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"card-container"},[_c('a-tabs',{attrs:{"type":"card"},on:{"change":_vm.tabChange}},_vm._l((_vm.tabs),function(tabItem,index){return _c('a-tab-pane',{key:index,attrs:{"tab":tabItem.name}},[(tabItem.name == _vm.tabEnum.成员)?[_c('a-list',{attrs:{"data-source":_vm.orgMember},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:"item.id",attrs:{"size":"large"}},[_c('a-descriptions',[_c('a-descriptions-item',{attrs:{"label":`${item.name ? item.name : '用户ID' + item.id}${
                    item.phone ? ' | ' + item.phone : ''
                  }`}},_vm._l((item.roles),function(role,roleIndex){return _c('span',{key:roleIndex,staticClass:"role ant-btn-link",staticStyle:{"margin-right":"20px"}},[_vm._v(_vm._s(role.name))])}),0)],1),_c('a-divider',{staticClass:"action-divider",attrs:{"type":"vertical"}}),_c('div',{staticClass:"role-actions h-box f-c-v"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openOrgMemberModal(
                      _vm.enumOrgMemberModalMode['ADD_ROLE'],
                      item,
                    )}}},[_vm._v("添加角色")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openOrgMemberModal(
                      _vm.enumOrgMemberModalMode['DELETE_ROLE'],
                      item,
                    )}}},[_vm._v("删除角色")]),_c('a-button',{staticClass:"org-member__delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.showConfirm(item.id)}}},[_vm._v("删除成员")])],1)],1)}}],null,true)},[(_vm.orgMember.length < 1)?_c('a-empty'):_vm._e()],1)]:_vm._e(),(tabItem.name == _vm.tabEnum.角色)?[_c('a-list',{attrs:{"data-source":_vm.organizationRoleList},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:"item.id",attrs:{"size":"large"}},[_c('a-descriptions',[_c('a-descriptions-item',{attrs:{"label":item.roleName}},[_c('span',{staticClass:"role"},[_vm._v(_vm._s(item.permission.length > 0 ? item.permission .map((item) => { return item.permissionName; }) .join('，') : '暂无'))])])],1),_c('a-divider',{staticClass:"action-divider",attrs:{"type":"vertical"}}),_c('div',{staticClass:"role-actions h-box f-c-v"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openAssignPermissionModal(item)}}},[_vm._v("管理权限")]),_c('a-popconfirm',{attrs:{"title":"确定要删除吗？","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.deleteOrganizationRole(item.roleID)}}},[_c('a-button',{attrs:{"type":"link"}},[_vm._v("删除角色")])],1)],1)],1)}}],null,true)},[(
                (tabItem.name === '成员' && _vm.orgMember.length < 1) ||
                (tabItem.name === '角色' && _vm.organizationRoleList.length < 1)
              )?_c('a-empty'):_vm._e()],1)]:_vm._e()],2)}),1)],1),_c('OrgMemberModal',{ref:"orgMemberModal",attrs:{"orgId":_vm.orgId,"orgMemberModalConfig":_vm.orgMemberModalConfig,"visible":_vm.orgMemberModalVisible},on:{"close":_vm.closeOrgMemberModal}}),_c('AssignPermissionToRoleModal',{attrs:{"assignPermissionToRoleConfig":_vm.assignPermissionToRoleConfig,"organizationId":_vm.orgId,"visible":_vm.assignPermissionModalVisible},on:{"close":_vm.closeAssignPermissionModal,"updateRolePermission":_vm.updateRolePermission}}),_c('AddRoleModal',{ref:"addRoleModal",attrs:{"visible":_vm.addRoleModalVisible,"organizationId":_vm.orgId},on:{"close":_vm.closeAddRoleModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }