
import lazyInject from '@/di';
import { Vue, Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { BASE_URL } from '@/common/constant';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import { $delay } from '@/common/utils';
import UserService from '@/services/user.service';
import { BrandService } from '@/services/brand_service';

@Component({})
export default class EditBrandModal extends Vue {
  uploading = false;

  BASE_URL = BASE_URL;

  isLoading = false;

  isVisible = false;

  token: string | undefined = undefined;

  form = {
    name: '',
    primaryAccount: '',
    brandAdmin: '',
    brandcode: '',
    prodLink: '',
    logo: '',
    headlineImage: { id: 0, url: '' },
  };

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  rules = {
    name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
    primaryAccount: [
      { required: true, message: '请输入主账号', trigger: 'blur' },
    ],
    brandAdmin: [
      {
        required: true,
        message: '请输入品牌管理员账号，需要与主账号不一致',
        trigger: 'blur',
      },
    ],
    brandcode: [
      { required: true, message: '请输入brandcode', trigger: 'blur' },
    ],
    logo: [{ required: true, message: '请输入品牌logo链接', trigger: 'blur' }],
    headlineImage: [
      {
        validator: this.headlineImageValidator,
        message: '请上传品牌头图',
        trigger: 'blur',
      },
    ],
  };

  @Ref()
  readonly ruleForm!: FormModel;

  @lazyInject(BrandService)
  protected brandService!: BrandService;

  @lazyInject(UserService)
  protected userService!: UserService;

  headlineImageValidator() {
    const { headlineImage } = this.form;
    if (headlineImage.url && headlineImage.id !== 0) {
      return true;
    }
    return false;
  }

  beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.$message.error('请上传 png/jpeg 格式的照片!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.$message.error('照片必须小于5MB!');
    }

    return isJpgOrPng && isLt5M;
  }

  async onChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const headlineImage = {
        id: data[0]?.id,
        url: data[0]?.url,
      };
      this.$set(this.form, 'headlineImage', headlineImage);
      this.uploading = false;
    }
  }

  async submit() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        if (this.isLoading) {
          return;
        }
        this.isLoading = true;

        try {
          const {
            name,
            primaryAccount,
            brandAdmin,
            brandcode,
            logo,
            headlineImage,
            prodLink,
          } = this.form;
          const res = await this.brandService.create({
            name,
            owner_id: primaryAccount,
            brand_code: brandcode,
            staff_id: brandAdmin,
            header_pic: headlineImage.id,
            logo,
            webhook_url: prodLink || '',
          });
          if (res) {
            this.$message.success(res);
            this.$emit('refresh');
            await $delay(500);
            this.close();
          }
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
      }
    });
  }

  reset() {
    this.form = {
      name: '',
      primaryAccount: '',
      brandAdmin: '',
      brandcode: '',
      prodLink: '',
      logo: '',
      headlineImage: { id: 0, url: '' },
    };

    if (this.isLoading) {
      this.isLoading = false;
    }
    this.ruleForm.resetFields();
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
