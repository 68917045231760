
import { EOperationType } from '@/common/enum/shop';
import { IShopOperationLogItem } from '@/common/interface/shop';
import lazyInject from '@/di';
import { ShopOperationLogService } from '@/services/shop_operation_log.service';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class SpuLeadingLog extends Vue {
  isVisible = false;

  spuId = 0;

  columns = [
    {
      title: '修改时间',
      dataIndex: 'operateTime',
      key: 'operateTime',
      ellipsis: true,
    },
    {
      title: '修改人姓名',
      dataIndex: 'operatorName',
      key: 'operatorName',
      ellipsis: true,
    },
    {
      title: '修改人工号',
      dataIndex: 'operatorId',
      key: 'operatorId',
      ellipsis: true,
    },
    {
      title: '修改类型',
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
    },
  ];

  listData: IShopOperationLogItem[] = [];

  type = EOperationType.LEADING_SPU;

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  @lazyInject(ShopOperationLogService)
  operationLogService!: ShopOperationLogService;

  async open(id: number) {
    this.spuId = id;
    const { pageSize, current } = this.pagination;
    await this.list({ pageSize, page: current });
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  reset() {
    this.spuId = 0;
    this.listData = [];
    Object.assign(this.pagination, { total: 0, pagesSize: 15, current: 1 });
  }

  async paginationChange(current: number) {
    this.pagination.current = current;
    await this.list({ pageSize: this.pagination.pageSize, page: current });
  }

  async list(param: { pageSize: number; page: number }) {
    const { pageSize, page } = param;
    const reqParam = {
      page_size: pageSize,
      page,
      operate_type: this.type,
      ext_idx: this.spuId,
    };
    const res = await this.operationLogService.list(reqParam);
    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }
}
