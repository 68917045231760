
import { Vue, Component } from 'vue-property-decorator';
import Welcome from '../../../public/welcome.svg';

@Component({})
export default class Home extends Vue {
  visible = true;

  svg = Welcome;

  start() {
    this.$router.push({
      name: 'main.shop.list',
    });
  }
}
