import Vue from 'vue';
import router from './router';
import LocalApp from './LocalApp.vue';
import Antd, { message } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './scss/index.scss';
import components from './components';
// import 'reflect-metadata';
import AmapVue from '@amap/amap-vue';
import uploader from 'vue-simple-uploader';
import directives from './common/directives';
import VueClipboard from 'vue-clipboard2';
import 'viewerjs/dist/viewer.css';
import VueViewer, { directive as viewerDirective } from 'v-viewer';

// AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '6f5a626b93850e6e0d46610046818b13';

Vue.config.productionTip = false;

message.config({
  top: '25%',
  duration: 3,
});

Vue.prototype.$message = message;

Vue.use(AmapVue);
Vue.use(Antd);
Vue.use(components);
Vue.use(uploader);
Vue.use(directives);
Vue.use(VueClipboard);
Vue.use(VueViewer);
Vue.directive('viewer', viewerDirective({ debug: true }));

new Vue({
  router,
  render: (h) => h(LocalApp),
}).$mount('#app');
