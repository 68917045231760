import { AuthEnum } from '@/services/permission.service';
import { KeyObject } from '@/common/interface';
import axios from 'axios';
import { message } from 'ant-design-vue';
import { BASE_RBAC_URL } from '@/common/constant/index';


axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data && response.data.data) {
      return Promise.resolve(response.data.data as KeyObject<boolean>);
    } else {
      return null;
    }
  },
  (error) => {
    return processError(error);
  },
);


const asyncPermission = async (pnames: AuthEnum[]): Promise<KeyObject<boolean> | null> => {
  let permission: KeyObject<boolean> | null = {};

  const permissionStorage = localStorage.getItem('permission');
  const permissionMap = new Map();

  if (!permissionStorage) {
    // 没有缓存
    permission = await getAsyncPermission(pnames);
  } else {
    // 有缓存
    for (const [key, val] of Object.entries(JSON.parse(permissionStorage))) {
      permissionMap.set(key, val);
    }

    for (let i = 0; i < pnames.length; i++) {
      if (!permissionMap.has(pnames[i])
        || permissionMap.get(pnames[i]).expired < Math.floor(Date.now() / 1000)
      ) {
        break;
      }

      (permission as unknown as KeyObject<boolean>)[pnames[i]] = permissionMap.get(pnames[i]).value;
      if (i === pnames.length - 1) {
        return permission;
      }
    }
    permission = await getAsyncPermission(pnames);
  }

  if (permission) {
    savePermission(permission);
  }
  return permission;
};

const getAsyncPermission = async (pnames: AuthEnum[]): Promise<KeyObject<boolean> | null> => {
  try {
    const userStorage = localStorage.getItem('user');
    const userId = userStorage
      ? JSON.parse(userStorage).id
      : null;
    const AUTHORIZATION = userStorage
      ? JSON.parse(userStorage).token
      : null;
    if (!userId || !AUTHORIZATION) {
      message.error('无用户信息，权限请求失败！');
      return null;
    }

    const res = (await axios.get(
      `${BASE_RBAC_URL}/user/${userId}/permission/${encodeURIComponent(pnames.join(','))}`,
      {
        data: {
          loadingEl: document.body,
        },
        headers: {
          AUTHORIZATION,
        },
      },
    )) as unknown as KeyObject<boolean>;

    if (res) {
      return res;
    } else {
      return {};
    }
  } catch (error) {
    message.error('权限请求失败！');
    return null;
  }
};

const savePermission = (permission: KeyObject<boolean | null>) => {
  const permissionMap = new Map();
  const permissionStorage = localStorage.getItem('permission');

  if (permissionStorage) {
    for (const [key, val] of Object.entries(JSON.parse(permissionStorage))) {
      permissionMap.set(key, val);
    }
  }

  const entries = Object.entries(permission);
  for (const [key, val] of entries) {
    permissionMap.set(key, {
      value: val,
      expired: Math.floor(Date.now() / 1000) + 60 * 3,
    });
  }

  localStorage.setItem('permission', JSON.stringify(Object.fromEntries(permissionMap)));
};

const processError = (error: any) => {
  if (axios.isCancel(error)) {
    // eslint-disable-next-line no-console
    console.log(`取消重复请求${error.message}`);
  } else {
    // 其它异常处理
    return Promise.reject(error);
  }
};

export default asyncPermission;
