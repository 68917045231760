
import lazyInject from '@/di';
import PermissionService, {
  InterfaceOrganizationRoleRes,
} from '@/services/permission.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

interface MultipleSelectOptionInterface {
  key: number;
  label: string;
}

@Component({})
export default class AssignPermissionToRoleModal extends Vue {
  loading = false;

  fetching = false;

  permissionListOfRole: Array<{
    key: number;
    label: string;
  }> = [];

  permissionOptions: Array<{
    id: number;
    name: string;
  }> = [];

  @Prop()
  visible!: boolean;

  @Prop({
    default: () => {
      return '';
    },
  })
  organizationId!: string;

  @Prop({
    default: () => {
      return '';
    },
  })
  title!: string;

  @Prop({
    default: () => {
      return null;
    },
  })
  assignPermissionToRoleConfig!: InterfaceOrganizationRoleRes;

  @Watch('assignPermissionToRoleConfig', { immediate: true, deep: true })
  watchAssignPermissionToRoleConfig() {
    if (
      this.assignPermissionToRoleConfig == null ||
      this.assignPermissionToRoleConfig.roleID === -1
    ) {
      this.permissionListOfRole = [];
      return;
    }

    this.copyPermissionListOfRole(this.assignPermissionToRoleConfig.permission);
  }

  copyPermissionListOfRole(
    permission: Array<{
      permissionId: number;
      permissionName: string;
    }>,
  ) {
    const list: Array<{
      key: number;
      label: string;
    }> = [];

    if (permission.length === 0) {
      return;
    }

    for (const p of permission) {
      const item = {
        key: p.permissionId,
        label: p.permissionName,
      };
      list.push(item);
    }
    this.permissionListOfRole = list;
  }

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  async searchPermission(value: string) {
    try {
      const res = await this.permissionService.permissionList({
        page: 1,
        pageSize: 50,
        name: value,
      });
      if (res && res.list) {
        this.permissionOptions = res.list;
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  toDeletePermission(): string {
    const permissionList: number[] = [];
    const permissionMap = new Map(
      this.permissionListOfRole.map((item) => [item.key, item]),
    );

    for (const item of this.assignPermissionToRoleConfig.permission) {
      if (!permissionMap.get(item.permissionId)) {
        permissionList.push(item.permissionId);
      }
    }

    return permissionList.join(',');
  }

  toAddPermissionList(): number[] {
    const permissionIds: number[] = [];
    const permissionMap = new Map(
      this.assignPermissionToRoleConfig.permission.map((item) => [
        item.permissionId,
        item,
      ]),
    );

    for (const item of this.permissionListOfRole) {
      if (!permissionMap.get(item.key)) {
        permissionIds.push(item.key);
      }
    }

    return permissionIds;
  }

  handleChange(value: MultipleSelectOptionInterface[]) {
    this.permissionListOfRole = value;
  }

  async submit() {
    try {
      const toDeletePermissionIds = this.toDeletePermission();
      const toAddPermissionList = this.toAddPermissionList();

      if (toDeletePermissionIds) {
        await this.permissionService.deletePermissionOfOrganizationRole({
          roleId: this.assignPermissionToRoleConfig.roleID,
          organizationId: this.organizationId,
          permissionIds: toDeletePermissionIds,
        });
      }

      if (toAddPermissionList && toAddPermissionList.length > 0) {
        await this.permissionService.addPermissionToOrganizationRole({
          roleId: this.assignPermissionToRoleConfig.roleID,
          organizationId: this.organizationId,
          permissionIds: toAddPermissionList,
        });
      }
      this.$emit(
        'updateRolePermission',
        this.assignPermissionToRoleConfig.roleID,
      );

      if (toDeletePermissionIds || toAddPermissionList.length > 0) {
        this.$message.success('更新成功');
      }
    } catch (error) {
      this.$message.error(`${error}`, 3000);
    }
  }
}
