
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Shop, City } from '@/common/interface/shop';

@Component({
  components: {},
})
export default class ShopListExamine extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  form = {
    name: '',
    regionId: '',
  };

  listData: Shop[] = [];

  citys: City[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  mounted() {
    this.handleSearch();
    this.getCityList();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    this.handleSearch();
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, regionId } = this.form;
    this.$router.push({
      query: {
        name,
        regionId,
        timestamp: `${Math.round(Date.now() / 1000)}`,
      },
    });
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;
    const { name, regionId } = this.$route.query;

    const data = await this.shopService.getList(
      {
        pageSize,
        page: current,
        name: name as string,
        regionId: regionId as string,
      },
      this.$el,
    );
    if (!data) {
      this.pagination = {
        total: 0,
        pageSize: 10,
        current: 1,
        onChange: this.paginationChange,
      };
      this.listData = [];
      return;
    }
    const { total, list } = data;
    this.pagination = {
      total,
      pageSize,
      current,
      onChange: this.paginationChange,
    };
    this.listData = list;
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      regionId: '',
    };
    this.$router.push({
      query: {
        name: '',
        regionId: '',
      },
    });
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
