
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class NameField extends Vue {
  val = '';

  @Prop()
  placeholder?: string;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled?: boolean;

  @Prop()
  refs!: any;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    if (this.val && this.val !== '') {
      if (this.refs) {
        this.refs[0].clearValidate('name');
      }
    }
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
