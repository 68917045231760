
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import { AgencyOperationService } from '@/services/agency_operation.service';
import ShopService from '@/services/shop.service';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class AddAgentStaffModal extends Vue {
  loading: boolean = false;

  isVisible: boolean = false;

  form: {
    name: string;
    uid: number | string;
    phone: string | number;
  } = {
    name: '',
    uid: '',
    phone: '',
  };

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  @lazyInject(AgencyOperationService)
  protected agencyOperationService!: AgencyOperationService;

  /** 手机号检测 */
  testPhoneValid(): boolean {
    return /1[3-9]\d{9}/.test(`${this.form.phone}`);
  }

  async submit() {
    if (!this.form.name) {
      return;
    }

    if (!this.testPhoneValid()) {
      this.$message.error('手机号不合法！');
      return;
    }

    if (!this.form.uid) {
      return;
    }

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const { name, uid, phone } = this.form;

      const res = await this.agencyOperationService.add({
        name,
        uid: Number(uid),
        phone: +phone,
      });

      if (res && res === true) {
        this.$message.success('添加成功！');

        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
      await $delay(500);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      name: '',
      uid: '',
      phone: '',
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
  }
}
