/*
 * @Author: Src
 * @Date: 2024-10-12 13:56:19
 * @LastEditTime: 2024-10-15 11:27:30
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/examine.service.ts
 * @Description: 头部注释配置模板
 */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';
import StorageService from './storage.service';
import {
  List,
} from '@/common/interface/examine';


interface ShopListRes {
  list: List[];
  total: number;
  pageSize: number;
  page: number;
}


@injectable()
export default class ExamineService {
  @inject(HttpClient)
  protected http!: HttpClient;

  @inject(StorageService)
  protected storage!: StorageService;

  async getList(
    params: {
      pageSize: number;
      page: number;
    },
  ) {
    try {
      const result = (await this.http.get('/research/agent/shop/list', { params })) as ShopListRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  async addResearchShop(data: any) {
    try {
      const result = (await this.http.post('/research/add/research/shop', data)) as any;
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSearchList(
    params: {
      pageSize: number;
      page: number;
    },
  ) {
    try {
      const result = (await this.http.get('/research/search/list', { params })) as ShopListRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSearchSummary(
    params: {
      pageSize: number;
      page: number;
    },
  ) {
    try {
      const result = (await this.http.get('/research/result/summary', { params })) as ShopListRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  async deleteResearchShop(
    params: {
    id: any;
  },
) {
    try {
      const result = (await this.http.get('/research/delete/research/shop', { params })) as any;
      return result;
    } catch (error) {
      return null;
    }
  }

  async agentList() {
    try {
      const result = (await this.http.get('/research/agent/list')) as any;
      return result;
    } catch (error) {
      return null;
    }
  }

  async updateStatus(
    params: {
      boss_id: any;
      research_results: any;
    },
  ) {
    try {
      const result = (await this.http.get('/research/update/status', { params })) as any;
      return result;
    } catch (error) {
      return null;
    }
  }
}
