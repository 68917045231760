
import { EPayeeAccountFreezeStatus } from '@/common/enum/shop';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class PayeeAccountFreezeStatusField extends Vue {
  EPayeeAccountFreezeStatus = EPayeeAccountFreezeStatus;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: any;
}
