
import { EAuditStatus, EAuditTaskDelStatus } from '@/common/enum/audit';
import { IResAuditProject, IResAuditTaskBrief } from '@/common/interface/audit';
import lazyInject from '@/di';
import AuditService from '@/services/audit.service';
import moment, { Moment } from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class AuditAll extends Vue {
  EAuditTaskDelStatus = EAuditTaskDelStatus;

  EAuditStatus = EAuditStatus;

  isVisible = false;

  current: any = [];

  typeClass = 0;

  columns = [
    {
      title: 'ID',
      dataIndex: 'taskId',
      key: 'taskId',
    },
    {
      title: '任务名称',
      dataIndex: 'taskName',
      key: 'taskName',
      scopedSlots: { customRender: 'taskName' },
    },
    {
      title: '店铺名称',
      dataIndex: 'shopName',
      key: 'shopName',
      scopedSlots: { customRender: 'shopName' },
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      scopedSlots: { customRender: 'poi' },
    },
    {
      title: '提交时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '发起人',
      key: 'createUserName',
      dataIndex: 'createUserName',
      scopedSlots: { customRender: 'createUserName' },
    },
    {
      title: '审核状态',
      key: 'auditStatus',
      dataIndex: 'auditStatus',
      scopedSlots: { customRender: 'auditStatus' },
    },
    {
      title: '审核时间',
      key: 'auditTime',
      dataIndex: 'auditTime',
      scopedSlots: { customRender: 'auditTime' },
    },
  ];

  auditStatusList: EAuditStatus[] = [
    EAuditStatus.全部,
    EAuditStatus.待审核,
    EAuditStatus.审核中,
    EAuditStatus.已通过,
    EAuditStatus.已拒绝,
  ];

  auditList: IResAuditTaskBrief[] = [];

  auditProjects: IResAuditProject[] = [];

  filterForm: {
    name: string;
    projectId: number;
    createStartTime: Moment | null;
    createEndTime: Moment | null;
    auditStartTime: Moment | null;
    auditEndTime: Moment | null;
    auditStatus: number;
    poi: string;
    shopName: string;
  } = {
      name: '',
      projectId: 0, // 审核类型
      createStartTime: null,
      createEndTime: null,
      auditStartTime: null,
      auditEndTime: null,
      auditStatus: -1,
      poi: '',
      shopName: '',
    };

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  paginationChange(current: number) {
    this.pagination.current = current;
    const query = this.extractRouterQueryAndFillFilterForm();
    Object.assign(query, { page: current });

    this.$router.push({ query });
    // this.handleSearch();
  }

  goToTaskDetail(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'audit.task',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }

  extractRouterQueryAndFillFilterForm() {
    const {
      name,
      poi,
      shopName,
      projectId,
      auditStatus,
      createStartTime,
      createEndTime,
      auditStartTime,
      auditEndTime,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (typeof projectId !== 'undefined') {
      param.projectId = projectId;
      this.filterForm.projectId = +projectId;
    }

    if (poi) {
      param.poi = poi;
      this.filterForm.poi = poi as string;
    }

    if (shopName) {
      param.shopName = shopName;
      this.filterForm.shopName = shopName as string;
    }

    if (name) {
      param.name = name;
      this.filterForm.name = name as string;
    }


    if (createStartTime) {
      param.createStartTime = createStartTime as unknown as Moment;
      this.filterForm.createStartTime = createStartTime as unknown as Moment;
    }
    if (createEndTime) {
      param.createEndTime = createEndTime as unknown as Moment;
      this.filterForm.createEndTime = createEndTime as unknown as Moment;
    }

    if (auditStartTime) {
      param.auditStartTime = auditStartTime as unknown as Moment;
      this.filterForm.auditStartTime = auditStartTime as unknown as Moment;
    }
    if (auditEndTime) {
      param.auditEndTime = auditEndTime as unknown as Moment;
      this.filterForm.auditEndTime = auditEndTime as unknown as Moment;
    }

    // if (
    //   createStartTime &&
    //   moment(createStartTime as string, moment.ISO_8601).isValid() &&
    //   createEndTime &&
    //   moment(createEndTime as string, moment.ISO_8601).isValid()
    // ) {
    //   param.createStartTime = createStartTime;
    //   param.createEndTime = createEndTime;

    //   if (this.filterForm.createStartTime == null) {
    //     this.filterForm.createStartTime = moment(createStartTime as string);
    //   } else if (
    //     this.filterForm.createStartTime.valueOf() !==
    //     moment(createStartTime as string).valueOf()
    //   ) {
    //     this.filterForm.createStartTime = moment(createStartTime as string);
    //   }

    //   if (this.filterForm.createEndTime == null) {
    //     this.filterForm.createEndTime = moment(
    //       createEndTime as string,
    //       moment.ISO_8601,
    //     );
    //   } else if (
    //     this.filterForm.createEndTime.valueOf() !==
    //     moment(createEndTime as string).valueOf()
    //   ) {
    //     this.filterForm.createEndTime = moment(createEndTime as string);
    //   }
    // }

    // if (
    //   auditStartTime &&
    //   moment(auditStartTime as string, moment.ISO_8601).isValid() &&
    //   auditEndTime &&
    //   moment(auditEndTime as string, moment.ISO_8601).isValid()
    // ) {
    //   param.auditStartTime = auditStartTime;
    //   param.auditEndTime = auditEndTime;

    //   if (this.filterForm.auditStartTime == null) {
    //     this.filterForm.auditStartTime = moment(auditStartTime as string);
    //   } else if (
    //     this.filterForm.auditStartTime.valueOf() !==
    //     moment(auditStartTime as string).valueOf()
    //   ) {
    //     this.filterForm.auditStartTime = moment(auditStartTime as string);
    //   }

    //   if (this.filterForm.auditEndTime == null) {
    //     this.filterForm.auditEndTime = moment(auditEndTime as string);
    //   } else if (
    //     this.filterForm.auditEndTime.valueOf() !==
    //     moment(auditEndTime as string).valueOf()
    //   ) {
    //     this.filterForm.auditEndTime = moment(auditEndTime as string);
    //   }
    // }

    if (typeof auditStatus !== 'undefined') {
      param.auditStatus = auditStatus;
      this.filterForm.auditStatus = +auditStatus;
    }
    return param;
  }
  /** 切换table */
  changeType(val: any) {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.typeClass = val;
    this.current[0] = val;
    const param = this.extractFilterFormParam();
    Object.assign(param, { projectId: `${val}` });
    this.$router.push({
      query: param,
    });
  }

  extractFilterFormParam() {
    const {
      name,
      poi,
      shopName,
      projectId,
      auditStatus,
      createStartTime,
      createEndTime,
      auditStartTime,
      auditEndTime,
    } = this.filterForm;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      page: `${this.pagination.current}`,
      projectId,
    };

    if (auditStatus !== -1) {
      param.auditStatus = auditStatus;
    }

    if (poi) {
      param.poi = poi;
    }

    if (shopName) {
      param.shopName = shopName;
    }

    if (name) {
      param.name = name;
    }

    if (createStartTime && createEndTime) {
      param.createStartTime = createStartTime;
      param.createEndTime = createEndTime;
    }

    if (auditStartTime && auditEndTime) {
      param.auditStartTime = auditStartTime;
      param.auditEndTime = auditEndTime;
    }
    return param;
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };

    const param = this.extractFilterFormParam();
    Object.assign(param, { page: `${this.pagination.current}` });
    param.timestamp = Math.round(Date.now() / 1000);

    this.$router.push({
      query: param,
    });
  }

  async getProject() {
    // this.auditProjects = [{ id: 0, name: '全部' }].concat(
    //   await this.auditService.auditProjects(),
    // );
    this.auditProjects = await this.auditService.auditProjects();
    this.typeClass = this.auditProjects[0]?.id;
    this.current[0] = this.auditProjects[0]?.id;
    const param = this.extractFilterFormParam();
    Object.assign(param, { projectId: `${this.auditProjects[0]?.id}` });
    this.$router.push({
      query: param,
    });
  }

  @Watch('$route')
  async handleSearch() {
    try {
      const { current, pageSize } = this.pagination;

      const param = this.extractRouterQueryAndFillFilterForm();

      Object.assign(param, {
        pageSize,
        page: current,
      });
      await this.all(param);
    } catch (error) {
      console.error(error);
    }
  }

  @lazyInject(AuditService)
  protected auditService!: AuditService;

  onChangeCreateTime(value: Moment[]) {
    if (!value.length) {
      this.filterForm.createStartTime = null;
      this.filterForm.createEndTime = null;
      return;
    }

    this.$set(
      this.filterForm,
      'createStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.filterForm,
      'createEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  onChangeAuditTime(value: Moment[]) {
    if (!value.length) {
      this.filterForm.auditStartTime = null;
      this.filterForm.auditEndTime = null;
      return;
    }

    this.$set(
      this.filterForm,
      'auditStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.filterForm,
      'auditEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  /**
   * 获取全部审核列表
   */
  async all(params: {
    pageSize?: number;
    page: number;
    name?: string;
    projectId?: number;
    auditStatus?: number;
    createStartTime?: string;
    createEndTime?: string;
    auditStartTime?: string;
    auditEndTime?: string;
  }) {
    const res = await this.auditService.list(params, this.$el);

    if (res) {
      this.auditList = res.list;

      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  async created() {
    await this.getProject();
    this.$nextTick(async () => {
      await this.handleSearch();
    });
  }
}
