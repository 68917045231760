
import { BusinessTimeSegment, EnumDays } from '@/models/businessTime.model';
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment, { Moment } from 'moment';

@Component({})
export default class BusinessTimePicker extends Vue {
  visible = false;

  savable = false;

  segment: BusinessTimeSegment = new BusinessTimeSegment();

  originalSegment: BusinessTimeSegment = new BusinessTimeSegment();

  daySelectedStates: string[] = [];

  daysText = EnumDays;

  moment = moment;

  @Watch('segment', { immediate: true })
  segmentChange(segment: BusinessTimeSegment) {
    this.daySelectedStates = segment.daySelectState();

    segment.$daySelectState.subscribe((state: string[]) => {
      this.daySelectedStates = state;
    });

    segment.$valid.subscribe((valid: boolean) => {
      this.savable = valid;
    });
  }

  get pickerValue() {
    if (this.segment) {
      return [
        this.segment.startHour,
        this.segment.startMinute / 15,
        0,
        this.segment.endHour,
        this.segment.endMinute / 15,
      ];
    } else {
      return [0, 0, 0, 0, 0];
    }
  }

  get is24Hours(): Boolean {
    if (this.segment) {
      return (
        this.segment.startHour === 0 &&
        this.segment.startMinute / 15 === 0 &&
        this.segment.endHour === 24 &&
        this.segment.endMinute / 15 === 0
      );
    } else {
      return true;
    }
  }
  onChangeStartTime(time: Moment) {
    this.segment.setStartTime(time.format('HH:mm'));
  }

  onChangeEndTime(time: Moment) {
    this.segment.setEndTime(time.format('HH:mm'));
  }

  /** 点击 营业日 ' 周x ' */
  clickDay(day: number) {
    const state = this.daySelectedStates[day];
    if (state === 'selected') {
      this.segment.removeDay(day);
    } else if (state === 'unselect') {
      this.segment.addDay(day);
    }
  }

  /** pickerView 选择具体时间 */
  onTimeChange(e: any) {
    const targetValue = e.detail.value;

    const startTime = `${`0${targetValue[0]}`.slice(-2)}:${`0${
      targetValue[1] * 15
    }`.slice(-2)}`;
    const endTime = `${`0${targetValue[3]}`.slice(-2)}:${`0${
      targetValue[4] * 15
    }`.slice(-2)}`;

    this.segment.setStartTime(startTime);
    this.segment.setEndTime(endTime);
  }

  /** 点击 X */
  hide() {
    this.visible = false;
    this.$emit('closeTimepicker');
  }

  show() {
    this.visible = true;
  }

  edit(segment: BusinessTimeSegment) {
    this.originalSegment = segment;
    this.segment = segment.clone()!;
    this.show();
  }

  /** 重置营业时间 */
  // async reset() {
  //   // 确定重置营业时间为初始态吗？ 取消/确定
  //   try {
  //     const res = await this.uniService.showModal({
  //       title: '提示',
  //       content: '确定重置营业时间为初始态吗？',
  //       confirmText: '确定',
  //       cancelText: '取消',
  //     })
  //     if (res.confirm) {
  //       this.segment.copy(this.originalSegment);
  //     }
  //   } catch (error) {
  //     this.uniService.showToastTips(`${error}`, 3000)
  //   }
  // }

  /** 点击'确认'按钮 ，增加当前营业时间设置 */
  save() {
    this.originalSegment.copy(this.segment!);
    this.hide();
  }

  /** 自定义 / 24小时 */
  modifySegmentTime(str: string) {
    const arr = str.split('-');
    this.segment.setStartTime(arr[0]);
    this.segment.setEndTime(arr[1]);
  }
}
