
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import PermissionService, {
  OrganizationListRes,
} from '@/services/permission.service';
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class OrganizationList extends Vue {
  newOrgModalVisible = false;

  loading = false;

  newOrganizationForm = {
    name: '',
  };

  form = {
    name: '',
  };

  list: Array<{
    id: number;
    name: string;
  }> = [];

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  paginationChange(current: number) {
    this.pagination.current = current;
    this.handleSearch();
  }

  /**
   * 关闭 新增团队 mdoal
   */
  closeNewOrgModal() {
    this.newOrgModalVisible = false;
    this.$set(this.newOrganizationForm, 'name', '');
  }

  /**
   * submit 新团队
   */
  async submit() {
    if (!this.newOrganizationForm.name) {
      return;
    }
    const res = await this.permissionService.createOrganization(
      this.newOrganizationForm.name,
    );

    if (res) {
      // 创建新团队成功
      this.refresh(true);
      this.$message.success('创建新团队成功！');
      await $delay(800);
      this.closeNewOrgModal();
    }
  }

  showConfirm(orgId: string) {
    this.$confirm({
      title: '您确定要删除该团队吗？',
      onOk: () => this.deleteOrg(orgId),
      onCancel: () => {},
    });
  }

  /**
   * 删除团队
   */
  async deleteOrg(orgId: string) {
    const res = await this.permissionService.deleteOrganization(orgId);
    if (res) {
      this.refresh(true);
      this.$message.success('删除成功');
    }
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name } = this.form;
    this.$router.push({
      query: {
        name,
        timestamp: `${Math.round(Date.now() / 1000)}`,
      },
    });
  }

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;
    const { name } = this.$route.query;
    const param = {
      pageSize,
      page: current,
      name: name ? `${name}` : '',
    };

    const data = (await this.permissionService.orgList(
      param,
    )) as OrganizationListRes;

    const { page, total, list } = data;

    this.pagination = {
      total,
      pageSize,
      current: +page,
      onChange: this.paginationChange,
    };
    this.list = list;
  }

  /**
   * 页面刷新
   */
  refresh(forceRefresh?: boolean) {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
    };
    this.$router.push({
      query: {
        name: '',
      },
    });

    // 强制刷新，避免路由无变化时未及时刷新
    if (forceRefresh) {
      this.handleSearch();
    }
  }

  mounted() {
    this.handleSearch();
  }
}
