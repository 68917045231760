/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable, inject } from 'inversify';
import HttpClient from './http.service';
import { City } from '@/common/interface/shop';
import { freeze } from '@/common/utils';

@injectable()
export default class BaseService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 获取城市里列表
   *
   * @param {boolean} [covered]
   * @return {Promise<City[]>}
   * @memberof BaseService
   */
  async getCityList(payload: {
    covered?: boolean;
    joined?: boolean;
    aggregation?: boolean;
  } = {
    // covered: true,
    joined: true,
    aggregation: true,
  }): Promise<City[]> {
    const query: string[] = [];
    Object.entries(payload).forEach(([key, value]) => {
      query.push(`${key}=${value}`);
    });
    const data: City[] = await this.http.get(`/region?${query.join('&')}`);
    return freeze(data);
  }
}
