
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EShopBusinessStatus } from '@/common/enum/shop';
import moment, { Moment } from 'moment';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';

@Component({})
export default class BusinessStatusControl extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  EShopBusinessStatus = EShopBusinessStatus;

  checkNumVisible = false;

  checkStringVisible = false;

  checkNum = '';

  checkString = '';

  reason = '';

  date: Moment | null = null;

  /**
   * 当前事件类型
   */
  type: EShopBusinessStatus = EShopBusinessStatus.正常营业;

  random = 0;

  @Prop()
  shopId!: string;

  get checkBeforeStopTitle(): string {
    return `为防止操作失误请输入数字：[${this.random}]`;
  }

  /**
   * 事件区分
   */
  changeBusinessStatus(type: EShopBusinessStatus) {
    this.type = type;
    switch (type) {
      case EShopBusinessStatus.正常营业:
        this.startBusiness();
        break;
      case EShopBusinessStatus.暂停营业:
        this.checkBeforeStop();
        break;
      case EShopBusinessStatus.永久停业:
        this.$warning({
          title:
            '请与高德和德友惠技术人员确认待核销记录 并充分沟通后 再执行下架操作！',
          okText: '永久停业',
          onOk: this.checkBeforeStop,
          okType: 'danger',
        });
        break;
      case EShopBusinessStatus.惩罚下线:
        this.checkBeforeStop();
        break;
      default:
        break;
    }
  }

  /**
   * 暂停/停止 前确认逻辑
   */
  checkBeforeStop() {
    this.checkNum = '';
    this.random = Math.round(Math.random() * 1000);
    this.checkNumVisible = true;
  }

  /**
   * 恢复营业
   */
  async startBusiness() {
    const res = await this.shopService.startBusiness<string>(this.shopId);
    if (!res) {
      return;
    }
    this.$emit('refresh');
  }

  handleNumCheck() {
    if (+this.checkNum === this.random) {
      this.checkNumVisible = false;
      this.checkNum = '';
      this.checkString = '';
      this.date = null;
      this.checkStringVisible = true;
    } else {
      this.$message.error('输入错误！');
    }
  }

  /** 停业 */
  async handleStringCheck() {
    if (this.checkString === this.type) {
      if (!this.reason) {
        return this.$message.error(`${this.type}的原因不能为空！`);
      }

      switch (this.type) {
        case EShopBusinessStatus.暂停营业: {
          if (!this.date) {
            return this.$message.error('请选择恢复营业日期');
          }

          const res = await this.shopService.stopBusiness<string>(
            this.shopId,
            `${this.date.format('YYYY-MM-DD')} 00:00:00`,
            this.reason,
          );
          if (!res) {
            return;
          }
          this.$emit('refresh');
          this.checkStringVisible = false;
          this.reason = '';
          break;
        }
        case EShopBusinessStatus.永久停业: {
          const res = await this.shopService.foreverStopBusiness<string>(
            this.shopId,
            this.reason,
          );
          if (!res) {
            return;
          }
          this.$emit('refresh');
          this.checkStringVisible = false;
          this.reason = '';
          break;
        }
        case EShopBusinessStatus.惩罚下线: {
          const res = await this.shopService.punitiveStopBusiness<string>(
            this.shopId,
            this.reason,
          );
          if (!res) {
            return;
          }
          this.$emit('refresh');
          this.checkStringVisible = false;
          this.reason = '';
          break;
        }
        default: {
          break;
        }
      }
    } else {
      this.$message.error('输入错误！');
    }
  }

  /** 惩罚下线 */
  async penalty() {
    // punitiveStopBusiness
  }

  /** 日期范围禁用 */
  public disabledDate(current: Moment) {
    return current < moment().add(-1, 'd');
  }
}
