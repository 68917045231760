
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Moment } from 'moment';
import { FormModel } from 'ant-design-vue';
import ShopService from '@/services/shop.service';
import lazyInject from '@/di';
import { inject } from 'inversify';
import UserService from '@/services/user.service';


@Component({})
export default class ShopInspectionRecord extends Vue {
  private visible = false;

  @inject(UserService)
  protected userService!: UserService;

  loading = false;

  form: {
    title: string;
    subtitle: string;
    content: string;
    inspector: string;
    inspectTime: Moment|null;
  } = {
    title: '',
    subtitle: '',
    content: '',
    inspector: '',
    inspectTime: null,
  };

  listData: Array< {
    city: string;
    content: string;
    created_at: string;
    id: number;
    poi: string;
    record_user: string;
    shop_id: string;
    title: string;
    bi_url: string;
  }> = [];

  rules = {
    title: [
      { required: true, message: '请选择巡检标题', trigger: 'blur' },
    ],
    subtitle: [
      { required: true, message: '巡检标题和子标题都不能为空', trigger: 'blur' },
    ],
    content: [
      { required: true, message: '请输入巡检记录', trigger: 'blur' },
    ],
    inspector: [
      { required: true, message: '请输入巡检人员姓名', trigger: 'blur' },
    ],
    inspectTime: [
      { required: false, message: '请选择巡检时间', trigger: 'blur' },
    ],
  };

  titleMap = new Map<string, string[]>();

  titles: string[] = [];

  subTitles: string[] = [];


  @Prop()
  id!: string;

  @Ref()
  public readonly ruleForm!: FormModel;

  @lazyInject(ShopService)
  shopService!: ShopService;

  get biPagePath() {
    if (!this.listData.length) {
      return '/#/bi/embedded/门店/门店巡检记录/30d59166-a84b-488d-bf3f-3d0f7f3fe0b9/';
    }
    return `/#/bi/embedded/门店/门店巡检记录/30d59166-a84b-488d-bf3f-3d0f7f3fe0b9?${this.listData[0].bi_url}`;
  }

  show() {
    this.visible = true;
    const userData = JSON.parse(localStorage.getItem('user') || '');
    this.form.inspector = userData.name;
  }

  handleTitleChange(str: string) {
    this.$set(this.form, 'title', str);
    const subtitles = this.titleMap.get(str) || [];
    this.subTitles = [...subtitles];
  }

  handleSubtitleChange(sub: string) {
    this.$set(this.form, 'subtitle', sub);
  }

  async fetchAllTitles() {
    const res = await this.shopService.shopInspectionTitles();
    const map = new Map<string, string[]>();
    if (res && res.length) {
      for (const item of res) {
        const subtitles = item.value.map((subtitle) => subtitle.name);
        map.set(item.name, subtitles);
      }
    }
    this.titles = Array.from(map.keys());
    this.titleMap = new Map([...this.titleMap, ...map]);
  }

  async list() {
    const res = await this.shopService.shopInspectionRecordList(this.id);
    if (res && res.length) {
      this.listData = res;
    }
  }

  async add() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        if (this.loading) {
          return;
        }
        this.loading = true;

        try {
          const { title, subtitle, content, inspector } = this.form;
          const res = await this.shopService.addShopInspectionRecord({ shopId: this.id, title, subtitle, content, inspector });

          if (res) {
            this.$message.success(`${res}`);
            await this.list();

            setTimeout(() => {
              this.close();
            }, 300);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error', error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  close() {
    this.$nextTick(() => {
      this.visible = false;
      this.ruleForm.resetFields();
      this.form = {
        title: '',
        subtitle: '',
        content: '',
        inspector: '',
        inspectTime: null,
      };
      this.$forceUpdate();
    });
  }

  goToShopInspectionDetail() {

  }

  mounted() {
    this.fetchAllTitles();
    this.list();
  }
}
