
import { City } from '@/common/interface';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import BaseService from '@/services/base.service';
import moment, { Moment } from 'moment';
import RegionService from '@/services/region.service';

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class RegionCoverModal extends Vue {
  loading = false;

  cities: City[] = [];

  coverableCities: City[] = [];

  visible = false;

  form: {
    regionId: string | number;
    startDate: Moment | null;
  } = {
    regionId: '',
    startDate: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionCoveredMap!: Map<string, any>;

  @lazyInject(RegionService)
  protected regionService!: RegionService;

  @lazyInject(BaseService)
  protected baseService!: BaseService;

  @Prop({
    default: () => {
      return [];
    },
  })
  coveredList!: Array<City & { province: City | null }>;

  @Watch('coveredList', { immediate: true, deep: true })
  handleRegionCoveredMap() {
    if (!this.coveredList.length) {
      return;
    }
    this.regionCoveredMap = new Map(
      this.coveredList.map((item) => [`${item.id}`, item]),
    );

    if (!this.cities.length) {
      return;
    }

    const coverableCities: City[] = [];
    const { cities } = this;

    for (const city of cities) {
      if (!this.regionCoveredMap.has(`${city.id}`)) {
        coverableCities.push(city);
      }
    }
    this.coverableCities = coverableCities;
  }

  @Watch('cities', { immediate: true })
  watchCities() {
    if (!this.cities.length) {
      return;
    }

    if (!this.regionCoveredMap) {
      return;
    }

    const coverableCities: City[] = [];
    const { cities } = this;

    for (const city of cities) {
      if (!this.regionCoveredMap.has(`${city.id}`)) {
        coverableCities.push(city);
      }
    }
    this.coverableCities = coverableCities;
  }

  async submit() {
    if (!this.form.regionId) {
      this.$message.warn('请选择开城城市！', 4);
      return;
    }
    if (!this.form.startDate) {
      this.$message.warn('请选择开城日期！', 4);
      return;
    }
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const startDate = moment(this.form.startDate).format('YYYYMMDD');
      const res = await this.regionService.cover(
        +this.form.regionId,
        startDate,
      );

      if (res) {
        this.$message.success('开城成功');
        this.$emit('refresh');
        await $delay(500);
        this.close();
        this.reset();
      }
    } catch (error) {
      this.$message.info(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      regionId: '',
      startDate: null,
    };
  }

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
    this.reset();
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  async getCityList() {
    const response = (await this.baseService.getCityList({
      aggregation: true,
    })) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.cities = citys;
    } else {
      this.cities = [];
    }
  }

  async mounted() {
    await this.getCityList();
  }
}
