
import axios from 'axios';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import UTILService from '@/services/utils.service';
import {
  Vue,
  Component,
  Watch,
  Model,
  Prop,
  Ref,
} from 'vue-property-decorator';
import VideoUModal from '@/components/video/VideoModal.vue';

interface IVideo {
  id: string;
  url: string;
  cover: string;
  order?: number;
  origin_url?: string;
}

@Component({
  components: {
    VideoUModal,
  },
})
export default class VideosField extends Vue {
  videosLimit = 1;

  uploading = false;

  uploadProgress = 0;

  val: IVideo[] = [];

  watchable = true;

  @Prop({
    default: () => {
      return '';
    },
  })
  shopId!: string;

  @Model('change')
  value!: IVideo[];

  @Ref()
  videoModal!: VideoUModal;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit(
      'change',
      !this.val.length ? [] : this.val.map((item) => item.id),
    );

    this.$emit('updateSubmittable', this.val.length !== 0);
  }

  @Watch('value')
  watchValue() {
    if (!this.watchable) {
      return;
    }
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  @lazyInject(UTILService)
  protected toolsService!: UTILService;

  removeVideo(idx: number) {
    const videos = [...this.val];
    videos.splice(idx, 1);
    this.val = [...videos];
  }

  async getVideosLimit() {
    const res = await this.shopService.shopVideosLimit();
    if (res) {
      this.videosLimit = res;
    }
  }

  async beforeUploadVideo(file: File) {
    const isLt150M = file.size / 1024 / 1024 < 100;
    if (!isLt150M) {
      this.$message.error('视频必须小于100MB!');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async customUpload($event: any) {
    if (this.uploading) {
      return;
    }
    this.uploading = true;

    const ossConfig = await this.toolsService.getOssConfig(this.shopId);
    if (ossConfig == null) {
      this.uploading = false;
      return;
    }

    const { access_id, policy, signature, callback, dir, host, file_name } =
      ossConfig;
    const { file } = $event;
    const extensionName: string = file.name.split('.')[1];

    const formData = new FormData();
    formData.append('key', `${dir}${file_name}.${extensionName}`);
    formData.append('name', file_name);
    formData.append('OSSAccessKeyId', access_id);
    formData.append('policy', policy);
    formData.append('signature', signature);
    formData.append('success_action_status', '200');
    formData.append('callback', callback);
    formData.append('file', file);

    try {
      axios({
        url: host,
        data: formData,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = (loaded / total) * 100;
          $event.onProgress({ percent });
          this.uploadProgress = percent;
        },
      }).then(
        (res) => {
          if (res) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { cover, id, url, origin_url } = res as any;
            const videos = [...this.val];
            videos.unshift({
              id,
              url,
              cover,
              origin_url: origin_url as string,
            });
            this.val = [...videos];

            $event.onSuccess(res, file);
            this.uploading = false;
          }
        },
        (err) => {
          $event.onError(err);
          this.uploading = false;
        },
      );
    } catch (error) {
      $event.onError(error);
      this.uploading = false;
    }
  }

  async mounted() {
    this.watchValue();
    this.watchable = false;
    await this.getVideosLimit();
  }
}
