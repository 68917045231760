/** C端订状态32 */
export enum ECOrderStatus {
  全部 = 0,
  未核销 = 1,
  已核销 = 2,
}

/** C端订单退款进度 */
export enum ECOrderRefundProgress {
  空 = 0,
  线下退款待转账 = 1,
  线下退款已转账 = 2,
}

export enum ECOrderRefundAction {
  发起线下退款 = 1,
  线下转账记录 = 2,
}
