import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import LabelList from '@/views/label/LabelList.vue';

const labelRoutes: RouteConfig = {
  name: 'label',
  path: '/label',
  component: Main,
  redirect: {
    name: 'label.list',
  },
  meta: {
    title: '标签管理',
  },
  children: [
    {
      name: 'label.list',
      path: '/label/list',
      component: LabelList,
      meta: {
        title: '标签列表',
      },
    },
  ],
};

export default labelRoutes;
