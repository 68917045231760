
import { EOperationType } from '@/common/enum/shop';
import VideoUModal from '@/components/video/VideoModal.vue';
import { Vue, Component, Ref } from 'vue-property-decorator';

interface IDrawerConfig {
  title: string;
  current: string[];
  previous: string[];
  type: EOperationType;
}

const initConfigValue = {
  title: '',
  current: [] as string[],
  previous: [] as string[],
  type: EOperationType.DEFAULT,
};

@Component({
  components: {
    VideoUModal,
  },
})
export default class OperationLogDetailDrawer extends Vue {
  EOperationType = EOperationType;

  isVisible = false;

  drawerConfig: IDrawerConfig = { ...initConfigValue };

  viewImageVisible = false;

  viewImageSource = '';

  @Ref()
  videoModal!: VideoUModal;

  openVideoModal(videoUrl: string) {
    this.videoModal.open(videoUrl);
  }

  setDrawerConfig(config: IDrawerConfig) {
    this.drawerConfig = Object.assign({}, config);
  }

  viewImage(src: string) {
    this.viewImageSource = src;
    this.viewImageVisible = true;
  }

  onClose() {
    this.isVisible = false;
    this.drawerConfig = { ...initConfigValue };
  }
}
