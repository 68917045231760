
import { AgentAccountRes } from '@/common/interface/account';
import lazyInject from '@/di';
import { AgentService } from '@/services/agent.service';
import { Vue, Component, Ref } from 'vue-property-decorator';
import EditAgentModal from './components/EditAgentModal.vue';
import AddAgentRegion from './components/AddAgentRegion.vue';

@Component({
  components: {
    'edit-agent-modal': EditAgentModal,
    'add-agent-ragion': AddAgentRegion,
  },
})
export default class AgentList extends Vue {
  agentList: AgentAccountRes[] = [];

  selectedAgent: AgentAccountRes | null = null;

  selectedAgentId: number | null = null;

  @Ref('editAgentModal')
  editAgentModal!: EditAgentModal;

  @Ref('addAgentRegion')
  addAgentRegion!: AddAgentRegion;

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oepnEditAgentModal(agent: any) {
    // eslint-disable-next-line no-console
    if (agent !== undefined) {
      this.selectedAgent = agent;
    }
    this.editAgentModal.open();
  }


  openAddRegionModal(id: number) {
    if (id == null) {
      this.$message.error('代理商id无效，请检测！', 4500);
      return;
    }
    this.selectedAgentId = id;
    this.addAgentRegion.open();
  }

  async removeRegion(agentId: number, areaId: number) {
    const res = await this.agentService.removeRegion(agentId, areaId);
    if (res) {
      this.$message.success(res);
      this.refresh();
    }
  }

  async getAgentList() {
    const res = await this.agentService.all();
    this.agentList = res;
  }

  async refresh() {
    await this.getAgentList();
  }

  async created() {
    await this.getAgentList();
  }
}
