/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ActivityType } from '@/common/enum/activity';
import { IResActivity } from '@/common/interface/activity';
import { Moment } from 'moment';

export default class Activity {
  name = '';

  id?: number;

  actType = ActivityType.秒杀;

  adcodes?: string;

  description = ''; // 活动规则

  totalLimit = 0; // 总库存

  dailyLimit = 0; // 日库存

  icon = ''; // 活动头图

  subIcon = ''; // 专区标题图

  gmtStartTime: Moment | undefined = undefined;

  gmtEndTime: Moment | undefined = undefined;

  activityPriceCent = 0; // 活动价格，单位分；

  platformProductId: number | string = ''; // 平台商品id;

  static from(data: IResActivity) {
    const act = new Activity();
    act.name = data.name;
    act.actType = data.act_type;
    act.description = data.description;
    act.dailyLimit = data.act_day_limit;
    act.totalLimit = data.act_total_limit;
    act.icon = data.icon;
    act.subIcon = data.subIcon;
    act.platformProductId = data.spu_id;
    act.activityPriceCent = data.act_price;
    act.gmtEndTime = data.gmt_end;
    act.gmtStartTime = data.gmt_start;
    if (data.id) {
      act.id = data.id;
    }
    if (data.adcodes && data.adcodes !== '') {
      act.adcodes = data.adcodes;
    }

    return act;
  }
}
