
import { EKASpuTyreTag } from '@/common/enum/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TyreTagField extends Vue {
  val: EKASpuTyreTag[] = [];

  kaList: Array<{ key: string; val: number }> = [];

  tags: EKASpuTyreTag[] = [
    EKASpuTyreTag.静音,
    EKASpuTyreTag.操控,
    EKASpuTyreTag.运动,
    EKASpuTyreTag.新能源,
    EKASpuTyreTag.稳定,
    EKASpuTyreTag.全路况,
    EKASpuTyreTag.防爆,
    EKASpuTyreTag.冬季胎,
    EKASpuTyreTag.自修补,
  ];

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change')
  value!: string;

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value.split(',') as EKASpuTyreTag[];
    } else {
      this.val = [];
    }
  }

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val.join(','));
  }

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  onChange(val: EKASpuTyreTag[]) {
    this.val = [...val];
  }

  async getKaList() {
    const res = await this.spuService.kaSpuAccessibleKas();
    if (res && res.length) {
      this.kaList = [...res];
    }
  }

  mounted() {
    this.watchValue();
    console.log('++++ KA+++ mounted end');
  }

  async created() {
    if (!this.disabled) {
      await this.getKaList();
    }
  }
}
