
import { ENonBusinessHours } from '@/common/enum/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class UnavailableTimeField extends Vue {
  val: number[] = [];

  nonBusinessHours = ['周末不可用', '春节期间不可用', '法定节假日不可用'];

  ENonBusinessHours = ENonBusinessHours;

  allTextsMap = new Map<string, string>();

  get nonBusinessHoursText() {
    if (!this.val.length) {
      return '全部时段可用';
    }
    return this.val
      .map((item) => {
        return ENonBusinessHours[item as any];
      })
      .join(',');
  }

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model()
  value!: number[];

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Watch('val', { immediate: true })
  watchInternalVal(): void {
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    if (this.value && this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  onChange(checkedValues: string[]) {
    const values = checkedValues.map((item: any) => {
      return ENonBusinessHours[item];
    });

    this.val = [...values] as unknown as number[];
    this.$emit('update', this.val);
  }

  async getSpuUnavailableTimeTexts() {
    const res = await this.spuService.spuUnavailableTimeText();
    const map = new Map<string, string>();
    if (res) {
      for (const [k, v] of Object.entries(res)) {
        map.set(k, v);
      }
      this.allTextsMap = map;
    }
  }

  mounted() {
    this.watchValue();
  }

  getUnavailableTimeText(val: string[]) {
    if (!val.length) {
      return '';
    }
    const key = val.join(',');
    return this.allTextsMap.get(`${key}`);
  }

  async created() {
    await this.getSpuUnavailableTimeTexts();
  }
}
