
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseLayout extends Vue {
  /**
   * 标题
   */
  @Prop({ default: 'title' })
  title?: string;

  /**
   * 副标题
   */
  @Prop({ default: '' })
  subTitle?: string;

  /**
   * 是否显示刷新按钮
   */
  @Prop({
    default: true,
  })
  showRefresh?: boolean;

  /**
   * 刷新事件
   */
  @Prop()
  refresh?: () => void;

  /**
   * 是否显示返回按钮
   */
  @Prop({
    default: false,
  })
  showBack?: boolean;

  get headerClass(): string {
    return `base-layout--header ${this.showBack ? '' : 'hide-back'}`;
  }

  /**
   * 刷新事件
   */
  handleRefresh() {
    if (this.refresh) {
      this.refresh();
      this.$message.info('正在刷新!');
    } else {
      this.$message.warn('未设置刷新事件');
    }
  }

  /**
   * 返回事件
   */
  @Prop()
  back?: () => void;

  handleBack() {
    if (this.back) {
      this.back();
    } else {
      this.$router.go(-1);
    }
  }
}
