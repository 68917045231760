/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'debounce';
import Vue, { ComponentOptions } from 'vue';
import { createDecorator } from 'vue-class-component';
import { is } from '../utils/index';

/**
 * @decorator
 * 防抖函数转换器(将组件的上的函数自动转化成debounce函数)
 * @param interval debounce时间 默认618ms
 * @param immediate  是否立即执行
 *
 */
export default function DebounceTime(
  interval = 618, // 默认黄金分割 618ms
  immediate = false, // 立即执行
) {
  return createDecorator((options: ComponentOptions<Vue>, key) => {
    if (!(options.methods && options.methods[key])) {
      return;
    }
    const originCreated = options.created;
    // eslint-disable-next-line no-param-reassign
    options.created = function () {
      const currentFn = (this as unknown as any)[key] as Function;

      if (!is(currentFn, Function)) {
        throw new Error(`${key} 不是函数`);
      }
      const debouncedFn = debounce(currentFn, interval, immediate);
      if (originCreated && is(originCreated, Function)) {
        originCreated.apply(this);
      }
      (this as any)[key] = debouncedFn;
    };
    // options.methods[key] = debouncedFn as any;
    // eslint-disable-next-line no-param-reassign
    options.methods[key] = debounce(options.methods[key], interval, immediate);
  });
}
