import { render, staticRenderFns } from "./Push.vue?vue&type=template&id=0cd55a56&scoped=true"
import script from "./Push.vue?vue&type=script&lang=ts"
export * from "./Push.vue?vue&type=script&lang=ts"
import style0 from "./Push.vue?vue&type=style&index=0&id=0cd55a56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd55a56",
  null
  
)

export default component.exports