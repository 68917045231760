
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class KaField extends Vue {
  val = '';

  kaList: Array<{ key: string; val: number }> = [];

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change')
  value!: string;

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
  }

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  onChange(val: number | string) {
    const idx = this.kaList?.findIndex((item) => item.val === val);

    if (this.kaList && typeof idx !== 'undefined' && idx > -1) {
      const targert = this.kaList[idx];
      this.val = targert.key;
    }
  }

  async getKaList() {
    const res = await this.spuService.kaSpuAccessibleKas();
    if (res && res.length) {
      this.kaList = [...res];
    }
  }

  mounted() {
    this.watchValue();
  }

  async created() {
    if (!this.readonly) {
      await this.getKaList();
    }
  }
}
