/* eslint-disable @typescript-eslint/no-explicit-any */
import { EShopField, EShopStaffRole } from '@/common/enum/shop';
// import { SpuNonBusinessHoursEnum } from '@/views/shop/common/SPUForm';

/**
 * 店铺列表每一条
 *
 * @export
 * @interface Shop
 */
export interface Shop {
  poi: string;
  syncId: number;
  shopId: number;
  passId: number;
  name: string;
  address: string;
  tels: string;
  aname: string;
  staffName: string;
  businessStatus: string;
  audit: any;
  regionName: string;
  venderId: string;
  bindStatus: PlatformSpuBindStatusEnum;
}

export interface Mechanisc {
  id: number;
  introduce: string;
  mech: string;
  name: string;
  order: number;
  shopId: number;
  years: string;
  picId: Array<{
    id: number;
    order: number;
    url: string;
  }>;
}

export interface Staff {
  group: EShopStaffRole;
  name: string;
  phone: string;
  shopId: number;
  userId: number;
}

export interface ShopProfileDetail {
  shopId: number;
  shopName: string;
  poi: string;
  address: string;
  // eslint-disable-next-line no-use-before-define
  businessStatus: ShopProfileBusinessStatusEnum; // ?
  city: string;
  dyhStaffName: string | null;
  crmStaffName: string | null;
  bossName: string | null;
  claimTime: string | null;
  crmClaimTime: string | null;
  isApply: number;
  isEdit: number;
  isPass: number;
}

export enum ShopProfileBusinessStatusEnum {
  正常营业 = 0,
  暂停营业 = 1,
  永久暂停营业 = 2,
  未入驻 = 3,
  申请中 = 4,
  已填写草稿 = 5,
}

export enum MerchantSettledEnum {
  未入驻 = -1,
  入驻中 = 0,
  入驻成功 = 1,
  入驻失败 = 2,
  资料修改中 = 3,
  已下线 = 4,
}

export enum PlatformSpuBindStatusEnum {
  全部 = 0,
  已报名 = 1,
  报名过 = 2,
  未报名 = 3,
}

export interface MerchantInfo {
  info: string;
  status: MerchantSettledEnum;
}

export interface ShopDetail {
  advertiserMobilePhoneNumber: string;
  address: string;
  bossIdImage: Array<{
    id: number;
    order: number;
    url: string;
  }>;
  bossTels: string;
  businessStatus: string;
  businessTime: string;
  dataChed: string | null;
  discount: string | null;
  latitude: string;
  longitude: string;
  mainBusiness: string[];
  managerTels: string;
  name: string;
  parkNum: number | null;
  poi: string;
  qrcode: string;
  restArea: string | null;
  shopId: number;
  shopImages: Array<{
    id: number;
    order: number;
    url: string;
  }>;
  shopVideos: Array<{
    id: number;
    order: number;
    url: string;
    cover: string;
  }>;
  videos: Array<{
    id: number;
    order: number;
    url: string;
    cover: string;
  }>;
  shoplicense: Array<{
    id: number;
    order: number;
    url: string;
  }>;
  startTime: string;
  station: number | null;
  tels: string;
  mechaniscs: Mechanisc[];
  staffList: {
    [key: string]: {
      roleId: number;
      desc: string;
      data: Staff[];
    };
  };
  region: string;
}

export type EditShopFiledType = Partial<
  Pick<
    ShopDetail,
    | 'bossIdImage'
    | 'bossTels'
    | 'advertiserMobilePhoneNumber'
    | 'managerTels'
    | 'shoplicense'
    | 'shopImages'
    | 'startTime'
    | 'mainBusiness'
    | 'tels'
    | 'restArea'
    | 'station'
    | 'parkNum'
    | 'businessTime'
    | 'dataChed'
    | 'name'
    | 'address'
  > & {
    label: EShopField;
    labelText: string;
    storageRack: any;
  }
>;

export interface City {
  code: string; // 城市编号
  depth: number;
  id: number; // 城市id
  name: string;
  pid?: number;
  pinyin: string;
  pinyin2: string;
  province?: City;
}

export interface Kalist {
  kaId: number;
  kaName: string;
}

export interface UploaderFileResponse {
  file: File;
  fileOriginName?: string;
  fileBucket?: string;
  fileName?: string;
  fileEtag?: string;
  /** 文件预览地址 */
  fileUrl: string;
}

export interface RequestConfig {
  file: File;
  onError: (err: Error) => void;
  onProgress: (options: { percent: number }, file: File) => void;
  onSuccess: (response: UploaderFileResponse) => void;
}

export interface IShopOperationLogItem {
  operateTime: string;
  operatorName: string;
  operatorId: number | string;
  content: any;
}

export interface IResShopRunningReport {
  shop_name: string;
  date_list: string[];
  report_data?: {
    search_uv: string;
    detail_uv: string;
    nav_uv: string;
    tel_num: string;
    order_num: string;
    gmv: string;
    comment_num: string;
    search_contrast: string;
    detail_contrast: string;
    nav_contrast: string;
    tel_contrast: string;
    order_contrast: string;
    gmv_contrast: string;
    comment_contrast: string;
    display: string;
    cost: string;
    click: string;
  };
  msg?: string;
}

/** 周环比指数趋势 */
export type TWeekOnWeekBasisTrend = 'up' | 'down' | 'flat';

export interface IWeeklyRunningReport {
  navNum: string; // 导航量
  navNumWeekOnWeekBasis: string;
  navNumTrend: TWeekOnWeekBasisTrend;
  impressions: string; // 搜索曝光量
  impressionsWeekOnWeekBasis: string;
  impressionsTrend: TWeekOnWeekBasisTrend;
  clicks: string; // 点击量
  clicksWeekOnWeekBasis: string;
  clicksTrend: TWeekOnWeekBasisTrend;
  callNum: string; // 电话量
  callNumWeekOnWeekBasis: string;
  callNumTrend: TWeekOnWeekBasisTrend;
  cOrderNum: string; // C端订单量
  cOrderNumWeekOnWeekBasis: string;
  cOrderNumTrend: TWeekOnWeekBasisTrend;
  gmv: string; // 成交金额
  gmvWeekOnWeekBasis: string;
  gmvTrend: TWeekOnWeekBasisTrend;
  comments: string; // 累计评论数
  commentsWeekOnWeekBasis: string;
  commentsTrend: TWeekOnWeekBasisTrend;
  campaign: {
    costAmount: string;
    impressions: string;
    clicks: string;
  };
}
/** @todo 再度优化 */
export interface IShopFormField {
  labelName: string;
  label: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  fieldTitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
  placeholder?: string;
  extra?: string;
  rule?: Array<{
    required?: boolean;
    pattern?: RegExp;
    message: string;
    trigger: string;
    validator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;
  }>;
  submitAction: (val?: any) => void;
}

export interface IEditShopFieldAddress {
  locationName: string;
  address: string;
  regions: number[];
  latitude: string;
  longitude: string;
}

/** 门店员工数据结构 */
export interface IShopStaffConfig {
  [role: string]: {
    desc: string; // 角色描述
    roleId: number;
    data: Array<{
      shopId: number;
      group: string;
      name: string;
      phone: string;
      userId: number;
    }>;
  };
}

export interface IShopField {
  labelName: string;
  label: EShopField;
  placeholder?: string;
  extra?: string;
  readonly?: boolean;
  rule?: Array<{
    required?: boolean;
    pattern?: RegExp;
    message: string;
    trigger: string;
    validator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;
  }>;
  fieldOpts?: any;
  isHidden?: boolean;
}
