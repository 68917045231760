/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { WithdrawOrderItem, WithdrawOrderRes } from '@/common/interface/withdraw';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class WithdrawService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async all(
    params: {
      pageSize?: number;
      page: number;
      shopName?: string;
      status?: number;
    },
    loadingEl?: Element | Vue,
  ): Promise<
    {
      lastPage: number;
      page: number;
      total: number;
      pageSize: string;
      list: WithdrawOrderItem[];
    }> {
    const res = await this.http.get('withdraw/list', { params, data: { loadingEl } }) as {
      list: WithdrawOrderRes[];
      lastPage: number;
      page: number;
      pageSize: string;
      total: number;
    };

    const { list, lastPage, page, pageSize, total } = res;
    return {
      lastPage,
      page,
      total,
      pageSize,
      list: list.map(item => {
        const { account, amountCent, createTime, note, orderId, payOrderId, shopId, shopName, status, updateTime, userName, regionName, withdrawStatus } = item;
        return {
          alipay: {
            account,
            name: userName,
          },
          createTime,
          amountCent,
          remark: note,
          orderId,
          alipayOrderId: payOrderId,
          shopId,
          shopName,
          status,
          updateTime,
          regionName: regionName || '--',
          withdrawStatus: withdrawStatus || '--',
        };
      }),
    };
  }

  /** 转账 */
  async transfer(
    orderId: number,
    loadingEl?: Element | Vue,
  ) {
    const res = await this.http.post(`withdraw/agreeWithDraw/${orderId}`,
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;
    return res;
  }

  /** 提现撤销 */
  async cancel(orderId: number, loadingEl?: Element | Vue) {
    const res = await this.http.post(`withdraw/revokeApply/${orderId}`,
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;
    return res;
  }
}
