var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.auditNode.length > 0)?_c('a-row',{staticStyle:{"align-items":"center"}},[_c('a-col',{staticStyle:{"color":"#000","font-weight":"bold","text-align":"right","font-size":"15px"},attrs:{"span":4}},[_vm._v(" 审核步骤： ")]),_c('a-col',{attrs:{"span":8}},[_c('a-steps',{attrs:{"direction":"vertical"}},[_c('a-step',{attrs:{"status":"finish","title":_vm.createNode.title,"description":_vm.createNode.description}}),_vm._l((_vm.auditNode),function(node,nodeIndex){return _c('a-step',{key:nodeIndex,attrs:{"status":node.auditStatus == _vm.auditStatus.已通过
            ? 'finish'
            : node.auditStatus == _vm.auditStatus.已拒绝
            ? 'error'
            : node.auditStatus == _vm.auditStatus.待审核
            ? 'process'
            : 'loading',"title":_vm.auditStatus[node.auditStatus],"description":`${node.auditUserName} ${node.auditTime}`}},[(node.auditStatus == _vm.auditStatus.待审核)?_c('a-icon',{attrs:{"slot":"icon","type":"loading"},slot:"icon"}):_vm._e()],1)})],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }