/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable, inject } from 'inversify';
import HttpClient from './http.service';
import {
  ActivityListRes,
  SignUp,
  ActivityFormRequestVal,
  SpuSignupableActivity,
  IResActivity,
  IResActivityList,
} from '@/common/interface/activity';
import { ActivityStatus, SignupStatus } from '@/common/enum/activity';
import Activity from '@/models/activity.model';

@injectable()
export default class ActivityService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 获取活动列表
   * @param params
   * @param loadingEl
   * @returns
   */
  async getActivityList(
    params: {
      pageSize: number;
      page: number;
      name: string;
      status: number;
      adCode?: string;
      activityPackageId: string;
    },
    loadingEl?: Element | Vue,
  ): Promise<ActivityListRes> {
    const data = (await this.http.get('/activity/list', {
      params,
      data: {
        loadingEl,
      },
    })) as ActivityListRes;
    return data;
  }

  /**
   * 获取商品可报名的活动列表
   * @param spuid
   * @returns
   */
  async getSpuSignupableActivityList(spuid: string) {
    try {
      const res = (await this.http.get(
        `/spu/${spuid}/activity`,
      )) as SpuSignupableActivity[];
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 修改活动状态&同步至高德
   * @param id
   * @param status
   * @param loadingEl
   * @returns
   */
  async status(
    id: number,
    status: ActivityStatus,
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = (await this.http.post(
      `/activity/${id}/status`,
      {
        status,
      },
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 查询活动下的报名信息
   * @param id
   * @param loadingEl
   * @returns
   */
  async getSignup(id: number, loadingEl?: Element | Vue): Promise<SignUp[]> {
    const data = (await this.http.get(`/activity/${id}/signup`, {
      data: {
        loadingEl,
      },
    })) as SignUp[];
    return data;
  }

  /**
   * 更新活动详情
   * @param id
   * @param payload
   * @param loadingEl
   * @returns
   */
  async updateActivityDetail(
    id: number,
    payload: ActivityFormRequestVal,
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = (await this.http.post(
      `/activity/${id}/update`,
      {
        ...payload,
        gmt_signup_start: payload.gmt_signup_start?.format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        gmt_signup_end: payload.gmt_signup_end?.format('YYYY-MM-DD HH:mm:ss'),
        gmt_start: payload.gmt_start?.format('YYYY-MM-DD HH:mm:ss'),
        gmt_end: payload.gmt_end?.format('YYYY-MM-DD HH:mm:ss'),
        ext: JSON.stringify(payload.ext),
      },
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 同步状态至高德
   * @param id
   * @param loadingEl
   * @returns
   */
  async asyncStatus(id: number, loadingEl?: Element | Vue): Promise<string> {
    const data = (await this.http.post(
      `/activity/${id}/async-status`,
      {},
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 同步详情 编辑活动
   * @param id
   * @param loadingEl
   * @returns
   */
  async asyncDetail(id: number, loadingEl?: Element | Vue): Promise<string> {
    const data = (await this.http.post(
      `/activity/${id}/async-detail`,
      {},
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 推送报名信息
   * @param id
   * @param loadingEl
   * @returns
   */
  async asyncSignup(id: number, loadingEl?: Element | Vue): Promise<string> {
    const data = (await this.http.post(
      `/activity/${id}/async-signup`,
      {},
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 创建活动
   * @param payload
   * @param loadingEl
   * @returns
   */
  async createActivity(
    payload: ActivityFormRequestVal,
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = (await this.http.put(
      '/activity/create',
      {
        ...payload,
        gmt_signup_start: payload.gmt_signup_start?.format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        gmt_signup_end: payload.gmt_signup_end?.format('YYYY-MM-DD HH:mm:ss'),
        gmt_start: payload.gmt_start?.format('YYYY-MM-DD HH:mm:ss'),
        gmt_end: payload.gmt_end?.format('YYYY-MM-DD HH:mm:ss'),
        ext: JSON.stringify(payload.ext),
      },
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 更新(审核)报名信息状态
   * @param id
   * @param status
   * @param loadingEl
   * @returns
   */
  async updateSignupStatus(
    id: number,
    status: SignupStatus,
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = (await this.http.post(
      `activity/signup/${id}/status/${status}`,
      {},
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    )) as string;
    return data;
  }

  /**
   * 商品报名活动
   * @param params
   * @returns
   */
  async spuSignup(params: {
    activityId: string;
    spuid: string;
    shopid: string;
  }) {
    const { activityId, spuid, shopid } = { ...params };
    const result = (await this.http.post(`activity/${activityId}/signup`, {
      spuid,
      shopid,
    })) as string;

    return result;
  }

  async activityPackages() {
    const res = (await this.http.get('activity/package-list')) as Array<{
      activityPackageId: number;
      activityPackageName: string;
    }>;

    if (res) {
      return res;
    }
    return [];
  }

  /**
   * 创建标准商品营销活动
   * @param payload IResActivity
   * @param loadingEl
   */
  async createActivityV2(payload: IResActivity, loadingEl?: Element | Vue) {
    const data = (await this.http.post(
      '/activity/add_active',
      {
        ...payload,
        gmt_start: payload.gmt_start,
        gmt_end: payload.gmt_end,
      },
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  async updateActivity(packageId: number, payload: IResActivity, loadingEl?: Element | Vue) {
    const data = (await this.http.post(
      `/activity/${packageId}/edit_active`,
      {
        ...payload,
      },
      {
        data: {
          loadingEl,
        },
      },
    )) as string;
    return data;
  }

  async list(
    params: {
      pageSize: number;
      page: number;
      name?: string;
      status?: number;
      adcode?: string;
      platformProductId: string;
      gmtStartTime?: string;
      gmtEndTime?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const { pageSize, page, name, status, adcode, platformProductId, gmtStartTime, gmtEndTime } = params;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newParams: any = {
      page,
      pageSize,
    };
    if (name) {
      newParams.name = name;
    }
    if (status) {
      newParams.status = status;
    }
    if (adcode) {
      newParams.adCode = adcode;
    }
    if (platformProductId) {
      newParams.spuId = platformProductId;
    }
    if (gmtStartTime) {
      newParams.gmt_start = gmtStartTime;
    }
    if (gmtEndTime) {
      newParams.gmt_end = gmtEndTime;
    }

    const res = await this.http.get('activity/package_list', {
      params: newParams,
      data: {
        loadingEl,
      },
    }) as IResActivityList;

    return res;
  }

  async detail(package_id: number | string) {
    const res = await this.http.get(`activity/${package_id}/detail`) as IResActivity;

    return Activity.from(res);
  }

  /**
   * 上线
   * @param package_id
   * @returns
   */
  async online(package_id: number | string) {
    const res = await this.http.get(`activity/${package_id}/online`) as string;

    return res;
  }

  /**
   * 下线
   * @param package_id
   * @returns
   */
  async offline(package_id: number | string) {
    const res = await this.http.get(`activity/${package_id}/offline`) as string;

    return res;
  }

  /**
   * 复制活动
   * @param package_id
   * @returns
   */
  async duplicate(package_id: number | string, cloneBaseinfo?: boolean) {
    const url = typeof cloneBaseinfo !== 'undefined' && cloneBaseinfo === true ? `activity/${package_id}/copy?lite=1` : `activity/${package_id}/copy`;
    const res = await this.http.get(`${url}`) as string;

    return res;
  }
}
