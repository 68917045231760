/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import AESEncryptor from '@/services/aes_encryptor.service';

export default class User {
  id = 0;

  name = '';

  agentName = '';

  phone = '';

  token = '';

  appToken = '';

  public aesEncryptedToken(shopId = 0): string | null {
    const token = this.appToken;

    const stringfied = JSON.stringify({
      uid: this.id,
      ts: Math.floor(Date.now() / 1000),
      shopid: shopId,
      token,
    });

    const encryptor = new AESEncryptor();
    const result = encryptor.encrypt(stringfied);

    return result;
  }
}
