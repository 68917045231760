/** 周一到周日 枚举 */

import { Subject, BehaviorSubject } from 'rxjs';
import { message } from 'ant-design-vue';

// eslint-disable-next-line no-shadow
export enum EnumDays {
  周一 = 0,
  周二 = 1,
  周三 = 2,
  周四 = 3,
  周五 = 4,
  周六 = 5,
  周日 = 6,
}

const ALL_DAYS = new Set([0, 1, 2, 3, 4, 5, 6]);

export class BusinessTimeSegment {
  // $daySelectState = new Subject<string[]>();
  // $change = new Subject<boolean>();
  // $valid = new BehaviorSubject<boolean>(false);
  $daySelectState = new Subject();
  $change = new Subject();
  $valid = new BehaviorSubject(false);

  constructor(days: number[] = [], startTime = '00:00', endTime = '24:00') {
    this.days = new Set(days);
    this.startTime = startTime;
    this.endTime = endTime;

    this.$change.subscribe(() => {
      this.$daySelectState.next(this.daySelectState());
      this.$valid.next(this.isValid());
    });
  }

  public days!: Set<number>;

  protected startTime!: string;

  protected endTime!: string;

  public businessTime: BusinessTime | undefined;

  static parseTime(s: string): [string, string] {
    const fields = s.split('-');
    return [fields[0], fields[1]];
  }

  copy(segment: BusinessTimeSegment): void {
    this.setDays(new Set(segment.days));
    this.setStartTime(segment.startTime);
    this.setEndTime(segment.endTime);
  }

  setBusinessTime(businessTime: BusinessTime): void {
    this.businessTime = businessTime;
    this.$change.next(true);
  }

  setStartTime(s: string): void {
    this.startTime = s;
    this.$change.next(true);
  }

  setEndTime(s: string): void {
    this.endTime = s;
    this.$change.next(true);
  }

  get startHour(): number {
    return parseInt(this.startTime.split(':')[0], 10);
  }

  get startMinute(): number {
    return parseInt(this.startTime.split(':')[1], 10);
  }

  get endHour(): number {
    return parseInt(this.endTime.split(':')[0], 10);
  }

  get endMinute(): number {
    return parseInt(this.endTime.split(':')[1], 10);
  }

  setDays(days: Set<number>): void {
    this.days = days;
    this.$change.next(true);
  }

  addDay(day: number): void {
    if (day < 0 || day > 6) {
      return;
    }

    this.days = new Set(Array.from(this.days.add(day)).sort());
    this.$change.next(true);
  }

  removeDay(day: number): void {
    this.days.delete(day);
    this.$change.next(true);
  }

  isValid(): boolean {
    if (this.days.size === 0) {
      return false;
    }

    if (this.endTime <= this.startTime) {
      return false;
    }

    return true;
  }

  daySelectState() {
    const states: string[] = [];

    for (let day = 0; day < 7; day++) {
      if (this.days.has(day)) {
        states[day] = 'selected';
      } else if (
        this.businessTime != null &&
        this.businessTime.hasDaySelected(day)
      ) {
        states[day] = 'disable';
      } else {
        states[day] = 'unselect';
      }
    }
    return states;
  }

  clone(): BusinessTimeSegment | null {
    if (!this.businessTime) {
      const segment = new BusinessTimeSegment();
      segment.copy(this);
      return segment;
    }

    const clonedBusinessTime = this.businessTime.clone();
    for (const segment of clonedBusinessTime.segments) {
      if (this.isEqual(segment)) {
        return segment;
      }
    }
    return null;
  }

  isEqual(segment: BusinessTimeSegment): boolean {
    if (
      this.startTime !== segment.startTime ||
      this.endTime !== segment.endTime
    ) {
      return false;
    }

    if (this.days.size !== segment.days.size) {
      return false;
    }

    for (const day of this.days) {
      if (!segment.days.has(day)) {
        return false;
      }
    }

    return true;
  }

  static parseDays(str: string): Set<number> {
    const days = new Set<number>();
    const fileds = str
      .trim()
      .replace(/,/g, '|,|')
      .replace(/至/g, '|至|')
      .split('|');

    let last = 0;
    for (let i = 0; i < fileds.length; i++) {
      const s: string = fileds[i];
      if (s === ',') {
        continue;
      }
      if (s === '至') {
        const nextStr = fileds[i + 1];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const next = Number(EnumDays[nextStr as any]);
        for (let j = last + 1; j <= next; j++) {
          days.add(j);
        }
        i += 1;
        continue;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const day = Number(EnumDays[s as any]);
      last = day;
      days.add(last);
    }
    return days;
  }

  static parseToSegment(str: string): BusinessTimeSegment | undefined {
    const fields = str.split(' ');

    const segment = new BusinessTimeSegment();
    if (fields.length === 0) {
      return undefined;
    } else if (fields.length === 1) {
      if (fields[0].indexOf(',') > -1) {
        setTimeout(() => {
          message.error(`${fields[0]}的时间格式不合法，请重新设置`);
        }, 800);
        return undefined;
      }
      const times = BusinessTimeSegment.parseTime(fields[0]);
      segment.setDays(ALL_DAYS);
      segment.startTime = times[0];
      segment.endTime = times[1];
    } else {
      const daysStr = fields[0];
      const days = BusinessTimeSegment.parseDays(daysStr);

      const timeStr = fields[1];
      const times = BusinessTimeSegment.parseTime(timeStr);

      segment.setDays(days);
      segment.startTime = times[0];
      segment.endTime = times[1];
    }

    return segment;
  }

  static sortContinuousArray(arr: number[]) {
    const list = [];
    let temp = [arr[0]];
    for (let i = 1, l = arr.length; i < l; i++) {
      if (arr[i] === arr[i - 1] + 1) {
        temp.push(arr[i]);
      } else {
        list.push(temp);
        temp = [arr[i]];
      }
    }
    list.push(temp);
    return list;
  }

  daysToString(): string | null {
    if (this.days.size === 0) {
      return null;
    }

    const days = Array.from(this.days).sort();

    const splitedDays = BusinessTimeSegment.sortContinuousArray(days);

    const daysStr = [];

    for (const splitedDay of splitedDays) {
      if (splitedDay.length === 1) {
        daysStr.push(EnumDays[splitedDay[0]]);
      } else if (splitedDays[0].length < 7) {
        daysStr.push(
          `${EnumDays[splitedDay[0]]}至${
            EnumDays[splitedDay[splitedDay.length - 1]]
          }`,
        );
      }
    }

    return daysStr.join(',');
  }

  timeToString(): string {
    return `${this.startTime}-${this.endTime}`;
  }

  segmentToString(): string | null {
    const daysString = this.daysToString();
    if (daysString == null) {
      return null;
    }

    return !this.daysToString()
      ? this.timeToString()
      : `${this.daysToString()} ${this.timeToString()}`;
  }

  toString() {
    return this.segmentToString();
  }
}

export class BusinessTime {
  // $avaliableDays: BehaviorSubject<Set<number>> = new BehaviorSubject<Set<number>>(ALL_DAYS);

  // $segments = new BehaviorSubject<BusinessTimeSegment[]>([]);
  $avaliableDays = new BehaviorSubject(ALL_DAYS);

  $segments = new BehaviorSubject([]);

  constructor() {
    this.$segments.subscribe(() => {
      this.$avaliableDays.next(this.avaliableDays());
    });
  }

  clone(): BusinessTime {
    const cloned = new BusinessTime();
    for (const segment of this.segments) {
      const seg = cloned.createSegment();
      seg.copy(segment);
    }
    return cloned;
  }

  createSegment(): BusinessTimeSegment {
    const segment = new BusinessTimeSegment();
    segment.setBusinessTime(this);

    const segments = this.$segments.value;
    segments.push(segment);
    this.$segments.next(segments);
    return segment;
  }

  /** 删除某个营业时段 */
  removeSegment(idx: number) {
    const segments = this.$segments.value.concat([]);
    segments.splice(idx, 1);

    this.$segments.next(segments);
  }

  static parse(str: string): BusinessTime {
    const businessTime = new BusinessTime();

    if (str) {
      const segments: BusinessTimeSegment[] = str
        .split(';')
        .map((s) => {
          return s.trim();
        })
        .map((s) => {
          return BusinessTimeSegment.parseToSegment(s)!;
        })
        .filter((s) => {
          return s !== undefined;
        });

      segments.forEach((segment) => {
        segment.setBusinessTime(businessTime);
      });
      businessTime.$segments.next(segments);
    } else {
      businessTime.$segments.next([]);
    }

    return businessTime;
  }

  sortValidSegments(): void {
    if (this.$segments.value.length < 1) {
      return;
    }

    const segments = this.$segments.value;
    const newSegments = segments
      // eslint-disable-next-line array-callback-return
      .filter((item: BusinessTimeSegment) => {
        if (item.isValid()) {
          return item;
        }
      })
      .sort((a: BusinessTimeSegment, b: BusinessTimeSegment) => {
        return Array.from(a.days).sort()[0] - Array.from(b.days).sort()[0];
      });
    this.$segments.next(newSegments as BusinessTimeSegment[]);
  }

  static parseToList(str: string): string[] {
    if (str) {
      return str.split(';');
    } else {
      return [];
    }
  }

  toString(): string {
    return this.segments
      .map((s) => {
        return s.segmentToString();
      })
      .filter((s) => {
        return s != null;
      })
      .join('; ');
  }

  avaliableDays(): Set<number> {
    let usedDays = new Set<number>();

    for (const segment of this.$segments.value) {
      usedDays = new Set<number>([...usedDays, ...segment.days]);
    }
    const avaliableDays = new Set(
      [...ALL_DAYS].filter((x) => !usedDays.has(x)),
    );

    return avaliableDays;
  }

  get segments(): BusinessTimeSegment[] {
    return this.$segments.value;
  }

  hasDaySelected(day: number): boolean {
    for (const segment of this.segments) {
      if (segment.days.has(day)) {
        return true;
      }
    }
    return false;
  }

  isValid(): boolean {
    return this.$segments.value.length > 0;
  }
}
