
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class StationField extends Vue {
  val: number | null = 0;

  /** 洗车工位数 */
  stationArray = Array.from({ length: 50 }, (_v, k) => {
    return {
      value: k + 1,
      label: `${k + 1}个`,
    };
  });

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: number;

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  mounted() {
    this.watchValue();
  }
}
