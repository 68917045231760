import { render, staticRenderFns } from "./AddRoleModal.vue?vue&type=template&id=59c32f99&scoped=true"
import script from "./AddRoleModal.vue?vue&type=script&lang=ts"
export * from "./AddRoleModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c32f99",
  null
  
)

export default component.exports