import { EShopField } from './shop';

/** 审核模板枚举 @todo 移除 */
export enum EAuditTemplate {
  SHOP_INFO = 'shop-info', // 门店资料（特别是门店入驻）
  SHOP_SERVICE = 'shop-service', // 商品服务
  PUBLICK = 'public', // 其他类型
}

/** 门店资料field */
export const AUDIT_SHOP_FIELD = {
  ...EShopField,
  ADDRESS: 'address',
  AREA: 'area',
  BUSINESS_STATUS: 'businessStatus',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  NAME: 'name',
  POI: 'poi',
  QRCODE: 'qrcode',
  MECHANICS: 'mechaniscs',
  SHOP_ID: 'shopId',
  STAFF_LIST: 'staffList',
  BOSS_TELS: 'bossTels',
  ALIPAY_INFO: 'alipayInfo',
  SHOP_SERVICE: 'shopService',
  ORIGIN_NAME: 'originName',
  MECHINE_CALLBACK_DATA: 'mechineCallBackResponse',
  VIDEOS: 'videos',
  CONTRACT: 'contract', // 合同
  REGION: 'region', // 省市区
  MAP_LOCATION: 'mapLocation',
  B_ORDER: 'bOrder',
};

/**
 * 门店资料审核项的中文描述
 */
export enum ShopMaterialLabelEnum {
  alipayInfo = '支付宝账号',
  address = '详细地址',
  bossIdImage = '身份证',
  bossTels = '老板电话',
  businessStatus = '营业状态',
  businessTime = '营业时间',
  dataChed = '独立洗车位',
  longitude = '经度',
  latitude = '纬度',
  mainBusiness = '主营业务',
  managerTels = '经理电话 ',
  name = '店铺名称',
  parkNum = '免费停车位',
  poi = 'POIID',
  qrcode = '二维码',
  restArea = '免费休息区',
  shopId = '店铺ID ',
  shopImages = '店铺图片',
  shoplicense = '营业执照',
  shopVideos = '店铺视频',
  startTime = '开业时间',
  station = '工位数量 ',
  tels = '联系电话',
  mechaniscs = '金牌技师',
  staffList = '店长与员工',
  shopService = '商品列表',
  mechineCallBackResponse = '机器审核数据',
  videos = '实拍视频(审核)',
  area = '营业面积',
  contract = '合同',
  region = '省市区',
  bOrder = 'B端年费信息',
  mapLocation = '地图位置',
}

/** 审核操作枚举 */
export enum EAuditOperation {
  PASS = 2, // 通过
  DISMISS = 3, // 拒绝
}

/** 审核状态枚举 */
export enum EAuditStatus {
  全部 = -1,
  待审核 = 0,
  审核中 = 1,
  已通过 = 2,
  已拒绝 = 3,
  // 待提交 = 4,
}

/** 审核任务删除 */
export enum EAuditTaskDelStatus {
  正常 = 0,
  删除 = 1,
}
/** 审核类型 */
export enum EAuditProject {
  审核入驻门店 = 3,
  店铺商品审核 = 5,
  店铺编辑审核 = 6,
}
