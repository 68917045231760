
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { DebounceTime } from '@/common/decorator';
import { Shop } from '@/common/interface/shop';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import SPUService from '@/services/spu.service';
import { IResSPU } from '@/common/interface/spu';

interface CopyModel {
  type?: '1' | '2';
  sourceShopId?: number;
  spuId?: number;
}

@Component({})
export default class CopySPUModal extends Vue {
  @Prop()
  copyVisible!: boolean;

  @Prop()
  shopId!: string;

  @Ref()
  readonly copyForm!: FormModel;

  @lazyInject(SPUService)
  spuService!: SPUService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  copyLoading = false;

  copyModel: CopyModel = {
    type: undefined,
    spuId: undefined,
    sourceShopId: undefined,
  };

  copyRules = {
    type: [{ required: true, message: '请选择复制方式', trigger: 'blur' }],
    sourceShopId: [
      { required: true, message: '请选择源店铺', trigger: 'blur' },
    ],
    spuId: [{ required: true, message: '请选择商品', trigger: 'blur' }],
  };

  sourceShopList: Shop[] = [];

  copyBriefs: IResSPU[] = [];

  disabled = false;

  /**
   * 确认复制
   */
  handleCopyOk() {
    this.copyForm.validate(async (valid: boolean) => {
      if (!valid) return;
      const { type, spuId, sourceShopId } = this.copyModel;
      this.disabled = true;
      let msg = '';
      if (type === '1') {
        // 单个商品复制
        const result = await this.spuService.spuClone(
          this.shopId,
          spuId as number,
        );
        this.disabled = false;
        msg = result || '';
        if (!result) return;
      } else {
        // 门店商品复制
        const result = await this.spuService.shopSpusClone(
          sourceShopId as number,
          this.shopId,
        );
        this.disabled = false;
        msg = result || '';
        if (!result) return;
      }
      this.disabled = false;
      this.afterCopy(msg);
    });
  }

  /**
   * 关闭复制
   */
  handleCopyClose() {
    this.copyForm.resetFields();
    this.$emit('close');
  }

  /**
   * 复制 源店铺搜索
   */
  @DebounceTime()
  async searchSourceShop(key: string) {
    this.copyLoading = true;
    const data = await this.shopService.getList({
      pageSize: 20,
      page: 1,
      name: key,
      regionId: '',
    });
    this.copyLoading = false;
    this.sourceShopList = data ? data.list : [];
  }

  /** 复制SPU筛选过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  copySPUFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  afterCopy(msg: string) {
    this.copyForm.resetFields();
    this.$emit('refresh');
    this.$message.success(msg);
  }

  @Watch('copyModel.sourceShopId')
  async onSourceShopChange() {
    this.copyModel.spuId = undefined;
    if (this.copyModel.type === '1') {
      if (this.copyModel.sourceShopId) {
        const data = await this.spuService.shopSpus(
          `${this.copyModel.sourceShopId}`,
        );
        this.copyBriefs = data;
      } else {
        this.copyBriefs = [];
      }
    }
  }

  @Watch('copyModel.type')
  async onCopyTypeChange() {
    this.copyModel.spuId = undefined;
    this.copyModel.sourceShopId = undefined;
    this.sourceShopList = [];
    this.copyBriefs = [];
  }
}
