
import { Vue, Component } from 'vue-property-decorator';
import BaseBIPage from '@/views/order/components/BaseBIPage.vue';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';

@Component({
  components: {
    BaseBIPage,
  },
})
export default class BIEmbedded extends Vue {
  pageUrl = '';

  title = '';

  pageId = '';

  biParams = '';

  @lazyInject(OrderService)
  orderService!: OrderService;

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.init();
      },
    );
    this.init();
  }

  async init() {
    if (!this.$route.params.pageId) {
      return;
    }
    if (this.$route.params.title) {
      this.title = this.$route.params.title as string;
    }
    if (this.$route.query.param) {
      this.biParams = this.$route.query.param as string;
    }

    if (['SHOP_OPREATION_LOG', 'WITHDRAW', 'SHOP', 'SHOP_SUMMARY', 'B_ORDER', 'DAILY_B_SELL', 'AGENT_VISIT', 'CITY_SHOP', 'BLACK_CARD_DETAIL', 'SHOP_OUTDATE', 'RIGHT_OUTDATE', 'ERROR_CHECK', 'PASS_SHOP', 'C_ORDER_SUMMARY', 'C_ORDER_LIST'].includes(this.$route.params.pageId)) {
      const path = await this.orderService.BITable(this.$route.params.pageId);
      this.pageUrl = `https://bi.aliyuncs.com/dashboard/view/pc.htm?pageId=${path || this.$route.params.pageId}&qbi_version_param=2`;
      return false;
    }
    if (this.biParams !== '') {
      this.setPageId(this.$route.params.pageId, this.biParams);
    } else {
      this.setPageId(this.$route.params.pageId);
    }
  }

  setPageId(id: string, biParams?: string) {
    this.pageId = id;
    let pageUrl = `https://bi.aliyuncs.com/dashboard/view/pc.htm?pageId=${id}&qbi_version_param=2`;
    if (typeof biParams !== 'undefined' && biParams !== '') {
      pageUrl += `&param=${encodeURI(biParams)}`;
    }

    this.pageUrl = pageUrl;
  }
}
