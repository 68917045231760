
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { FormModel } from 'ant-design-vue';
import { Vue, Component, Ref } from 'vue-property-decorator';
import moment, { Moment } from 'moment';

interface IForm {
  channel: string | undefined;
  orderId: string;
  refundOrderNum: string;
  refundTime: Moment | null;
  refundAmount: string;
  payee: string;
}

@Component({})
export default class COrderTransferModal extends Vue {
  isVisible = false;

  isLoading = false;

  labelCol = { span: 6 };

  wrapperCol = { span: 14 };

  form: IForm = {
    channel: undefined,
    orderId: '',
    refundOrderNum: '', // 退款流水单后
    refundTime: null, // 退款时间
    refundAmount: '', // 金额
    payee: '', // 退款账号
  };

  get disabled() {
    if (
      this.form.orderId &&
      this.form.channel &&
      this.form.refundAmount &&
      this.form.payee &&
      this.form.refundTime
    ) {
      return false;
    }
    return true;
  }

  rules = {
    channel: [
      {
        required: true,
        message: '退款方式不能为空',
        rigger: 'blur',
      },
    ],
    refundOrderNum: [
      {
        required: true,
        message: '流水单号不能为空',
        trigger: 'blur',
      },
    ],
    refundTime: [
      {
        required: true,
        message: '退款时间不能为空',
        trigger: 'blur',
      },
    ],
    refundAmount: [
      {
        required: true,
        message: '退款金额不能为空',
        trigger: 'blur',
      },
    ],
    payee: [
      {
        required: true,
        message: '收款账号不能为空',
        trigger: 'blur',
      },
    ],
  };

  refundChannel = [
    { label: '支付宝', value: '支付宝' },
    { label: '微信', value: '微信' },
    { label: '银行转账', value: '银行转账' },
    { label: '其它', value: '其它' },
  ];

  @Ref() readonly ruleForm!: FormModel;

  @lazyInject(OrderService)
  orderService!: OrderService;

  onChangeDatetime(value: Moment) {
    if (value == null) {
      this.form.refundTime = null;
      return;
    }

    this.form.refundTime = value;
    this.ruleForm.clearValidate('refundTime');
  }

  async open(
    orderId: string,
    refundInfo?: {
      channel: string;
      transferOrderNum: string;
      refundAmount: string;
      refundTime: string;
      payee: string;
    },
  ) {
    this.$set(this.form, 'orderId', orderId);
    if (refundInfo) {
      const { channel, payee, refundAmount, refundTime, transferOrderNum } =
        refundInfo;
      Object.assign(this.form, {
        channel: channel && channel !== '' ? channel : undefined,
        payee,
        refundAmount,
        refundTime,
        refundOrderNum: transferOrderNum,
      });
    }

    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  reset() {
    this.form = {
      channel: undefined,
      orderId: '',
      refundOrderNum: '',
      refundTime: null,
      refundAmount: '',
      payee: '',
    };

    this.isLoading = false;
  }

  async submit() {
    if (
      !this.form.channel ||
      !this.form.orderId ||
      !this.form.refundOrderNum ||
      !this.form.refundAmount ||
      !this.form.payee ||
      !this.form.refundTime
    ) {
      return;
    }

    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const {
        orderId,
        refundAmount,
        refundTime,
        payee,
        refundOrderNum,
        channel,
      } = this.form;
      const res = await this.orderService.COrderRefundRecordEdit({
        channel,
        orderId,
        refundAmount,
        transferOrderNum: refundOrderNum,
        payee,
        refundTime: moment(refundTime).format('YYYY-MM-DD HH:mm:ss'),
      });
      if (res) {
        this.$message.success(`${res}`);
        setTimeout(() => {
          this.close();
          this.$emit('refresh');
        }, 0);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }
}
