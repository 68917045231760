/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { AgentAccountRes } from '@/common/interface/account';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

/** 服务商 */
export interface AgentRes {
  aid: number;
  name: string;
}

@injectable()
export class AgentService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 获取服务商
   * @param {{
   *  name?: string;
   * pageSize: number;
   * page: number
   * }}
   * @return {Promise<AgentRes[]>}
   */
  async list(params: {
    name?: string;
    pageSize: number;
    page: number;
  }): Promise<AgentRes[]> {
    try {
      const result = (await this.http.get('/agent/search/list', {
        params,
      })) as { list: AgentRes[] };
      return result.list;
    } catch (error) {
      throw error;
    }
  }

  async staff() {
    try {
      const res = await this.http.get('/agent/staff/list ') as Array<{
        agentName: string;
        staffs: Array<{
          name: string;
          id: number;
          phone: string;
        }>;
      }>;
      return res;
    } catch (error) {
      throw error;
    }
  }

  async staffChange(param: {orderId: number; userId: number; reason: string}) {
    try {
      const { orderId, userId, reason } = param;
      const res = await this.http.put(`dyh-order/${orderId}/create-staff/change`, {
        userId,
        reason,
      }) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  async all() {
    try {
      const res = await this.http.get('w/agent/list') as AgentAccountRes[];
      return res;
    } catch (error) {
      throw error;
    }
  }


  /**
   * 创建代理商
   * @param name
   * @param loadingEl
   * @returns
   */
  async create(name: string, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.post('w/agent', {
        name,
      }, {
        data: {
          loadingEl: loadingEl || document.body,

        },
      }) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 修改代理商
   * @param agentId
   * @param name
   * @param status
   * @param loadingEl
   * @returns
   */
  async modify(agentId: number, name: string, status: number, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.put(`w/agent/${agentId}`, {
        name,
        status,
      },
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;

      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * 给代理商添加区域
   * @param params
   * @returns
   */
  async addRegion(params: {agentId: number; regionPid: number; regionId: number }) {
    const { agentId, regionPid, regionId } = params;

    try {
      const res = await this.http.post(`w/agent/${agentId}/area`, {
        regionPid,
        regionId,
      }) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 代理商移除区域
   * @param agentId
   * @param areaId
   * @param loadingEl
   * @returns
   */
  async removeRegion(agentId: number, areaId: number, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.delete(`w/agent/${agentId}/area/${areaId}`, {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }
}
