
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TelsField extends Vue {
  val: string[] = [];

  phone = '';

  get inputLength(): number {
    if (this.phone.indexOf('0') === 0) {
      return 13;
    } else if (this.phone.indexOf('400') === 0) {
      return 10;
    } else {
      return 11;
    }
  }

  get addTelsDisabled() {
    const phoneReg = /^400\d{7}|^1[3-9]\d{9}|^0[1-9]\d{1,2}-{1}[2-9]\d{6,7}/;
    return !phoneReg.test(this.phone as string);
  }

  @Prop()
  placeholder?: string;

  @Prop()
  name!: string;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val.join(','));

    if (!this.val.length) {
      this.$emit('updateSubmittable', false);
    } else {
      this.$emit('updateSubmittable', true);
    }
  }

  @Watch('value')
  watchValue() {
    if (this.value && this.value !== '') {
      if (this.val !== this.value.split(',')) {
        this.val = this.value.split(',');
      }
    } else {
      this.val = [];
    }
  }

  addTels(phoneNum: string) {
    this.val.push(phoneNum);
    this.phone = '';
  }

  deletePhone(idx: number) {
    this.val.splice(idx, 1);
  }

  setPreferredPhone(idx: number) {
    const head = this.val.splice(idx, 1);
    this.val.unshift(head[0]);
  }

  mounted() {
    this.watchValue();
  }
}
