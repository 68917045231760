
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class NameField extends Vue {
  val = '';

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val', { immediate: true })
  watchInternalVal(): void {
    this.$emit('change', this.val);

    if (!this.val || this.val === '') {
      this.$emit('updateSubmittable', false);
    } else {
      this.$emit('updateSubmittable', true);
    }
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
