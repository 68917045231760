import { Vue } from 'vue-property-decorator';
import Header from './Header.vue';
import MainNav from './MainNav.vue';
import BaseLayout from './base-layout/index.vue';
import ViewImage from './ViewImage.vue';

export default {
  install(V: typeof Vue) {
    V.component('app-header', Header);
    V.component('app-main-nav', MainNav);
    V.component('base-layout', BaseLayout);
    V.component('view-image', ViewImage);
  },
};
