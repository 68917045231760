
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { AuthEnum } from '@/services/permission.service';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class PartialRefoundPopover extends Vue {
  clicked = false;

  AuthEnum = AuthEnum;

  @Prop({
    default: 0,
  })
  orderId!: number;

  @lazyInject(OrderService)
  orderService!: OrderService;

  handleClickChange(visible: boolean) {
    this.clicked = visible;
  }

  /** 退款确认 */
  async refund(id: number, isPartialRefound?: boolean) {
    const res = await this.orderService.refund(id, isPartialRefound);
    if (!res) {
      return;
    }
    this.$message.success(res);
    this.clicked = false;
    this.$emit('refresh', true);
  }
}
