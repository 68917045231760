/*
 * @Author: Src
 * @Date: 2024-10-14 17:56:47
 * @LastEditTime: 2024-10-17 10:57:41
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/questionnaireRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import AuthHome from '@/views/auth/AuthHome.vue';
import ExamineQuestionnaire from '@/views/examine/ExamineQuestionnaire.vue';

const authRoutes: RouteConfig = {
  name: 'examine.home',
  path: '/examine',
  component: AuthHome,
  children: [
    {
      name: 'examine.questionnaire',
      path: '/examine/questionnaire',
      component: ExamineQuestionnaire,
      meta: { title: '市场调研' },
    },
  ],
};

export default authRoutes;
