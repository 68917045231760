
import moment from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import {
  City,
} from '@/common/interface/shop';
import ShopService from '@/services/shop.service';
import ExamineService from '@/services/examine.service';

@Component({})
export default class ExamineShop extends Vue {
  expandedRowKeys = [];

  citys: City[] = [];
  dataPic = '';
  moment = moment;
  tagentList: any = [];

  monthFormat = 'YYYY/MM';

  @lazyInject(ShopService)
  shopService!: ShopService;


  @lazyInject(ExamineService)
  examineService!: ExamineService;

  form: {
    name: string;
    is_write: any;
    research_results: any;
    research_month: any;
    research_year: any;
    agent_id: any;
  } = {
      name: '',
      is_write: undefined,
      research_year: '',
      research_month: '',
      research_results: undefined,
      agent_id: undefined,
    };

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  columns = [
    {
      title: '考核月份',
      dataIndex: 'research_year',
      key: 'research_year',
      scopedSlots: { customRender: 'research_year' },
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      scopedSlots: { customRender: 'poi' },
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      key: 'shop_name',
    },
    {
      title: '调研结果',
      dataIndex: 'research_results',
      key: 'research_results',
      ellipsis: true,
      scopedSlots: { customRender: 'research_results' },

    },
    {
      title: '加入考核列表时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 180,
      scopedSlots: { customRender: 'created_at' },
    },
    {
      title: '填写时间',
      dataIndex: 'write_at',
      key: 'write_at',
      width: 180,
      scopedSlots: { customRender: 'write_at' },

    },
    {
      title: '代运营',
      dataIndex: 'agent_name',
      key: 'agent_name',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ];
  selectList = [
    { label: 0, value: '否' },
    { label: 1, value: '是' },
  ];
  data = [];


  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { is_write, research_results, research_year, research_month, agent_id } = this.$route.query;
    const { pageSize, current } = this.pagination;
    const param: any = {
      pageSize,
      is_write,
      research_results,
      research_year,
      research_month,
      agent_id,
      page: current,
    };
    if (research_year && research_month) {
      this.dataPic = `${research_year}-${research_month}`;
    }
    if (research_year) {
      this.form.research_year = research_year;
    }
    if (research_month) {
      this.form.research_month = research_month;
    }
    if (is_write) {
      this.form.is_write = Number(is_write);
    }
    if (research_results) {
      this.form.research_results = Number(research_results);
    }
    this.getList(param);
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const {
      is_write,
      research_results,
      research_year,
      research_month,
      agent_id,
    } = this.form;
    const query: any = {
      page: current,
    };
    query.is_write = is_write;
    if (research_year) {
      query.research_year = research_year;
    }
    if (research_month) {
      query.research_month = research_month;
    }
    if (agent_id) {
      query.agent_id = agent_id;
    }
    query.research_results = research_results;
    this.$router.push({
      query,
    });
  }

  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
      showTotal: (total: any) => `共 ${total} 条`,
    };
    this.form = {
      name: '',
      is_write: undefined,
      research_year: '',
      research_month: '',
      research_results: undefined,
      agent_id: undefined,
    };
    this.dataPic = '';
    this.$router.push({
      query: {},
    });
  }

  search() {
    const {
      is_write,
      research_results,
      research_year,
      research_month,
      agent_id,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.pagination.current = 1;
    const query: any = {
      page: 1,
    };
    query.is_write = is_write;
    if (research_year) {
      query.research_year = research_year;
    }
    if (research_month) {
      query.research_month = research_month;
    }
    if (agent_id) {
      query.agent_id = agent_id;
    }
    query.research_results = research_results;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  async confirm(val: any) {
    const params: any = {
      id: val.id,
    };
    const response = (await this.examineService.deleteResearchShop(params)) as any;
    if (response) {
      this.$message.success('操作成功');
      this.search();
    }
  }
  async getList(params: {
    pageSize: number;
    page: number;
  }) {
    const response = (await this.examineService.getSearchList(params)) as any;
    if (response) {
      const list = response.list || [];
      list.forEach((item: any) => {
        item.key = item.shop_id;
      });
      this.data = list;
      this.pagination = {
        total: response.total,
        current: response.page,
        pageSize: +response.pageSize,
        onChange: this.paginationChange,
        showTotal: (total: any) => `共 ${total} 条`,
      };
    }
  }

  handleChange(value: any) {
    this.form.is_write = value;
  }

  resultsChange(value: any) {
    this.form.research_results = value;
  }

  agentFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  onChange(date: any) {
    const year = date._d.getFullYear(); // 获取当前年份，例如：2021
    const month = date._d.getMonth() + 1; // 获取当前月份，注意需要加1，例如：9
    this.form.research_year = year;
    this.form.research_month = month;
    this.dataPic = `${year}-${month}`;
  }
  async getagentList() {
    const response = (await this.examineService.agentList()) as any;
    if (response) {
      this.tagentList = response;
    }
  }
  mounted() {
    this.handleSearch();
    this.getagentList();
  }
}
