/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { IResRegion } from '@/common/interface/region';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class RegionService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async coveredList(loadingEl?: Element | Vue) {
    try {
      const res = await this.http.get('/region', {
        params: {
          covered: true,
          aggregation: true,
        },
        data: { loadingEl },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async cover(regionId: number, startDate: string) {
    try {
      const res = await this.http.post(`/region/${regionId}/cover/${startDate}`);
      return res;
    } catch (error) {
      throw (error);
    }
  }

  /** 全部地区:三级联动 */
  async allRegions(loadingEl?: Element | Vue) {
    const url = '/citycode_config';
    try {
      const res = await this.http.get(url, {
        data: { loadingEl },
      }) as IResRegion[];
      return res;
    } catch (error) {
      throw (error);
    }
  }
}
