
import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from 'vue-property-decorator';

@Component({})
export default class IsComboField extends Vue {
  val = false;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: Boolean,
  })
  value!: boolean;

  @Watch('val')
  watchInternalVal(): void {
    this.updateVal();
  }

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }

  @Emit('update')
  updateVal() {
    return this.val;
  }

  mounted() {
    this.watchValue();
  }
}
