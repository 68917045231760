
import { BASE_URL } from '@/common/constant';
import { IImage } from '@/common/interface';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { v4 } from 'uuid';
import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
  components: { draggable },
})
export default class HeadlineImagesField extends Vue {
  val: IImage[] = [];

  uploading = false;

  BASE_URL = BASE_URL;

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  token: string | undefined = undefined;

  viewImageSource = '';

  viewImageVisible = false;

  uuid = v4;

  max = 9;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  fieldOpts?: { max: number };

  @Model()
  value!: IImage[];

  @Watch('fieldOpts', { deep: true })
  watchFieldOpts() {
    if (this.fieldOpts && this.fieldOpts.max) {
      this.max = this.fieldOpts.max;
    }
  }

  @Watch('value')
  watchValue() {
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  @Emit('update')
  updateVal() {
    return this.val;
  }

  @lazyInject(UserService)
  protected userService!: UserService;

  get uploaderVisible() {
    if (!this.val || !this.val.length || this.val.length < this.max) {
      return true;
    }
    return false;
  }

  onFileSuccess(rootFile: File, file: File, json: string) {
    this.val.push(JSON.parse(json).data[0]);
    this.updateVal();
  }

  async onFileError(rootFile: File, file: File, json: string) {
    const { code, error } = JSON.parse(json);
    if (code === 400) {
      this.$message.error(error);
      await $delay(1000);
      this.$router.push({
        path: '/login',
        query: {
          redirect: this.$route.fullPath,
        },
      });
    } else {
      this.$message.error(error);
    }
  }

  viewImage(src: string) {
    this.viewImageSource = src;
    this.viewImageVisible = true;
  }

  setCoverImg(index: number) {
    const list = [...this.val];
    const head = list.splice(index, 1);
    list.unshift(head[0]);
    this.val = [...list];
    this.updateVal();
  }

  delImage(index: number) {
    const list = [...this.val];
    list.splice(index, 1);
    this.val = [...list];
    this.updateVal();
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }

  mounted() {
    this.watchValue();
  }
}
