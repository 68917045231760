/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable, inject } from 'inversify';
import HttpClient from './http.service';
import {
  LabelStatus,
  SourceType,
} from '@/common/enum/label';
import {
  LabelListRes,
  Label,
  RelationLabel,
} from '@/common/interface/label';

@injectable()
export default class LabelService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 获取标签列表
   * @param params
   * @param loadingEl
   * @returns
   */
  async getLabelList(params: {
    pageSize: number;
    page: number;
    name: string;
    status: LabelStatus | '';
  }, loadingEl?: Element | Vue): Promise<LabelListRes> {
    const data = await this.http.get(
      '/label',
      {
        params,
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as LabelListRes;
    return data;
  }

  /**
   * 修改标签状态
   * @param id
   * @param status
   * @param loadingEl
   * @returns
   */
  async changeStatus(
    id: number, status: LabelStatus, loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = await this.http.post(
      `/label/${id}/status`,
      {
        status,
      },
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as string;
    return data;
  }

  /**
   * 编辑标签
   * @param id
   * @param label
   * @param loadingEl
   * @returns
   */
  async updateDetail(
    id: number, label: Label, loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = await this.http.post(
      `/label/${id}/update`,
      {
        ...label,
      },
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as string;
    return data;
  }

  /**
   * 创建标签
   * @param payload
   * @param loadingEl
   * @returns
   */
  async create(
    payload: Label,
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = await this.http.put(
      '/label/create',
      {
        ...payload,
      },
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as string;
    return data;
  }

  /**
   * 获取已关联的label
   * @param payload
   * @param loadingEl
   * @returns
   */
  async getRelations(
    sourceId: number,
    sourceType: SourceType,
    loadingEl?: Element | Vue,
  ): Promise<RelationLabel[]> {
    const data = await this.http.get(
      `/label/relations?sourceId=${sourceId}&sourceType=${sourceType}`,
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as RelationLabel[];
    return data;
  }

  /**
   * 关联标签
   * @param sourceId
   * @param sourceType
   * @param labels
   * @param loadingEl
   * @returns
   */
  async relation(
    sourceId: number,
    sourceType: SourceType,
    labels: number[],
    loadingEl?: Element | Vue,
  ): Promise<string> {
    const data = await this.http.post(
      '/label/relations',
      {
        sourceId,
        sourceType,
        labels,
      },
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      },
    ) as string;
    return data;
  }
}
