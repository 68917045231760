import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import SpuKa from '@/views/spu/SpuKa.vue';
import SpuPlatform from '@/views/spu/SpuPlatform.vue';
import SpuLeading from '@/views/spu/SpuLeading.vue';

const spuRoutes: RouteConfig = {
  name: 'spu',
  path: '/spu',
  component: Main,
  redirect: {
    name: 'spu.platform',
  },
  meta: {
    title: '商品管理',
  },
  children: [
    {
      name: 'spu.platform',
      path: '/spu/platform',
      component: SpuPlatform,
      meta: {
        title: '平台标准商品',
      },
    },
    {
      name: 'spu.ka',
      path: '/spu/ka',
      component: SpuKa,
      meta: {
        title: '品牌标准商品',
      },
    },
    {
      name: 'spu.leading',
      path: '/spu/leading',
      component: SpuLeading,
      meta: {
        title: '批量操作商品',
      },
    },
  ],
};

export default spuRoutes;
