import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import ActivityList from '@/views/activity/ActivityList.vue';
import { AuthEnum } from '@/services/permission.service';

const activityUnitRoutes: RouteConfig = {
  name: 'activity',
  path: '/activity',
  component: Main,
  redirect: {
    name: 'activity.list',
  },
  meta: {
    title: '活动管理',
  },
  children: [
    {
      name: 'activity.list',
      path: '/activity/list',
      component: ActivityList,
      props: {
        auth: [AuthEnum.活动列表],
      },
      meta: {
        title: '活动列表',
      },
    },
  ],
};

export default activityUnitRoutes;
