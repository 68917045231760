import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import AgentList from '@/views/account-management/AgentList.vue';
import BrandList from '@/views/account-management/BrandList.vue';
import UserList from '@/views/account-management/UserList.vue';
import AgencyOperation from '@/views/account-management/AgencyOperation.vue';
import { AuthEnum } from '@/services/permission.service';

const accountManagementRoutes: RouteConfig = {
  name: 'account',
  path: '/account',
  component: Main,
  redirect: {
    name: 'account.agent',
  },
  meta: {
    title: '账号管理',
  },
  children: [
    {
      name: 'account.brand',
      path: '/account/brand',
      component: BrandList,
      props: {
        auth: [AuthEnum.菜单品牌管理],
      },
      meta: {
        title: '品牌管理',
      },
    },
    {
      name: 'account.user',
      path: '/account/user',
      component: UserList,
      props: {
        auth: [AuthEnum.账号管理],
      },
      meta: {
        title: '用户管理',
      },
    },
    {
      name: 'account.agent',
      path: '/account/agent',
      component: AgentList,
      props: {
        auth: [AuthEnum.账号管理],
      },
      meta: {
        title: '代理商管理',
      },
    },
    {
      name: 'account.agency-operation',
      path: '/account/agency-operation',
      component: AgencyOperation,
      meta: {
        title: '代运营管理',
      },
      props: {
        auth: [AuthEnum.代运营管理],
      },
    },
  ],
};

export default accountManagementRoutes;
