
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class MainBusinessField extends Vue {
  val = '';

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: string[];

  @Watch('value')
  watchValue() {
    if (this.value && this.value.length) {
      this.val = this.value.join(',');
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
