/*
 * @Author: Src
 * @Date: 2024-10-10 18:56:08
 * @LastEditTime: 2024-10-23 09:58:45
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/examineRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import ExamineShop from '@/views/examine/ExamineShop.vue';
import ExamineSummary from '@/views/examine/ExamineSummary.vue';
import ExamineResearch from '@/views/examine/ExamineResearch.vue';
import { AuthEnum } from '@/services/permission.service';

const examineRoutes: RouteConfig = {
  name: 'examine',
  path: '/examine',
  component: Main,
  meta: {
    title: '考核调研',
  },
  redirect: {
    name: 'examine.shop',
  },
  children: [
    {
      name: 'examine.shop',
      path: '/examine/shop',
      component: ExamineShop,
      props: {
        auth: [AuthEnum.设置考核门店],
      },
      meta: { title: '设置考核门店' },
    },
    {
      name: 'examine.summary',
      path: '/examine/summary',
      component: ExamineSummary,
      props: {
        auth: [AuthEnum.门店控价考核调研表],
      },
      meta: { title: '门店控价考核调研表' },
    },
    {
      name: 'examine.research',
      path: '/examine/research',
      component: ExamineResearch,
      props: {
        auth: [AuthEnum.调研结果汇总],
      },
      meta: { title: '调研结果汇总' },
    },
  ],
};

export default examineRoutes;
