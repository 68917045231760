
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { FormModel } from 'ant-design-vue';

@Component({})
export default class BCOrderRemarkModal extends Vue {
  type!: 'COrder' | 'BOrder';

  orderId = '';

  title = '';

  labelCol = { span: 1 };

  wrapperCol = { span: 16 };

  isVisible = false;

  isLoading = false;

  form = {
    remark: '',
  };

  get isDisabled() {
    if (!this.form.remark || this.form.remark === '') {
      return true;
    }
    return false;
  }

  @Ref() readonly ruleForm!: FormModel;

  @lazyInject(OrderService)
  orderService!: OrderService;

  open(param: {
    type: 'BOrder' | 'COrder';
    orderId: string;
    title: string;
    text: string;
  }) {
    const { type, orderId, title, text } = param;
    this.type = type;
    this.orderId = `${orderId}`;
    this.title = title;
    this.form.remark = text;

    this.isVisible = true;
  }

  close() {
    if (this.isLoading) {
      setTimeout(() => {
        this.$emit('close');
        this.reset();
      }, 300);
      return;
    }
    this.reset();
  }

  async submit() {
    let res;
    if (this.type === 'BOrder') {
      // B端订单追加备注
      res = await this.orderService.addRemark(this.orderId, this.form.remark);
    } else {
      // C端订单备注
      res = await this.orderService.COrderRemarkEdit(
        this.orderId,
        this.form.remark,
      );
    }

    if (res) {
      this.$message.success(`${res}`);
      this.$emit('refresh');
      this.reset();
    }
  }

  reset() {
    this.isVisible = false;
    this.isLoading = false;
    this.title = '';
    this.orderId = '';

    this.form = {
      remark: '',
    };
  }
}
