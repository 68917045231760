
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class BossTelsField extends Vue {
  val = '';

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: String,
  })
  value!: number;

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = `${this.value}`;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
