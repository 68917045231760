/** DYH用户类型枚举 */
export enum EDYHUserType {
  全部 = 0,
  管理员 = 1,
  审核员 = 2,
  地推员 = 3,
  店铺老板 = 4,
  雷达安装工 = 5,
}

/** DYH用户状态枚举 */
export enum EDYHUserStatus {
  未注册完成 = 0,
  正常 = 1,
  禁止登录 = 2,
}
/** 代运营类型枚举 */
export enum EAgencyType {
  推广通代运营管理 = 1,
  年费服务代运营管理 = 2,
  代运营管理 = 3, // 小B代运营管理，推广通，年费服务合并一起
  品牌代运营管理 = 4, // 大B代运营管理，即KA
}

export enum EShopType {
  未知 = 0,
  年费门店 = 1,
  推广通门店 = 2,
}
