
import VideoUModal from '@/components/video/VideoModal.vue';
import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';

@Component({
  components: {
    VideoUModal,
  },
})
export default class VideosField extends Vue {
  val: Array<{ id: number; cover: string; url: string }> = [];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: Array<{ id: number; cover: string; url: string }>;

  @Ref()
  videoModal!: VideoUModal;

  @Watch('value')
  watchValue() {
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  openVideoModal(videoUrl: string) {
    this.videoModal.open(videoUrl);
  }

  mounted() {
    this.watchValue();
  }
}
