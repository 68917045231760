
import moment from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import {
  City,
} from '@/common/interface/shop';
import ShopService from '@/services/shop.service';
import ExamineService from '@/services/examine.service';


@Component({})
export default class ExamineShop extends Vue {
  expandedRowKeys = [];

  moment = moment;

  monthFormat = 'YYYY/MM';

  selectedRowKeys: any = [];

  shop: any = [];

  citys: City[] = [];

  visible = false;

  tagentList: any = [];

  dataPic: any = '';

  fromData: {
    research_month: any;
    research_year: any;
    shop: [];
  } = {
      research_month: '',
      research_year: '',
      shop: [],
    };


  statusList = [
    { label: 0, value: '未调研' },
    { label: 1, value: '已调研' },
    { label: 2, value: '调研中' },
  ];

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(ExamineService)
  examineService!: ExamineService;

  form: {
    name: string;
    shop_name: string;
    poi: string;
    city: string | number;
    status: any;
    agent_id: any;
  } = {
      name: '',
      shop_name: '',
      city: '',
      poi: '',
      agent_id: undefined,
      status: undefined,
    };

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };
  columns = [
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      key: 'shop_name',
    },
    {
      title: '门店地址',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      title: '电话',
      dataIndex: 'tel',
      key: 'tel',
      ellipsis: true,
    },
    {
      title: '省/市',
      dataIndex: 'province_name',
      key: 'province_name',
      scopedSlots: { customRender: 'province_name' },

      ellipsis: true,
    },
    {
      title: '年费代运营',
      dataIndex: 'agent_name',
      key: 'agent_name',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      scopedSlots: { customRender: 'status' },
    },
  ];

  data = [];

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const { status, poi, shop_name, city, agent_id } = this.$route.query;
    const param: any = {
      pageSize,
      page: current,
      status,
      shop_name,
      poi,
      city,
      agent_id,
    };
    if (shop_name) {
      this.form.shop_name = shop_name as string;
    }
    if (poi) {
      this.form.poi = poi as string;
    }
    if (city) {
      this.form.city = +city;
    }

    if (agent_id) {
      this.form.agent_id = +agent_id;
    }

    this.getList(param);
  }

  disabledDate(current: any) {
      // Can not select days before today and today
      const currentMonth = moment();
      // 获取上个月
      const lastMonth = currentMonth.subtract(1, 'months');
      return current && current < lastMonth;
  }
  changeAgent() {
    this.cancel();
  }
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
      showTotal: (total: any) => `共 ${total} 条`,
    };
    this.form = {
      name: '',
      shop_name: '',
      city: '',
      agent_id: undefined,
      poi: '',
      status: undefined,
    };
    this.fromData = {
      research_month: '',
      research_year: '',
      shop: [],
    };
    this.dataPic = '';
    this.$router.push({
      query: {},
    });
  }
  hideModal() {
    //   this.fromData.research_year = year;
    //   this.fromData.research_month = month;
    if (this.fromData.research_year) {
      this.visible = false;
      this.submit();
    } else {
      this.$message.warning('先选择时间');
    }
  }

  showModal() {
    if (this.selectedRowKeys.length > 0) {
      this.visible = true;
    } else {
      this.$message.warning('先选择门店');
    }
  }

  search() {
    const {
      status,
      poi,
      shop_name,
      city,
      agent_id,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.pagination.current = 1;
    const query: any = {
      page: 1,
    };
    if (shop_name) {
      query.shop_name = shop_name;
    }
    if (poi) {
      query.poi = poi;
    }
    if (city) {
      query.city = city;
    }
    if (agent_id) {
      query.agent_id = agent_id;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    query.status = status;
    this.$router.push({
      query,
    });
  }

  statusChange(value: any) {
    this.form.status = value;
  }
  onChangeData() {
  }
  onSelect(record: any, selected: any) {
    if (selected) {
      this.selectedRowKeys.push(record.key);
      this.shop.push(record);
    } else {
      const _a = this.selectedRowKeys.filter((item: any) => item !== record.key);
      const _b = this.shop.filter((item: any) => item.key !== record.key);
      this.$set(this, 'selectedRowKeys', _a);
      this.shop = _b;
    }
  }
  onSelectAll(selected: any, selectedRows: any, changeRows: any) {
    const cdata: any[] = [];
    changeRows.forEach((element: any) => {
      cdata.push(element.key);
    });
    if (selected) {
      this.selectedRowKeys = [...cdata, ...this.selectedRowKeys];
      this.shop = [...changeRows, ...this.shop];
    } else {
      cdata.forEach((i: any) => {
        this.selectedRowKeys = this.selectedRowKeys.filter((item: any) => item !== i);
        this.shop = this.shop.filter((item: any) => item.key !== i);
      });
    }
  }

  async submit() {
    this.fromData.shop = this.shop;
    const response = (await this.examineService.addResearchShop(this.fromData)) as any;
    if (response) {
      this.handleSearch();
    }
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const {
      status,
      poi,
      shop_name,
      city,
      agent_id,
    } = this.form;

    const query: any = {
      page: current,
    };
    if (shop_name) {
      query.shop_name = shop_name;
    }
    if (poi) {
      query.poi = poi;
    }
    if (city) {
      query.city = city;
    }
    if (agent_id) {
      query.agent_id = agent_id;
    }
    query.status = status;
    this.$router.push({
      query,
    });
  }
  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  agentFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }


  async getList(params: {
    pageSize: number;
    page: number;
  }) {
    const response = (await this.examineService.getList(params)) as any;
    if (response) {
      const list = response.list || [];
      list.forEach((item: any) => {
        item.key = item.shop_id;
      });
      this.data = list;
      this.pagination = {
        total: response.total,
        current: response.page,
        pageSize: +response.pageSize,
        onChange: this.paginationChange,
        showTotal: (total: any) => `共 ${total} 条`,
      };
    }
  }
  async getagentList() {
    const response = (await this.examineService.agentList()) as any;
    if (response) {
      this.tagentList = response;
    }
  }
  onChange(date: any) {
    const year = date._d.getFullYear(); // 获取当前年份，例如：2021
    const month = date._d.getMonth() + 1; // 获取当前月份，注意需要加1，例如：9
    this.fromData.research_year = year;
    this.fromData.research_month = month;
    this.dataPic = `${year}-${month}`;
  }
  cancel() {
    this.selectedRowKeys = [];
    this.shop = [];
    this.fromData = {
      research_month: '',
      research_year: '',
      shop: [],
    };
  }

  mounted() {
    this.getCityList();
    this.handleSearch();
    this.getagentList();
  }
}
