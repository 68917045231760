
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';

@Component({})
export default class BaseBIPage extends Vue {
  @lazyInject(OrderService)
  orderService!: OrderService;

  @Prop()
  title!: string;

  @Prop()
  baseSrc!: string;

  src = '';

  created() {
    this.init();
  }

  @Watch('baseSrc')
  async init() {
    const token = await this.orderService.BIToken();
    if (!token) {
      return;
    }
    this.src = `${this.baseSrc}&accessToken=${token}`;
  }
}
