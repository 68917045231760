
import { EAgencyType } from '@/common/enum/account';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class EditThirdpartnarModal extends Vue {
  loading = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  thirdpartnar?: {
    shopId: string | number;
    name: string;
    uid: string | number;
    type: EAgencyType;
  };

  isVisible = false;

  fromType: any = '';

  typeData = 0;

  EAgencyType = EAgencyType;

  form: {
    name: string;
    uid: number | string;
  } = {
    name: '',
    uid: '',
  };

  @Watch('thirdpartnar', { deep: true, immediate: true })
  watchThirdpartnarChange() {
    if (this.thirdpartnar == null) {
      this.reset();
      return;
    }
    if (this.thirdpartnar.type === EAgencyType.推广通代运营管理) {
      this.search(this.thirdpartnar.shopId);
    }
    const { uid, name, type } = this.thirdpartnar;

    this.fromType = type;

    this.form = {
      uid,
      name,
    };
  }

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  async search(val: any) {
    const _t = await this.shopService.thirdpartnarSearch(val);

    this.typeData = _t;
  }

  async submit() {
    if (!this.thirdpartnar) {
      return;
    }
    if (!this.form.uid) {
      return;
    }

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const res = this.thirdpartnar.type === EAgencyType.推广通代运营管理 ? await this.shopService.thirdpartnar(this.thirdpartnar.shopId, +this.form.uid) :
        await this.shopService.bindBlackCardAgency(this.thirdpartnar.shopId, +this.form.uid);

      if (res) {
        const msg = this.thirdpartnar == null ? '添加成功' : '修改成功';
        this.$message.success(msg);

        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
    } catch (error) {
      this.$message.info(`${error}`);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      uid: '',
      name: '',
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close', true);
  }
}
