import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import PermissionList from '@/views/permission/PermissionList.vue';
import OrganizationList from '@/views/permission/OrganizationList.vue';
import OrganizationDetail from '@/views/permission/OrganizationDetail.vue';

const permissionRoutes: RouteConfig = {
  name: 'permission',
  path: '/permission',
  component: Main,
  redirect: {
    name: 'permission.list',
  },
  meta: {
    title: '权限管理',
  },
  children: [
    {
      name: 'permission.list',
      path: '/permission/list',
      component: PermissionList,
      meta: {
        title: '权限列表',
      },
    },
    {
      name: 'organization.list',
      path: '/permission/organization',
      component: OrganizationList,
      meta: {
        title: '团队列表',
      },
    },
    {
      name: 'organization.detail',
      path: '/permission/organization/detail/:id',
      component: OrganizationDetail,
      props: {},
      meta: { title: '团队详情' },
    },
  ],
};

export default permissionRoutes;
