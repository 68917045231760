
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import moment, { Moment } from 'moment';
import OrderService, { PaymentChannelEnum } from '@/services/order.service';
import { FormModel } from 'ant-design-vue';
import { Order } from '@/common/interface/order';
import { DebounceTime } from '@/common/decorator';

@Component({})
export default class UploadPaymentOrderNo extends Vue {
  labelCol = { span: 6 };

  wrapperCol = { span: 16 };

  isVisible = false;

  isLoading = false;

  buttonText = '提交';

  form: {
    paymentChannelId: string | undefined;
    transactionNo: string;
    paymentTime: Moment | null;
  } = {
    paymentChannelId: undefined,
    transactionNo: '',
    paymentTime: null,
  };

  paymentChannel: Array<{
    id: PaymentChannelEnum;
    name: string;
  }> = [];

  transactionNoCheckStatus: 'toCheck' | 'checking' | 'checked' = 'toCheck';

  extra = '';

  order: Order | null = null;

  isHidden = false;

  get disabled() {
    if (
      this.form.paymentChannelId &&
      this.form.transactionNo &&
      this.form.paymentTime !== null
    ) {
      return false;
    } else if (
      this.form.paymentChannelId &&
      +this.form.paymentChannelId === PaymentChannelEnum.无需付款
    ) {
      return false;
    } else {
      return true;
    }
  }

  disabledtype = false;

  @Ref() readonly ruleForm!: FormModel;

  @lazyInject(OrderService)
  orderService!: OrderService;

  @DebounceTime(320)
  async onChange() {
    if (this.extra && this.extra !== '') {
      this.extra = '';
      this.transactionNoCheckStatus = 'toCheck';
      this.buttonText = '提交';
    }
  }

  onChangePaymentTime(value: Moment) {
    this.$set(this.form, 'paymentTime', value);
  }

  oChangeChannel(val: PaymentChannelEnum | string) {
    const channelId = this.form.paymentChannelId;

    if (channelId === (val as string)) {
      return;
    }

    if (Number(val) === PaymentChannelEnum.无需付款) {
      this.$set(this.form, 'transactionNo', '');
      this.$set(this.form, 'paymentTime', null);
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
    this.$set(this.form, 'paymentChannelId', `${val}`);
  }

  async init() {
    // 获取支付渠道
    const res = await this.orderService.paymentChannels();
    if (res) {
      this.paymentChannel = res;
    }
  }

  open(order: Order) {
    this.order = Object.assign({}, order);
    this.isVisible = true;
  }

  close() {
    if (this.isLoading) {
      setTimeout(() => {
        this.reset();
      }, 300);
      return;
    }
    this.reset();
  }

  paymentValidatort() {
    if (!this.form.paymentChannelId) {
      this.$message.warn('请选择支付渠道！');
      return false;
    }

    if (+this.form.paymentChannelId === PaymentChannelEnum.无需付款) {
      this.$set(this.form, 'transactionNo', '');
      this.$set(this.form, 'paymentTime', null);
      return true;
    }

    if (
      !this.form.transactionNo ||
      this.form.transactionNo.replace(/\s*/g, '') === ''
    ) {
      this.$message.warn('请填写支付流水单号！');
      return false;
    }

    if (!this.form.paymentTime) {
      this.$message.warn('收款时间不能为空。');
      return false;
    }

    return true;
  }

  async preVerifyTansactionNo(orderId: string, tradeid: string) {
    if (this.transactionNoCheckStatus === 'checking') {
      return;
    }
    this.transactionNoCheckStatus = 'checking';
    const res = await this.orderService.BOrderTransactionNoCheck(
      orderId,
      tradeid,
    );

    this.transactionNoCheckStatus = 'checked';

    if (res !== null) {
      this.disabledtype = false;
      this.buttonText = '继续提交';
      this.extra = res;
      return;
    }
    // 流水单号未使用，直接提交
    await this.submit(true);
  }

  submit(retry?: boolean) {
    this.disabledtype = true;
    this.funsubmit(retry);
  }

  @DebounceTime(618)
  async funsubmit(retry?: boolean) {
    if (this.order === null) {
      return;
    }
    if (this.order.isPay !== '未支付') {
      this.$message.warn('当前订单支付状态异常，请检查！');
      return;
    }

    if (
      (typeof retry !== 'undefined' && retry === true) ||
      this.transactionNoCheckStatus === 'checked' ||
      (this.form.paymentChannelId &&
        +this.form.paymentChannelId === PaymentChannelEnum.无需付款)
    ) {
      /** @todo 无需付款订单，应移除支付时间和流水单号，待接口支持 */
      const params = {
        id: this.order?.orderId,
        paychannel: this.form.paymentChannelId!,
        paymentTime: moment(this.form.paymentTime).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        tradeid: `${this.form.transactionNo}`,
      };

      const res = await this.orderService.payStatus(params);
      if (res) {
        this.$message.success('成功');
        this.$emit('refresh');
        setTimeout(() => {
          this.reset();
        }, 500);
      }
      return;
    }

    this.ruleForm.validate(async (valid) => {
      if (valid && this.paymentValidatort()) {
        if (this.isLoading) {
          return;
        }
        this.isLoading = true;
        try {
          await this.preVerifyTansactionNo(
            `${this.order?.orderId}`,
            this.form.transactionNo,
          );
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
      }
    });
  }

  reset() {
    this.isVisible = false;
    this.isLoading = false;
    this.isHidden = false;
    this.disabledtype = false;
    this.extra = '';
    this.transactionNoCheckStatus = 'toCheck';

    this.ruleForm.resetFields();

    this.form = {
      paymentChannelId: undefined,
      transactionNo: '',
      paymentTime: null,
    };
    this.buttonText = '提交';
    this.order = null;
  }

  created() {
    this.init();
  }
}
