import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import BOrderList from '@/views/order/BOrderList.vue';
import CorderList from '@/views/order/COrderList.vue';
import COrderStat from '@/views/order/COrderStat.vue';
import { AuthEnum } from '@/services/permission.service';

const shopRoutes: RouteConfig = {
  name: 'main.order',
  path: '/order',
  component: Main,
  redirect: {
    name: 'main.order.list.b',
  },
  meta: {
    title: '订单管理',
  },
  children: [
    {
      name: 'main.order.list.b',
      path: '/order/orderlist_b',
      component: BOrderList,
      props: {
        auth: [AuthEnum.B端订单列表],
      },
      meta: { title: 'B端订单列表' },
    },
    {
      name: 'main.order.list.b.notpay',
      path: '/order/orderlist_b_notpay',
      component: BOrderList,
      props: {
        auth: [AuthEnum.B端订单列表],
      },
      meta: { title: 'B端订单列表' },
    },
    {
      name: 'main.order.list.c',
      path: '/order/orderlist_c',
      component: CorderList,
      props: {
        auth: [AuthEnum.C端订单列表],
      },
      meta: { title: 'C端订单列表' },
    },
    {
      name: 'main.order.stat.c',
      path: '/order/orderstat_c',
      component: COrderStat,
      props: {
        auth: [AuthEnum.C端订单列表],
      },
      meta: { title: 'C端订单统计' },
    },
  ],
};

export default shopRoutes;
