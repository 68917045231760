
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { FormModel, Upload } from 'ant-design-vue';
import lazyInject from '@/di';
import BaseService from '@/services/base.service';
import ActivityService from '@/services/activity.service';
import Activity from '@/models/activity.model';
import { ActivityType } from '@/common/enum/activity';
import { BASE_URL } from '@/common/constant';
import { v4 } from 'uuid';
import { IResActivity } from '@/common/interface/activity';
import { City } from '@/common/interface';
import moment, { Moment } from 'moment';

@Component({})
export default class ActivityDetailDrawer extends Vue {
  @Prop()
  visible!: boolean;

  @Ref() readonly ruleForm!: FormModel;

  @Ref() readonly adcodeUpload!: Upload;

  @lazyInject(BaseService)
  baseService!: BaseService;

  @lazyInject(ActivityService)
  activityService!: ActivityService;

  BASE_URL = BASE_URL;

  uuid = v4;

  form = new Activity();

  rules = {
    name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
    gmtStartTime: [{ required: true, message: '请选择时间', trigger: 'blur' }],
    gmtEndTime: [{ required: true, message: '请选择时间', trigger: 'blur' }],
    actType: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
    activityPriceCent: [
      { required: true, message: '请输入活动价', trigger: 'blur' },
      { validator: this.activityPriceCentValidator },
    ],
    totalLimit: [
      { required: true, message: '请输入总库存', trigger: 'blur' },
      { validator: this.isTotalLimitValid },
    ],
    dailyLimit: [
      { required: true, message: '请输入日库存', trigger: 'blur' },
      { validator: this.isDailyLimitValid },
    ],
    // adcodes: [
    //   { required: true, message: '请输入或上传城市adcode，多个以英文逗号“,”分隔', trigger: 'blur' },
    //   { validator: this.adcodeValidator },
    // ],
    adcodes: [
      { required: true, message: '请输入或上传城市adcode，多个以英文逗号“,”分隔', trigger: 'blur' },
    ],
    icon: [
      {
        required: true,
        message: 'TPS上传后，在此粘贴图片地址',
        trigger: 'blur',
      },
    ],
    description: [
      { required: true, message: '请输入活动描述', trigger: 'blur' },
    ],
    platformProductId: [
      {
        required: true,
        message: '请输入平台商品后台创建的商品ID',
        trigger: 'blur',
      },
      { validator: this.platformProductIdValidator },
    ],
  };

  uploadLoading = false;

  fileList: File[] = [];

  labelCol = { span: 6 };

  wrapperCol = { span: 14 };

  ActivityType = ActivityType;

  onChangeStartTime(value: Moment) {
    if (value == null) {
      this.form.gmtStartTime = undefined;
      return;
    }
    this.$set(this.form, 'gmtStartTime', moment(value).format('YYYY-MM-DD HH:mm:ss'));
    this.ruleForm.clearValidate('gmtStartTime');
  }

  onChangeEndTime(value: Moment) {
    if (value == null) {
      this.form.gmtEndTime = undefined;
      return;
    }

    this.$set(this.form, 'gmtEndTime', moment(value).format('YYYY-MM-DD HH:mm:ss'));
    this.ruleForm.clearValidate('gmtEndTime');
  }

  validateBeginTimeAndEndTime() {
    if (this.form.gmtStartTime == null || this.form.gmtEndTime == null) {
      return true;
    }

    const startTimeStamp = moment(this.form.gmtStartTime).valueOf();
    const endTimeStamp = moment(this.form.gmtEndTime).valueOf();

    if (endTimeStamp < startTimeStamp) {
      this.$message.error('活动结束时间不能早于开始时间，请重新选择！');
      return false;
    }
    return true;
  }


  setFormFields(activity?: Activity) {
    if (activity == null) {
      this.form = new Activity();
      return;
    }
    Object.assign(this.form, activity);
  }

  public activityPriceCentValidator(
    rule: { [key: string]: string | (() => boolean) },
    discount: number,
    callback: (err?: string) => void,
  ) {
    const isNaturalNum = /^[0-9]*[0-9][0-9]*$/.test(`${discount}`);
    if (!isNaturalNum) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('请输入大于等于0的整数, 若活动不需要此字段可填0');
      return;
    }
    const { actType } = this.form;
    if (!actType || actType === ActivityType.折扣) {
      callback();
      return;
    }
    if (discount <= 0) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('非折扣活动的活动价须大于0');
    } else {
      callback();
    }
  }

  public isDailyLimitValid(
    rule: { [key: string]: string | (() => boolean) },
    dailyLimit: number,
    callback: (err?: string) => void,
  ) {
    const isNaturalNum = /^[0-9]*[0-9][0-9]*$/.test(`${dailyLimit}`);
    if (!isNaturalNum) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('请输入大于等于0的整数, 若活动不需要此字段可填0');
      return;
    }
    const { actType } = this.form;
    if (!actType) {
      callback();
      return;
    }
    if (actType === ActivityType.秒杀) {
      if ((this.form.totalLimit || 0) < dailyLimit) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('秒杀活动总库存须大于等于日库存');
      } else if (dailyLimit === 0) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('秒杀活动总库存须大于0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  public isTotalLimitValid(
    rule: { [key: string]: string | (() => boolean) },
    totalLimit: number,
    callback: (err?: string) => void,
  ) {
    const isNaturalNum = /^[0-9]*[0-9][0-9]*$/.test(`${totalLimit}`);
    if (!isNaturalNum) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('请输入大于等于0的整数, 若活动不需要此字段可填0');
      return;
    }
    const { actType } = this.form;
    if (!actType) {
      callback();
      return;
    }
    if (actType === ActivityType.秒杀) {
      if (totalLimit < (this.form.dailyLimit || 0)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('秒杀活动总库存须大于等于日库存');
      } else if (totalLimit === 0) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('秒杀活动日库存须大于0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  extractIResActivity(data: Activity): IResActivity {
    const {
      name,
      gmtStartTime,
      gmtEndTime,
      actType,
      adcodes,
      description,
      icon,
      subIcon,
      activityPriceCent,
      platformProductId,
      totalLimit,
      dailyLimit,
    } = data;

    const request: IResActivity = {
      name,
      adcodes: adcodes || '',
      gmt_end: gmtEndTime,
      gmt_start: gmtStartTime,
      act_type: actType,
      act_price: activityPriceCent,
      act_total_limit: totalLimit,
      act_day_limit: dailyLimit,
      icon: icon || '',
      subIcon,
      spu_id: `${platformProductId}` || '',
      description,
    };

    return request;
  }

  isLoading = false;

  async submit() {
    try {
      this.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isLoading) {
            return;
          }
          this.isLoading = true;
          const { adcodes } = this.form;
          if (!this.validateAdcode(adcodes || '') || !this.validateBeginTimeAndEndTime()) {
            this.isLoading = false;
            return;
          }

          const payload = this.extractIResActivity(this.form);

          if (this.form.id) {
          // 编辑活动
            const res = await this.activityService.updateActivity(this.form.id, payload, document.body);
            if (res) {
              this.$message.success('更新活动成功');
              this.$emit('close', true);
            }
          } else {
          // 新增活动
            const res = await this.activityService.createActivityV2(payload, document.body);
            if (res) {
              this.$message.success('创建活动成功');
              this.$emit('close', true);
            }
          }
          this.isLoading = false;
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('保存活动error：', error);
      if (this.isLoading) {
        this.isLoading = false;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeUploadAdcode(file: any) {
    const isTxt = file.type === 'text/plain';
    if (!isTxt) {
      return false;
    }

    this.uploadLoading = true;
    const fileReader: FileReader = new FileReader();

    fileReader.onloadend = () => {
      if (fileReader.result) {
        this.$set(this.form, 'adcodes', `${fileReader.result}`);
        this.$forceUpdate();
      }
    };
    fileReader.readAsText(file);
    this.fileList = [file];
    this.uploadLoading = false;
    return false;
  }

  onAdcodesBlur() {
    this.$forceUpdate();
  }

  validateAdcode(adcodes: string) {
    if (!adcodes) {
      this.$message.error('城市adcode不能为空');
      return false;
    }

    const adcodeList = adcodes.split(',').filter(item => { return item !== ''; });

    if (!Array.isArray(adcodeList) || !adcodeList.length) {
      this.$message.error(`城市adcode ${adcodes}格式不正确，请重新输入`);
      return false;
    }

    for (let index = 0; index < adcodeList.length; index++) {
      const adcodeItem = adcodeList[index];
      if (!/[1-8][0-9]{5}/.test(adcodeItem)) {
        this.$message.error(`城市adcode格式为6位编码， 第${index + 1}个 【${adcodeItem}】格式不正确，请重新输入`);
        return false;
      }
    }

    for (let index = 0; index < adcodeList.length; index++) {
      const adcodeItem = adcodeList[index];

      if (!this.cityMap.get(adcodeItem)) {
        this.$message.error(`城市adcode第${index + 1}个 【${adcodeItem}】不存在该城市，请检测后重新输入`);
        return false;
      }
    }

    return true;
  }

  adcodeValidator(
    rule: { [key: string]: string | (() => boolean) },
    adcodes: string,
    callback: (err?: string) => void,
  ) {
    if (!adcodes) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('城市adcode不能为空');
      return;
    }

    const adcodeList = adcodes.split(',').filter(item => { return item !== ''; });

    if (!Array.isArray(adcodeList) || !adcodeList.length) {
      // eslint-disable-next-line standard/no-callback-literal
      callback(`城市adcode ${adcodes}格式不正确，请重新输入`);
      return;
    }

    for (let index = 0; index < adcodeList.length; index++) {
      const adcodeItem = adcodeList[index];
      if (!/[1-8][0-9]{5}/.test(adcodeItem)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`城市adcode格式为6位编码， 第${index + 1}个 【${adcodeItem}】格式不正确，请重新输入`);
        return;
      }
    }

    for (let index = 0; index < adcodeList.length; index++) {
      const adcodeItem = adcodeList[index];

      if (!this.cityMap.get(adcodeItem)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`城市adcode第${index + 1}个 【${adcodeItem}】不存在该城市，请检测后重新输入`);
        return;
      }
    }
    callback();
  }

  platformProductIdValidator(
    rule: { [key: string]: string | (() => boolean) },
    platformProductId: string,
    callback: (err?: string) => void,
  ) {
    if (platformProductId == null) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('平台商品ID不能为空');
      return;
    }

    if (!/^[1-9][0-9]*$/.test(platformProductId)) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('平台商品ID仅支持数字');
      return;
    }

    callback();
  }

  removeUploadedAdcode() {
    this.$set(this.form, 'adcodes', undefined);
    this.fileList = [];
    this.ruleForm.clearValidate('adcodes');
  }

  close() {
    this.$emit('close');
    this.reset();
  }

  reset() {
    this.$nextTick(() => {
      if (this.ruleForm) {
        this.ruleForm.resetFields();
      }
      this.adcodeUpload.remove(this.fileList[0]);
    });
  }


  cityMap = new Map<string, City>();

  async getCityList() {
    if (this.cityMap.size > 0) {
      return;
    }

    const response = await this.baseService.getCityList() as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };

    let allCity: City[] = [];
    if (response) {
      const citys = Object.values(response)
        .map(item => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);

      const provinces = Object.values(response)
        .map(item => item.province).filter(p => p !== null);

      allCity = citys.concat(provinces);
    }


    if (allCity.length) {
      const map = new Map();
      for (const city of allCity) {
        map.set(`${city.id}`, city);
      }
      this.cityMap = new Map(map);
    }
  }

  async mounted() {
    await this.getCityList();
  }
}
