import dev from './config/config.test';
import prod from './config/config.production';
import local from './config/config.local';

let config: {
  API_URL?: string;
  RBAC_URL?: string;
  AUDIT_URL?: string;
} = {};
if (process.env.NODE_ENV === 'development') {
  config = dev;
} else if (process.env.NODE_ENV === 'production') {
  config = prod;
} else {
  config = local;
}

export default {
  ...config,
  isProd: process.env.NODE_ENV === 'production',
};
