
import { Vue, Component, Prop } from 'vue-property-decorator';
import { v4 } from 'uuid';

@Component({})
export default class LocationView extends Vue {
  private visible = false;

  private zoom = 14;

  private uuid = v4;

  @Prop()
  lng?: number;

  @Prop()
  lat?: number;

  @Prop()
  name?: string;

  private domStyle = {
    'border-radius': '4px',
    // 'font-weight': '700',
    border: 'none',
    'background-color': '#40a9ff',
    color: '#fff',
  };

  show() {
    this.visible = true;
  }
}
