
import { BASE_URL } from '@/common/constant';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import UserService from '@/services/user.service';
import ApplicableShops from '@/components/spu/ApplicableShops.vue';
import {
  Vue,
  Component,
  Prop,
  Watch,
  Ref,
  Model,
} from 'vue-property-decorator';
import { DebounceTime } from '@/common/decorator';

enum EInvalidIds {
  SUSPENDED_BIZ_IDS = 'suspendedBizIds',
  PERMANENT_BIZ_IDS = 'permanentBizIds',
  EXCEOPTION_IDS = 'exceptionIds ',
}

@Component({
  components: {
    ApplicableShops,
  },
})
export default class TempSpuShopIdsField extends Vue {
  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  val = '';

  uploading = false;

  suspendedBizIds = '';

  permanentBizIds = '';

  exceptionIds = '';

  EInvalidIds = EInvalidIds;

  get suspendedBizStatus() {
    return this.suspendedBizIds !== '';
  }

  get exceptionIdsStatus() {
    return this.exceptionIds !== '';
  }

  get permanentBizStatus() {
    return this.permanentBizIds !== '';
  }

  get uploadDisabled() {
    if (this.val !== '' || this.uploading === true || this.disabled === true) {
      return true;
    }
    return false;
  }

  get deltaBtnVisible() {
    if (this.val !== '') {
      return true;
    }
    return false;
  }

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  placeholder!: string;

  @Prop()
  spuType!: 'spu' | 'platformSpu' | 'kaSpu' | 'leadingSpu';

  @Model('change', {
    type: String,
  })
  value!: string;

  @Ref()
  applicableShops!: ApplicableShops;

  @Watch('value')
  watchValue() {
    if (this.value !== undefined && this.val !== this.value) {
      this.val = this.value;
    }
  }

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(SPUService)
  spuService!: SPUService;

  reset() {
    this.val = '';
    this.uploading = false;
    this.suspendedBizIds = '';
    this.permanentBizIds = '';
    this.exceptionIds = '';
  }

  /** 一键移除 */
  async remove(invalidType: EInvalidIds) {
    const all = this.val.split(',').map((item) => {
      return Number(item);
    });
    let diff: number[] = [];
    switch (invalidType) {
      case EInvalidIds.SUSPENDED_BIZ_IDS: {
        const suspendList = this.suspendedBizIds.split(',').map((item) => {
          return Number(item);
        });
        diff = all.filter((item) => suspendList.indexOf(item) === -1);
        this.suspendedBizIds = '';
        break;
      }

      case EInvalidIds.PERMANENT_BIZ_IDS: {
        const permanentList = this.permanentBizIds.split(',').map((item) => {
          return Number(item);
        });
        diff = all.filter((item) => permanentList.indexOf(item) === -1);
        this.permanentBizIds = '';
        break;
      }

      default: {
        const exceptionList = this.exceptionIds.split(',').map((item) => {
          return Number(item);
        });
        diff = all.filter((item) => exceptionList.indexOf(item) === -1);
        this.exceptionIds = '';
        break;
      }
    }

    this.val = diff.join(',');
    this.$message.success('移除成功');
    this.onChange();
  }

  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  beforeUpload(file: { type: string; size: number }) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  /** 全量SHOP_ID导入 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onFullImportChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const { error, stop, forever } = data;
      const all = data.data;
      this.suspendedBizIds = stop;
      this.permanentBizIds = forever;
      this.exceptionIds = error;
      this.val = all;
      this.uploading = false;
      this.onChange();
    }
  }

  /** 增量SHOP_ID导入 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onDeltaImportChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const { error, stop, forever } = data;
      const newIds = data.data;
      const ids = this.val.split(',').concat(newIds.split(','));
      this.suspendedBizIds = stop;
      this.permanentBizIds = forever;
      this.exceptionIds = error;
      this.val = ids.join(',');
      this.uploading = false;
      this.onChange();
    }
  }

  async checkShopIds(val: string) {
    if (this.spuType === 'leadingSpu') {
      return this.spuService.leadingSpuCheckShopIds(val);
    } else if (this.spuType === 'kaSpu') {
      return this.spuService.kaSpuCheckShopIds(val);
    }
  }

  async handleShopIdsSave(val: string) {
    this.val = val;
    const res = await this.checkShopIds(val);
    if (res) {
      if (res.error) {
        this.exceptionIds = res.error;
      }
      if (res.forever) {
        this.permanentBizIds = res.forever;
      }
      if (res.stop) {
        this.suspendedBizIds = res.stop;
      }
    }

    this.onChange();
  }

  async onBlur(val: string) {
    await this.handleShopIdsSave(val);
  }

  openShopListModal() {
    this.applicableShops.open();
  }

  onCopySuccess() {
    this.$message.success('复制成功');
  }

  @DebounceTime(320)
  onChange() {
    this.$emit('change', this.val);
  }

  mounted() {
    this.watchValue();
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
