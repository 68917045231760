
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import PermissionService, {
  InterfaceOrganizationRole,
} from '@/services/permission.service';
import { FormModel } from 'ant-design-vue';

import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';

interface InterfaceOrgMember {
  id?: string;
  title: string;
  phone: string;
  orgRoleIds: string[];
}

@Component({})
export default class OrgMemberModal extends Vue {
  @Ref() readonly ruleForm!: FormModel;

  /**
   * modal标题: '添加成员/给${成员名}添加角色/给${成员名}删除角色'
   */
  @Prop({
    default: () => '',
  })
  protected title!: string;

  @Prop({
    default: () => {
      return {
        title: '',
        phone: '',
        orgRoleIds: [],
      };
    },
  })
  protected orgMemberModalConfig!: InterfaceOrgMember;

  /**
   * modal是否可见
   */
  @Prop()
  visible!: boolean;

  /**
   * 团队id
   */
  @Prop()
  orgId!: string;

  loading = false;

  labelCol = { span: 4 };

  wrapperCol = { span: 18 };

  form = {
    phone: '',
    userName: '',
    orgRoleId: [],
  };

  rules = {
    userName: [
      {
        required: false,
      },
    ],
    phone: [
      {
        required: true,
        message: '请输入正确的手机号',
        trigger: 'blur',
      },
    ],
    orgRoleId: [
      {
        type: 'array',
        required: true,
        message: '请至少选择1个角色！',
        trigger: 'change',
      },
    ],
  };

  organizationRoleList: InterfaceOrganizationRole[] = [];

  isOrgMemberFormValid() {
    if (this.orgMemberModalConfig.id && !/1[3-9]\d{9}/.test(this.form.phone)) {
      this.$message.warn('数据异常，检测到当前用户，缺失手机号，请检查！');
      return false;
    }

    if (!/1[3-9]\d{9}/.test(this.form.phone)) {
      this.$message.warn('手机格式不正确，请重新输入');
      return false;
    }

    if (!this.form.orgRoleId || this.form.orgRoleId.length < 1) {
      this.$message.warn('请至少选择1个角色');
      return false;
    }

    return true;
  }

  @Watch('visible', { immediate: true })
  watchOrgMemberModalConfig() {
    if (!this.visible) {
      return;
    }

    if (this.organizationRoleList.length < 1 && this.orgId) {
      this.getOrgRoleList();
    }

    const { phone, orgRoleIds } = { ...this.orgMemberModalConfig };
    if (phone) {
      this.$set(this.form, 'phone', phone);
    }

    if (orgRoleIds && orgRoleIds.length > 0) {
      this.$set(this.form, 'orgRoleId', orgRoleIds);
    }
  }

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  async getOrgRoleList() {
    const res = await this.permissionService.organizationRoleList(this.orgId);
    if (res) {
      this.organizationRoleList = res;
    }
  }

  /**
   * 点击 modal 确认
   */
  async submit() {
    this.ruleForm.validate(async (valid) => {
      if (valid && this.isOrgMemberFormValid()) {
        try {
          /**
           * @notice 新增团队成员，以及给成员增加、删除角色，已合用一个接口，此处便不再做另行区分；
           */
          this.editOrgMember();
        } catch (error) {
          this.$message.info(`${error}`);
        }
      }
    });
  }

  /**
   * 添加团队成员，给成员增加/删除角色
   */
  async editOrgMember() {
    const res = (await this.permissionService.addOrgMember({
      phone: this.form.phone,
      userName: this.form.userName || '',
      orgId: this.orgId,
      orgRoleId: this.form.orgRoleId,
    })) as string;
    // '成功'
    if (res) {
      this.$message.success(res);

      await $delay(500);
      this.$emit('close', true);
    }
  }

  reset() {
    this.$set(this.form, 'phone', '');
    this.$set(this.form, 'orgRoleId', []);
    this.$set(this.form, 'userName', '');

    this.ruleForm.resetFields();
  }
}
