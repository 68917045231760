
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class MechineCallbackDataField extends Vue {
  val: {
    payStatus: boolean;
    payMoney: number;
    orderMoney: number;
  } | null = null;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: {
    payStatus: boolean;
    payMoney: number;
    orderMoney: number;
  };

  @Watch('value')
  watchValue() {
    this.val = this.value || null;
  }

  mounted() {
    this.watchValue();
  }
}
