
import ExamineService from '@/services/examine.service';
import lazyInject from '@/di';
import questionnaire from '@/assets/questionnaire.png';

import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class ExamineQuestionnaire extends Vue {
  value: any = null;

  isWrite: any = null;

  researchResults: any = null;

  researchMonth: any = null;

  questionnaire = questionnaire;

  typeEnd = true;

  @lazyInject(ExamineService)
  examineService!: ExamineService;


  @Watch('$route')
  async handleSearch() {
    const { isWrite, researchResults, researchMonth } = this.$route.query;
    this.isWrite = isWrite;
    this.researchResults = researchResults;
    this.researchMonth = researchMonth;
    if (isWrite) {
      this.value = Number(researchResults);
    }
  }
  onChange(e: any) {
    this.value = e.target.value;
  }
  async sumbit() {
    const { boss_id } = this.$route.query;
    const bossId: any = encodeURIComponent(boss_id as string);
    const params: any = {
      boss_id: bossId,
      research_results: this.value,
    };
    const _month = String(new Date().getMonth() + 1);
    if (_month == this.researchMonth) {
      if (this.value != null) {
        const response = (await this.examineService.updateStatus(params)) as any;
        if (response) {
          this.typeEnd = false;
        }
      }
    } else {
      this.$message.warning('问卷已过期');
    }
  }
  mounted() {
    this.handleSearch();
  }
}
