
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { LabelStatus, LabelTarget } from '@/common/enum/label';
import {
  LabelListSearchArea,
  Label,
  LabelDetailForm,
} from '@/common/interface/label';
import { v4 } from 'uuid';
import lazyInject from '@/di';
import LabelService from '@/services/label.service';
import { is } from '@/common/utils/index';
import LabelDetailDrawer from './components/LabelDetailDrawer.vue';

@Component({
  components: {
    LabelDetailDrawer,
  },
})
export default class LabelList extends Vue {
  drawerVisible = false;

  uuid = v4;

  is = is;

  LabelStatus = LabelStatus;

  labelStatusMap = new Map([
    [LabelStatus.在线, '在线'],
    [LabelStatus.离线, '离线'],
  ]);

  labelTargetMap = new Map([
    [LabelTarget.圈人, '圈人'],
    [LabelTarget.圈店, '圈店'],
    [LabelTarget.圈商品, '圈商品'],
    [LabelTarget.圈活动, '圈活动'],
  ]);

  form: LabelListSearchArea = {
    status: '',
    name: '',
  };

  labelList: Label[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  @Ref()
  drawer!: LabelDetailDrawer;

  @lazyInject(LabelService)
  labelService!: LabelService;

  mounted() {
    this.handleSearch();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    this.handleSearch();
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;
    const { name, status } = this.$route.query;

    const data = await this.labelService.getLabelList({
      pageSize,
      page: current,
      name: name ? `${name}` : '',
      status: status ? (status as LabelStatus) : '',
    });

    const { page, total, list } = data;

    this.pagination = {
      total,
      pageSize,
      current: page,
      onChange: this.paginationChange,
    };
    this.labelList = list;
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      status: '',
    };
    this.$router.push({
      query: {},
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, status } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = { name, status };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  async changeStatus(id: number, status: LabelStatus) {
    const result = await this.labelService.changeStatus(id, status, this.$el);
    if (result) {
      this.$message.success(result);
      const label = this.labelList.find((item) => item.id === id);
      if (label) {
        label.status = status;
      }
    }
  }

  drawerClose(refresh = false) {
    this.drawerVisible = false;
    if (refresh) {
      this.handleSearch();
    }
  }

  add() {
    this.drawerVisible = true;
    this.drawer.reset();
  }

  edit(label: LabelDetailForm) {
    this.drawerVisible = true;
    this.drawer.form = {
      ...label,
      condition: JSON.stringify(label.condition),
    };
  }
}
