
import { Vue, Component, Prop } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { EPayeeAccountFreezeStatus } from '@/common/enum/shop';

@Component({})
export default class SuspendAccountControl extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  visible = false;

  reason = '';

  payeeAccountFreezeStatus!: EPayeeAccountFreezeStatus;

  @Prop()
  shopId!: string;

  async confirm(status: EPayeeAccountFreezeStatus) {
    if (status === EPayeeAccountFreezeStatus.冻结) {
      this.payeeAccountFreezeStatus = status;
      this.visible = true;
      return;
    }
    await this.changePayeeAccountStatus(status);
  }

  /** 冻结 | 解冻 */
  async changePayeeAccountStatus(status: EPayeeAccountFreezeStatus) {
    if (status === EPayeeAccountFreezeStatus.冻结) {
      if (!this.reason) {
        return this.$message.error('冻结账号原因不能为空！');
      }
    }

    const result = await this.shopService.changePayeeAccountStatus(
      this.shopId as string,
      status,
      this.$el,
      this.reason || undefined,
    );
    if (!result) {
      return;
    }

    this.visible = false;
    this.reason = '';
    this.$emit('refresh');
  }
}
