
import lazyInject from '@/di';
import { Vue, Component, Watch } from 'vue-property-decorator';
import AuditService from '@/services/audit.service';
import { EAuditStatus, EAuditTemplate } from '@/common/enum/audit';
import ShopAudit from './components/ShopAudit.vue';
import SPUAudit from './components/SPUAudit.vue';
import AuditSteps from './components/AuditSteps.vue';
import SPUService from '@/services/spu.service';

@Component({
  components: {
    ShopAudit,
    SPUAudit,
    AuditSteps,
  },
})
export default class TaskDetail extends Vue {
  auditData: any = null; // 审核任务原始数据

  auditUpdateData: any = null;

  taskId = '';

  tplType: EAuditTemplate = EAuditTemplate.PUBLICK;

  shouldShowOriginalData = false;

  auditNode: Array<{
    auditStatus: EAuditStatus;
    auditNodeId: number;
    auditUserName: string;
    auditTime: string;
  }> = [];

  loading = false;

  EAuditTemplate = EAuditTemplate;

  readOnly = true;

  @lazyInject(AuditService)
  protected auditService!: AuditService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  back() {
    this.$router.go(-1);
  }

  createNode: {
    title: string;
    description: string;
  } = {
    title: '',
    description: '',
  };

  /**
   * 初始化查询
   */
  async init() {
    const res = await this.auditService.task(+this.taskId, this.$el);

    await this.$nextTick();
    if (res) {
      const {
        auditData,
        auditUpdateData,
        auditNode,
        tplType,
        createTime,
        creator,
        shouldShowOriginalData,
      } = res;

      /** @note 20240318迭代增加：商品参考价  */
      // if (tplType === EAuditTemplate.SHOP_SERVICE) {
      //   const indicativePrice = await this.spuService.indicativePrice({
      //     categoryId: auditUpdateData.category.id,
      //     shopId: auditUpdateData.shopId,
      //   });
      //   Object.assign(auditUpdateData, {
      //     indicativePrice: `${indicativePrice}` || '',
      //   });
      // }

      this.auditData = auditData;
      this.auditUpdateData = auditUpdateData;

      this.auditNode = auditNode;
      this.createNode = {
        title: '提交',
        description: `${creator} ${createTime}`,
      };
      this.tplType = tplType;
      this.shouldShowOriginalData = shouldShowOriginalData;
    }
  }

  @Watch('$route')
  async handleRoute() {
    this.taskId = `${this.$route.params.id}`;
  }

  mounted() {
    this.handleRoute();
    this.init();
  }
}
