
import lazyInject from '@/di';
import Permission from '@/models/permission.model';
import PermissionService from '@/services/permission.service';
import { Vue, Component, Ref } from 'vue-property-decorator';
import AddPermissionModal from './components/AddPermissionModal.vue';

@Component({
  components: {
    AddPermissionModal,
  },
})
export default class PermissionList extends Vue {
  addPermissionModalVisible = false;

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  list: Permission[] = [];

  @Ref()
  permissionModal!: AddPermissionModal;

  /**
   * 删除权限
   */
  async deletePermissionItem(id: string) {
    const result = await this.permissionService.deletePermission(id);

    if (result) {
      // 删除成功后刷新列表
      this.refresh();
    }
  }

  /**
   * 翻页
   */
  paginationChange(current: number) {
    this.pagination.current = current;
    this.getPermissionList();
  }

  showConfirm(permissionId: string) {
    this.$confirm({
      title: '您确定要删除该项权限吗？',
      onOk: () => this.deletePermissionItem(permissionId),
      onCancel: () => {},
    });
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };

    this.getPermissionList();
  }

  /**
   * 添加权限
   */
  add() {
    this.addPermissionModalVisible = true;
  }

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  async getPermissionList() {
    try {
      const { current, pageSize } = this.pagination;
      const data = await this.permissionService.permissionList({
        page: current,
        pageSize,
      });

      if (data) {
        const { page, total, list } = data;

        this.pagination = {
          total,
          pageSize,
          current: page,
          onChange: this.paginationChange,
        };
        this.list = list;
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  async close(toRefresh?: boolean) {
    if (toRefresh) {
      this.refresh();
    }
    this.addPermissionModalVisible = false;
    this.permissionModal.reset();
  }

  mounted() {
    this.getPermissionList();
  }
}
