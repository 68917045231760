
import { BASE_URL } from '@/common/constant';
import { EAgencyType, EShopType } from '@/common/enum/account';
import { IBlackCardAgencyOperation } from '@/common/interface/account';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import { AgencyOperationService } from '@/services/agency_operation.service';
import { AuthEnum } from '@/services/permission.service';
import UserService from '@/services/user.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Options } from '../shop/common/SPUForm';
import AddAgentStaffModal from './components/AddAgentStaff.vue';
import TransferShopModal from './components/TransferShopModal.vue';

interface IRAgencyOperation {
  id: string | number;
  uid: string | number;
  phone: number;
  userName: string;
  createTime: string;
  updateTime: string;
}

@Component({
  components: {
    'add-agent-staff-modal': AddAgentStaffModal,
    'transfer-shop-modal': TransferShopModal,
  },
})
export default class AgencyOperation extends Vue {
  AuthEnum = AuthEnum;

  EShopType = EShopType;

  filterForm: {
    userName: string;
    phone: string;
    uid: number | string;
  } = {
    userName: '',
    phone: '',
    uid: '',
  };

  pagination = {
    total: 0,
    pageSize: 30,
    current: 1,
    onChange: this.paginationChange,
  };

  agencyOperationList: IRAgencyOperation[] = [];

  BASE_URL = BASE_URL;

  options: Options = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/agent/import_city`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  token: string | undefined = undefined;

  uploadLoading = false;

  transferShop(param: {
    kaType: string;
    shopType: EShopType;
    agentStaff: IRAgencyOperation;
  }) {
    const { kaType, shopType, agentStaff } = param;
    this.transferShopModal?.open({
      kaType,
      shopType,
      uid: agentStaff.uid,
      name: agentStaff.userName,
    });
  }

  add() {
    this.addAgentStaffModal?.open();
  }

  agentColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
      width: 110,
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
      width: 180,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
    },
    {
      title: '年费门店\n不包含永久停业',
      customHeaderCell: () => {
        return {
          style: {
            whiteSpace: 'pre-wrap',
          },
        };
      },
      dataIndex: 'annualShopNum',
      key: 'annualShopNum',
      scopedSlots: { customRender: 'annualShopNum' },
      width: 100,
    },
    {
      title: '推广通门店\n不包含永久停业',
      customHeaderCell: () => {
        return {
          style: {
            whiteSpace: 'pre-wrap',
          },
        };
      },
      dataIndex: 'adCampaignShopNum',
      key: 'adCampaignShopNum',
      scopedSlots: { customRender: 'adCampaignShopNum' },
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
    },
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 200,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 130,
    },
  ];

  kaAgentColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
      width: 110,
    },
    {
      title: '代运营姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 120,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
    },
    {
      title: '品牌名称',
      dataIndex: 'kaName',
      key: 'kaName',
      width: 100,
    },
    {
      title: '年费门店\n不包含永久停业',
      customHeaderCell: () => {
        return {
          style: {
            whiteSpace: 'pre-wrap',
          },
        };
      },
      dataIndex: 'annualShopNum',
      key: 'annualShopNum',
      scopedSlots: { customRender: 'annualShopNum' },
      width: 100,
    },
    {
      title: '推广通门店\n不包含永久停业',
      customHeaderCell: () => {
        return {
          style: {
            whiteSpace: 'pre-wrap',
          },
        };
      },
      dataIndex: 'adCampaignShopNum',
      key: 'adCampaignShopNum',
      scopedSlots: { customRender: 'adCampaignShopNum' },
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
    },
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 200,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 130,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleUploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploadLoading = true;
    }
    if (info.file.status === 'error') {
      this.uploadLoading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      this.$message.success(`${data}`);
      this.uploadLoading = false;

      setTimeout(() => {
        this.refresh();
      }, 500);
    }
  }

  beforeUpload(file: { type: string; size: number }) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;

    const param = this.extractRouterQueryAndFillFilterForm();

    Object.assign(param, {
      pageSize,
      page: current,
    });

    if (this.currentTab === EAgencyType.代运营管理) {
      param.kaType = '0';
    } else if (this.currentTab === EAgencyType.品牌代运营管理) {
      param.kaType = '1';
    }

    await this.list(param);
  }

  @Ref('transferShopModal')
  transferShopModal?: TransferShopModal;

  @Ref('addAgentStaffModal')
  addAgentStaffModal?: AddAgentStaffModal;

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(AgencyOperationService)
  agencyOperationService!: AgencyOperationService;

  EAgencyType = EAgencyType;

  currentTab = EAgencyType.代运营管理;

  blackCardAgencyList: IBlackCardAgencyOperation[] = [];

  /** 切换代运营tab，保留搜索条件，重置第1页； */
  async tabChange(tab: EAgencyType) {
    if (tab === this.currentTab) {
      return;
    }

    this.currentTab = tab;
    this.agencyOperationList = [];
    this.pagination.current = 1;
    this.search();
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const query = this.extractRouterQueryAndFillFilterForm();
    Object.assign(query, { page: current });

    this.$router.push({ query });
  }

  extractRouterQueryAndFillFilterForm() {
    const { userName, phone, uid, type } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (typeof type !== 'undefined') {
      this.currentTab = +type as EAgencyType;
    }

    if (typeof userName !== 'undefined') {
      param.userName = userName;
      this.filterForm.userName = userName as string;
    }

    if (typeof phone !== 'undefined') {
      param.phone = phone;
      this.filterForm.phone = phone as string;
    }

    if (typeof uid !== 'undefined' && +uid !== -1) {
      param.uid = uid;
      this.filterForm.uid = +uid;
    }

    return param;
  }

  /** 手机号检测 */
  testPhoneValid(): boolean {
    return /1[3-9]\d{9}/.test(this.filterForm.phone);
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 30,
      current: 1,
      onChange: this.paginationChange,
    };

    const { userName, uid, phone } = this.filterForm;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
      type: this.currentTab,
    };

    if (userName) {
      query.userName = userName;
    }

    if (typeof uid !== 'undefined' && uid !== 0 && uid !== '') {
      query.uid = uid;
    }

    if (phone) {
      if (!this.testPhoneValid()) {
        this.$message.warn('手机格式不正确，请重新输入');
        return;
      }
      query.phone = phone;
    }
    query.timestamp = Math.round(Date.now() / 1000);

    this.$router.push({
      query,
    });
  }

  async list(params: {
    kaType: number;
    page: number;
    pageSize?: number;
    phone?: string;
    uid?: number;
    userName?: string;
  }) {
    const res = await this.agencyOperationService.list(params);
    if (res) {
      this.agencyOperationList = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  /**
   * 页面刷新
   */
  async refresh() {
    this.filterForm = {
      userName: '',
      phone: '',
      uid: '',
    };

    this.pagination = {
      total: 0,
      pageSize: 30,
      current: 1,
      onChange: this.paginationChange,
    };

    let needForceRefresh = false;
    const { query } = this.$route;
    if (Object.keys(query).length === 0) {
      needForceRefresh = true;
    }

    this.$router.push({
      query: {},
    });

    if (needForceRefresh) {
      await this.handleSearch();
    }
  }

  async created() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }

    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
    await this.handleSearch();
  }
}
