
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class ComboTotalPriceField extends Vue {
  val = {
    originalPriceCent: '--',
    newUserPriceCent: '--',
    vipPriceCent: '--',
  };

  @Prop()
  value!: {
    originalPriceCent: number;
    newUserPriceCent: number;
    vipPriceCent: number;
  };

  @Watch('value', { deep: true })
  watchValue() {
    if (this.value === undefined) {
      return;
    }
    if (this.value.newUserPriceCent !== undefined) {
      this.$set(
        this.val,
        'newUserPriceCent',
        this.value.newUserPriceCent !== 0
          ? (this.value.newUserPriceCent / 100).toFixed(2)
          : '--',
      );
    }

    if (this.value.vipPriceCent !== undefined) {
      this.$set(
        this.val,
        'vipPriceCent',
        (this.value.vipPriceCent / 100).toFixed(2),
      );
    }

    if (this.value.originalPriceCent !== undefined) {
      this.$set(
        this.val,
        'originalPriceCent',
        (this.value.originalPriceCent / 100).toFixed(2),
      );
    }
  }

  mounted() {
    this.watchValue();
  }
}
