import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import BIEmbedded from '@/views/bi/BIEmbedded.vue';
import Map from '@/views/bi/Map.vue';
import BiGoodsList from '@/views/bi/GoodsList.vue';
import { AuthEnum } from '@/services/permission.service';


const biRoutes: RouteConfig = {
  name: 'bi',
  path: '/bi',
  component: Main,
  props: (route) => {
    return {
      breadcrumbs: [
        {
          title: route.params.title,
          path: route.fullPath,
        },
      ],
    };
  },
  meta: {
    title: '报表管理',
  },
  children: [
    {
      name: 'bi.embedded',
      path: '/bi/embedded/:category/:title/:pageId',
      component: BIEmbedded,
    },
    {
      name: 'bi.map',
      path: '/bi/map/:regionid/:name',
      component: Map,
    },
    {
      name: 'bi.goods.list.c',
      path: '/goods/goodslist_c',
      component: BiGoodsList,
      props: {
        auth: [AuthEnum.BI商品报表],
      },
      meta: { title: '商品列表' },
    },
    {
      name: 'bi.lead.b.c',
      path: '/lead/big_b',
      component: BiGoodsList,
      props: {
        auth: [AuthEnum.BI商品报表],
      },
      meta: { title: '品牌加盟线索' },
    },
  ],
};

export default biRoutes;
