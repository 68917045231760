
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class MainBusinessField extends Vue {
  val: string[] = [];

  mainBizArray: Array<{ id: number; name: string }> = [];

  isReady = false;

  @Prop()
  placeholder?: string;

  @Model('change')
  value!: string[];

  @Watch('value')
  watchValue() {
    if (this.value.length) {
      const intersection = this.val.filter(
        (item) => this.value.indexOf(item) > -1,
      );
      if (!intersection.length || this.val.length !== this.value.length) {
        this.val = [...this.value];
      }
    } else {
      this.val = [];
    }
    if (!this.isReady) {
      this.isReady = true;
    }
  }

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  onChange(val: string) {
    this.val = [val];

    this.$emit('change', [val]);
    if (!this.val.length) {
      this.$emit('updateSubmittable', false);
    } else {
      this.$emit('updateSubmittable', true);
    }
  }

  async getAllMainBusiness() {
    const res = await this.shopService.allMainBusiness();
    if (res && res.length) {
      this.mainBizArray = res;
    }
  }

  async mounted() {
    await this.getAllMainBusiness();
    this.watchValue();
  }
}
