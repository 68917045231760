
import { DebounceTime } from '@/common/decorator';
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class OriginalPriceCentField extends Vue {
  val = '';

  @Prop()
  placeholder?: string;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled?: boolean;

  @Prop()
  refs!: any;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
  }

  @DebounceTime(320)
  onChange() {
    this.refs[0].onFieldChange();
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
