import { render, staticRenderFns } from "./ShopVideosField.vue?vue&type=template&id=32cfd2f3&scoped=true"
import script from "./ShopVideosField.vue?vue&type=script&lang=ts"
export * from "./ShopVideosField.vue?vue&type=script&lang=ts"
import style0 from "./ShopVideosField.vue?vue&type=style&index=0&id=32cfd2f3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32cfd2f3",
  null
  
)

export default component.exports