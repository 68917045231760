
import { Vue, Component, Ref } from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import { Options } from '../common/SPUForm';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { $delay } from '@/common/utils';
import { FormModel, Upload } from 'ant-design-vue';
import SPUService from '@/services/spu.service';

@Component({})
export default class BatchDeletePois extends Vue {
  options: Options = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  token: string | undefined = '';

  visible = false;

  BASE_URL = BASE_URL;

  uploadLoading = false;

  fileList: File[] = [];

  fileKey = Math.random();

  form = {
    pois: '',
  };

  rules = {
    pois: [{ required: true, message: 'POIID不能为空', trigger: 'blur' }],
  };

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(SPUService)
  spuService!: SPUService;

  @Ref()
  public readonly ruleForm!: FormModel;

  @Ref() readonly poiUpload!: Upload;

  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  beforeUploadPoi(file: { type: string; size: number }) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleUploadPoiIdChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploadLoading = true;
    }
    if (info.file.status === 'error') {
      this.uploadLoading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      this.uploadLoading = false;
      const { data } = JSON.parse(info.file.xhr.response);
      this.$message.success(`${data}`);
      this.$set(this.form, 'pois', data);
      this.fileList = [info.file];
    }
  }

  onRemove() {
    this.$set(this.form, 'pois', undefined);
    this.fileList = [];
    this.ruleForm.clearValidate('pois');
  }

  reset() {
    this.$nextTick(() => {
      this.visible = false;
      this.fileList = [];
      this.fileKey = Math.random();
      this.ruleForm.resetFields();
      this.uploadLoading = false;
    });
  }

  validatePois(pois: string) {
    const list = pois.split(',');

    for (let index = 0; index < list.length; index++) {
      const poi = list[index];
      if (!/[A-Z][A-Z0-9]{9}/.test(poi)) {
        this.$message.error(
          `门店poi 第${index + 1}个 - ${poi}格式不正确，请重新输入`,
        );
        return false;
      }
    }
    return true;
  }

  async submit() {
    this.ruleForm.validate(async (valid) => {
      if (valid && this.validatePois(this.form.pois)) {
        const resMsg = await this.spuService.platformSpusPoisBatchRemove(
          this.form.pois,
        );
        if (resMsg) {
          this.$message.success(`${resMsg}`);
          this.reset();
        }
      }
    });
  }

  mounted() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
