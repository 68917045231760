
import { IResAuditShopSpu } from '@/common/interface/spu';
import _ from 'lodash';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class ShopServiceField extends Vue {
  val: IResAuditShopSpu[] = [];

  columns = [
    {
      title: '商品图',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      ellipsis: true,
      scopedSlots: { customRender: 'headlineImage' },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: false,
    },
    {
      title: '分类',
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      scopedSlots: { customRender: 'category' },
    },
    {
      title: '服务次数',
      dataIndex: 'effectiveTimes',
      key: 'effectiveTimes',
      ellipsis: true,
    },
    {
      title: '是否热门',
      dataIndex: 'isHot',
      key: 'isHot',
      ellipsis: true,
      scopedSlots: { customRender: 'isHot' },
    },
    {
      title: '市场价',
      dataIndex: 'originalPriceCent',
      key: 'originalPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'originalPriceCent' },
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'vipPriceCent' },
    },
    {
      title: '新用户特价',
      dataIndex: 'newUserPriceCent',
      key: 'newUserPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'newUserPriceCent' },
    },
  ];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: IResAuditShopSpu[];

  @Watch('value')
  watchValue() {
    if (this.value.length) {
      this.val = _.cloneDeep(this.value);
    } else {
      this.val = [];
    }
  }

  mounted() {
    this.watchValue();
  }
}
