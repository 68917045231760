
import { EAuditStatus } from '@/common/enum/audit';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AuditSteps extends Vue {
  auditStatus = EAuditStatus;

  @Prop({
    default: () => {
      return {
        title: '',
        description: '',
      };
    },
  })
  createNode?: {
    title: string;
    description: string;
  };

  @Prop({
    default: () => {
      return [] as Array<{
        auditStatus: EAuditStatus;
        auditNodeId: number;
        auditUserName: string;
        auditTime: string;
      }>;
    },
  })
  auditNode?: Array<{
    auditStatus: EAuditStatus;
    auditNodeId: number;
    auditUserName: string;
    auditTime: string;
  }>;
}
