
import { City } from '@/common/interface';
import lazyInject from '@/di';
import ShopService, { BlackcardRankTypeEnum } from '@/services/shop.service';
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';
import PushSettingDrawer from './components/PushSettingDrawer.vue';

enum FranchiseTabEnum {
  线索统计 = 1,
  圈店发送 = 2,
}
const initPushConfig = {
  regionId: 110000,
  type: '',
  min: 0,
  max: 0,
};
@Component({
  components: {
    'push-setting-drawer': PushSettingDrawer,
  },
})
export default class Push extends Vue {
  uuid = v4;

  FranchiseTabEnum = FranchiseTabEnum;

  currentTab = FranchiseTabEnum.圈店发送;

  drawerVisible = false;

  blackcardNum = 0;

  filter: {
    regionId: number | string;
    navStartRank: string | number;
    navEndRank: string | number;
    callsStartRank: string | number;
    callsEndRank: string | number;
    orderStartRank: string | number;
    orderEndRank: string | number;
  } = {
    regionId: '',
    navStartRank: '',
    navEndRank: '',
    callsStartRank: '',
    callsEndRank: '',
    orderStartRank: '',
    orderEndRank: '',
  };

  pushable = true;

  citys: City[] = [];

  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customRender: (text: any, record: any, index: any) => `${index + 1}`,
    },
    {
      title: '门店名称',
      dataIndex: 'name',
      key: 'name',
      width: 240,
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      width: 240,
    },
    {
      title: '门店地址',
      dataIndex: 'address',
      key: 'address',
      width: 240,
    },
  ];

  listData: Array<{
    name: string;
    poi: string;
    address: string;
  }> = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  pushConfig: {
    regionId: number;
    type: BlackcardRankTypeEnum | string;
    min: number;
    max: number;
  } = {
    ...initPushConfig,
  };

  @Ref()
  drawer!: PushSettingDrawer;

  @lazyInject(ShopService)
  shopService!: ShopService;

  get navDisabled() {
    if (
      this.filter.callsStartRank ||
      this.filter.callsEndRank ||
      this.filter.orderStartRank ||
      this.filter.orderEndRank
    ) {
      return true;
    }
    return false;
  }

  get callDisabled() {
    if (
      this.filter.navStartRank ||
      this.filter.navEndRank ||
      this.filter.orderStartRank ||
      this.filter.orderEndRank
    ) {
      return true;
    }
    return false;
  }

  get orderDisabled() {
    if (
      this.filter.callsStartRank ||
      this.filter.callsEndRank ||
      this.filter.navStartRank ||
      this.filter.navEndRank
    ) {
      return true;
    }
    return false;
  }

  @Watch('$route')
  async handleSearch(ignoreRegionId?: boolean) {
    const { pageSize, current } = this.pagination;

    const {
      regionId,
      navStartRank,
      navEndRank,
      callsStartRank,
      callsEndRank,
      orderStartRank,
      orderEndRank,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: {
      pageSize: number;
      page: number;
      min: number;
      max: number;
      type: BlackcardRankTypeEnum | string;
      regionId: number;
    } = {
      pageSize,
      page: current,
      min: 0,
      max: 0,
      regionId: 0,
      type: '',
    };

    if (!regionId) {
      if (typeof ignoreRegionId === 'undefined') {
        this.$message.error('城市不能为空，请选择!');
      }

      return;
    }

    this.filter.regionId = +regionId;
    param.regionId = +regionId;
    await this.getCityBlackcardNum(+regionId);

    if (navStartRank) {
      this.filter.navStartRank = navStartRank as string;
      param.min = +navStartRank;
      param.type = BlackcardRankTypeEnum.NAV_PV;
    }
    if (navEndRank) {
      this.filter.navEndRank = navEndRank as string;
      param.max = +navEndRank;
      param.type = BlackcardRankTypeEnum.NAV_PV;
    }
    if (callsStartRank) {
      this.filter.callsStartRank = callsStartRank as string;
      param.min = +callsStartRank;
      param.type = BlackcardRankTypeEnum.TEL_NUM;
    }
    if (callsEndRank) {
      this.filter.callsEndRank = callsEndRank as string;
      param.max = +callsEndRank;
      param.type = BlackcardRankTypeEnum.TEL_NUM;
    }

    if (orderStartRank) {
      this.filter.orderStartRank = orderStartRank as string;
      param.min = +orderStartRank;
      param.type = BlackcardRankTypeEnum.ORDER_NUM;
    }
    if (orderEndRank) {
      this.filter.orderEndRank = orderEndRank as string;
      param.max = +orderEndRank;
      param.type = BlackcardRankTypeEnum.ORDER_NUM;
    }

    if (!param.type) {
      return;
    }

    await this.getCityBlackcardListByRank(param);
  }

  drawerClose(refresh = false) {
    this.drawerVisible = false;
    if (refresh) {
      /** @todo 刷新 */
    }
  }

  openPushSetting() {
    const {
      regionId,
      navStartRank,
      navEndRank,
      callsStartRank,
      callsEndRank,
      orderStartRank,
      orderEndRank,
    } = this.$route.query;
    if (!regionId) {
      return;
    }
    this.$set(this.pushConfig, 'regionId', regionId);
    if (navStartRank) {
      this.$set(this.pushConfig, 'min', navStartRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.NAV_PV);
    }
    if (navEndRank) {
      this.$set(this.pushConfig, 'max', navEndRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.NAV_PV);
    }
    if (callsStartRank) {
      this.$set(this.pushConfig, 'min', callsStartRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.TEL_NUM);
    }
    if (callsEndRank) {
      this.$set(this.pushConfig, 'max', callsEndRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.TEL_NUM);
    }
    if (orderStartRank) {
      this.$set(this.pushConfig, 'min', orderStartRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.ORDER_NUM);
    }
    if (orderEndRank) {
      this.$set(this.pushConfig, 'max', orderEndRank);
      this.$set(this.pushConfig, 'type', BlackcardRankTypeEnum.ORDER_NUM);
    }

    this.drawerVisible = true;
    // eslint-disable-next-line no-console
    console.log('确认推送， this.drawerVisible=====', this.drawer);
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const {
      regionId,
      navStartRank,
      navEndRank,
      callsStartRank,
      callsEndRank,
      orderStartRank,
      orderEndRank,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };

    if (regionId) {
      query.regionId = regionId;
    }

    if (navStartRank) {
      query.navStartRank = navStartRank;
    }
    if (navEndRank) {
      query.navEndRank = navEndRank;
    }

    if (callsStartRank) {
      query.callsStartRank = callsStartRank;
    }
    if (callsEndRank) {
      query.callsEndRank = callsEndRank;
    }

    if (orderStartRank) {
      query.orderStartRank = orderStartRank;
    }
    if (orderEndRank) {
      query.orderEndRank = orderEndRank;
    }

    this.$router.push({
      query,
    });
  }

  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const {
      regionId,
      navStartRank,
      navEndRank,
      callsStartRank,
      callsEndRank,
      orderStartRank,
      orderEndRank,
    } = this.filter;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };

    if (!regionId) {
      this.$message.error('城市不能为空，请重新选择');
      return;
    }

    query.regionId = regionId;

    if (navStartRank) {
      query.navStartRank = navStartRank;
    }
    if (navEndRank) {
      query.navEndRank = navEndRank;
    }

    if (callsStartRank) {
      query.callsStartRank = callsStartRank;
    }
    if (callsEndRank) {
      query.callsEndRank = callsEndRank;
    }

    if (orderStartRank) {
      query.orderStartRank = orderStartRank;
    }
    if (orderEndRank) {
      query.orderEndRank = orderEndRank;
    }

    if (!this.navDisabled) {
      if (
        !navStartRank ||
        !navEndRank ||
        Number(navStartRank) > Number(navEndRank)
      ) {
        this.$message.warning(
          '请设置导航最小值和最大值，且最大值必须大于最小值',
        );
        return;
      }
    }
    if (!this.callDisabled) {
      if (
        !callsStartRank ||
        !callsEndRank ||
        Number(callsStartRank) > Number(callsEndRank)
      ) {
        this.$message.warning(
          '请设置电话最小值和最大值，且最大值必须大于最小值',
        );
        return;
      }
    }
    if (!this.orderDisabled) {
      if (
        !orderStartRank ||
        !orderEndRank ||
        Number(orderStartRank) > Number(orderEndRank)
      ) {
        this.$message.warning(
          '请设置订单最小值和最大值，且最大值必须大于最小值',
        );
        return;
      }
    }

    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  async onCityChange(regionId: number) {
    if (typeof regionId !== 'number') {
      this.blackcardNum = 0;
      const query = { ...this.$route.query };
      if (query.regionId) {
        delete query.regionId;
      }
      this.$router.push({
        query,
      });
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      regionId,
    };
    this.$router.push({
      query,
    });
  }

  async getCityBlackcardNum(regionId: number) {
    const res = await this.shopService.getCityBlackcardNum(regionId);
    if (res) {
      this.blackcardNum = res;
    }
  }

  async getCityBlackcardListByRank(params: {
    regionId: number;
    type: BlackcardRankTypeEnum | string;
    min: number;
    max: number;
    page: number;
    pageSize: number;
  }) {
    const res = await this.shopService.getCityBlackcardListByRank(params);
    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: +res.pageSize,
        onChange: this.paginationChange,
      };
    }
  }

  mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.$set(this.pagination, 'current', +page);
    }
    this.handleSearch(true);
    this.getCityList();
  }
}
