
import {
  ShopProfileBusinessStatusEnum,
  ShopProfileDetail,
} from '@/common/interface/shop';
import lazyInject from '@/di';
import { AuthEnum } from '@/services/permission.service';
import ShopService from '@/services/shop.service';
import { Vue, Component, Ref } from 'vue-property-decorator';
import DyhStaffChange from './components/DyhStaffChange.vue';
import moment, { Moment } from 'moment';

@Component({
  components: {
    DyhStaffChange,
  },
})
export default class ShopProfile extends Vue {
  AuthEnum = AuthEnum;

  @Ref('dyhStaffChange')
  dyhStaffChange!: DyhStaffChange;

  @lazyInject(ShopService)
  shopService!: ShopService;

  shopId = -1;

  businessStatusEnum = ShopProfileBusinessStatusEnum;

  shopProfile: ShopProfileDetail | null = null;

  filter: {
    startDate: Moment | null;
    endDate: Moment | null;
  } = {
    startDate: null,
    endDate: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData: any[] = [];

  onChangeStartTime(value: Moment[]) {
    if (!value.length) {
      this.filter.startDate = null;
      this.filter.endDate = null;
      return;
    }

    this.$set(this.filter, 'startDate', moment(value[0]).format('YYYY-MM-DD'));
    this.$set(this.filter, 'endDate', moment(value[1]).format('YYYY-MM-DD'));
  }

  async search() {
    const param: {
      shopId: number;
      startDate?: string;
      endDate?: string;
    } = {
      shopId: this.shopId,
    };

    if (this.filter.startDate != null) {
      param.startDate = this.filter.startDate as unknown as string;
      param.endDate = this.filter.endDate as unknown as string;
    }

    const res = await this.shopService.fetchShopRawData(param);

    if (!res) {
      return;
    }

    this.rawData = [...res];
  }

  reset() {
    this.rawData = [];
    this.$set(this.filter, 'startDate', null);
    this.$set(this.filter, 'endDate', null);
  }

  /** 删除门店草稿 */
  async deleteShopDraft(shopId: number) {
    const res = await this.shopService.deleteShopDraft(shopId, this.$el);
    if (!res) {
      return;
    }

    this.$message.success(`${res}`);
    await this.getShopProfile();
  }

  /** 删除门店申请信息 */
  async deleteShopApplication(shopId: number) {
    const res = await this.shopService.deleteShopApplication(shopId, this.$el);
    if (!res) {
      return;
    }
    this.$message.success(`${res}`);
    await this.getShopProfile();
  }

  /** 同步到高德 */
  async syncToAmap(poiId: string) {
    const res = await this.shopService.syncToAmap(poiId);
    if (!res) {
      return;
    }

    this.$message.success(`${res}`);
    await this.getShopProfile();
  }

  /** 释放地推员 */
  async emptyDyhStaff(shopId: number) {
    const res = await this.shopService.emptyDyhStaff(shopId, this.$el);
    if (!res) {
      return;
    }

    this.$message.success(`${res}`);
    await this.getShopProfile();
  }

  /** 转移地推员 */
  openDyhStaffChange() {
    this.dyhStaffChange.open();
  }

  async getShopProfile() {
    const res = (await this.shopService.profile(
      this.shopId,
      this.$el,
    )) as ShopProfileDetail;

    this.shopProfile = res;
  }

  created() {
    this.shopId = +this.$route.params.id;
    this.getShopProfile();
  }
}
