
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class ParkNumField extends Vue {
  val = 0;

  parkNumArray = [
    ...['无', '1 ~ 5个', '6 ~ 10个', '11 ~ 20个', '20个以上'],
  ].map((item, k) => ({ value: k, label: item }));

  @Prop()
  placeholder?: string;

  @Model('change', {
    type: Number,
  })
  value!: number;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val);
    this.$emit('updateSubmittable', true);
  }

  @Watch('value')
  watchValue() {
    if (typeof this.value !== 'undefined') {
      this.val = this.value;
    } else {
      this.val = 0;
    }
  }

  mounted() {
    this.watchValue();
  }
}
