
import {
  IResPlatformSPU,
  ISPUCategory,
  ISPUField,
} from '@/common/interface/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import ShopService from '@/services/shop.service';
import { Modal } from 'ant-design-vue';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { PlatformSPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import { ESPUField } from '@/common/enum/spu';
import _ from 'lodash';
import SPUPreview from '@/components/spu/SPUPreview.vue';

@Component({
  components: {
    SPUPreview,
  },
})
export default class ShopPlatformSpus extends Vue {
  form: {
    name: string;
    spuId: string;
    cate?: string | number; // 二级类目
    fcate?: string | number; // 一级类目
  } = {
    name: '',
    spuId: '',
    cate: '',
    fcate: '',
  };

  listData: IResPlatformSPU[] = [];

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      scopedSlots: { customRender: 'name' },
      width: 240,
    },
    {
      title: '商品类目',
      dataIndex: 'category',
      key: 'category',
      scopedSlots: { customRender: 'category' },
      width: 120,
    },
    {
      title: '商品照片',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      scopedSlots: { customRender: 'headlineImage' },
      width: 80,
    },
    {
      title: '门市价',
      dataIndex: 'originalPriceCent',
      scopedSlots: { customRender: 'originalPriceCent' },
      width: 100,
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      scopedSlots: { customRender: 'vipPriceCent' },
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      scopedSlots: { customRender: 'createTime' },
      width: 180,
    },
    {
      title: '勾选报名',
      dataIndex: 'actions',
      fixed: 'right',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  shopId = -1;

  /** 分类数据 */
  secondCategoryList: ISPUCategory[] = [];

  allSpuCategory: ISPUCategory[] = [];

  loading = false;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const { name, spuId, cate, fcate } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };

    if (name) {
      this.form.name = name as string;
      param.name = name;
    }
    if (spuId) {
      this.form.spuId = spuId as string;
      param.spuId = spuId;
    }
    if (fcate) {
      this.form.fcate = +fcate;
      param.fcate = fcate;
    }
    if (cate) {
      this.form.cate = +cate;
      param.cate = cate;
    } else {
      this.form.cate = '';
    }
    await this.all(param);
  }

  async boundStatusChange(spuId: number, binding?: number) {
    const res = await this.spuService.platformSpuBoundStatusChange(
      this.shopId,
      spuId,
      binding && binding === 1 ? -1 : 1,
    );

    if (res) {
      this.$message.success(`${res},正在刷新...`);
      this.handleSearch();
    }
  }

  filterBySpuCategory() {
    if (Number(this.form.fcate) < 0) {
      return [];
    } else {
      for (const idx of this.allSpuCategory) {
        if (Number(this.form.fcate) === idx.id) {
          return idx.children;
        }
      }
    }
  }

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }

  onChangeCate(val: number) {
    this.$set(this.form, 'cate', val);
  }

  onChangeFcate(val: number) {
    this.$set(this.form, 'cate', '');
    if (val) {
      this.$set(this.form, 'fcate', val);
    }
  }

  async getAllBoundSpus() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      const res = await this.spuService.shopPlatformSpusBound(this.shopId);
      if (res) {
        this.info(res);
      }
    } catch (error) {
      this.loading = false;
    }
  }

  info(arr: Array<{ name: string }>) {
    const h = this.$createElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const list: any[] = [
      h('h4', '请核对门店报名信息，避免出现线下客诉。'),
      h('h5', '报名商品'),
    ];

    for (const item of arr) {
      list.push(h('p', `${item.name}`));
    }

    this.$info({
      title: '门店报名信息',
      content: h('div', {}, [...list]),
      onOk: () => {
        Modal.destroyAll();
        this.loading = false;
      },
    });
  }

  async all(params: {
    pageSize: number;
    page: number;
    name?: string;
    spuId?: string;
    fcate?: string | number;
    cat?: string | number;
  }) {
    if (this.shopId != null) {
      const res = await this.spuService.shopPlatformSpus(
        this.shopId,
        params,
        this.$el,
      );
      if (res) {
        this.listData = res.list;
        this.pagination = {
          total: res.total,
          current: res.page,
          pageSize: +res.pageSize,
          onChange: this.paginationChange,
        };
      }
    }
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, spuId, cate, fcate } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }
    if (fcate) {
      query.fcate = fcate;
    }
    if (cate) {
      query.cate = cate;
    }

    this.$router.push({
      query,
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, spuId, fcate, cate } = this.form;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }

    if (fcate && fcate !== -1) {
      query.fcate = fcate;
    }

    if (cate && cate !== -1) {
      query.cate = cate;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      spuId: '',
      cate: '',
      fcate: '',
    };
    this.$router.push({
      query: {},
    });
  }

  created() {
    this.shopId = +this.$route.params.shopId;
  }

  mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    this.handleSearch();
    this.getCategory();
  }

  editingSpu: PlatformSPU = new PlatformSPU();

  spuForm: { [key: string]: any } = {};

  spuConfig: ISPUField[] = [];

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'platformSpu',
  });

  @Ref()
  spuPreview!: SPUPreview;

  async previewSpu(id: number) {
    const data = await this.spuService.platformSpuDetail(`${id}`);

    if (data) {
      this.editingSpu = PlatformSPU.from(data);

      this.initSpuForm(this.editingSpu);
    }
  }

  onClosePreiew() {
    this.editingSpu = new PlatformSPU();
  }

  async initSpuForm(editingSpu: PlatformSPU) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };

    this.spuForm = { ...form };
    this.spuPreview.setup(await this.initSpuConfig(), this.spuForm);
  }

  async initSpuConfig() {
    const spu = { ...this.spuForm };
    const detail = _.cloneDeep(this.initSpuFields);

    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);
    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const headlineImageIdx = detail.findIndex(
      (item) => item.label === ESPUField.HEADLINE_IMAGE,
    );
    const hasNewUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.HAS_NEW_USER_PRICE,
    );
    const imagesIdx = detail.findIndex(
      (item) => item.label === ESPUField.IMAGES,
    );

    if (spu.id === 0) {
      // 新增品牌标品时允许更换是否套餐、允许更换ka选项
      detail[isComboIndex].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 新增品牌标品时不允许更改是否套餐的选项、不允许更换ka选项
      detail[isComboIndex].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    const indicativePrice = await this.queryIndicativePrice({
      categoryId: spu.category.id,
    });
    if (indicativePrice) {
      this.editingSpu.indicativePrice = indicativePrice;
      // eslint-disable-next-line standard/computed-property-even-spacing
      detail[
        vipPriceIdx
      ].extra = `参考价：该商品分类，同城均价¥${indicativePrice}`;
    } else {
      detail[vipPriceIdx].extra = '暂无参考价';
    }

    delete detail[hasNewUserPriceIdx].extra;
    delete detail[headlineImageIdx].extra;
    delete detail[imagesIdx].extra;
    if (spu.hasNewUserPrice === true) {
      detail[hasNewUserPriceIdx].isHidden = true;
    } else {
      detail[hasNewUserPriceIdx].isHidden = false;
    }

    return detail;
  }

  /** 查询类目参考价：平台商品取全国中位价，无需shopId */
  async queryIndicativePrice(param: { categoryId: number }) {
    return this.spuService.indicativePrice(param);
  }
}
