
import { Vue, Component } from 'vue-property-decorator';
import BaseBIPage from '../order/components/BaseBIPage.vue';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';

@Component({
  components: {
    BaseBIPage,
  },
})

export default class GoodsList extends Vue {
  @lazyInject(OrderService)
  orderService!: OrderService;

  path = '';

  async created() {
    const path = await this.orderService.BITable();
    if (!path) {
      return;
    }
    this.path = path;
  }
}
