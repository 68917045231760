
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import {
  City,
} from '@/common/interface/shop';
import ShopService from '@/services/shop.service';
import ExamineService from '@/services/examine.service';
import moment from 'moment';

@Component({})
export default class ExamineShop extends Vue {
  expandedRowKeys = [];

  citys: City[] = [];

  yareList = [
    '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034',
  ];

  moment = moment;

  dataYear: any = String(new Date().getFullYear());

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(ExamineService)
  examineService!: ExamineService;

  form: {
    name: string;
    regionId: string | number;
  } = {
      name: '',
      regionId: '',
    };

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };
  columns = [
    {
      title: '考核时间',
      dataIndex: 'research_month',
      key: 'research_month',
      scopedSlots: { customRender: 'research_month' },
    },
    {
      title: '发出问卷数',
      dataIndex: 'research_sum',
      key: 'research_sum',
      scopedSlots: { customRender: 'research_sum' },
    },
    {
      title: '完成问卷数',
      dataIndex: 'isWriteCount',
      key: 'isWriteCount',
      ellipsis: true,
      scopedSlots: { customRender: 'isWriteCount' },

    },
    {
      title: '满足',
      dataIndex: 'satisfyCount',
      key: 'satisfyCount',
      ellipsis: true,
      scopedSlots: { customRender: 'satisfyCount' },

    },
    {
      title: '满足百分比',
      dataIndex: 'satisfaction_rat',
      key: 'satisfaction_rat',
      ellipsis: true,
      scopedSlots: { customRender: 'satisfaction_rat' },
    },
  ];

  data = [];

  dataPram = {
    research_year: String(new Date().getFullYear()),
  };

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const { research_year } = this.$route.query;
    const param: any = {
      pageSize,
      page: current,
    };
    if (research_year) {
      this.dataPram.research_year = research_year as string;
      param.research_year = research_year as string;
    } else {
      this.dataPram.research_year = String(new Date().getFullYear());
      param.research_year = String(new Date().getFullYear());
    }
    console.log('ddd');
    this.getList(param);
  }

  async getList(params: {
    pageSize: number;
    page: number;
    research_year: any;
  }) {
    try {
      const response = (await this.examineService.getSearchSummary(params)) as any;
      if (response) {
        const list = response;
        list.forEach((i: any) => {
          console.log(this.dataPram.research_year);
          i.year = this.dataPram.research_year;
        });
        this.data = list;
      }
    } catch (error) {
      return null;
    }
  }
  goToShopDetail(val: any) {
    const routeUrl = this.$router.resolve({
      name: 'examine.summary',
      query: {
        research_year: val.year,
        research_month: val.research_month,
      },
    });
    window.open(routeUrl.href, '_self');
  }
  goToisWriteCount(val: any) {
    const routeUrl = this.$router.resolve({
      name: 'examine.summary',
      query: {
        research_year: val.year,
        research_month: val.research_month,
        is_write: `${1}`,
      },
    });
    window.open(routeUrl.href, '_self');
  }
  goTosatisfyCount(val: any) {
    const routeUrl = this.$router.resolve({
      name: 'examine.summary',
      query: {
        research_year: val.year,
        research_month: val.research_month,
        research_results: '1',
      },
    });
    window.open(routeUrl.href, '_self');
  }
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.dataPram = {
      research_year: String(new Date().getFullYear()),
    };
    const query: any = {
      research_year: String(new Date().getFullYear()),
      timestamp: Math.round(Date.now() / 1000),
    };
    this.dataYear = String(new Date().getFullYear());
    this.$router.push({
      query,
    });
  }

  search() {
    const query: any = {
      research_year: this.dataPram.research_year,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const query: any = {
      page: current,
    };
    this.$router.push({
      query,
    });
  }
  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  onYearChange(date: any, dateString: any) {
    this.dataPram.research_year = dateString;
    this.dataYear = dateString;
    // const query: any = {
    //   research_year: dateString,
    // };
    // this.$router.push({
    //   query,
    // });
  }

  mounted() {
    const query: any = {
      research_year: String(new Date().getFullYear()),
    };
    this.$router.push({
      query,
    });
    this.handleSearch();
  }
}
