
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import {
  LabelDetailForm,
  Label,
} from '@/common/interface/label';
import {
  LabelStatus,
  LabelTarget,
} from '@/common/enum/label';
import Editor from '@/components/Editor.vue';
import lazyInject from '@/di';
import LabelService from '@/services/label.service';

const initial_form_val = {
  name: undefined,
  id: undefined,
  target: undefined,
  status: undefined,
  condition: '',
};

@Component({
  components: {
    Editor,
  },
})
export default class LabelDetailDrawer extends Vue {
  @Prop()
  visible!: boolean;

  @Ref() readonly ruleForm!: FormModel;

  @lazyInject(LabelService)
  labelService!: LabelService;

  form: LabelDetailForm = {
    ...initial_form_val,
  };

  rules = {
    name: [
      { required: true, message: '请输入标签名称', trigger: '' },
    ],
    target: [
      { required: true, message: '请选择圈选目标', trigger: '' },
    ],
    status: [
      { required: true, message: '请选择状态', trigger: '' },
    ],
    condition: [
      { required: true, message: '请输入配置', trigger: '' },
      { validator: this.isConditionValid },
    ],
  };

  labelCol = { span: 6 };

  wrapperCol = { span: 14 };

  LabelTarget = LabelTarget;

  LabelStatus = LabelStatus;

  isConditionValid(
    rule: {[key: string]: string | (() => boolean)},
    condition: string,
    callback: (err?: string) => void,
  ) {
    try {
      JSON.parse(condition);
      callback();
    } catch (error) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('json格式不合法');
    }
  }

  submit() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        if (this.form.id) {
          const result = await this.labelService.updateDetail(
            this.form.id as unknown as number,
            this.form as unknown as Label,
          );
          if (result) {
            this.$message.success(result);
            this.$emit('close', true);
          }
        } else {
          const result = await this.labelService.create(
            this.form as unknown as Label,
            document.body,
          );
          if (result) {
            this.$message.success(result);
            this.$emit('close', true);
          }
        }
      }
    });
  }

  reset() {
    this.form = {
      ...initial_form_val,
    };
  }
}
