var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-layout',{attrs:{"refresh":_vm.refresh,"title":"团队列表"},scopedSlots:_vm._u([{key:"header-control",fn:function(){return [_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.newOrgModalVisible = true}}},[_vm._v("添加团队")])]},proxy:true},{key:"search-area",fn:function(){return [_c('a-form-model',{attrs:{"model":_vm.form,"layout":"inline"}},[_c('a-form-model-item',{attrs:{"label":"输入团队名称"}},[_c('a-input',{attrs:{"allowClear":"","placeholder":"请输入团队名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.search}},[_vm._v("搜索")])],1)],1)]},proxy:true},{key:"default",fn:function(){return [_c('a-list',{attrs:{"data-source":_vm.list,"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{key:"item.id",staticClass:"pd-t"},[_c('div',{staticClass:"title-lg m-r-xl"},[_vm._v(_vm._s(index + 1))]),_c('a-descriptions',{staticClass:"pd-t"},[_c('a-descriptions-item',[_vm._v(_vm._s(item.name))])],1),_c('a-divider',{staticClass:"action-divider",attrs:{"type":"vertical"}}),_c('div',{staticClass:"actions h-box f-c-v"},[_c('a',{staticClass:"ant-btn ant-btn-link",on:{"click":function($event){return _vm.$router.push({
                name: 'organization.detail',
                params: {
                  id: `${item.id}`,
                },
                query: {
                  name: `${item.name}`,
                },
              })}}},[_vm._v("查看团队详情")]),_c('a',{staticClass:"ant-btn ant-btn-link",on:{"click":function($event){return _vm.showConfirm(item.id)}}},[_vm._v("删除团队")])])],1)}}])}),_c('a-modal',{attrs:{"title":'新增团队',"visible":_vm.newOrgModalVisible,"width":500},on:{"cancel":_vm.closeNewOrgModal,"ok":_vm.submit}},[_c('a-form-model',{attrs:{"model":_vm.newOrganizationForm}},[_c('a-form-model-item',[_c('a-input',{attrs:{"loading":_vm.loading,"placeholder":"请输入团队名称","size":"large"},model:{value:(_vm.newOrganizationForm.name),callback:function ($$v) {_vm.$set(_vm.newOrganizationForm, "name", $$v)},expression:"newOrganizationForm.name"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }