
import { EShopType } from '@/common/enum/account';
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import { AgencyOperationService } from '@/services/agency_operation.service';
import ShopService from '@/services/shop.service';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class TransferShopModal extends Vue {
  loading: boolean = false;

  isVisible: boolean = false;

  form: {
    title: string;
    name: string; // 当前代运营名称
    uid: number | string; // 当前代运营uid
    toUid: number | string;
    kaType: string; // 小B：'0'; 大B：'1';
    shopType: EShopType;
  } = {
    title: '',
    name: '',
    uid: '',
    toUid: '',
    kaType: '0',
    shopType: EShopType.未知,
  };

  @lazyInject(AgencyOperationService)
  protected agencyOperationService!: AgencyOperationService;

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  async submit() {
    const { kaType, shopType, toUid, uid } = this.form;

    if (!toUid || toUid === '') {
      return;
    }

    if (this.loading) {
      return;
    }

    try {
      this.loading = true;

      const res = await this.agencyOperationService.transferShop({
        kaType,
        shopType,
        uid,
        toUid,
      });

      if (res) {
        this.$message.success(`${res}`);
        await $delay(500);
        this.$emit('refresh', true);
        this.isVisible = false;
        this.reset();
      }
      await $delay(500);
      this.loading = false;
    } catch (error) {
      // this.$message.info(`${error}`);
      this.loading = false;
    }
  }

  reset() {
    this.form = {
      title: '',
      name: '',
      uid: '',
      toUid: '',
      kaType: '0',
      shopType: EShopType.未知,
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open(param: {
    kaType: string;
    shopType: EShopType;
    uid: number | string;
    name: string;
  }) {
    Object.assign(this.form, param);
    let title = '';
    const { kaType, shopType } = param;
    if (kaType === '1') {
      title += '大B：';
    } else {
      title += '小B：';
    }
    if (shopType === EShopType.年费门店) {
      title += '转让年费门店';
    } else if (shopType === EShopType.推广通门店) {
      title += '转让推广通门店';
    }

    this.$set(this.form, 'title', title);

    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
  }
}
