import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import Push from '@/views/lead/Push.vue';

const leadRoutes: RouteConfig = {
  name: 'lead',
  path: '/lead',
  component: Main,
  redirect: {
    name: 'lead.push',
  },
  meta: {
    title: '品牌加盟线索',
  },
  children: [
    {
      name: 'lead.push',
      path: '/lead/push',
      component: Push,
      meta: {
        title: '圈店发送',
      },
    },
  ],
};

export default leadRoutes;
