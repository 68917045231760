
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';
import moment, { Moment } from 'moment';

@Component({})
export default class StartTimeField extends Vue {
  val: Moment | null = null;

  @Prop()
  placeholder?: string;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.val === null
      ? this.$emit('change', '')
      : this.$emit('change', moment(this.val).format('YYYY'));

    this.$emit('updateSubmittable', this.val !== null);
  }

  @Watch('value')
  watchValue() {
    if (!this.value || this.value === '') {
      this.val = null;
      return;
    }
    this.val = moment(this.value);
  }

  onPanelChange(val: Moment) {
    this.val = val;
  }

  mounted() {
    this.watchValue();
  }
}
