
import { Vue, Component, Prop } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';

@Component({})
export default class ShopTransfer extends Vue {
  @lazyInject(ShopService)
  shopService!: ShopService;

  visible = false;

  form = {
    phone: '',
    reason: '',
  };

  @Prop()
  shopId!: string;

  /** 确认 */
  confirm() {
    this.$warning({
      title: '请您确认，是否确定执行门店转让操作！',
      okText: '门店转让',
      onOk: () => {
        this.form.phone = '';
        this.form.reason = '';
        this.visible = true;
      },
      okType: 'danger',
    });
  }

  /** 转让 */
  async transfer() {
    if (!this.form.phone) {
      return this.$message.error('请输入手机号！');
    }
    if (!/1[3-9]\d{9}/.test(this.form.phone)) {
      return this.$message.error('手机号格式错误！');
    }
    if (!this.form.reason) {
      return this.$message.error('转让原因不能为空！');
    }
    const res = await this.shopService.transfer<string>(
      this.shopId,
      this.form.phone,
      this.form.reason,
    );
    if (!res) {
      return;
    }
    this.visible = false;
    this.$emit('refresh');
  }
}
