
/**
 * 圈选目标
 */
export enum LabelTarget {
  圈人 = 'USER',
  圈店 = 'SHOP',
  圈商品 = 'SPU',
  圈活动 = 'ACTIVITY',
}


export enum LabelStatus {
  在线 = 'on',
  离线 = 'off',
}

/**
 * 关联标签 圈选来源
 */
export enum SourceType {
  公告 = 'ANNOUCEMENT',
  推送 = 'PUSH',
  推广活动 = 'PROMOTION',
}
