
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import AuditService from '@/services/audit.service';
import {
  IAuditAggregation,
  IReqSubmitAuditParam,
} from '@/common/interface/audit';
import { EAuditOperation, EAuditTemplate } from '@/common/enum/audit';
import { serialAsync } from '@/common/utils';
import ShopAudit from './components/ShopAudit.vue';
import SPUAudit from './components/SPUAudit.vue';
import SPUService from '@/services/spu.service';

@Component({
  components: {
    ShopAudit,
    SPUAudit,
  },
})
export default class AuditDetail extends Vue {
  @Ref()
  auditDetailComponent!: ShopAudit | SPUAudit;

  auditData: any = null; // 审核任务原始数据

  loading = false;

  EAuditTemplate = EAuditTemplate;

  EAuditOperation = EAuditOperation;

  polymerizeId = '';

  projectId = '';

  auditAggregation: IAuditAggregation[] = [];

  readOnly = true;

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  /** 审核【通过/不通过】 */
  @lazyInject(AuditService)
  protected auditService!: AuditService;

  getToAuditParam(idx: number) {
    const { nodeId, taskId } = this.auditAggregation[idx];

    try {
      // eslint-disable-next-line standard/computed-property-even-spacing
      const toAuditData = (this.auditDetailComponent as any)[
        idx
      ].getToAuditData();
      if (toAuditData) {
        return {
          nodeId,
          auditStatus: EAuditOperation.PASS,
          auditData:
            this.auditData[taskId] != null
              ? this.auditData[taskId]
              : toAuditData,
          auditUpdateData: toAuditData,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  getToAuditListParam(idx: number) {
    let shouldBlock = false;
    const list: IReqSubmitAuditParam[] = [];
    if (idx === -1) {
      // 全部通过
      for (let i = 0; i < this.auditAggregation.length; i++) {
        const param = this.getToAuditParam(i);
        if (!param) {
          shouldBlock = true;
          break;
        }
        list.push(param);
      }
    } else {
      // 通过具体项
      const param = this.getToAuditParam(idx);
      if (!param) {
        shouldBlock = true;
      } else {
        list.push(param);
      }
    }
    if (shouldBlock) {
      return [];
    }
    return list;
  }

  // 审核通过
  async approve(idx: number) {
    const list = this.getToAuditListParam(idx);
    if (!list.length) {
      return;
    }

    try {
      const res = await serialAsync(list, this.submitAccept);
      if (res) {
        if (list.length === 1) {
          // 聚合多商品列表中，审核一项；
          this.$message.success(`${res}`);

          this.auditAggregation.splice(idx, 1);
          if (!this.auditAggregation.length) {
            this.closeWindow();
          }
          return;
        }

        const num = res.length;
        const taskIds: string[] = [];

        for (let index = 0; index < num; index++) {
          const item = res[index];
          if (item != null) {
            this.$message.success(
              `${list.length > 1 ? `第${index}项` : ''}${res[index]}`,
            );
            taskIds.push(this.auditAggregation[index].taskId);
          }
        }

        for (let taskIndex = 0; taskIndex < taskIds.length; taskIndex++) {
          const taskId = taskIds[taskIndex];

          for (let index = 0; idx < this.auditAggregation.length; index++) {
            if (taskId === this.auditAggregation[index].taskId) {
              this.auditAggregation.splice(index, 1);
              break;
            }
          }
        }

        if (!this.auditAggregation.length) {
          this.closeWindow();
        }
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  async submitAccept(acceptItem: IReqSubmitAuditParam) {
    // eslint-disable-next-line no-return-await
    return await this.auditService.submit(acceptItem, this.$el);
  }

  // 审核不通过
  isVisible = false;

  dismissForm = {
    toAuditItemIndex: -1,
    reason: '',
    nodeId: '',
    taskId: '',
  };

  close() {
    this.isVisible = false;
    this.dismissForm = {
      toAuditItemIndex: -1,
      reason: '',
      nodeId: '',
      taskId: '',
    };
  }

  open(idx: number) {
    this.$set(this.dismissForm, 'nodeId', this.auditAggregation[idx].nodeId);
    this.$set(this.dismissForm, 'taskId', this.auditAggregation[idx].taskId);
    this.$set(this.dismissForm, 'toAuditItemIndex', idx);
    this.isVisible = true;
  }

  async disapprove() {
    const { taskId, nodeId, reason, toAuditItemIndex } = this.dismissForm;
    const res = await this.auditService.submit(
      {
        auditData: this.auditData[taskId],
        auditStatus: EAuditOperation.DISMISS,
        auditReason: reason,
        nodeId,
      },
      this.$el,
    );

    if (res) {
      // 审核成功
      this.close();
      this.$message.success(`${res}`);
      this.auditAggregation.splice(toAuditItemIndex, 1);
      if (!this.auditAggregation.length) {
        this.closeWindow();
      }
    }
  }

  closeWindow() {
    window.opener = null;
    window.close();
  }

  /**
   * 初始化查询
   */
  async init() {
    const res = await this.auditService.auditAggregation(
      this.projectId,
      this.polymerizeId,
      this.$el,
    );

    await this.$nextTick();
    if (res) {
      const { auditData, auditTaskAggregation } = res;
      this.auditAggregation = auditTaskAggregation;
      this.auditData = auditData;
    }
  }

  @Watch('$route')
  async handleRoute() {
    const { polymerizeId } = this.$route.query;
    this.polymerizeId = `${polymerizeId}`;
    this.projectId = `${this.$route.params.id}`;
  }

  mounted() {
    this.handleRoute();
    this.init();
  }
}
