/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ECOrderRefundAction } from '@/common/enum/order';
import { ICOrder, IResCOrder } from '@/common/interface/order';

export class COrder implements ICOrder {
  remainNum?: string | undefined;

  buttonAction: ECOrderRefundAction[] = [];

  id = '';

  refundInfo?: {
    channel: string; // 线下退款方式
    transferOrderNum: string; //  线下转账流水单号
    refundAmount: string; // 退款金额
    refundTime: string; // 退款时间
    payee: string; // 退款账号
  };

  status = '';

  name = '';

  totalVerificationNum = 0;

  verifiedTimes = 0;

  payAmount = '';

  createTime = '';

  refundTime = '';

  payChannel = '';

  regionName = '';

  shopName = ' ';

  poi = '';

  skuid = '';

  remark = '';

  refundableAmount = '';

  refundProgress = '';

  type = '';

  comRatio='';

  commissionPrice='';

  goodsType='';

  realPrice='';

  static from(data: IResCOrder) {
    const order = new COrder();
    order.id = data.order_id;
    order.status = data.order_status;
    order.name = data.service_name;
    order.totalVerificationNum = data.tnum;
    order.verifiedTimes = data.cnum;
    order.payAmount = data.pay_price;
    order.createTime = data.create_time;
    order.refundTime = data.verify_time || '--';
    order.payChannel = data.pay_type;
    order.regionName = data.city;
    order.shopName = data.shop_name;
    order.poi = data.poi;
    order.skuid = data.sku_id;
    order.remark = data.notes;

    order.refundableAmount = data.freeze || '--';

    order.comRatio = data.com_ratio || '--';
    order.commissionPrice = data.commission_price || '--';
    order.goodsType = data.goods_type || '--';
    order.realPrice = data.real_price || '--';


    order.refundProgress = data.progress || '--';
    order.type = data.order_type;

    order.buttonAction = data.show_button;

    if (data.remain_num) {
      order.remainNum = data.remain_num;
    }

    if (data.refund_info && data.refund_info.transfer_order_num) {
      const { payee, refund_amount, refund_time, transfer_order_num, channel } =
        data.refund_info;
      order.refundInfo = {
        payee,
        channel,
        refundAmount: refund_amount,
        refundTime: refund_time,
        transferOrderNum: transfer_order_num,
      };
    }

    return order;
  }
}
