
/**
 * loading 的图标
 */
const indicator = `
  <div class="ant-spin ant-spin-spinning" >
    <i class="anticon anticon-loading">
      <svg viewBox="0 0 1024 1024" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true" class="anticon-spin">
        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z">
        </path>
      </svg>
    </i>
  </div>
`;

/**
 * 加载状态的宿主元素
 */
const loadingHosts = new Map();

/**
 * 加载
 */
export default class Loading {
  private $host: HTMLElement;

  public constructor(target: HTMLElement) {
    this.$host = target;
    this.$host.classList.add('loading__parent--relative');
  }

  /**
   * 开启
   */
  public loading() {
    // 获取宿主元素的加载信息
    const currentHost = loadingHosts.get(this.$host);
    // 如果不已经存在加载状态，则创建加载图标
    if (!currentHost) {
      const $indicator = document.createElement('div');
      $indicator.classList.add('loading-mask');
      $indicator.innerHTML = indicator;
      this.$host.appendChild($indicator);
      loadingHosts.set(this.$host, { loadings: 1, $indicator });
    } else { // 如果已经存在加载状态，则不再创建加载图标
      currentHost.loadings += 1;
    }
  }

  /**
   * 关闭
   */
  public close() {
    const currentHost = loadingHosts.get(this.$host);
    if (currentHost) {
      currentHost.loadings -= 1;

      if (currentHost.loadings === 0) {
        this.$host.classList.remove('loading__parent--relative');
        currentHost.$indicator.remove();
        loadingHosts.delete(this.$host);
      }
    }
  }
}

