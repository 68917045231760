import { EAuditProject } from '@/common/enum/audit';
import { IAuditTask } from '@/common/interface/audit';

export default class AuditTask implements IAuditTask {
  static from(data: { [key: string]: any }, projectID: EAuditProject) {
    const task = new AuditTask();
    const parsedData: { [key: string]: any } = {};

    for (const [k, v] of Object.entries(data)) {
      parsedData[k] = JSON.parse(JSON.stringify(v));
    }
    if (projectID === EAuditProject.审核入驻门店) {
      /** @note 店铺入驻审核时，前端需组装地图扎点信息，以及B端年费信息查看链接 */
      parsedData.mapLocation = {
        name: data.name,
        location: [data.latitude, data.longitude],
      };
      parsedData.bOrder = {
        poi: data.poi,
        name: data.name,
      };
    }

    Object.assign(task, parsedData);

    return task;
  }
}
