
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class TelsField extends Vue {
  val: string[] = [];

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('value')
  watchValue() {
    if (!this.value) {
      this.val = [];
    } else {
      this.val = this.value.split(',');
    }
  }

  mounted() {
    this.watchValue();
  }
}
