
import { EShopField } from '@/common/enum/shop';
import { IShopField } from '@/common/interface/shop';
import AddressField from '@/components/shop/fields/AddressField.vue';
import AreaField from '@/components/shop/fields/AreaField.vue';
import BOrderField from '@/components/shop/fields/BOrderField.vue';
import BossIdImagesField from '@/components/shop/fields/BossIdImagesField.vue';
import ManagerTelsField from '@/components/shop/fields/ManagerTelsField.vue';
import BossTelsField from '@/components/shop/fields/BossTelsField.vue';
import BusinessTimeField from '@/components/shop/fields/BusinessTimeField.vue';
import CommonField from '@/components/shop/fields/CommonField.vue';
import DataChedField from '@/components/shop/fields/DataChedField.vue';
import LatitudeField from '@/components/shop/fields/LatitudeField.vue';
import LongitudeField from '@/components/shop/fields/LongitudeField.vue';
import MainBusinessField from '@/components/shop/fields/MainBusinessField.vue';
import MapLocationField from '@/components/shop/fields/MapLocationField.vue';
import MechineCallbackDataField from '@/components/shop/fields/MechineCallbackDataField.vue';
import NameField from '@/components/shop/fields/NameField.vue';
import ParkNumField from '@/components/shop/fields/ParkNumField.vue';
import PayeeAccountFreezeStatusField from '@/components/shop/fields/PayeeAccountFreezeStatusField.vue';
import PoiField from '@/components/shop/fields/PoiField.vue';
import RegionField from '@/components/shop/fields/RegionField.vue';
import RestAreaField from '@/components/shop/fields/RestAreaField.vue';
import ShopIdField from '@/components/shop/fields/ShopIdField.vue';
import ShopLicenseField from '@/components/shop/fields/ShopLicenseField.vue';
import ShopServiceField from '@/components/shop/fields/ShopServiceField.vue';
import StartTimeField from '@/components/shop/fields/StartTimeField.vue';
import StationField from '@/components/shop/fields/StationField.vue';
import TelsField from '@/components/shop/fields/TelsField.vue';
import VideosField from '@/components/shop/fields/VideosField.vue';

import _ from 'lodash';

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ShopImagesField from '@/components/shop/fields/ShopImagesField.vue';

@Component({})
export default class ShopAudit extends Vue {
  toAuditForm: { [key: string]: any } = {};

  toAuditFormItems: IShopField[] = [];

  rawForm: { [key: string]: any } | null = null;

  initShopFields: IShopField[] = [
    {
      label: EShopField.SHOP_ID,
      labelName: '店铺ID',
      readonly: true,
    },
    {
      label: EShopField.NAME,
      labelName: '店铺名称',
    },
    {
      label: EShopField.POI,
      labelName: 'POIID',
      readonly: true,
    },
    {
      label: EShopField.MAIN_BUSINESS,
      labelName: '主营业务',
    },
    {
      label: EShopField.REGION,
      labelName: '省市区',
    },
    {
      label: EShopField.ADDRESS,
      labelName: '详细地址',
    },
    {
      label: EShopField.MAP_LOCATION,
      labelName: '地图位置',
    },
    {
      label: EShopField.LATITUDE,
      labelName: '纬度',
    },
    {
      label: EShopField.LONGITUDE,
      labelName: '经度',
    },
    {
      label: EShopField.START_TIME,
      labelName: '开业时间',
    },
    {
      label: EShopField.BUSINESS_TIME,
      labelName: '营业时间',
    },
    {
      label: EShopField.AREA,
      labelName: '营业面积',
    },
    {
      label: EShopField.TELS,
      labelName: '联系电话',
    },
    {
      label: EShopField.BOSS_TELS,
      labelName: '老板电话',
    },
    {
      label: EShopField.MANAGER_TELS,
      labelName: '经理电话',
    },
    {
      label: EShopField.DATA_CHED,
      labelName: '独立洗车位',
    },
    {
      label: EShopField.PARK_NUM,
      labelName: '免费停车位',
    },
    {
      label: EShopField.REST_AREA,
      labelName: '免费休息区',
    },
    {
      label: EShopField.STATION,
      labelName: '工位数量',
    },
    {
      label: EShopField.SHOP_LICENSE,
      labelName: '营业执照',
    },
    {
      label: EShopField.BOSS_ID_IMAGES,
      labelName: '身份证',
    },
    {
      label: EShopField.SHOP_IMAGES,
      labelName: '店铺图片',
    },
    {
      label: EShopField.VIDEOS,
      labelName: '实拍视频(审核)',
    },
    {
      label: EShopField.SHOP_SERVICE,
      labelName: '商品列表',
    },
    {
      label: EShopField.B_ORDER,
      labelName: 'B端年费信息',
    },
    {
      label: EShopField.MECHINE_CALLBACK_DATA,
      labelName: '支付状态',
    },
    {
      label: EShopField.CONTRACT,
      labelName: '合同',
    },
    {
      label: EShopField.BUSINESS_STATUS,
      labelName: '营业状态',
    },
    {
      label: EShopField.PAYEE_ACCOUNT_FRESSZE_STATUS,
      labelName: '收款账户状态',
    },
  ];

  @Prop({
    default: {},
  })
  readonly auditUpdateData!: { [key: string]: any };

  @Prop()
  readonly rawData!: { [key: string]: any };

  @Watch('auditUpdateData', { deep: true })
  watchAuditUpdateData() {
    if (!this.auditUpdateData) {
      this.toAuditForm = {};
      return;
    }

    this.toAuditForm = _.cloneDeep(this.auditUpdateData);
    this.toAuditFormItems = this.initShopConfig(this.toAuditForm);
  }

  @Watch('rawData')
  watchRawData() {
    if (!this.rawData) {
      this.rawForm = null;
      return;
    }

    this.rawForm = _.cloneDeep(this.rawData);
  }

  getComponent(type: EShopField) {
    switch (type) {
      case EShopField.ADDRESS: {
        return AddressField;
      }
      case EShopField.AREA: {
        return AreaField;
      }
      case EShopField.BOSS_ID_IMAGES: {
        return BossIdImagesField;
      }
      case EShopField.BOSS_TELS: {
        return BossTelsField;
      }
      case EShopField.BUSINESS_TIME: {
        return BusinessTimeField;
      }
      case EShopField.DATA_CHED: {
        return DataChedField;
      }
      case EShopField.LATITUDE: {
        return LatitudeField;
      }
      case EShopField.LONGITUDE: {
        return LongitudeField;
      }
      case EShopField.MAIN_BUSINESS: {
        return MainBusinessField;
      }
      case EShopField.MANAGER_TELS: {
        return ManagerTelsField;
      }
      case EShopField.MAP_LOCATION: {
        return MapLocationField;
      }
      case EShopField.MECHINE_CALLBACK_DATA: {
        return MechineCallbackDataField;
      }
      case EShopField.NAME: {
        return NameField;
      }
      case EShopField.B_ORDER: {
        return BOrderField;
      }
      case EShopField.PARK_NUM: {
        return ParkNumField;
      }
      case EShopField.PAYEE_ACCOUNT_FRESSZE_STATUS: {
        return PayeeAccountFreezeStatusField;
      }
      case EShopField.POI: {
        return PoiField;
      }
      case EShopField.REGION: {
        return RegionField;
      }
      case EShopField.REST_AREA: {
        return RestAreaField;
      }
      case EShopField.SHOP_ID: {
        return ShopIdField;
      }
      case EShopField.SHOP_IMAGES: {
        return ShopImagesField;
      }
      case EShopField.SHOP_LICENSE: {
        return ShopLicenseField;
      }
      case EShopField.SHOP_SERVICE: {
        return ShopServiceField;
      }
      case EShopField.START_TIME: {
        return StartTimeField;
      }
      case EShopField.STATION: {
        return StationField;
      }
      case EShopField.TELS: {
        return TelsField;
      }

      case EShopField.VIDEOS: {
        return VideosField;
      }

      default: {
        return CommonField;
      }
    }
  }

  initShopConfig(shopForm: { [key: string]: any }) {
    const form = _.cloneDeep(shopForm);
    const keys = Object.keys(form);
    const shopFields = _.cloneDeep(this.initShopFields);
    const shopKernel = shopFields.filter((item: IShopField) => {
      return keys.indexOf(item.label) > -1;
    });
    const fieldNames: string[] = shopFields.map((item) => {
      return item.label;
    });

    const diff = keys.filter((item: string) => fieldNames.indexOf(item) === -1);
    const detail: IShopField[] = [...shopKernel];
    if (diff.length) {
      for (const fieldName of diff) {
        detail.push({
          label: `${fieldName}` as EShopField,
          labelName: `${fieldName}`,
        });
      }
    }

    return detail;
  }

  getToAuditData() {
    const toAuditData = _.cloneDeep(this.auditUpdateData);
    if (typeof toAuditData.mapLocation !== 'undefined') {
      delete toAuditData.mapLocation;
    }
    if (typeof toAuditData.bOrder !== 'undefined') {
      delete toAuditData.bOrder;
    }
    return toAuditData;
  }

  mounted() {
    this.watchAuditUpdateData();
    this.watchRawData();
  }
}
