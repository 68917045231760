import { render, staticRenderFns } from "./ShopImagesField.vue?vue&type=template&id=4bbc1016&scoped=true"
import script from "./ShopImagesField.vue?vue&type=script&lang=ts"
export * from "./ShopImagesField.vue?vue&type=script&lang=ts"
import style0 from "./ShopImagesField.vue?vue&type=style&index=0&id=4bbc1016&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbc1016",
  null
  
)

export default component.exports