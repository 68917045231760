
import { DebounceTime } from '@/common/decorator';
import { ESPUField } from '@/common/enum/spu';
import { ISPUField } from '@/common/interface/spu';
import { FormModel } from 'ant-design-vue';
import { v4 } from 'uuid';
import { Vue, Component, Emit, Prop, Watch, Ref } from 'vue-property-decorator';
import AutoOffShelfTimeField from './fields/AutoOffShelfTimeField.vue';
import CategoryField from './fields/CategoryField.vue';
import ComboTotalPriceField from './fields/ComboTotalPriceField.vue';
import CommissionRatioField from './fields/CommissionRatioField.vue';
import EffectiveTimesField from './fields/EffectiveTimesField.vue';
import FilterTagField from './fields/FilterTagField.vue';
import HasNewUserPriceField from './fields/HasNewUserPriceField.vue';
import HeadlineImageField from './fields/HeadlineImageField.vue';
import HeadlineImagesField from './fields/HeadlineImagesField.vue';
import IdField from './fields/IdField.vue';
import ImagesField from './fields/ImagesField.vue';
import IntroductionField from './fields/IntroductionField.vue';
import IsAutoOffShelfField from './fields/IsAutoOffShelfField.vue';
import IsComboField from './fields/IsComboField.vue';
import IsDrawCommissionField from './fields/IsDrawCommissionField.vue';
import IsHotField from './fields/IsHotField.vue';
import KaField from './fields/KaField.vue';
import NameField from './fields/NameField.vue';
import NewUserPriceCentField from './fields/NewUserPriceCentField.vue';
import OriginalPriceCentField from './fields/OriginalPriceCentField.vue';
import ProductsField from './fields/ProductsField.vue';
import RemarkField from './fields/RemarkField.vue';
import ShortCommentField from './fields/ShortCommentField.vue';
import TempSpuShopIdsField from './fields/TempSpuShopIdsField.vue';
import TempSpuShopPoisField from './fields/TempSpuShopPoisField.vue';
import TyreSpecField from './fields/TyreSpecField.vue';
import TyreTagField from './fields/TyreTagField.vue';
import UnavailableTimeField from './fields/UnavailableTimeField.vue';
import VideosField from './fields/VideosField.vue';
import VipPriceCentField from './fields/VipPriceCentField.vue';

@Component({})
export default class SPUEditor extends Vue {
  isVisible = false;

  uuid = v4;

  rules: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any[];
  } = {};

  form: { [key: string]: any } | null = {};

  formItems: ISPUField[] = [];

  watchable = false;

  shouldReset = false;

  @Prop()
  title!: string;

  @Prop()
  spuType!: 'spu' | 'platformSpu' | 'kaSpu' | 'leadingSpu';

  @Ref()
  readonly ruleForm!: FormModel;

  @DebounceTime(300)
  @Watch('form', { deep: true })
  watchForm() {
    if (!this.watchable || !this.form) {
      return;
    }
    this.update();
  }
  @Emit('submit')
  submit() {
  console.log(this.form);
    return { ...this.form };
  }

  @Emit('update')
  update() {
    return { ...this.form };
  }

  onSave() {
    this.ruleForm.validate((valid) => {
      if (valid) {
        this.submit();
      }
    });
  }

  getComponent(type: ESPUField) {
    switch (type) {
      case ESPUField.AUTO_OFF_SHELF_TIME: {
        return AutoOffShelfTimeField;
      }
      case ESPUField.CATEGORY: {
        return CategoryField;
      }
      case ESPUField.COMBO_TOTAL_PRICE: {
        return ComboTotalPriceField;
      }
      case ESPUField.EFFECTIVE_TIMES: {
        return EffectiveTimesField;
      }
      case ESPUField.FILTER_TAG: {
        return FilterTagField;
      }
      case ESPUField.HAS_NEW_USER_PRICE: {
        return HasNewUserPriceField;
      }
      case ESPUField.HEADLINE_IMAGE: {
        return HeadlineImageField;
      }
      case ESPUField.HEADLINE_IMAGES: {
        return HeadlineImagesField;
      }
      case ESPUField.ID: {
        return IdField;
      }
      case ESPUField.IMAGES: {
        return ImagesField;
      }
      case ESPUField.INTRODUCTION: {
        return IntroductionField;
      }
      case ESPUField.IS_AUTO_OFF_SHELF: {
        return IsAutoOffShelfField;
      }
      case ESPUField.IS_COMBO: {
        return IsComboField;
      }
      case ESPUField.IS_DRAW_COMMISSION: {
        return IsDrawCommissionField;
      }
      case ESPUField.IS_HOT: {
        return IsHotField;
      }
      case ESPUField.KA: {
        return KaField;
      }
      case ESPUField.NAME: {
        return NameField;
      }
      case ESPUField.NEW_USER_PRICE_CENT: {
        return NewUserPriceCentField;
      }
      case ESPUField.ORIGINAL_PRICE_CENT: {
        return OriginalPriceCentField;
      }
      case ESPUField.PRODUCTS: {
        return ProductsField;
      }
      case ESPUField.REMAK: {
        return RemarkField;
      }
      case ESPUField.SHORT_COMMENT: {
        return ShortCommentField;
      }
      case ESPUField.TEMP_SPU_SHOP_IDS: {
        return TempSpuShopIdsField;
      }
      case ESPUField.TEMP_SPU_SHOP_POIS: {
        return TempSpuShopPoisField;
      }
      case ESPUField.TYRE_SPEC: {
        return TyreSpecField;
      }
      case ESPUField.TYRE_TAG: {
        return TyreTagField;
      }
      case ESPUField.UNAVAILABLE_TIME: {
        return UnavailableTimeField;
      }
      case ESPUField.VIDEOS: {
        return VideosField;
      }
      case ESPUField.VIP_PRICE_CENT: {
        return VipPriceCentField;
      }
      case ESPUField.COMMISSION_RATIO: {
        return CommissionRatioField;
      }
       default: {
        return null;
      }
    }
  }

  setup(
    fields: ISPUField[],
    spuForm: { [key: string]: any },
    shouldResetFields?: boolean,
  ) {
    this.setRulesAndFormItems(fields);
    this.watchable = false;
    this.form = { ...spuForm };

    setTimeout(() => {
      this.watchable = true;
    }, 320);

    if (shouldResetFields) {
      this.ruleForm.resetFields();
    }
  }

  setRulesAndFormItems(fields: ISPUField[]) {
    this.formItems = [...fields];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rules: any = {};
    for (const field of fields) {
      rules[field.label] =
        field.rule && field.rule.length ? field.rule : undefined;
    }

    const ruleKeys = Object.keys(rules);
    for (const key of ruleKeys) {
      if (rules[key] === undefined) {
        delete rules[key];
      }
    }
    this.rules = rules;
    this.open();
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  open() {
    this.isVisible = true;
  }

  reset() {
    this.form = null;
    this.formItems = [];
    this.ruleForm.resetFields();
    this.$emit('reset');
  }
}
