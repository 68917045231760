
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import PermissionService from '@/services/permission.service';

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class COrderListModal extends Vue {
  loading = false;

  src = '';

  visible = false;

  @Prop()
  shopId!: string;

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  @lazyInject(OrderService)
  orderService!: OrderService;

  async init() {
    if (!this.shopId) {
      this.close();
      return;
    }

    const token = await this.orderService.BIToken();
    if (!token) {
      return;
    }
    const baseSrc = `https://bi.aliyuncs.com/dashboard/view/pc.htm?pageId=83ea3edd-6806-4fff-b1a0-049961d074e2&qbi_version_param=2&param=%5B%7B"paramKey"%3A"shop_id"%2C"conditionList"%3A%5B%7B"operate"%3A"%3D"%2C"value"%3A"${this.shopId}"%7D%5D%2C"joinType"%3A"and"%7D%5D`;

    this.src = `${baseSrc}&accessToken=${token}`;
  }

  close() {
    this.visible = false;
  }

  async open() {
    await this.init();
    this.visible = true;
  }
}
