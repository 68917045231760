

export enum ActivityType {
  秒杀 = 1,
  折扣 = 2,
  一口价 = 3,
}

export enum ActivityStatus {
  上线 = 1,
  下线 = 2,
  已过期= 3
}

export enum SignupStatus {
  未报名 = 0,
  审核中 = 1,
  成功 = 2,
  失败 = 3,
  删除 = 4,
}

export enum ActivityTag {
  洗车 = 'car_wash',
  美容 = 'car_beauty',
  养护 = 'car_maintenance',
}
