
import { Vue, Component, Ref } from 'vue-property-decorator';
import { AuthEnum } from '@/services/permission.service';
import CopySPUModal from './components/CopySPUModal.vue';
import SPUTable from './components/SPUTable.vue';

@Component({
  components: {
    'copy-spu': CopySPUModal,
    SPUTable,
  },
})
export default class Goods extends Vue {
  copyVisible = false;

  AuthEnum = AuthEnum;

  needRefresh = false;

  @Ref()
  spuTable!: SPUTable;

  shopId = '-1';

  created() {
    const { shopId } = this.$route.params;
    this.shopId = shopId;
  }

  /** * 返回 */
  back() {
    this.$confirm({
      title: '确定要离开吗',
      content: '请确定当前商品信息是否已保存！',
      onOk: () =>
        // eslint-disable-next-line implicit-arrow-linebreak
        this.$router.push({
          name: 'main.shop.detail',
          params: {
            id: this.shopId as string,
          },
        }),
      onCancel: () => {},
    });
  }

  /** 刷新商品列表 */
  refreshGoods() {
    this.needRefresh = true;
    this.copyVisible = false;
  }

  add() {
    this.spuTable.add();
  }
}
