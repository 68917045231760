import {
  IResShopRunningReport,
  IWeeklyRunningReport,
  TWeekOnWeekBasisTrend,
} from '@/common/interface/shop';

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class ShopWeeklyRunningReport implements IWeeklyRunningReport {
  navNum = '';

  navNumWeekOnWeekBasis = '';

  navNumTrend = 'flat' as TWeekOnWeekBasisTrend;

  impressions = '';

  impressionsWeekOnWeekBasis = '';

  impressionsTrend = 'flat' as TWeekOnWeekBasisTrend;

  clicks = '';

  clicksWeekOnWeekBasis = '';

  clicksTrend = 'flat' as TWeekOnWeekBasisTrend;

  callNum = '';

  callNumWeekOnWeekBasis = '';

  callNumTrend = 'flat' as TWeekOnWeekBasisTrend;

  cOrderNum = '';

  cOrderNumWeekOnWeekBasis = '';

  cOrderNumTrend = 'flat' as TWeekOnWeekBasisTrend;

  gmv = '';

  gmvWeekOnWeekBasis = '';

  gmvTrend = 'flat' as TWeekOnWeekBasisTrend;

  comments = '';

  commentsWeekOnWeekBasis = '';

  commentsTrend = 'flat' as TWeekOnWeekBasisTrend;

  campaign = { costAmount: '', impressions: '', clicks: '' };

  static parseWeekOnWeekBasis(idx: string) {
    if (idx.indexOf('+') > -1) {
      return 'up';
    } else if (idx.indexOf('--') > -1) {
      return 'flat';
    } else {
      return 'down';
    }
  }

  static from(dataRes: IResShopRunningReport) {
    const data = dataRes.report_data;
    if (data == null) {
      return null;
    }

    const report = new ShopWeeklyRunningReport();

    report.navNum = data.nav_uv;

    report.navNumWeekOnWeekBasis = data.nav_contrast;

    report.navNumTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.nav_contrast,
    );

    report.impressions = data.search_uv;
    report.impressionsWeekOnWeekBasis = data.search_contrast;
    report.impressionsTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.search_contrast,
    );

    report.clicks = data.detail_uv;
    report.clicksWeekOnWeekBasis = data.detail_contrast;
    report.clicksTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.detail_contrast,
    );

    report.callNum = data.tel_num;
    report.callNumWeekOnWeekBasis = data.tel_contrast;
    report.callNumTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.tel_contrast,
    );

    report.cOrderNum = data.order_num;
    report.cOrderNumWeekOnWeekBasis = data.order_contrast;
    report.cOrderNumTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.order_contrast,
    );

    report.comments = data.comment_num;
    report.commentsWeekOnWeekBasis = data.comment_contrast;
    report.commentsTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.comment_contrast,
    );
    report.gmv = data.gmv;
    report.gmvWeekOnWeekBasis = data.gmv_contrast;
    report.gmvTrend = ShopWeeklyRunningReport.parseWeekOnWeekBasis(
      data.gmv_contrast,
    );
    report.campaign = {
      clicks: data.click,
      costAmount: data.cost,
      impressions: data.display,
    };

    return report;
  }
}
