
import { Vue, Component, Watch, Model } from 'vue-property-decorator';

@Component({})
export default class RestAreaField extends Vue {
  val = false;

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', this.val === true ? '有' : '无');
  }

  @Watch('value')
  watchValue() {
    this.val = this.value === '有';
  }

  mounted() {
    this.watchValue();
    this.$emit('updateSubmittable', true);
  }
}
