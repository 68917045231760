
import { $delay } from '@/common/utils';
import lazyInject from '@/di';
import PermissionService, {
  InterfaceOrganizationRoleRes,
} from '@/services/permission.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import AddRoleModal from './components/AddRoleModal.vue';
import AssignPermissionToRoleModal from './components/AssignPermissionToRoleModal.vue';
import OrgMemberModal from './components/OrgMemberModal.vue';

enum EnumOrgMemberModalMode {
  ADD_MEMBER = 'addMember',
  ADD_ROLE = 'addRole',
  DELETE_ROLE = 'deleteRole',
}

enum TabEnum {
  成员 = '成员',
  角色 = '角色',
}

@Component({
  components: {
    AddRoleModal,
    OrgMemberModal,
    AssignPermissionToRoleModal,
  },
})
export default class OrganizationDetail extends Vue {
  enumOrgMemberModalMode = EnumOrgMemberModalMode;

  tabEnum = TabEnum;

  currentTab: TabEnum = TabEnum.成员;

  title = '团队详情';

  addRoleModalVisible = false;

  async closeAddRoleModal(refresh = false) {
    if (!refresh) {
      this.addRoleModalVisible = false;
      return;
    }
    // 刷新团队角色和权限
    await this.getOrganizationRoleList();
    this.addRoleModalVisible = false;
    this.addRoleModal.reset();
  }

  openAddRoleModal() {
    this.addRoleModalVisible = true;
  }

  orgMemberModalConfig: {
    id: string;
    title: string;
    phone: string;
    orgRoleIds: number[];
  } = {
    id: '',
    title: '',
    phone: '',
    orgRoleIds: [],
  };

  orgMemberModalVisible = false;

  orgId = ''; // 团队id

  assignPermissionToRoleConfig: InterfaceOrganizationRoleRes = {
    roleName: '',
    roleID: -1,
    permission: [],
  };

  assignPermissionModalVisible = false;

  tabs: Array<{ name: TabEnum }> = [
    {
      name: TabEnum.成员,
    },
    {
      name: TabEnum.角色,
    },
  ];

  orgMember: Array<{
    id: string;
    name: string;
    phone: string;
    roles: Array<{
      id: string;
      name: string;
    }>;
  }> = [];

  organizationRoleList: InterfaceOrganizationRoleRes[] = [];

  @Ref()
  orgMemberModal!: OrgMemberModal;

  @Ref()
  addRoleModal!: AddRoleModal;

  /** 切换tab */
  tabChange(tabIndex: number) {
    if (this.currentTab === this.tabs[tabIndex].name) {
      return;
    }

    this.currentTab = this.tabs[tabIndex].name;
  }

  openOrgMemberModal(
    mode: EnumOrgMemberModalMode,
    orgMemberItem?: {
      id: string;
      name: string;
      phone: string;
      roles: Array<{
        id: string;
        name: string;
      }>;
    },
  ) {
    switch (mode) {
      case EnumOrgMemberModalMode.ADD_MEMBER:
        this.$set(this.orgMemberModalConfig, 'title', '添加成员');

        break;

      case EnumOrgMemberModalMode.ADD_ROLE:
        Object.assign(this.orgMemberModalConfig, {
          title: `给${
            orgMemberItem?.name
              ? orgMemberItem.name
              : `用户ID${orgMemberItem?.id}`
          }添加角色`,
          phone: orgMemberItem?.phone || '',
          orgRoleIds: orgMemberItem?.roles.map((role) => {
            return role.id;
          }),
          id: orgMemberItem?.id,
        });
        break;

      case EnumOrgMemberModalMode.DELETE_ROLE:
        Object.assign(this.orgMemberModalConfig, {
          title: `给${
            orgMemberItem?.name
              ? orgMemberItem.name
              : `用户ID${orgMemberItem?.id}`
          }删除角色`,
          phone: orgMemberItem?.phone || '',
          orgRoleIds: orgMemberItem?.roles.map((role) => {
            return role.id;
          }),
          id: orgMemberItem?.id,
        });
        break;

      default:
        break;
    }
    this.orgMemberModalVisible = true;
  }

  /** 给角色增加权限 */
  openAssignPermissionModal(item: InterfaceOrganizationRoleRes) {
    const { roleID, roleName, permission } = item;
    this.assignPermissionToRoleConfig = {
      roleID,
      roleName,
      permission,
    };
    this.assignPermissionModalVisible = true;
  }

  closeAssignPermissionModal() {
    this.assignPermissionModalVisible = false;
    this.resetAssignPermissionToRoleConfig();
  }

  resetAssignPermissionToRoleConfig() {
    this.assignPermissionToRoleConfig = {
      roleName: '',
      roleID: -1,
      permission: [],
    };
  }

  async deleteOrganizationRole(roleID: number) {
    try {
      const res = await this.permissionService.deleteRoleOfOrganization(
        roleID,
        this.orgId,
      );
      if (res) {
        this.$message.success(`${res}`);
        this.getOrganizationRoleList();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  async updateRolePermission() {
    await this.getOrganizationRoleList();
    this.closeAssignPermissionModal();
  }

  showConfirm(userId: string) {
    this.$confirm({
      title: '您确定要删除该成员吗?',

      onOk: () => this.deleteOrgMember(userId),
      onCancel: () => {},
    });
  }

  /**
   * 删除团队下某个成员
   */
  async deleteOrgMember(userId: string) {
    try {
      const res = await this.permissionService.deleteOrgMember(
        this.orgId,
        userId,
      );
      if (res === null) {
        // 删除成功, 刷新页面
        this.initOrgDetail();
        this.$message.success('删除成员成功');
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  back() {
    this.$router.go(-1);
  }

  refresh() {
    this.initOrgDetail();
  }

  resetOrgMemberModalConfig() {
    this.orgMemberModalConfig = {
      id: '',
      title: '',
      phone: '',
      orgRoleIds: [],
    };
  }

  /**
   * 关闭添加成员modal
   */
  async closeOrgMemberModal(refresh: false) {
    this.orgMemberModalVisible = false;

    if (refresh) {
      this.initOrgDetail();
    }
    await $delay(500);
    this.resetOrgMemberModalConfig();
    this.orgMemberModal.reset();
  }

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  /**
   * 获取团队的角色列表
   * 团队详情页-权限 tab 展示
   */
  async getOrganizationRoleList() {
    try {
      const res = await this.permissionService.getOrganizationRoleList(
        this.orgId,
      );
      if (res) {
        this.organizationRoleList = res;
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  /**
   * 获取团队成员
   */
  async getOrgMemberList() {
    try {
      const res = await this.permissionService.getOrgMemberList(this.orgId);
      if (res) {
        this.orgMember = res;
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  @Watch('$route')
  async handleRoute() {
    const { name } = this.$route.query;
    this.title = `${name}`;
    this.orgId = `${this.$route.params.id}`;
  }

  async initOrgDetail() {
    await this.getOrgMemberList();
    await this.getOrganizationRoleList();
  }

  mounted() {
    this.handleRoute();
    this.initOrgDetail();
  }
}
