import { DirectiveOptions } from 'vue';

const downloadDirective: DirectiveOptions = {
  async inserted(el, binding) {
    el.addEventListener('click', () => {
      const link = document.createElement('a');
      const url = binding.value;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          link.download = '';
          document.body.appendChild(link);
          link.click();
        });
    });
  },
};

export default downloadDirective;
